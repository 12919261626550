// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { supabase } from '../../supabaseConfig';
// import {
//     X,
//     Sparkles,
//     AlertCircle,
//     CheckCircle2,
//     ArrowRight,
//     Building
// } from 'lucide-react';

// const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
// const isDev = process.env.NODE_ENV === 'development';

// const saveInterviewToSupabase = async (interviewData) => {
//     try {
//         const effectiveUserId = isDev ?
//             (interviewData.userId || DEV_FALLBACK_UUID) :
//             interviewData.userId;

//         const { data, error } = await supabase
//             .from('interview_sessions')
//             .insert([{
//                 session_id: interviewData.sessionId,
//                 user_id: effectiveUserId,
//                 company_name: interviewData.company,
//                 job_description: interviewData.jobDescription,
//                 interview_language: interviewData.language,
//                 special_instructions: interviewData.specialInstructions,
//                 simple_english: interviewData.simpleEnglish,
//                 generate_summary: interviewData.generateSummary,
//                 status: 'created',
//                 is_trial: isDev || !interviewData.isPremium,
//                 session_metadata: {
//                     clientTimestamp: new Date().toISOString(),
//                     browserInfo: navigator.userAgent,
//                     isDevelopment: isDev
//                 }
//             }])
//             .select();

//         if (error) throw error;
//         return data;
//     } catch (error) {
//         console.error('Error saving to Supabase:', error);
//         if (isDev) {
//             return [{
//                 id: `dev_${Math.random().toString(36).substr(2, 9)}`,
//                 session_id: interviewData.sessionId,
//                 ...interviewData
//             }];
//         }
//         throw error;
//     }
// };

// const StepIndicator = ({ currentStep }) => (
//     <div className="flex items-center justify-center gap-2 mb-6">
//         {[1, 2].map((step) => (
//             <div
//                 key={step}
//                 className={`w-2.5 h-2.5 rounded-full ${step === currentStep ? 'bg-orange-500' : 'bg-gray-200'
//                     }`}
//             />
//         ))}
//     </div>
// );

// const CreateInterviewModal = ({ isOpen, onClose, onComplete }) => {
//     const [step, setStep] = useState(1);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [formData, setFormData] = useState({
//         company: '',
//         jobDescription: '',
//         language: 'English',
//         specialInstructions: '',
//         simpleEnglish: false,
//         generateSummary: true
//     });

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (step === 1) {
//             setStep(2);
//             return;
//         }

//         setIsSubmitting(true);
//         try {
//             const sessionId = `session_${Math.random().toString(36).substr(2, 9)}`;
//             const interviewData = {
//                 sessionId,
//                 userId: isDev ? DEV_FALLBACK_UUID : undefined,
//                 ...formData,
//                 timestamp: new Date().toISOString()
//             };

//             // Save to Supabase
//             const savedData = await saveInterviewToSupabase(interviewData);

//             // Trigger transition to setup flow
//             onComplete({
//                 ...interviewData,
//                 dbSession: savedData?.[0]
//             });
//         } catch (error) {
//             console.error('Error:', error);
//             if (!isDev) {
//                 alert('Error creating interview. Please try again.');
//             }
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     if (!isOpen) return null;

//     return (
//         <AnimatePresence>
//             <motion.div
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 exit={{ opacity: 0 }}
//                 className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
//             >
//                 <motion.div
//                     initial={{ scale: 0.95, opacity: 0 }}
//                     animate={{ scale: 1, opacity: 1 }}
//                     exit={{ scale: 0.95, opacity: 0 }}
//                     className="bg-white rounded-lg w-full max-w-lg overflow-hidden"
//                 >
//                     {/* Header */}
//                     <div className="flex items-center justify-between p-6 border-b">
//                         <div>
//                             <h2 className="text-xl font-semibold">Create Interview Session</h2>
//                             <p className="text-sm text-gray-500 mt-1">
//                                 {step === 1 ? 'Interview Details' : 'Customize Experience'}
//                             </p>
//                         </div>
//                         <button
//                             onClick={onClose}
//                             disabled={isSubmitting}
//                             className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                         >
//                             <X className="w-5 h-5" />
//                         </button>
//                     </div>

//                     <StepIndicator currentStep={step} />

//                     {/* Form */}
//                     <form onSubmit={handleSubmit} className="p-6 space-y-4">
//                         {step === 1 ? (
//                             <>
//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Company</label>
//                                     <div className="relative">
//                                         <Building className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
//                                         <input
//                                             type="text"
//                                             value={formData.company}
//                                             onChange={(e) => setFormData(prev => ({
//                                                 ...prev,
//                                                 company: e.target.value
//                                             }))}
//                                             className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                             placeholder="Enter company name"
//                                             required
//                                             disabled={isSubmitting}
//                                         />
//                                     </div>
//                                 </div>

//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Job Description</label>
//                                     <textarea
//                                         value={formData.jobDescription}
//                                         onChange={(e) => setFormData(prev => ({
//                                             ...prev,
//                                             jobDescription: e.target.value
//                                         }))}
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                         placeholder="Software Engineer with Python, SQL, AWS..."
//                                         rows={3}
//                                         required
//                                         disabled={isSubmitting}
//                                     />
//                                 </div>

//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Interview Language</label>
//                                     <select
//                                         value={formData.language}
//                                         onChange={(e) => setFormData(prev => ({
//                                             ...prev,
//                                             language: e.target.value
//                                         }))}
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
//                                         disabled={isSubmitting}
//                                     >
//                                         <option value="English">English</option>
//                                         <option value="Spanish">Spanish</option>
//                                         <option value="French">French</option>
//                                         <option value="German">German</option>
//                                         <option value="Chinese">Chinese</option>
//                                     </select>
//                                 </div>
//                             </>
//                         ) : (
//                             <>
//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Special Instructions</label>
//                                     <textarea
//                                         value={formData.specialInstructions}
//                                         onChange={(e) => setFormData(prev => ({
//                                             ...prev,
//                                             specialInstructions: e.target.value
//                                         }))}
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                         placeholder="E.g., Be more technical, focus on system design..."
//                                         rows={3}
//                                         disabled={isSubmitting}
//                                     />
//                                 </div>

//                                 <div className="space-y-4">
//                                     <label className="relative inline-flex items-center cursor-pointer">
//                                         <input
//                                             type="checkbox"
//                                             checked={formData.simpleEnglish}
//                                             onChange={(e) => setFormData(prev => ({
//                                                 ...prev,
//                                                 simpleEnglish: e.target.checked
//                                             }))}
//                                             className="sr-only peer"
//                                             disabled={isSubmitting}
//                                         />
//                                         <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
//                                         <span className="ml-3 text-sm font-medium">Use Simple English</span>
//                                     </label>

//                                     <label className="relative inline-flex items-center cursor-pointer">
//                                         <input
//                                             type="checkbox"
//                                             checked={formData.generateSummary}
//                                             onChange={(e) => setFormData(prev => ({
//                                                 ...prev,
//                                                 generateSummary: e.target.checked
//                                             }))}
//                                             className="sr-only peer"
//                                             disabled={isSubmitting}
//                                         />
//                                         <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
//                                         <span className="ml-3 text-sm font-medium">Generate AI Summary</span>
//                                     </label>
//                                 </div>

//                                 {/* Settings Summary */}
//                                 <div className="mt-4 p-4 bg-orange-50 rounded-lg">
//                                     <div className="flex items-start gap-3">
//                                         <CheckCircle2 className="w-5 h-5 text-orange-500 mt-0.5" />
//                                         <div>
//                                             <h4 className="font-medium text-orange-800">Interview Settings</h4>
//                                             <div className="mt-1 text-sm text-orange-700 space-y-1">
//                                                 <p>{formData.company} - {formData.language}</p>
//                                                 {formData.simpleEnglish && (
//                                                     <p>• Using simplified English</p>
//                                                 )}
//                                                 {formData.generateSummary && (
//                                                     <p>• Will generate interview summary</p>
//                                                 )}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </>
//                         )}

//                         {/* Helper Text */}
//                         <div className="p-4 bg-blue-50 rounded-lg">
//                             <div className="flex items-start gap-3">
//                                 <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                 <div>
//                                     <h4 className="font-medium text-blue-800">Important Note</h4>
//                                     <p className="mt-1 text-sm text-blue-600">
//                                         {step === 1
//                                             ? 'Provide accurate details to help personalize your interview experience.'
//                                             : 'You can adjust these settings during the interview if needed.'}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Action Buttons */}
//                         <div className="pt-4 border-t flex justify-between">
//                             {step === 2 ? (
//                                 <button
//                                     type="button"
//                                     onClick={() => setStep(1)}
//                                     disabled={isSubmitting}
//                                     className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                 >
//                                     Back
//                                 </button>
//                             ) : (
//                                 <button
//                                     type="button"
//                                     onClick={onClose}
//                                     disabled={isSubmitting}
//                                     className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                 >
//                                     Cancel
//                                 </button>
//                             )}
//                             <button
//                                 type="submit"
//                                 disabled={isSubmitting}
//                                 className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors inline-flex items-center gap-2"
//                             >
//                                 {isSubmitting ? (
//                                     <span>Processing...</span>
//                                 ) : step === 1 ? (
//                                     <>
//                                         Next
//                                         <ArrowRight className="w-4 h-4" />
//                                     </>
//                                 ) : (
//                                     'Start Interview'
//                                 )}
//                             </button>
//                         </div>
//                     </form>
//                 </motion.div>
//             </motion.div>
//         </AnimatePresence>
//     );
// };
// export default CreateInterviewModal;

// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { supabase } from '../../supabaseConfig';
// import {
//     X,
//     AlertCircle,
//     CheckCircle2,
//     ArrowRight,
//     Building,
//     Upload,
//     FileText,
//     Loader2
// } from 'lucide-react';

// const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
// const isDev = process.env.NODE_ENV === 'development';

// /**
//  * Inserts a new row into `interview_sessions_prod`
//  * with matching column names.
//  */
// const saveInterviewToSupabase = async (interviewData) => {
//     try {
//         // Fallback user_id for local/dev
//         const effectiveUserId = isDev
//             ? (interviewData.userId || DEV_FALLBACK_UUID)
//             : interviewData.userId;

//         // Insert into interview_sessions_prod
//         const { data, error } = await supabase
//             .from('interview_sessions_prod')
//             .insert([{
//                 session_id: interviewData.sessionId,
//                 user_id: effectiveUserId,
//                 company_name: interviewData.company,
//                 job_description: interviewData.jobDescription,
//                 interview_language: interviewData.language,
//                 special_instructions: interviewData.specialInstructions,
//                 simple_english: interviewData.simpleEnglish,
//                 generate_summary: interviewData.generateSummary,
//                 resume_text: interviewData.resumeText,
//                 resume_id: interviewData.resumeId,
//                 status: 'created',
//                 is_trial: isDev || !interviewData.isPremium,
//                 session_metadata: {
//                     clientTimestamp: new Date().toISOString(),
//                     browserInfo: navigator.userAgent,
//                     isDevelopment: isDev
//                 }
//                 // Other optional columns like resume_summary, resume_highlights,
//                 // interview_summary, key_insights, etc. can be added here if you wish.
//             }])
//             .select();

//         if (error) throw error;
//         return data;
//     } catch (error) {
//         console.error('Error saving to Supabase:', error);
//         // In dev mode, return a mock row if Supabase fails
//         if (isDev) {
//             return [{
//                 id: `dev_${Math.random().toString(36).substr(2, 9)}`,
//                 session_id: interviewData.sessionId,
//                 ...interviewData
//             }];
//         }
//         throw error;
//     }
// };

// /** 
//  * Simple step indicator to show 1 -> 2 -> 3 
//  */
// const StepIndicator = ({ currentStep }) => (
//     <div className="flex items-center justify-center gap-2 mb-6">
//         {[1, 2, 3].map((step) => (
//             <div
//                 key={step}
//                 className={`w-2.5 h-2.5 rounded-full ${step === currentStep ? 'bg-orange-500' : 'bg-gray-200'
//                     }`}
//             />
//         ))}
//     </div>
// );

// const ResumeCard = ({ resume, isSelected, onSelect }) => (
//     <div
//         onClick={onSelect}
//         className={`p-4 border rounded-lg cursor-pointer transition-all ${isSelected
//                 ? 'border-orange-500 bg-orange-50'
//                 : 'border-gray-200 hover:border-orange-300'
//             }`}
//     >
//         <div className="flex items-start gap-3">
//             <FileText
//                 className={`w-5 h-5 ${isSelected ? 'text-orange-500' : 'text-gray-400'
//                     }`}
//             />
//             <div className="flex-1">
//                 <h4 className="font-medium text-gray-900">
//                     {resume.candidate_name || resume.file_name}
//                 </h4>
//                 <p className="text-sm text-gray-500 mt-1">
//                     {resume.company && `${resume.company} - `}
//                     {resume.role || 'No role specified'}
//                 </p>
//                 <p className="text-xs text-gray-400 mt-1">
//                     Uploaded {new Date(resume.created_at).toLocaleDateString()}
//                 </p>
//             </div>
//             {isSelected && <CheckCircle2 className="w-5 h-5 text-orange-500" />}
//         </div>
//     </div>
// );

// /**
//  * This modal shows a 3-step flow to create a new interview session in `interview_sessions_prod`.
//  */
// const CreateInterviewModal = ({ isOpen, onClose, onComplete }) => {
//     const [step, setStep] = useState(1);
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [isLoadingResumes, setIsLoadingResumes] = useState(true);
//     const [resumes, setResumes] = useState([]);
//     const [selectedResumeId, setSelectedResumeId] = useState(null);
//     const [isUploadingResume, setIsUploadingResume] = useState(false);
//     const [uploadProgress, setUploadProgress] = useState(0);
//     const [error, setError] = useState(null);

//     // Form data for the new interview
//     const [formData, setFormData] = useState({
//         company: '',
//         jobDescription: '',
//         language: 'English',
//         specialInstructions: '',
//         simpleEnglish: false,
//         generateSummary: true,
//         resumeText: '',
//         resumeId: null
//     });

//     // Load existing resumes from 'resume_uploads'
//     useEffect(() => {
//         const fetchResumes = async () => {
//             try {
//                 const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;
//                 const { data, error } = await supabase
//                     .from('resume_uploads')
//                     .select('*')
//                     .eq('uploaded_by', userId)
//                     .eq('status', 'completed')
//                     .order('created_at', { ascending: false });

//                 if (error) throw error;
//                 setResumes(data || []);
//             } catch (err) {
//                 console.error('Error fetching resumes:', err);
//                 setError('Failed to load resumes');
//             } finally {
//                 setIsLoadingResumes(false);
//             }
//         };

//         if (isOpen) {
//             void fetchResumes();
//         }
//     }, [isOpen]);

//     // Subscribe to resume upload events via Realtime
//     useEffect(() => {
//         if (!isUploadingResume || !selectedResumeId) return;

//         // Listen for updates to the 'resume_uploads' table
//         const channel = supabase.channel('resume_upload_status')
//             .on(
//                 'postgres_changes',
//                 {
//                     event: '*',
//                     schema: 'public',
//                     table: 'resume_uploads',
//                     filter: `id=eq.${selectedResumeId}`
//                 },
//                 (payload) => {
//                     if (payload.new.status === 'completed') {
//                         setUploadProgress(100);
//                         setIsUploadingResume(false);

//                         setFormData((prev) => ({
//                             ...prev,
//                             resumeText: payload.new.resume_text,
//                             resumeId: payload.new.id
//                         }));
//                         setResumes((prev) => [payload.new, ...prev]);
//                     } else if (payload.new.status === 'parsing') {
//                         setUploadProgress(75);
//                     } else if (payload.new.status === 'uploading') {
//                         setUploadProgress(50);
//                     }
//                 }
//             )
//             .subscribe();

//         // Cleanup
//         return () => {
//             supabase.removeChannel(channel);
//         };
//     }, [isUploadingResume, selectedResumeId]);

//     const handleResumeSelect = (resume) => {
//         setSelectedResumeId(resume.id);
//         setFormData((prev) => ({
//             ...prev,
//             resumeText: resume.resume_text,
//             resumeId: resume.id
//         }));
//     };

//     // Upload a new resume file
//     const handleFileUpload = async (file) => {
//         if (!file) return;

//         if (file.size > 10 * 1024 * 1024) {
//             setError('File size must be less than 10MB');
//             return;
//         }

//         const allowedTypes = [
//             'application/pdf',
//             'application/msword',
//             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
//         ];

//         if (!allowedTypes.includes(file.type)) {
//             setError('Only PDF, DOC, and DOCX files are allowed');
//             return;
//         }

//         setError(null);
//         setIsUploadingResume(true);
//         setUploadProgress(25);

//         try {
//             const recordId = crypto.randomUUID();
//             setSelectedResumeId(recordId);

//             const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;

//             // 1) Create initial record in 'resume_uploads'
//             const { error: recordError } = await supabase
//                 .from('resume_uploads')
//                 .insert([{
//                     id: recordId,
//                     uploaded_by: userId,
//                     status: 'uploading',
//                     file_name: file.name,
//                     company: formData.company,
//                     role: formData.jobDescription,
//                     created_at: new Date().toISOString()
//                 }]);

//             if (recordError) throw recordError;

//             // 2) Upload file to Storage
//             const filePath = `uploads/${userId}/${recordId}/${file.name}`;
//             const { error: uploadError } = await supabase.storage
//                 .from('resumes')
//                 .upload(filePath, file);

//             if (uploadError) throw uploadError;

//             const { data: { publicUrl } } = supabase.storage
//                 .from('resumes')
//                 .getPublicUrl(filePath);

//             // 3) Update record with file path & set status to 'parsing'
//             await supabase
//                 .from('resume_uploads')
//                 .update({
//                     status: 'parsing',
//                     file_url: publicUrl,
//                     file_path: filePath
//                 })
//                 .eq('id', recordId);

//             // 4) Call your external parse API
//             const apiFormData = new FormData();
//             apiFormData.append('file', file);
//             apiFormData.append('user_id', userId);
//             apiFormData.append('record_id', recordId);
//             apiFormData.append('parse', 'true');
//             apiFormData.append('mode', 'new');
//             apiFormData.append('company', formData.company);
//             apiFormData.append('role', formData.jobDescription);

//             const response = await fetch('https://your-parsing-api-endpoint.com/parse', {
//                 method: 'POST',
//                 body: apiFormData
//             });

//             if (!response.ok) throw new Error('Parse API Error');
//             // The Realtime channel above will pick up further status changes.
//         } catch (err) {
//             console.error('Upload error:', err);
//             setError(err.message || 'Failed to upload resume');
//             setIsUploadingResume(false);
//             setUploadProgress(0);

//             if (selectedResumeId) {
//                 await supabase
//                     .from('resume_uploads')
//                     .update({
//                         status: 'failed',
//                         error_message: err.message
//                     })
//                     .eq('id', selectedResumeId);
//             }
//         }
//     };

//     // Form submission for the 3 steps
//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Step 1 -> 2
//         if (step === 1) {
//             setStep(2);
//             return;
//         }
//         // Step 2 -> 3
//         if (step === 2) {
//             setStep(3);
//             return;
//         }

//         // Step 3: We need a resume selected or uploaded
//         if (!formData.resumeText) {
//             setError('Please select or upload a resume to continue');
//             return;
//         }

//         // Insert into interview_sessions_prod
//         setIsSubmitting(true);
//         try {
//             const sessionId = `session_${crypto.randomUUID()}`;
//             const interviewData = {
//                 sessionId,
//                 userId: isDev ? DEV_FALLBACK_UUID : undefined,
//                 ...formData,
//                 timestamp: new Date().toISOString()
//             };

//             const savedData = await saveInterviewToSupabase(interviewData);

//             onComplete({
//                 ...interviewData,
//                 dbSession: savedData?.[0] // The inserted row
//             });
//         } catch (err) {
//             console.error('Error:', err);
//             setError('Error creating interview. Please try again.');
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     if (!isOpen) return null;

//     return (
//         <AnimatePresence>
//             <motion.div
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 exit={{ opacity: 0 }}
//                 className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
//             >
//                 <motion.div
//                     initial={{ scale: 0.95, opacity: 0 }}
//                     animate={{ scale: 1, opacity: 1 }}
//                     exit={{ scale: 0.95, opacity: 0 }}
//                     className="bg-white rounded-lg w-full max-w-2xl overflow-hidden"
//                 >
//                     {/* Header */}
//                     <div className="flex items-center justify-between p-6 border-b">
//                         <div>
//                             <h2 className="text-xl font-semibold">Create Interview Session</h2>
//                             <p className="text-sm text-gray-500 mt-1">
//                                 {step === 1
//                                     ? 'Interview Details'
//                                     : step === 2
//                                         ? 'Customize Experience'
//                                         : 'Select Resume'}
//                             </p>
//                         </div>
//                         <button
//                             onClick={onClose}
//                             disabled={isSubmitting || isUploadingResume}
//                             className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                             type="button"
//                         >
//                             <X className="w-5 h-5" />
//                         </button>
//                     </div>

//                     <StepIndicator currentStep={step} />

//                     {/* Form Steps */}
//                     <form onSubmit={handleSubmit} className="p-6 space-y-4">
//                         {/* Step 1 */}
//                         {step === 1 && (
//                             <>
//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Company</label>
//                                     <div className="relative">
//                                         <Building className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
//                                         <input
//                                             type="text"
//                                             value={formData.company}
//                                             onChange={(e) =>
//                                                 setFormData((prev) => ({
//                                                     ...prev,
//                                                     company: e.target.value
//                                                 }))
//                                             }
//                                             className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                             placeholder="Enter company name"
//                                             required
//                                             disabled={isSubmitting}
//                                         />
//                                     </div>
//                                 </div>

//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Job Description</label>
//                                     <textarea
//                                         value={formData.jobDescription}
//                                         onChange={(e) =>
//                                             setFormData((prev) => ({
//                                                 ...prev,
//                                                 jobDescription: e.target.value
//                                             }))
//                                         }
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                         placeholder="Software Engineer with Python, SQL, AWS..."
//                                         rows={3}
//                                         required
//                                         disabled={isSubmitting}
//                                     />
//                                 </div>

//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">Interview Language</label>
//                                     <select
//                                         value={formData.language}
//                                         onChange={(e) =>
//                                             setFormData((prev) => ({
//                                                 ...prev,
//                                                 language: e.target.value
//                                             }))
//                                         }
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
//                                         disabled={isSubmitting}
//                                     >
//                                         <option value="English">English</option>
//                                         <option value="Spanish">Spanish</option>
//                                         <option value="French">French</option>
//                                         <option value="German">German</option>
//                                         <option value="Chinese">Chinese</option>
//                                     </select>
//                                 </div>
//                             </>
//                         )}

//                         {/* Step 2 */}
//                         {step === 2 && (
//                             <>
//                                 <div className="space-y-2">
//                                     <label className="block text-sm font-medium">
//                                         Special Instructions
//                                     </label>
//                                     <textarea
//                                         value={formData.specialInstructions}
//                                         onChange={(e) =>
//                                             setFormData((prev) => ({
//                                                 ...prev,
//                                                 specialInstructions: e.target.value
//                                             }))
//                                         }
//                                         className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                         placeholder="E.g., Be more technical, focus on system design..."
//                                         rows={3}
//                                         disabled={isSubmitting}
//                                     />
//                                 </div>

//                                 <div className="space-y-4">
//                                     <label className="relative inline-flex items-center cursor-pointer">
//                                         <input
//                                             type="checkbox"
//                                             checked={formData.simpleEnglish}
//                                             onChange={(e) =>
//                                                 setFormData((prev) => ({
//                                                     ...prev,
//                                                     simpleEnglish: e.target.checked
//                                                 }))
//                                             }
//                                             className="sr-only peer"
//                                             disabled={isSubmitting}
//                                         />
//                                         <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
//                                         <span className="ml-3 text-sm font-medium">Use Simple English</span>
//                                     </label>

//                                     <label className="relative inline-flex items-center cursor-pointer">
//                                         <input
//                                             type="checkbox"
//                                             checked={formData.generateSummary}
//                                             onChange={(e) =>
//                                                 setFormData((prev) => ({
//                                                     ...prev,
//                                                     generateSummary: e.target.checked
//                                                 }))
//                                             }
//                                             className="sr-only peer"
//                                             disabled={isSubmitting}
//                                         />
//                                         <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
//                                         <span className="ml-3 text-sm font-medium">Generate AI Summary</span>
//                                     </label>
//                                 </div>
//                             </>
//                         )}

//                         {/* Step 3 */}
//                         {step === 3 && (
//                             <>
//                                 {error && (
//                                     <div className="mb-4 p-4 bg-red-50 border border-red-100 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
//                                             <p className="text-red-800">{error}</p>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="space-y-4">
//                                     {/* Resume selection / upload */}
//                                     <div className="flex items-center justify-between">
//                                         <h3 className="text-lg font-medium">Select Resume</h3>
//                                         <button
//                                             type="button"
//                                             onClick={() => {
//                                                 document.getElementById('resume-upload').click();
//                                             }}
//                                             disabled={isUploadingResume}
//                                             className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-orange-700 bg-orange-50 rounded-lg hover:bg-orange-100 transition-colors"
//                                         >
//                                             <Upload className="w-4 h-4" />
//                                             Upload New
//                                         </button>
//                                         <input
//                                             id="resume-upload"
//                                             type="file"
//                                             className="hidden"
//                                             accept=".pdf,.doc,.docx"
//                                             onChange={(e) => handleFileUpload(e.target.files?.[0])}
//                                             disabled={isUploadingResume}
//                                         />
//                                     </div>

//                                     {isLoadingResumes ? (
//                                         <div className="flex items-center justify-center py-8">
//                                             <Loader2 className="w-8 h-8 text-orange-500 animate-spin" />
//                                         </div>
//                                     ) : resumes.length === 0 ? (
//                                         <div className="text-center py-8 text-gray-500">
//                                             <FileText className="w-12 h-12 mx-auto text-gray-400 mb-3" />
//                                             <p>No resumes found. Upload one to continue.</p>
//                                         </div>
//                                     ) : (
//                                         <div className="space-y-3 max-h-64 overflow-y-auto">
//                                             {resumes.map((resume) => (
//                                                 <ResumeCard
//                                                     key={resume.id}
//                                                     resume={resume}
//                                                     isSelected={selectedResumeId === resume.id}
//                                                     onSelect={() => handleResumeSelect(resume)}
//                                                 />
//                                             ))}
//                                         </div>
//                                     )}

//                                     {isUploadingResume && (
//                                         <div className="p-4 bg-orange-50 rounded-lg">
//                                             <div className="flex items-center gap-3 mb-3">
//                                                 <Loader2 className="w-5 h-5 text-orange-500 animate-spin" />
//                                                 <span className="font-medium text-orange-800">
//                                                     {uploadProgress < 50
//                                                         ? 'Uploading resume...'
//                                                         : 'Processing resume...'}
//                                                 </span>
//                                             </div>
//                                             <div className="w-full bg-orange-200 rounded-full h-2">
//                                                 <div
//                                                     className="bg-orange-500 h-2 rounded-full transition-all duration-300"
//                                                     style={{ width: `${uploadProgress}%` }}
//                                                 />
//                                             </div>
//                                         </div>
//                                     )}
//                                 </div>
//                             </>
//                         )}

//                         {/* Info / Helper Text */}
//                         <div className="p-4 bg-blue-50 rounded-lg">
//                             <div className="flex items-start gap-3">
//                                 <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                 <div>
//                                     <h4 className="font-medium text-blue-800">Important Note</h4>
//                                     <p className="mt-1 text-sm text-blue-600">
//                                         {step === 1
//                                             ? 'Provide accurate details to help personalize your interview experience.'
//                                             : step === 2
//                                                 ? 'You can adjust these settings during the interview if needed.'
//                                                 : 'The selected resume will be used to personalize your interview questions.'}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>

//                         {/* Action Buttons */}
//                         <div className="pt-4 border-t flex justify-between">
//                             {step > 1 ? (
//                                 <button
//                                     type="button"
//                                     onClick={() => setStep(step - 1)}
//                                     disabled={isSubmitting || isUploadingResume}
//                                     className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                 >
//                                     Back
//                                 </button>
//                             ) : (
//                                 <button
//                                     type="button"
//                                     onClick={onClose}
//                                     disabled={isSubmitting || isUploadingResume}
//                                     className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                 >
//                                     Cancel
//                                 </button>
//                             )}
//                             <button
//                                 type="submit"
//                                 disabled={
//                                     isSubmitting ||
//                                     isUploadingResume ||
//                                     (step === 3 && !selectedResumeId)
//                                 }
//                                 className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 
//                                     transition-colors inline-flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
//                             >
//                                 {isSubmitting ? (
//                                     <>
//                                         <Loader2 className="w-4 h-4 animate-spin" />
//                                         <span>Processing...</span>
//                                     </>
//                                 ) : step === 3 ? (
//                                     'Start Interview'
//                                 ) : (
//                                     <>
//                                         Next
//                                         <ArrowRight className="w-4 h-4" />
//                                     </>
//                                 )}
//                             </button>
//                         </div>
//                     </form>
//                 </motion.div>
//             </motion.div>
//         </AnimatePresence>
//     );
// };

// export default CreateInterviewModal;


import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../../supabaseConfig';
import {
    X,
    AlertCircle,
    CheckCircle2,
    ArrowRight,
    Building,
    Upload,
    FileText,
    Loader2
} from 'lucide-react';

const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

/**
 * Inserts a new row into `interview_sessions_prod`
 * with matching column names and saves any extra
 * fields into session_metadata if desired.
 */
const saveInterviewToSupabase = async (interviewData) => {
    try {
        // Fallback user_id for local/dev
        const effectiveUserId = isDev
            ? (interviewData.userId || DEV_FALLBACK_UUID)
            : interviewData.userId;

        // Build the insertion payload (storing extras in session_metadata if needed)
        const insertPayload = {
            session_id: interviewData.sessionId,
            user_id: effectiveUserId,
            company_name:
                interviewData.selectedCompany === 'Custom'
                    ? interviewData.customCompany
                    : interviewData.selectedCompany,
            job_description:
                interviewData.selectedRole === 'Custom'
                    ? interviewData.customRole
                    : interviewData.selectedRole,
            interview_language: interviewData.language,
            special_instructions: interviewData.specialInstructions,
            simple_english: interviewData.simpleEnglish,
            generate_summary: interviewData.generateSummary,
            resume_text: interviewData.resumeText,
            resume_id: interviewData.resumeId,
            status: 'created',
            is_trial: isDev || !interviewData.isPremium,
            session_metadata: {
                clientTimestamp: new Date().toISOString(),
                browserInfo: navigator.userAgent,
                isDevelopment: isDev,
                originalCompanySelection: interviewData.selectedCompany,
                originalRoleSelection: interviewData.selectedRole,
            },
        };

        // Insert into interview_sessions_prod
        const { data, error } = await supabase
            .from('interview_sessions_prod')
            .insert([insertPayload])
            .select();

        if (error) throw error;
        return data;
    } catch (error) {
        console.error('Error saving to Supabase:', error);
        if (isDev) {
            return [{
                id: `dev_${Math.random().toString(36).substr(2, 9)}`,
                session_id: interviewData.sessionId,
                ...interviewData
            }];
        }
        throw error;
    }
};

/** 
 * Simple step indicator: 1 -> 2 -> 3 
 */
const StepIndicator = ({ currentStep }) => (
    <div className="flex items-center justify-center gap-2 mb-6">
        {[1, 2, 3].map((step) => (
            <div
                key={step}
                className={`w-2.5 h-2.5 rounded-full ${step === currentStep ? 'bg-orange-500' : 'bg-gray-200'
                    }`}
            />
        ))}
    </div>
);

const ResumeCard = ({ resume, isSelected, onSelect }) => (
    <div
        onClick={onSelect}
        className={`p-4 border rounded-lg cursor-pointer transition-all ${isSelected
                ? 'border-orange-500 bg-orange-50'
                : 'border-gray-200 hover:border-orange-300'
            }`}
    >
        <div className="flex items-start gap-3">
            <FileText
                className={`w-5 h-5 ${isSelected ? 'text-orange-500' : 'text-gray-400'
                    }`}
            />
            <div className="flex-1">
                <h4 className="font-medium text-gray-900">
                    {resume.candidate_name || resume.file_name}
                </h4>
                <p className="text-sm text-gray-500 mt-1">
                    {resume.company && `${resume.company} - `}
                    {resume.role || 'No role specified'}
                </p>
                <p className="text-xs text-gray-400 mt-1">
                    Uploaded {new Date(resume.created_at).toLocaleDateString()}
                </p>
            </div>
            {isSelected && <CheckCircle2 className="w-5 h-5 text-orange-500" />}
        </div>
    </div>
);

/**
 * This modal shows a 3-step flow to create a new interview session in `interview_sessions_prod`.
 * We'll poll the 'resume_uploads' table every 3s until status becomes 'completed'.
 */
const CreateInterviewModal = ({ isOpen, onClose, onComplete }) => {
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingResumes, setIsLoadingResumes] = useState(true);
    const [resumes, setResumes] = useState([]);
    const [selectedResumeId, setSelectedResumeId] = useState(null);
    const [isUploadingResume, setIsUploadingResume] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);

    // Hardcoded company dropdown
    const companyOptions = [
        'Amazon',
        'Apple',
        'Google',
        'Microsoft',
        'Meta (Facebook)',
        'Netflix',
        'Salesforce',
        'Tesla',
        'Adobe',
        'Uber',
        'Custom'
    ];

    // Hardcoded role dropdown
    const roleOptions = [
        'Software Engineer',
        'Data Scientist',
        'Data Analyst',
        'Marketing Manager',
        'Sales Representative',
        'Product Manager',
        'Customer Success Engineer',
        'Custom'
    ];

    // Additional languages
    const languages = [
        'English',
        'Spanish',
        'French',
        'German',
        'Chinese',
        'Japanese',
        'Portuguese',
        'Russian',
        'Arabic',
        'Hindi',
        'Korean',
        'Italian',
        'Other'
    ];

    // Our main form data
    const [formData, setFormData] = useState({
        selectedCompany: 'Amazon',
        customCompany: '',
        selectedRole: 'Software Engineer',
        customRole: '',
        language: 'English',
        specialInstructions: '',
        simpleEnglish: false,
        generateSummary: true,
        resumeText: '',
        resumeId: null
    });

    // 1) Load existing "completed" resumes
    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;
                const { data, error } = await supabase
                    .from('resume_uploads')
                    .select('*')
                    .eq('uploaded_by', userId)
                    .eq('status', 'completed')
                    .order('created_at', { ascending: false });

                if (error) throw error;
                setResumes(data || []);
            } catch (err) {
                console.error('Error fetching resumes:', err);
                setError('Failed to load resumes');
            } finally {
                setIsLoadingResumes(false);
            }
        };

        if (isOpen) {
            void fetchResumes();
        }
    }, [isOpen]);

    // 2) Poll every 3s if we are "uploading"
    useEffect(() => {
        if (!isUploadingResume || !selectedResumeId) return;

        const POLL_INTERVAL_MS = 3000;
        const intervalId = setInterval(async () => {
            try {
                const { data, error } = await supabase
                    .from('resume_uploads')
                    .select('*')
                    .eq('id', selectedResumeId)
                    .single();

                if (error) {
                    console.error('Error polling resume_uploads:', error);
                    return;
                }
                if (!data) {
                    console.warn('No record found for id=', selectedResumeId);
                    return;
                }

                // Check status
                if (data.status === 'completed') {
                    // Done
                    setUploadProgress(100);
                    setIsUploadingResume(false);

                    // store
                    setFormData((prev) => ({
                        ...prev,
                        resumeText: data.resume_text,
                        resumeId: data.id
                    }));

                    // Insert new item at top
                    setResumes((prev) => [data, ...prev]);
                    clearInterval(intervalId);
                }
                else if (data.status === 'parsing') {
                    setUploadProgress(75);
                }
                else if (data.status === 'uploading') {
                    setUploadProgress(50);
                }
                else if (data.status === 'failed') {
                    setIsUploadingResume(false);
                    setError('Resume processing failed. Please try again.');
                    clearInterval(intervalId);
                }
            } catch (err) {
                console.error('Polling error:', err);
            }
        }, POLL_INTERVAL_MS);

        // Clean up
        return () => clearInterval(intervalId);
    }, [isUploadingResume, selectedResumeId]);

    // Handler: pick a resume from list
    const handleResumeSelect = (resume) => {
        setSelectedResumeId(resume.id);
        setFormData((prev) => ({
            ...prev,
            resumeText: resume.resume_text,
            resumeId: resume.id
        }));
    };

    // 3) Upload new file
    const handleFileUpload = async (file) => {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            setError('File must be <10MB');
            return;
        }

        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        if (!allowedTypes.includes(file.type)) {
            setError('Only PDF, DOC, DOCX allowed');
            return;
        }

        setError(null);
        setIsUploadingResume(true);
        setUploadProgress(25);

        try {
            const recordId = crypto.randomUUID();
            setSelectedResumeId(recordId);

            const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;

            // Insert row with status='uploading'
            const { error: recordError } = await supabase
                .from('resume_uploads')
                .insert([{
                    id: recordId,
                    uploaded_by: userId,
                    status: 'uploading',
                    file_name: file.name,
                    company: formData.selectedCompany === 'Custom'
                        ? formData.customCompany
                        : formData.selectedCompany,
                    role: formData.selectedRole === 'Custom'
                        ? formData.customRole
                        : formData.selectedRole,
                    created_at: new Date().toISOString()
                }]);

            if (recordError) throw recordError;

            // Upload to storage
            const filePath = `uploads/${userId}/${recordId}/${file.name}`;
            const { error: uploadError } = await supabase.storage
                .from('resumes')
                .upload(filePath, file);

            if (uploadError) throw uploadError;

            const { data: { publicUrl } } = supabase.storage
                .from('resumes')
                .getPublicUrl(filePath);

            // Mark row as 'parsing'
            await supabase
                .from('resume_uploads')
                .update({
                    status: 'parsing',
                    file_url: publicUrl,
                    file_path: filePath
                })
                .eq('id', recordId);

            // POST to external parse API
            const apiFormData = new FormData();
            apiFormData.append('file', file);
            apiFormData.append('user_id', userId);
            apiFormData.append('record_id', recordId);
            apiFormData.append('parse', 'true');
            apiFormData.append('mode', 'new');
            apiFormData.append('company', formData.selectedCompany);
            apiFormData.append('role', formData.selectedRole);

            const response = await fetch(
                'https://robo-resumeably-backend.onrender.com/api/v1/resume/upload_robo',
                { method: 'POST', body: apiFormData }
            );

            if (!response.ok) throw new Error('Parse API Error');
            // We'll poll until completed
        } catch (err) {
            console.error('Upload error:', err);
            setError(err.message || 'Failed to upload resume');
            setIsUploadingResume(false);
            setUploadProgress(0);

            if (selectedResumeId) {
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'failed',
                        error_message: err.message
                    })
                    .eq('id', selectedResumeId);
            }
        }
    };

    // 4) Final wizard submission => Insert into interview_sessions_prod
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (step === 1) {
            setStep(2);
            return;
        }
        if (step === 2) {
            setStep(3);
            return;
        }

        // Must have resumeText (or some resumeId)
        if (!formData.resumeText) {
            setError('Select or upload a resume first.');
            return;
        }

        setIsSubmitting(true);
        try {
            const sessionId = `session_${crypto.randomUUID()}`;
            const interviewData = {
                sessionId,
                userId: isDev ? DEV_FALLBACK_UUID : undefined,
                ...formData,
                timestamp: new Date().toISOString()
            };

            const savedData = await saveInterviewToSupabase(interviewData);
            onComplete({
                ...interviewData,
                dbSession: savedData?.[0]
            });
        } catch (err) {
            console.error('Error:', err);
            setError('Error creating interview. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-[#B1EDF1]/50 flex items-center justify-center z-50 p-4"
            >
                <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                    className="bg-white rounded-lg w-full max-w-2xl overflow-hidden"
                >
                    {/* Header */}
                    <div className="flex items-center justify-between p-6 border-b">
                        <div>
                            <h2 className="text-xl font-semibold">Create Interview Session</h2>
                            <p className="text-sm text-gray-500 mt-1">
                                {step === 1
                                    ? 'Interview Details'
                                    : step === 2
                                        ? 'Customize Experience'
                                        : 'Select Resume'}
                            </p>
                        </div>
                        <button
                            onClick={onClose}
                            disabled={isSubmitting || isUploadingResume}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            type="button"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    <StepIndicator currentStep={step} />

                    {/* Steps */}
                    <form onSubmit={handleSubmit} className="p-6 space-y-4">
                        {/* Step 1 */}
                        {step === 1 && (
                            <>
                                {/* Company */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium">
                                        Company
                                    </label>
                                    <select
                                        value={formData.selectedCompany}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                selectedCompany: e.target.value,
                                                customCompany:
                                                    e.target.value === 'Custom'
                                                        ? prev.customCompany
                                                        : ''
                                            }))
                                        }
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {companyOptions.map((company) => (
                                            <option key={company} value={company}>
                                                {company}
                                            </option>
                                        ))}
                                    </select>
                                    {formData.selectedCompany === 'Custom' && (
                                        <div className="mt-2">
                                            <label className="block text-sm font-medium text-gray-600 mb-1">
                                                Custom Company
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.customCompany}
                                                onChange={(e) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        customCompany: e.target.value
                                                    }))
                                                }
                                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                placeholder="Enter company name"
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Role */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium">
                                        Job Description / Role
                                    </label>
                                    <select
                                        value={formData.selectedRole}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                selectedRole: e.target.value,
                                                customRole:
                                                    e.target.value === 'Custom'
                                                        ? prev.customRole
                                                        : ''
                                            }))
                                        }
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {roleOptions.map((role) => (
                                            <option key={role} value={role}>
                                                {role}
                                            </option>
                                        ))}
                                    </select>
                                    {formData.selectedRole === 'Custom' && (
                                        <div className="mt-2">
                                            <label className="block text-sm font-medium text-gray-600 mb-1">
                                                Custom Role
                                            </label>
                                            <input
                                                type="text"
                                                value={formData.customRole}
                                                onChange={(e) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        customRole: e.target.value
                                                    }))
                                                }
                                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                                placeholder="Enter role name"
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Language */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium">Interview Language</label>
                                    <select
                                        value={formData.language}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                language: e.target.value
                                            }))
                                        }
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {languages.map((lang) => (
                                            <option key={lang} value={lang}>
                                                {lang}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}

                        {/* Step 2 */}
                        {step === 2 && (
                            <>
                                {/* Special Instructions */}
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium">
                                        Special Instructions
                                    </label>
                                    <textarea
                                        value={formData.specialInstructions}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                specialInstructions: e.target.value
                                            }))
                                        }
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                        placeholder="E.g., Focus on system design, keep it short..."
                                        rows={3}
                                        disabled={isSubmitting}
                                    />
                                </div>

                                <div className="space-y-4">
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={formData.simpleEnglish}
                                            onChange={(e) =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    simpleEnglish: e.target.checked
                                                }))
                                            }
                                            className="sr-only peer"
                                            disabled={isSubmitting}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
                                        <span className="ml-3 text-sm font-medium">
                                            Use Simple English
                                        </span>
                                    </label>

                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={formData.generateSummary}
                                            onChange={(e) =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    generateSummary: e.target.checked
                                                }))
                                            }
                                            className="sr-only peer"
                                            disabled={isSubmitting}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
                                        <span className="ml-3 text-sm font-medium">
                                            Generate AI Summary
                                        </span>
                                    </label>
                                </div>
                            </>
                        )}

                        {/* Step 3 */}
                        {step === 3 && (
                            <>
                                {error && (
                                    <div className="mb-4 p-4 bg-red-50 border border-red-100 rounded-lg">
                                        <div className="flex items-start gap-3">
                                            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
                                            <p className="text-red-800">{error}</p>
                                        </div>
                                    </div>
                                )}

                                <div className="space-y-4">
                                    {/* Resume selection / upload */}
                                    <div className="flex items-center justify-between">
                                        <h3 className="text-lg font-medium">Select Resume</h3>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                document.getElementById('resume-upload').click();
                                            }}
                                            disabled={isUploadingResume}
                                            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-orange-700 bg-orange-50 rounded-lg hover:bg-orange-100 transition-colors"
                                        >
                                            <Upload className="w-4 h-4" />
                                            Upload New
                                        </button>
                                        <input
                                            id="resume-upload"
                                            type="file"
                                            className="hidden"
                                            accept=".pdf,.doc,.docx"
                                            onChange={(e) => handleFileUpload(e.target.files?.[0])}
                                            disabled={isUploadingResume}
                                        />
                                    </div>

                                    {isLoadingResumes ? (
                                        <div className="flex items-center justify-center py-8">
                                            <Loader2 className="w-8 h-8 text-orange-500 animate-spin" />
                                        </div>
                                    ) : resumes.length === 0 ? (
                                        <div className="text-center py-8 text-gray-500">
                                            <FileText className="w-12 h-12 mx-auto text-gray-400 mb-3" />
                                            <p>No resumes found. Upload one to continue.</p>
                                        </div>
                                    ) : (
                                        <div className="space-y-3 max-h-64 overflow-y-auto">
                                            {resumes.map((resume) => (
                                                <ResumeCard
                                                    key={resume.id}
                                                    resume={resume}
                                                    isSelected={selectedResumeId === resume.id}
                                                    onSelect={() => handleResumeSelect(resume)}
                                                />
                                            ))}
                                        </div>
                                    )}

                                    {isUploadingResume && (
                                        <div className="p-4 bg-orange-50 rounded-lg">
                                            <div className="flex items-center gap-3 mb-3">
                                                <Loader2 className="w-5 h-5 text-orange-500 animate-spin" />
                                                <span className="font-medium text-orange-800">
                                                    {uploadProgress < 50
                                                        ? 'Uploading resume...'
                                                        : 'Processing resume...'}
                                                </span>
                                            </div>
                                            <div className="w-full bg-orange-200 rounded-full h-2">
                                                <div
                                                    className="bg-orange-500 h-2 rounded-full transition-all duration-300"
                                                    style={{ width: `${uploadProgress}%` }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {/* Info / Helper Text */}
                        <div className="p-4 bg-[#B1EDF1] rounded-lg">
                            <div className="flex items-start gap-3">
                                <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
                                <div>
                                    <h4 className="font-medium text-blue-800">Important Note</h4>
                                    <p className="mt-1 text-sm text-blue-600">
                                        {step === 1
                                            ? 'Provide accurate details to help personalize your interview experience.'
                                            : step === 2
                                                ? 'You can adjust these settings during the interview if needed.'
                                                : 'The selected resume will be used to personalize your interview questions.'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="pt-4 border-t flex justify-between">
                            {step > 1 ? (
                                <button
                                    type="button"
                                    onClick={() => setStep(step - 1)}
                                    disabled={isSubmitting || isUploadingResume}
                                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                                >
                                    Back
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={onClose}
                                    disabled={isSubmitting || isUploadingResume}
                                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                                >
                                    Cancel
                                </button>
                            )}
                            <button
                                type="submit"
                                disabled={
                                    isSubmitting ||
                                    isUploadingResume ||
                                    (step === 3 && !selectedResumeId)
                                }
                                className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 
                                    transition-colors inline-flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="w-4 h-4 animate-spin" />
                                        <span>Processing...</span>
                                    </>
                                ) : step === 3 ? (
                                    'Start Interview'
                                ) : (
                                    <>
                                        Next
                                        <ArrowRight className="w-4 h-4" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default CreateInterviewModal;

