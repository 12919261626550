// authRedirectStorageClient.js
const REDIRECT_FLAG_KEY = 'alreadyRedirected';

export const hasAlreadyRedirected = () => {
  const flag = localStorage.getItem(REDIRECT_FLAG_KEY);
  return Boolean(flag);
};

export const setAlreadyRedirected = () => {
  localStorage.setItem(REDIRECT_FLAG_KEY, 'true');
};
export const storeIsNewUser = (boolString) => {
  localStorage.setItem('isNewUser', boolString);
};
export const getStoredIsNewUser = () => {
  return localStorage.getItem('isNewUser');
};
export const storeHasCompletedDiceAccount = () => {
  localStorage.setItem('hasCompletedDiceAccount', 'true');
};
export const getStoredHasCompletedDiceAccount = () => {
  return localStorage.getItem('hasCompletedDiceAccount');
};

export const clearAlreadyRedirected = () => {
  localStorage.removeItem(REDIRECT_FLAG_KEY);
};
export const getStoredSession = () =>
  JSON.parse(localStorage.getItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`));
export const storeSession = (session) =>
  localStorage.setItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`, JSON.stringify(session));

export const removeTokensFromLocalStorage = () => {
  // localStorage.clear();
  localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
  localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-expiration`);
};

export const getStoredLastCampaign = () => localStorage.getItem('last_campaign');
export const storeLastCampaign = (campaignId) => localStorage.setItem('last_campaign', campaignId);
export const getTotalApplications = () => localStorage.getItem('total_successful_applications');
export const storeTotalApplications = (totalApplications) =>
  localStorage.setItem('total_successful_applications', totalApplications);
