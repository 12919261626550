import React, { useState } from 'react';
import CreateApplicationForm from '../concrete/CreateApplicationForm/CreateApplicationForm';
import { redirect, useFetcher, useLoaderData, useNavigate, useRouteLoaderData } from 'react-router';

import { useToastContext } from '../contexts/useToastContext/useToastContext';
import { PricingCard } from '../concrete/PricingCard/PricingCard';
import { Modal } from '../base/Modal/Modal';

export const AutoApplyPage = () => {
  const {
    user,
    userProfile,
    applicationData,
    applicationData: { hasActiveCampaign },
  } = useLoaderData();

  const fetcher = useFetcher();

  const [loading, setLoading] = useState(false);
  const [activelyApplying, setActivelyApplying] = useState(false);
  const [successDetails, setSuccessDetails] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [error, setError] = useState();
  const { showToast } = useToastContext();

  const setShowPricingScreen = () => {};

  const handleSubmitApplication = async (event, formData) => {
    try {
      await fetcher.submit(formData, { method: 'POST', action: '/auto-apply' });

      setSuccessDetails({
        jobType: formData.job_type,
        numJobs: formData.num_jobs,
        location: formData.location,
      });
      showToast('Application submitted successfully', 'success');
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Application error:', error);
      setSuccessDetails(null);
      setShowSuccessModal(false);

      showToast(
        {
          title: 'Error',
          description: error.response?.data?.error || 'An error occurred during the application process',
        },
        'error',
      );
    } finally {
      // setLoading(false); TODO: use fetcher state
      setActivelyApplying(false);
    }
  };

  return (
    <div className="p-6 bg-white flex flex-col items-center rounded-lg shadow-sm">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Start Auto Apply Campaign</h2>
      <div className={' flex w-full '}>
        <CreateApplicationForm
          handleSubmit={handleSubmitApplication}
          loading={fetcher.state !== 'idle'}
          error={error}
          activelyApplying={activelyApplying}
          userId={user?.id}
          most_recent_resume_link={userProfile?.most_recent_resume_url}
          userEmail={user?.email}
          diceEmail={userProfile?.dice_specific_email || user?.email}
          setShowPricingScreen={setShowPricingScreen}
          setShowPricingModal={setShowPricingModal}
          hasActiveCampaign={hasActiveCampaign}
        />
      </div>
      <Modal
        className={'bg-transparent p-0 w-full max-w-full'}
        isOpen={showPricingModal}
        onClose={() => setShowPricingModal(false)}
      >
        <PricingCard
          userId={user?.id}
          onClose={() => setShowPricingModal(false)}
          onSuccess={() => {
            setShowPricingModal(false);
          }}
        />
      </Modal>
    </div>
  );
};
