import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const RoboApplyLogo = forwardRef((props, forwardedRef) => {
  const { className = '', iconClassName, ...restProps } = props;
  const containerClasses = twMerge('flex flex-shrink min-w-0 items-center space-x-2 ', className);
  const iconClasses = twMerge('h-6 w-6 md:h-10 md:w-10', iconClassName);
  return (
    <div ref={forwardedRef} className={containerClasses} {...restProps}>
      <img src="/favicon.svg" alt="RoboApply Logo" className={iconClasses} />
      <span className="text-1xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500 ">
        RoboApply
      </span>
    </div>
  );
});
