import React, { useState, useEffect } from 'react';
import { Brain, CircuitBoard, ChartBar, Database, Network, BarChart, Sparkles } from 'lucide-react';

const JobCampaignDAG = () => {
  const [activeNodes, setActiveNodes] = useState([]);
  const [messageIndex, setMessageIndex] = useState(0);
  const [jobCounts, setJobCounts] = useState({});
  const [expandedRole, setExpandedRole] = useState(null);

  const messages = [
    '✨ Your resume intelligently adapts to each role',
    '💫 AI tailors applications for maximum impact',
    '✨ Simultaneous campaigns across 6 career paths',
    '💫 Intelligent distribution to 40+ top companies',
    '✨ Each version optimized for specific requirements',
  ];

  const primaryRoles = [
    {
      id: 'ml',
      label: 'ML Engineer',
      icon: Brain,
      angle: -20,
      targetCount: 342,
      color: '#24C660',
      primarySkills: ['Python', 'TensorFlow', 'PyTorch'],
    },
    {
      id: 'ai',
      label: 'AI Engineer',
      icon: CircuitBoard,
      angle: 40,
      targetCount: 298,
      color: '#24C660',
      primarySkills: ['PyTorch', 'MLOps', 'Deep Learning'],
    },
    {
      id: 'da',
      label: 'Data Analyst',
      icon: ChartBar,
      angle: 100,
      targetCount: 287,
      color: '#24C660',
      primarySkills: ['SQL', 'Python', 'Tableau'],
    },
    {
      id: 'de',
      label: 'Data Engineer',
      icon: Database,
      angle: 160,
      targetCount: 312,
      color: '#24C660',
      primarySkills: ['ETL', 'Spark', 'Snowflake'],
    },
    {
      id: 'ds',
      label: 'Data Scientist',
      icon: Network,
      angle: 220,
      targetCount: 256,
      color: '#1B9E4C',
      primarySkills: ['Python', 'R', 'Machine Learning'],
    },
    {
      id: 'bi',
      label: 'Power BI Dev',
      icon: BarChart,
      angle: 280,
      targetCount: 276,
      color: '#24C660',
      primarySkills: ['DAX', 'Power Query', 'SQL'],
    },
  ];

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 4000);

    const jobCountInterval = setInterval(() => {
      setJobCounts((prevCounts) => {
        const newCounts = { ...prevCounts };
        primaryRoles.forEach((role) => {
          if (!newCounts[role.id]) newCounts[role.id] = 0;
          if (newCounts[role.id] < role.targetCount) {
            newCounts[role.id] = Math.min(newCounts[role.id] + Math.ceil(role.targetCount / 100), role.targetCount);
          }
        });
        return newCounts;
      });
    }, 50);

    primaryRoles.forEach((role, index) => {
      setTimeout(() => {
        setActiveNodes((prev) => [...prev, role.id]);
      }, index * 500);
    });

    return () => {
      clearInterval(messageInterval);
      clearInterval(jobCountInterval);
    };
  }, []);

  const getNodePosition = (angle, radius = 35) => {
    const centerX = 50;
    const centerY = 35;
    const radianAngle = (Math.PI / 180) * angle;
    const x = centerX + radius * Math.cos(radianAngle);
    const y = centerY + radius * Math.sin(radianAngle);
    return { x, y };
  };

  const getFlowPath = (role) => {
    const { x, y } = getNodePosition(role.angle);
    return `M 50 35 L ${x} ${y}`;
  };

  return (
    <div className="bg-gradient-to-br from-[#24C660]/10 to-white p-8 rounded-3xl shadow-xl w-full max-w-5xl mx-auto">
      {/* OLDHeader Section */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-[#24C660] mb-4 flex items-center justify-center gap-2">
          <span className="text-4xl">📄</span>
          AI-Powered Resume Distribution
          <span className="text-4xl">✨</span>
        </h2>
        <div className="text-xl font-medium text-[#1B9E4C] flex items-center justify-center gap-3">
          {messages[messageIndex]}
        </div>
      </div>

      {/* Main Visualization Area */}
      <div className="relative h-[600px] bg-[#24C660]/5 rounded-3xl p-6 overflow-hidden">
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20">
          {/* Adjusted to top-1/2 */}
          <div className="w-32 h-32 rounded-full bg-gradient-to-r from-[#FFD700] to-[#FFA500] animate-pulse flex items-center justify-center">
            <div className="w-28 h-28 rounded-full bg-white flex flex-col items-center justify-center">
              <span className="text-4xl mb-2">📄</span>
              <span className="font-medium text-[#1B9E4C]"></span>
              <Sparkles className="w-6 h-6 text-[#FFD700] mt-1" />
            </div>
          </div>
        </div>

        {/* Pulsating Lines with Resume Emojis */}
        <svg className="absolute inset-0 w-full h-full">
          <defs>
            {primaryRoles.map((role) => (
              <path
                key={`path-${role.id}`}
                id={`path-${role.id}`}
                d={getFlowPath(role)}
                style={{ visibility: 'hidden' }}
              />
            ))}
          </defs>
          {primaryRoles.map((role, index) => (
            <g key={role.id}>
              <path
                d={getFlowPath(role)}
                stroke={role.color}
                strokeWidth="4"
                fill="none"
                className={`transition-opacity duration-500 ${
                  activeNodes.includes(role.id) ? 'opacity-100' : 'opacity-0'
                }`}
              />
              {activeNodes.includes(role.id) && (
                <text fontSize="16" fill="red">
                  <textPath href={`#path-${role.id}`} startOffset="0%">
                    📄
                    <animate attributeName="startOffset" from="0%" to="100%" dur="3s" repeatCount="indefinite" />
                  </textPath>
                </text>
              )}
              {/* Emoji emanating animation */}
              {activeNodes.includes(role.id) && (
                <circle r="3" fill="red">
                  <animateMotion dur="3s" repeatCount="indefinite" path={getFlowPath(role)}>
                    <mpath href={`#path-${role.id}`} />
                  </animateMotion>
                </circle>
              )}
            </g>
          ))}
        </svg>

        {/* Role Nodes with Enhanced Resume Indicators */}
        {primaryRoles.map((role) => {
          const { x, y } = getNodePosition(role.angle);
          return (
            <div
              key={role.id}
              className={`absolute transform -translate-x-1/2 -translate-y-1/2 z-10 transition-all duration-500 ${
                activeNodes.includes(role.id) ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
              }`}
              style={{ left: `${x}%`, top: `${y}%` }}
              onClick={() => setExpandedRole(role.id === expandedRole ? null : role.id)}
            >
              <div
                className={`
            p-4 w-32 h-32 rounded-xl shadow-lg 
            ${expandedRole === role.id ? 'bg-[#24C660]/10' : 'bg-white'}
            border-2 border-[#FFD700]
            hover:scale-105 transition-all duration-300 cursor-pointer
            relative
        `}
              >
                <div className="absolute -top-2 -right-2 text-xl animate-bounce">📄</div>
                <role.icon className="w-8 h-8 text-[#24C660] mb-2" />
                <div className="font-medium text-[#1B9E4C]">{role.label}</div>
                <div className="text-2xl font-bold text-[#24C660] mt-2">
                  {jobCounts[role.id] || 0}
                  <span className="text-sm font-normal ml-1">jobs</span>
                </div>
                <div className="flex flex-wrap gap-1 mt-2">
                  {role.primarySkills.map((skill, i) => (
                    <span key={i} className="text-xs bg-[#24C660]/10 px-2 py-1 rounded-full">
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Total Jobs Counter */}
      <div className="mt-8 text-center">
        <div className="text-3xl font-bold text-[#24C660] flex items-center justify-center gap-3">
          <span className="text-4xl">📄</span>
          {Object.values(jobCounts)
            .reduce((a, b) => a + b, 0)
            .toLocaleString()}
          <span className="text-xl font-normal">Total Opportunities</span>
          <span className="text-4xl">✨</span>
        </div>
      </div>
    </div>
  );
};

export default JobCampaignDAG;
