import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router'; // Make sure it is `react-router-dom`
import {
    Search, AlertCircle, Loader2, Upload, Sparkles, CheckCircle,
    Clock
} from 'lucide-react';
import { format, formatDistanceToNow } from 'date-fns';
import { supabase } from '../../supabaseConfig';
import ResumeActionButton from './ResumeActionButton'; // Import your snippet here
import ResumePreviewModal from './ResumePreviewModal';
import JobDescriptionModal from './JobDescriptionModal';
import ResumeUpload from './ResumeUpload';
import { toast } from 'sonner';

// Fallback dev logic
const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

// Possible statuses
const UPLOAD_STATUS = {
    UPLOADING: 'uploading',
    PARSING: 'parsing',
    COMPLETED: 'completed',
    FAILED: 'failed',
    PENDING: 'pending'
};

// Debounce helper
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const ResumeDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({
        key: 'created_at',
        direction: 'desc'
    });
    const [uploads, setUploads] = useState([]);
    const [filters, setFilters] = useState({
        status: 'all',
        parseStatus: 'all'
    });
    // For modals
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewFileUrl, setPreviewFileUrl] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [isJobModalOpen, setIsJobModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [hasCompletedUpdate, setHasCompletedUpdate] = useState(false);
    // For polling
    const [pollingIds, setPollingIds] = useState(new Set());

    // We reuse your deduplicate function as is
    const deduplicateUploads = useCallback((uploadsList) => {
        try {
            const deduplicatedMap = new Map();

            uploadsList.forEach((upload) => {
                const key = upload.file_name;
                const existing = deduplicatedMap.get(key);

                if (
                    !existing ||
                    (upload.status === 'completed' && existing.status !== 'completed') ||
                    new Date(upload.created_at) > new Date(existing.created_at)
                ) {
                    deduplicatedMap.set(key, upload);
                }
            });

            const deduplicatedUploads = Array.from(deduplicatedMap.values());
            setUploads(deduplicatedUploads);
            toast.success('Duplicate resumes have been handled.');
        } catch (err) {
            console.error('Error deduplicating uploads:', err);
            toast.error('Failed to deduplicate resumes.');
        }
    }, []);

    // 1) Fetch uploads
    const fetchUploads = useCallback(async () => {
        try {
            let user;
            if (isDev) {
                user = { id: DEV_FALLBACK_UUID };
            } else {
                const { data: { user: supabaseUser }, error: userError } = await supabase.auth.getUser();
                if (userError || !supabaseUser) {
                    navigate('/login');
                    return;
                }
                user = supabaseUser;
            }

            const { data: resumeUploads, error: uploadsError } = await supabase
                .from('resume_uploads')
                .select('*')
                .eq('uploaded_by', user.id)
                .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });

            if (uploadsError) throw uploadsError;

            // Deduplicate before setting the state
            deduplicateUploads(resumeUploads || []);
        } catch (error) {
            console.error('Error fetching uploads:', error);
            setError(error.message || 'Failed to fetch uploads');
            toast.error(error.message || 'Failed to fetch uploads');
        } finally {
            setLoading(false);
        }
    }, [navigate, sortConfig, deduplicateUploads]);

    // 2) Polling for a single upload
    const pollUploadStatus = useCallback(async (uploadId) => {
        try {
            const { data: upload, error } = await supabase
                .from('resume_uploads')
                .select('*')
                .eq('id', uploadId)
                .single();

            if (error) throw error;

            setUploads(current =>
                current.map(u => (u.id === uploadId ? { ...u, ...upload } : u))
            );

            if (upload.status === UPLOAD_STATUS.COMPLETED || upload.status === UPLOAD_STATUS.FAILED) {
                setPollingIds(prev => {
                    const newIds = new Set(prev);
                    newIds.delete(uploadId);
                    return newIds;
                });

                if (upload.status === UPLOAD_STATUS.COMPLETED) {
                    toast.success(`Resume processing completed for ${upload.file_name}`);
                } else if (upload.status === UPLOAD_STATUS.FAILED) {
                    toast.error(`Resume processing failed for ${upload.file_name}`);
                }
            }

            return upload.status;
        } catch (err) {
            console.error('Error polling upload status:', err);
            toast.error(`Failed to poll status for upload ${uploadId}`);
            return 'error';
        }
    }, []);

    // 3) Polling for all
    const pollAllProcessingUploads = useCallback(async () => {
        const processingIds = Array.from(pollingIds);
        if (processingIds.length === 0) return;

        const results = await Promise.all(
            processingIds.map(id => pollUploadStatus(id))
        );

        if (results.every(status =>
            status === UPLOAD_STATUS.COMPLETED ||
            status === UPLOAD_STATUS.FAILED ||
            status === 'error'
        )) {
            setPollingIds(new Set());
        }
    }, [pollingIds, pollUploadStatus]);

    useEffect(() => {
        if (pollingIds.size > 0) {
            const interval = setInterval(pollAllProcessingUploads, 3000);
            return () => clearInterval(interval);
        }
    }, [pollingIds, pollAllProcessingUploads]);

    // 4) Realtime subscription
    useEffect(() => {
        fetchUploads();

        const channel = supabase.channel('dashboard-changes')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'resume_uploads'
                },
                (payload) => {
                    console.log('Realtime change received:', payload);

                    if (payload.eventType === 'INSERT') {
                        setUploads(current => [payload.new, ...current]);
                        if (
                            payload.new.status === UPLOAD_STATUS.UPLOADING ||
                            payload.new.status === UPLOAD_STATUS.PARSING
                        ) {
                            setPollingIds(prev => new Set([...prev, payload.new.id]));
                        }
                    } else if (payload.eventType === 'UPDATE') {
                        setUploads(current =>
                            current.map(upload =>
                                upload.id === payload.new.id
                                    ? { ...upload, ...payload.new }
                                    : upload
                            )
                        );
                        if (
                            payload.new.status === UPLOAD_STATUS.COMPLETED ||
                            payload.new.status === UPLOAD_STATUS.FAILED
                        ) {
                            setPollingIds(prev => {
                                const newIds = new Set(prev);
                                newIds.delete(payload.new.id);
                                return newIds;
                            });
                            setHasCompletedUpdate(true);
                        }
                    }
                }
            )
            .subscribe();

        return () => {
            supabase.removeChannel(channel);
        };
    }, [fetchUploads]);

    // 5) Debounce for search
    const debouncedSearch = useCallback(
        debounce((value) => setSearchTerm(value), 300),
        []
    );

    const handleSearchChange = (e) => {
        debouncedSearch(e.target.value);
    };

    // 6) Show color-coded statuses with "PROCESSED" pill
    const getUploadStatus = (upload) => {
        const getProgressWidth = (status) => {
            switch (status) {
                case UPLOAD_STATUS.UPLOADING: return '25%';
                case UPLOAD_STATUS.PARSING: return '75%';
                case UPLOAD_STATUS.COMPLETED: return '100%';
                default: return '0%';
            }
        };

        const getProgressAnimation = (status) => {
            if (status === UPLOAD_STATUS.COMPLETED || status === UPLOAD_STATUS.FAILED) {
                return '';
            }
            return 'animate-progress';
        };

        switch (upload.status) {
            case UPLOAD_STATUS.UPLOADING:
                return (
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2 text-blue-600">
                            <Loader2 className="h-4 w-4 animate-spin" />
                            <span className="text-sm font-medium">Uploading...</span>
                        </div>
                        <div className="w-full h-1.5 bg-gray-200 rounded-full overflow-hidden">
                            <div
                                className={`h-full bg-blue-500 rounded-full transition-all duration-500 ease-out ${getProgressAnimation(upload.status)}`}
                                style={{ width: getProgressWidth(upload.status) }}
                            />
                        </div>
                    </div>
                );
            case UPLOAD_STATUS.PARSING:
                return (
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2 text-green-600">
                            <Sparkles className="h-4 w-4 animate-pulse" />
                            <span className="text-sm font-medium">AI Processing...</span>
                        </div>
                        <div className="w-full h-1.5 bg-gray-200 rounded-full overflow-hidden">
                            <div
                                className={`h-full bg-green-500 rounded-full transition-all duration-500 ease-out ${getProgressAnimation(upload.status)}`}
                                style={{ width: getProgressWidth(upload.status) }}
                            />
                        </div>
                    </div>
                );
            case UPLOAD_STATUS.COMPLETED:
                return (
                    <div className="flex items-center gap-2 text-green-600">
                        <CheckCircle className="h-4 w-4" />
                        <span className="px-2 py-1 bg-green-100 text-green-700 rounded-md text-xs">
                            PROCESSED
                        </span>
                    </div>
                );
            case UPLOAD_STATUS.FAILED:
                return (
                    <div className="flex items-center gap-2 text-red-600">
                        <AlertCircle className="h-4 w-4" />
                        <span className="text-sm font-medium">
                            {upload.error_message || 'Failed'}
                        </span>
                    </div>
                );
            default:
                return (
                    <div className="flex items-center gap-2 text-gray-600">
                        <Clock className="h-4 w-4" />
                        <span className="text-sm font-medium">
                            {upload.parse_requested ? 'Queued for Processing' : 'Basic Upload'}
                        </span>
                    </div>
                );
        }
    };

    // 7) Render small details (city, email, position)
    const renderResumeDetails = (upload) => {
        const details = [];
        if (upload.resume_json) {
            try {
                const json =
                    typeof upload.resume_json === 'string'
                        ? JSON.parse(upload.resume_json)
                        : upload.resume_json;
                if (json?.basics?.location?.city) {
                    details.push(json.basics.location.city);
                }
                if (json?.basics?.email) {
                    details.push(json.basics.email);
                }
                if (json?.work?.[0]?.position) {
                    details.push(json.work[0].position);
                }
            } catch (e) {
                console.error('Error parsing resume_json:', e);
            }
        }
        return details.length > 0 ? (
            <div className="text-xs text-gray-500 mt-1">
                {details.join(' • ')}
            </div>
        ) : null;
    };

    // 8) Table row: now we only have 5 columns, and the first column is <ResumeActionButton />
    //    We pass the entire "upload" (resume) to ResumeActionButton, which handles Edit, Preview, Download.
    const renderTableRow = (upload) => (
        <tr key={upload.id} className="group hover:bg-gray-50">
            {/* 1) ACTIONS: uses <ResumeActionButton resume={upload} /> */}
            <td className="px-6 py-4 whitespace-nowrap">
                <ResumeActionButton resume={upload} />
            </td>

            {/* 2) STATUS */}
            <td className="px-6 py-4">
                {getUploadStatus(upload)}
            </td>

            {/* 3) CANDIDATE */}
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900">
                    {upload.candidate_name || upload.name || 'Unnamed Candidate'}
                </div>
                {renderResumeDetails(upload)}
            </td>

            {/* 4) ROLE */}
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900">
                    {upload.role || 'No Role Specified'}
                </div>
                {upload.company && (
                    <div className="text-xs text-gray-500 mt-1">
                        {upload.company}
                    </div>
                )}
            </td>

            {/* 5) UPLOAD DATE */}
            <td className="px-6 py-4">
                <div className="text-sm text-gray-900">
                    {format(new Date(upload.created_at), 'MMM d, yyyy')}
                </div>
                <div className="text-xs text-gray-500">
                    {formatDistanceToNow(new Date(upload.created_at), { addSuffix: true })}
                </div>
            </td>
        </tr>
    );

    // Filter logic for searchTerm, parseStatus, etc.
    const filteredUploads = uploads.filter(upload => {
        const searchLower = searchTerm.toLowerCase();
        return (
            (upload.candidate_name?.toLowerCase().includes(searchLower) ||
                upload.role?.toLowerCase().includes(searchLower) ||
                upload.company?.toLowerCase().includes(searchLower)) &&
            (filters.status === 'all' || upload.status === filters.status) &&
            (filters.parseStatus === 'all' || upload.parse_status === filters.parseStatus)
        );
    });

    const handleRefresh = () => {
        fetchUploads();
        setHasCompletedUpdate(false);
        toast.info('Dashboard refreshed.');
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <header className="bg-white border-b border-gray-200">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-semibold text-gray-900">
                            Resume Dashboard
                        </h1>
                        <button
                            onClick={() => setIsUploadModalOpen(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent 
                                       rounded-md shadow-sm text-sm font-medium text-white bg-green-600 
                                       hover:bg-green-700"
                        >
                            <Upload className="h-4 w-4 mr-2" />
                            Upload Resume
                        </button>
                    </div>
                </div>
            </header>

            {/* Main */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {/* Search, filters, refresh */}
                <div className="mb-6">
                    <div className="flex gap-4 items-center">
                        <div className="flex-1 relative">
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                            <input
                                type="text"
                                placeholder="Search resumes..."
                                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg 
                                           focus:outline-none focus:ring-2 focus:ring-green-500 bg-white text-gray-700"
                                onChange={handleSearchChange}
                            />
                        </div>
                        <select
                            value={filters.parseStatus}
                            onChange={(e) => setFilters(prev => ({ ...prev, parseStatus: e.target.value }))}
                            className="border border-gray-300 rounded-lg px-3 py-2 
                                       focus:outline-none focus:ring-2 focus:ring-green-500 bg-white text-gray-700"
                        >
                            <option value="all">All Statuses</option>
                            <option value="completed">Processed</option>
                            <option value="pending">Processing</option>
                            <option value="failed">Failed</option>
                        </select>
                        {hasCompletedUpdate && (
                            <button
                                onClick={handleRefresh}
                                className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors"
                            >
                                Refresh
                            </button>
                        )}
                    </div>
                </div>

                {/* Table */}
                <div className="bg-white shadow rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Candidate
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Role
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Upload Date
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredUploads.length > 0 ? (
                                filteredUploads.map(renderTableRow)
                            ) : (
                                <tr>
                                    <td colSpan="5" className="px-6 py-12 text-center text-gray-500">
                                        <div className="flex flex-col items-center justify-center gap-2">
                                            <Search className="h-8 w-8 text-gray-400" />
                                            {searchTerm ? (
                                                <>
                                                    <h3 className="text-lg font-medium text-gray-900">
                                                        No matching resumes found
                                                    </h3>
                                                    <p className="text-sm text-gray-500">
                                                        Try adjusting your search or filters
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <h3 className="text-lg font-medium text-gray-900">
                                                        No resumes uploaded yet
                                                    </h3>
                                                    <p className="text-sm text-gray-500">
                                                        Upload your first resume to get started
                                                    </p>
                                                    <button
                                                        onClick={() => setIsUploadModalOpen(true)}
                                                        className="mt-4 inline-flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                                                    >
                                                        <Upload className="h-4 w-4" />
                                                        <span>Upload Resume</span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Upload modal */}
                {isUploadModalOpen && (
                    <ResumeUpload
                        isOpen={isUploadModalOpen}
                        onClose={() => setIsUploadModalOpen(false)}
                        onUploadComplete={fetchUploads}
                        isDev={isDev}
                        devFallbackUUID={DEV_FALLBACK_UUID}
                    />
                )}

                {/* PDF preview modal */}
                {isPreviewModalOpen && (
                    <ResumePreviewModal
                        isOpen={isPreviewModalOpen}
                        onClose={() => setIsPreviewModalOpen(false)}
                        fileUrl={previewFileUrl}
                    />
                )}

                {/* Job description modal */}
                {isJobModalOpen && (
                    <JobDescriptionModal
                        isOpen={isJobModalOpen}
                        onClose={() => setIsJobModalOpen(false)}
                        jobDescription={jobDescription}
                    />
                )}

                {/* Error banner */}
                {error && (
                    <div className="mt-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                        <div className="flex items-center">
                            <AlertCircle className="h-5 w-5 mr-2" />
                            <span>{error}</span>
                        </div>
                    </div>
                )}

                {/* Loading spinner */}
                {loading && (
                    <div className="flex justify-center items-center mt-4">
                        <Loader2 className="h-8 w-8 animate-spin text-green-600" />
                    </div>
                )}

                {/* Footer info about how many displayed */}
                {filteredUploads.length > 0 && (
                    <div className="mt-4 flex justify-between items-center text-sm text-gray-500">
                        <span>
                            Showing {filteredUploads.length} of {uploads.length} resumes
                        </span>
                    </div>
                )}
            </main>

            <style jsx global>{`
                @keyframes progress {
                    0% { transform: translateX(-100%); }
                    50% { transform: translateX(0); }
                    100% { transform: translateX(100%); }
                }
                .animate-progress {
                    animation: progress 2s infinite linear;
                }
            `}</style>
        </div>
    );
};

export default ResumeDashboard;
