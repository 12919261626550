import React, { useState } from 'react';
import { useAuth } from '../contexts/useAuth/useAuth';
import { ReviewCard } from '../concrete/ReviewCard/ReviewCard';
import { SAMPLE_REVIEWS } from '../../utilities/constants';

export const DevTestPage = () => {
  // const user = { user_metadata: { profile: {}, credits: 1000, email: 'testuser@gmail.com', id: 'test-user' } };
  const [isOpen, setIsOpen] = useState(true);
  const { user = null } = useAuth();
  const userId = user?.id;
  const handlePricingSubmit = (selectedTier) => {
    console.log('Pricing Details Submitted: ', selectedTier);
  };
  const handlePurchaseSuccess = (response) => {
    console.log('Purchase Success: ', response);
  };

  const PEOPLE = [
    {
      image_link: 'https://lh3.googleusercontent.com/a/ACg8ocJA_NReprwRTtSuDCxzvAjaKW_EDWPKZbLhDMmARR3CRhAM7Tef=s96-c',
      first_name: 'Christy',
      last_initial: 'L',
      review:
        'Been job hunting for months until I found Robo. The auto-application feature saved me so much time - applied to 50+ jobs in one sitting. Got 3 interviews last week!',
      stars: 5,
      company: 'Google',
    },
  ];
  const currentJobIndex = 0;
  const messages = [
    'Top companies - low effort',
    'Accelerate your job search!',
    'Apply effortlessly to leading firms',
    'Your career, powered by RoboApply',
    'Seamlessly apply to tech giants',
  ];

  return (
    <div className="h-[400px] sm:h-[380px] md:[280px] relative overflow-hidden">
      <div className="absolute inset-0">
        {SAMPLE_REVIEWS.map((job, index) => (
          <ReviewCard
            job={job}
            isActive={index === currentJobIndex}
            style={{
              transform: `translateY(${(index - currentJobIndex) * 100}%)`,
              opacity: index === currentJobIndex ? 1 : 0,
              pointerEvents: index === currentJobIndex ? 'auto' : 'none',
            }}
            message={messages[currentJobIndex % messages.length]}
            key={`${job.first_name}-${job.last_initial}-${index}`}
          />
        ))}
      </div>
    </div>
  );
};
