// src/api/JobSearchApi.js

// API Configuration
export const API_CONFIG = {
    BASE_URL: 'https://robo-resumeably-backend.onrender.com/api/search',
    TIMEOUT: 30000,
    RETRY_ATTEMPTS: 3,
    RETRY_DELAY: 1000,
};

// Constants
export const JOB_TYPES = {
    FULL_TIME: 'fulltime',
    PART_TIME: 'parttime',
    CONTRACT: 'contract',
    INTERNSHIP: 'internship',
    TEMPORARY: 'temporary',
};

export const EXPERIENCE_LEVELS = {
    ENTRY: 'entry',
    MID: 'mid',
    SENIOR: 'senior',
    EXECUTIVE: 'executive',
};

export const SITES = {
    INDEED: 'indeed',
    LINKEDIN: 'linkedin',
    GLASSDOOR: 'glassdoor',
    GOOGLE: 'google',
};

// Custom API Error class
export class APIError extends Error {
    constructor(message, status, code, data) {
        super(message);
        this.name = 'APIError';
        this.status = status;
        this.code = code;
        this.data = data;
    }
}

// Cache manager implementation
class CacheManager {
    constructor(defaultTTL = 15 * 60 * 1000) { // 15 minutes
        this.cache = new Map();
        this.defaultTTL = defaultTTL;
    }

    setItem(key, value, ttl = this.defaultTTL) {
        const item = {
            value,
            timestamp: Date.now(),
            ttl,
        };
        this.cache.set(key, JSON.stringify(item));
        this._cleanup();
    }

    getItem(key) {
        const item = this.cache.get(key);
        if (!item) return null;

        const { value, timestamp, ttl } = JSON.parse(item);
        if (Date.now() - timestamp > ttl) {
            this.cache.delete(key);
            return null;
        }

        return value;
    }

    _cleanup() {
        const now = Date.now();
        for (const [key, item] of this.cache.entries()) {
            const { timestamp, ttl } = JSON.parse(item);
            if (now - timestamp > ttl) {
                this.cache.delete(key);
            }
        }
    }
}

// API Client Implementation
class JobSearchClient {
    constructor(baseUrl = API_CONFIG.BASE_URL) {
        this.baseUrl = baseUrl;
        this.cache = new CacheManager();
    }

    async fetchWithRetry(endpoint, options = {}, retries = API_CONFIG.RETRY_ATTEMPTS) {
        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), API_CONFIG.TIMEOUT);

            const response = await fetch(`${this.baseUrl}${endpoint}`, {
                ...options,
                signal: controller.signal,
            });

            clearTimeout(timeoutId);

            if (!response.ok) {
                let errorData;
                try {
                    errorData = await response.json();
                } catch {
                    errorData = await response.text();
                }
                throw new APIError(
                    `API request failed with status ${response.status}`,
                    response.status,
                    errorData?.code,
                    errorData
                );
            }

            const data = await response.json();
            return data;
        } catch (error) {
            if (retries > 0 && error.name !== 'AbortError') {
                const delay = API_CONFIG.RETRY_DELAY * (API_CONFIG.RETRY_ATTEMPTS - retries + 1);
                await new Promise(resolve => setTimeout(resolve, delay));
                return this.fetchWithRetry(endpoint, options, retries - 1);
            }
            throw error;
        }
    }

    async searchJobs({
        search_term,
        location,
        sites = [SITES.INDEED],
        job_types,
        results_wanted = 20,
        hours_old,
        is_remote,
        country,
        min_salary,
        max_salary,
        experience_level,
        page = 1,
        per_page = 20,
    }) {
        if (!search_term || !location) {
            throw new Error('Search term and location are required');
        }

        const request = {
            search_term,
            location,
            sites,
            job_types,
            results_wanted,
            hours_old,
            is_remote,
            country,
            min_salary,
            max_salary,
            experience_level,
            page,
            per_page,
            api_version: '1050',
        };

        const cacheKey = `search_${JSON.stringify(request)}`;

        // Check cache first
        const cachedResponse = this.cache.getItem(cacheKey);
        if (cachedResponse) {
            return cachedResponse;
        }

        try {
            const response = await this.fetchWithRetry('', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request),
            });

            // Cache the response
            this.cache.setItem(cacheKey, response);
            return response;
        } catch (error) {
            if (error instanceof APIError) {
                throw error;
            }
            throw new APIError(
                'Failed to fetch jobs',
                500,
                'REQUEST_FAILED',
                error
            );
        }
    }

    async saveJob(jobId, data) {
        return this.fetchWithRetry('/saved-jobs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_id: jobId,
                ...data,
            }),
        });
    }

    async trackApplication(jobId, data) {
        return this.fetchWithRetry('/applications', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_id: jobId,
                ...data,
            }),
        });
    }

    clearCache() {
        this.cache.clear();
    }
}

// Export a singleton instance
export const jobSearchApi = new JobSearchClient();