import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useFetcher } from 'react-router';
import { CreateProfileCardUI } from './CreateProfileCardUI';
import { getUserAvatar, postUserAvatar } from '../../../api/supabaseClient';
import { storeIsNewUser } from '../../../api/localStorageClient';
// import { useToaster } from 'react-hot-toast';
import { useToastContext } from '../../contexts/useToastContext/useToastContext';
import { createUserProfileAction } from '../../../router/routerActions';

/**
 * Returns a random integer between min and max (inclusive).
 *
 * @param {number} min - The minimum number of milliseconds (default: 1000).
 * @param {number} max - The maximum number of milliseconds (default: 5000).
 * @returns {number} - A random integer between min and max.
 */
const getRandomTime = (min = 1000, max = 5000) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const MAX_AVATAR_SIZE_MB = 5;
const JOB_TITLES = [
  'Software Engineer',
  'Senior Software Engineer',
  'Full Stack Developer',
  'Frontend Developer',
  'Backend Developer',
  'DevOps Engineer',
  'Data Scientist',
  'Data Analyst',
  'Machine Learning Engineer',
  'Product Manager',
  'Project Manager',
  'UI/UX Designer',
  'Quality Assurance Engineer',
  'Business Analyst',
  'Systems Analyst',
  'Cloud Engineer',
  'Network Engineer',
  'Security Engineer',
  'Technical Writer',
  'Web Developer',
  'Mobile Developer',
  'Game Developer',
  'Data Engineer',
  'DevSecOps Engineer',
  'Research Scientist',
  'Blockchain Developer',
  'AI Engineer',
  'Embedded Systems Engineer',
  'Site Reliability Engineer',
  'Game Designer',
  'IT Support Specialist',
  'Chief Technology Officer (CTO)',
  'Technical Project Manager',
  'Product Designer',
  'Digital Marketing Manager',
  'SEO Specialist',
  'Content Marketing Strategist',
  'Social Media Manager',
  'Email Marketing Specialist',
  'PPC Specialist',
  'Growth Hacker',
  'Brand Manager',
  'Marketing Analyst',
  'Product Marketing Manager',
  'Marketing Coordinator',
  'Affiliate Marketing Manager',
  'Public Relations Specialist',
  'Event Marketing Manager',
  'Influencer Marketing Manager',
  'Creative Director',
  'Art Director',
  'Graphic Designer',
  'Copywriter',
  'Content Strategist',
  'Web Analytics Specialist',
  'User Experience Researcher',
  'Customer Experience Manager',
  'Sales Manager',
  'Account Executive',
  'Sales Development Representative',
  'Business Development Manager',
  'Sales Operations Manager',
  'Inside Sales Representative',
  'Outside Sales Representative',
  'Territory Sales Manager',
  'Field Sales Representative',
  'Regional Sales Manager',
  'Sales Engineer',
  'Key Account Manager',
  'Customer Success Manager',
  'Lead Generation Specialist',
  'Proposal Manager',
  'Channel Sales Manager',
  'Sales Trainer',
  'Revenue Operations Manager',
  'Sales Enablement Manager',
  'Director of Sales',
  'Chief Sales Officer (CSO)',
  'Chief Marketing Officer (CMO)',
  'Head of Growth',
  'Market Research Analyst',
  'Market Intelligence Analyst',
  'Retail Marketing Manager',
  'Product Analyst',
  'Supply Chain Manager',
  'Operations Manager',
  'Logistics Coordinator',
  'Inventory Manager',
  'Vendor Manager',
  'Financial Analyst',
  'Cost Analyst',
  'Investment Analyst',
  'Compliance Manager',
  'Risk Manager',
  'Project Coordinator',
  'Program Manager',
  'Change Manager',
  'Agile Coach',
  'Scrum Master',
  'Operations Analyst',
  'Quality Control Inspector',
  'Facilities Manager',
  'Data Governance Manager',
  'Database Administrator',
  'IT Project Coordinator',
  'Help Desk Technician',
  'Technical Support Engineer',
  'System Administrator',
  'Network Administrator',
  'IT Manager',
  'Chief Information Officer (CIO)',
  'Chief Data Officer (CDO)',
  'Data Privacy Officer',
  'Compliance Analyst',
  'Training and Development Manager',
  'HR Business Partner',
  'Talent Acquisition Specialist',
  'Employee Engagement Manager',
  'Organizational Development Specialist',
  'Diversity and Inclusion Manager',
];
const validationRules = {
  username: {
    minLength: 3,
    required: true,
  },
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  resume: {
    required: true,
  },
};
const loadingMessages = [
  'We are submitting your form...',
  'This may take a few moments...',
  'Free credits are being generated...',
  'Hang tight, almost there...',
  'Dont leave we\'re scanning jobs boards...',
  'Still working, thanks for your patience!',
];

const validate = (formData) => {
  const errors = {};
  Object.keys(formData).forEach((key) => {
    // make key human-readable. from camelCase to Title Case
    const makeTitleCase = (str) => {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    };
    const validationRule = validationRules[key];
    console.log('formData: ', formData);
    const formField = formData[key];
    if (!validationRule) {
      console.log(`No validation rule found for ${key}`);
      return;
    }

    const field = makeTitleCase(key);
    if (validationRule?.required && !formField) {
      errors[key] = `The ${field} field is required`;
    }
    if (validationRule?.minLength && formField.length < validationRule.minLength && formField.length > 0) {
      errors[key] = `${field} field must be at least ${validationRule?.minLength} characters long`;
    }
  });

  if (!Object.keys(errors).length > 0) {
    return null;
  }
  return errors;
};

export const CreateProfileCard = ({ className, user, userProfile, onComplete = (data) => { }, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState('');
  const [error, setError] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const { showToast } = useToastContext();
  const [formData, setFormData] = useState({
    username: '',
    jobTitle: '',
    avatar: null,
    avatarUrl: user?.user_metadata?.avatar_url || './favicon-96x96.png',
    firstName: '',
    lastName: '',
    resume: null,
  });

  const fetcher = useFetcher();
  const intervalRef = useRef([]);

  useEffect(() => {
    storeIsNewUser('true');
  }, []);
  console.log('fetcher: ', fetcher);

  useEffect(() => {
    // If user has a full name in meta data, split it into first and last name and prefill form fields.
    // Note: This may not handle all full name cases (like having two last names) but should work for most.

    const fullName = user?.user_metadata?.full_name;
    if (fullName) {
      const [firstName, lastName] = fullName.split(' ');
      setFormData((prev) => ({
        ...prev,
        firstName: firstName || '',
        lastName: lastName || '',
        username: user.user_metadata.username || '',
      }));
    }
    if (userProfile?.dice_specific_email) {
      setShowLoginForm(false);
    }
  }, [user, userProfile]);

  const handleSubmitResponse = useCallback(
    (data) => {
      setLoading(false);
      setLoadingStep('');

      if (data?.showExistingDiceLoginForm) {
        setShowLoginForm(true);
        showToast('Existing Dice account detected', 'success');
        return;
      }

      if (!data?.success) {
        //get error type data.error.response.data.detail
        const details = data?.error?.response?.data?.detail || [];
        console.log('Create Profile Failed: details: ', details);
        const detailCount = details?.length || 0;
        if (typeof details === 'string' && details.includes('username_length')) {
          showToast('Username must be at least 3 characters long', 'error');
          return;
        }
        // if no details in error response, show generic error message
        if (detailCount === 0) {
          console.log('Create Profile Failed: data: ', data);
          const errorMessage = data?.message || 'Profile creation failed due to an unknown error';
          showToast(errorMessage, 'error');
          return;
        }

        const getValidationErrors = (data) => {
          const errors = [];
          const details = data?.error?.response?.data?.detail;
          if (!details) return errors;
          details.forEach((error) => {
            if (error.type === 'value_error') {
              errors.push({ msg: error.msg, elementName: error.loc[1] });
            }
          });
          return errors;
        };

        const validationErrors = getValidationErrors(data);
        // if there are no validation errors, show generic error message
        if (validationErrors.length === 0) {
          console.log('Create Profile Failed: data: ', data);
          const errorMessage = data?.message || 'Profile creation failed';
          showToast(errorMessage, 'error');
          return;
        }
        const allErrorsAreValidationErrors = validationErrors.length === detailCount && detailCount > 0;

        // Set error fields to highlight fields with errors
        const errorInputs = validationErrors.map((error) => error.elementName);
        setErrorFields(errorInputs);

        // Set Focus on the first element with an error
        const firstErrorElement = validationErrors[0].elementName;
        const element = document.getElementById(firstErrorElement);
        if (element) element.focus();

        // if errors are not just validation errors, show generic error message
        if (!allErrorsAreValidationErrors) {
          console.log('Create Profile Failed: data: ', data);
          const errorMessage = data?.message || 'Profile creation failed';
          showToast(errorMessage, 'error');
          return;
        }

        // if there are multiple validation errors, show generic error message and highlight all fields with errors
        const genericMessage = data?.message || 'Profile creation failed';
        const specificMessage = `Please correct the ${validationErrors[0].elementName} field`;
        const errorMessage = validationErrors.length > 1 ? genericMessage : specificMessage;
        showToast(errorMessage, 'error');
        return;
      }

      setShowConfetti(true);
      showToast('Profile created successfully!', 'success');
      setTimeout(() => {
        setShowConfetti(false);
        onComplete(data);
      }, 3000);
    },
    [onComplete, showToast],
  );

  // useEffect(() => {
  //   // when there is fetcher data, handle the response
  //   if (fetcher.data) {
  //     console.log('CreateProfileCard fetcher.data: ', fetcher.data);
  //     handleSubmitResponse(fetcher.data);
  //   }
  // }, [fetcher.data, handleSubmitResponse]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_AVATAR_SIZE_MB * 1024 * 1024) {
      showToast(`File size must be less than ${MAX_AVATAR_SIZE_MB}MB`, 'error');
      setError(`File size must be less than ${MAX_AVATAR_SIZE_MB}MB`);
      return;
    }

    try {
      setLoading(true);
      setLoadingStep('Uploading profile picture...');

      const fileExt = file.name.split('.').pop();
      const fileName = `${user?.id}_${Date.now()}.${fileExt}`;
      const filePath = `avatars/${fileName}`;

      const { error: uploadError } = await postUserAvatar(filePath, file);

      if (uploadError) {
        throw new Error(`Error uploading image: ${uploadError.message}`);
      }

      const { data: urlData } = getUserAvatar(filePath);

      setFormData((prev) => ({
        ...prev,
        avatar: file,
        avatarUrl: urlData.publicUrl,
      }));

      showToast('Profile picture updated successfully!', 'success');
    } catch (error) {
      console.error('Error uploading image:', error);
      showToast('Error uploading image. Please try again.', 'error');
      setError(`Error uploading image: ${error.message}`);
    } finally {
      setLoading(false);
      setLoadingStep('');
    }
  };

  const handleResumeUpload = (e) => {
    const file = e?.target?.files[0];
    if (!file) return;

    const allowedTypes = ['.pdf', '.doc', '.docx'];
    const fileExt = `.${file.name.split('.').pop().toLowerCase()}`;

    if (!allowedTypes.includes(fileExt)) {
      showToast('Please upload a PDF, DOC, or DOCX file', 'error');
      setError('Please upload a PDF, DOC, or DOCX file');
      return;
    }

    setFormData((prev) => ({ ...prev, resume: file }));
    setErrorFields((prev) => prev.filter((field) => field !== 'resume'));
    showToast('Resume selected successfully!', 'success');
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    // setisSubmitting(true);
    e.preventDefault();
    setError(null);
    // 1) Immediately schedule loading-step timeouts
    setLoadingStep('Submitting your profile...');
    setMessageIndex(0); // start from the first message

    // Start cycling messages every 5 seconds:
    intervalRef.current = setInterval(() => {
      setMessageIndex((prevIndex) => {
        // Cycle through each message in loadingMessages
        const nextIndex = (prevIndex + 1) % loadingMessages.length;
        return nextIndex;
      });
    }, 5000);

    const validationErrors = validate(formData);

    if (validationErrors) {
      const errorInputs = Object.keys(validationErrors);
      setErrorFields(errorInputs);
      const firstErrorElement = errorInputs[0];
      const element = document.getElementById(firstErrorElement);
      if (element) element.focus();
      showToast(`${validationErrors[firstErrorElement]}`, 'error');
      // Clear all timeouts if validation fails
      setLoading(false);
      setLoadingStep('');
      return;
    }

    const submitData = new FormData();
    // submitData.append('actionType', 'createProfile');
    submitData.append('id', user.id);
    submitData.append('first_name', formData.firstName);
    submitData.append('last_name', formData.lastName);
    submitData.append('email', user.email);
    submitData.append('username', formData.username);
    submitData.append('job_title', formData.jobTitle);
    submitData.append('resume', formData.resume);
    console.log('FormData keys:', Array.from(submitData.keys()));
    console.log('FormData values:', Array.from(submitData.values()));

    try {
      const submitResponse = await createUserProfileAction({ request: { formData: () => submitData } });
      //
      // const mockApiCall = () => {
      //   return new Promise((resolve) => {
      //     setTimeout(() => {
      //       resolve({ data: { error: 'Fake Error' } });
      //     }, 10000); // Simulate a 5-second delay
      //   });
      // };
      // const submitResponse = await mockApiCall();
      // Handle the API response
      handleSubmitResponse(submitResponse);
    } catch (error) {
      console.error('Error submitting profile:', error);
      showToast('Error submitting profile. Please try again.', 'error');
      setError('Error submitting profile. Please try again.');

      // Clear all active timeouts due to error
      // 5) Clear timeouts upon success
      setLoading(false);
      setLoadingStep('');
    } finally {
      // Stop cycling messages & reset
      clearInterval(intervalRef.current);
      setLoadingStep('');
      intervalRef.current = null;
      setLoading(false);
      setMessageIndex(0);
    }
  };
  // Clean up all active timeouts when the component unmounts
  // Cleanup timeouts if component unmounts
  // Clean up the interval if the component unmounts mid-submission
  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <CreateProfileCardUI
      className={className}
      user={user}
      loading={loading || fetcher.state !== 'idle'}
      loadingStep={loadingMessages[messageIndex]}
      formData={formData}
      setFormData={setFormData}
      onCompletedDiceLogin={onClose}
      handleSubmit={handleSubmit}
      setShowLoginForm={setShowLoginForm}
      showDiceLoginForm={showLoginForm}
      showConfetti={showConfetti}
      jobTitles={JOB_TITLES}
      error={error}
      errorFields={errorFields}
      setError={setError}
      handleResumeUpload={handleResumeUpload}
      handleImageUpload={handleImageUpload}
      isSubmitting={loading || fetcher.state === 'submitting'}
    />
  );
};
