import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { ArrowLeft, ExternalLink, Loader, Lock, Mail, Shield, User, Eye, EyeOff } from 'lucide-react';

import { Form, useActionData, useFetcher, useNavigate, useNavigation } from 'react-router';
import { useToastContext } from '../../contexts/useToastContext/useToastContext';
import { twMerge } from 'tailwind-merge';
import { Button } from '../../base/Button/Button';

const LoadingMessage = ({ message, className }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className={twMerge('flex flex-nowrap gap-2 items-center justify-center py-0 text-black', className)}
  >
    <Loader className="animate-spin text-blue-500" size={20} />
    <span className="text-gray-600 font-medium">{message}</span>
  </motion.div>
);

export const ExistingDiceAccountForm = ({ userId, userEmail = '', onBack, onSuccess = () => {} }) => {
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const [loadingStep, setLoadingStep] = useState(null);

  useEffect(() => {
    if (fetcher.data?.error) {
      setError(fetcher.data.error);
      setPassword('');
      return;
    }
    if (!!fetcher.data?.success && fetcher.data?.actionType === 'dice-success') {
      // Dice account connected successfully.
      // You can redirect or show a toast from the parent component if needed.

      setShowConfetti(true);
      showToast('Successfully connected your Dice account!', 'success');
      // storeHaseCompletedDiceAccount(true);
      // Route to /apply after short delay
      setTimeout(() => {
        setShowConfetti(false);
      }, 1500);
    }
  }, [showToast, fetcher.data]);

  const handleOnSubmit = (e, fetcher) => {
    e.preventDefault();
    setLoadingStep('Validating credentials...');

    // Collect form data from the event
    const submitData = new FormData(e.currentTarget);

    // Submit formData using fetcher
    fetcher.submit(submitData, {
      method: 'POST',
      action: 'profile/existing-dice-login',
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="max-w-lg mx-auto mb-3 bg-white border rounded-2xl p-6 flex flex-col gap-4"
    >
      <div className="text-center space-y-4">
        <div className="inline-flex items-center gap-2">
          <div className="inline-block p-3 rounded-full bg-blue-50 text-blue-500">
            <User size={24} />
          </div>
          <div className="inline-block p-3 rounded-full bg-green-50 text-green-500">
            <Shield size={24} />
          </div>
        </div>
        <h2 className="text-xl font-semibold text-gray-800">
          You already have an existing Dice Account (our application pool platform)
        </h2>
        <p className="text-gray-600">Please enter your Dice.com password to continue with job applications.</p>
      </div>

      <form method="post" className="space-y-6" onSubmit={(e) => handleOnSubmit(e, fetcher)}>
        <input type="hidden" name="actionType" value="existingDiceLogin" />
        <input type="hidden" name="userId" value={userId} />
        <input type="hidden" name="email" value={userEmail} />

        <div className="space-y-4">
          <div className="space-y-3">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                name="diceEmail"
                className="w-full py-2 px-3 pl-10 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(false);
                }}
                placeholder="Enter Dice.com email"
                required
              />
            </div>

            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                name="dicePassword"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(false);
                }}
                placeholder="Dice Account Password"
                required
                autoFocus
                disabled={fetcher.state === 'submitting'}
                className={`w-full py-2 pl-10 pr-3 border
                  ${error ? 'border-red-400 bg-red-50' : ''}
                  ${!error && fetcher.state === 'submitting' ? 'border-green-500 bg-green-50' : 'border-gray-200'}
                  rounded-md text-gray-800 placeholder-gray-400
                  focus:outline-none focus:ring-2
                  ${error ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
                  focus:border-transparent
                  disabled:bg-gray-50 disabled:cursor-not-allowed
                  transition-all duration-200`}
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            <AnimatePresence>
              {error && (
                <motion.p
                  animate={{ opactiy: 1, y: -4 }}
                  exit={{ opacity: 0, y: -10 }}
                  initial={{ opacity: 1, y: -10 }}
                  className="text-red-500 text-sm"
                >
                  {error}.
                </motion.p>
              )}
            </AnimatePresence>
          </div>

          <div className="flex items-center justify-center gap-4 text-sm text-blue-500">
            <a
              href="https://www.dice.com/dashboard/login"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-1 hover:text-blue-600 transition-colors"
            >
              Dice Login <ExternalLink size={14} />
            </a>
            <span className="text-gray-300">•</span>
            <a
              href="https://www.dice.com/dashboard/profiles/resume"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-1 hover:text-blue-600 transition-colors"
            >
              View Profile <ExternalLink size={14} />
            </a>
          </div>
        </div>

        <div className="space-y-3">
          {/*<Button*/}
          {/*  type="submit"*/}
          {/*  className="relative w-full py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-md hover:from-blue-600 hover:to-purple-600 disabled:opacity-40 transition-colors"*/}
          {/*  disabled={fetcher.state === 'submitting'}*/}
          {/*>*/}
          {/*  <AnimatePresence>*/}
          {/*    {fetcher.state === 'submitting' && loadingStep && (*/}
          {/*      <LoadingMessage className={'absolute top-1/2 right-1/2'} message={loadingStep} />*/}
          {/*    )}*/}
          {/*  </AnimatePresence>*/}
          {/*  {fetcher.state === 'submitting' ? { loadingStep } : 'Validate Account'}*/}
          {/*</Button>*/}
          <Button
            type="submit"
            className={`w-full rounded-md bg-gradient-to-r from-blue-500 to-purple-500 font-bold text-white hover:from-blue-600 hover:to-purple-600 ${
              fetcher.state === 'submitting' ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={fetcher.state === 'submitting'}
          >
            {fetcher.state === 'submitting' ? (
              <span className="flex flex-nowrap items-center justify-center space-x-2">
                <Loader className="animate-spin" size={16} />
                <span>{loadingStep}...</span>
              </span>
            ) : (
              'Validate Account'
            )}
          </Button>

          <button
            type="button"
            onClick={onBack}
            className="w-full py-2 flex items-center justify-center space-x-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50 transition-colors"
            disabled={fetcher.state === 'submitting'}
          >
            <ArrowLeft size={16} />
            <span>Back to Profile Creation</span>
          </button>
        </div>
      </form>
    </motion.div>
  );
};

// export const ExistingDiceAccountForm = ({ userEmail = '', onSubmit, onBack, loading, loadingStep }) => {
//   const [password, setPassword] = useState(''); //TODO: debounce(or throttle?) inputs. Maybe make custom
//   const [validating, setValidating] = useState(false);
//   const [email, setEmail] = useState(userEmail);
//   const [error, setError] = useState(false);
//   const { addToast } = useToastContext();
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setValidating(true);
//     setError(false);
//
//     try {
//       const emailToUse = email?.length ? email : userEmail;
//       console.log('emailToUse: ', emailToUse);
//
//       const response = await validateDiceCredentials(emailToUse, password);
//       if (!response) {
//         setError(true);
//         setPassword('');
//         handleError('Unable to verify credentials. Please try again.');
//         return;
//       }
//
//       if (response?.data?.status === 'success' && response?.data?.message === 'Profile credentials valid') {
//         // If validation successful, call parent's onSubmit
//         if (!onSubmit) return;
//         onSubmit({
//           password: password,
//           dice_specific_email: email || null,
//         });
//       } else {
//         setError(true);
//         setPassword('');
//         handleError('Invalid credentials. Please try again.');
//       }
//     } catch (error) {
//       setError(true);
//       setPassword('');
//       handleError('Unable to verify credentials. Please try again.');
//     } finally {
//       setValidating(false);
//     }
//   };
//
//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       exit={{ opacity: 0, y: -20 }}
//       className="max-w-md mx-auto mb-3 space-y-6"
//     >
//       <div className="text-center space-y-4">
//         <div className="inline-flex items-center gap-2">
//           <div className="inline-block p-3 rounded-full bg-blue-50 text-blue-500">
//             <User size={24} />
//           </div>
//           <div className="inline-block p-3 rounded-full bg-green-50 text-green-500">
//             <Shield size={24} />
//           </div>
//         </div>
//         <h2 className="text-xl font-semibold text-gray-800">
//           You already have an existing Dice Account (our application pool platform)
//         </h2>
//         <p className="text-gray-600">Please enter your Dice.com password to continue with job applications.</p>
//       </div>
//
//       <form onSubmit={handleSubmit} className="space-y-6">
//         <div className="space-y-4">
//           {/* Email section */}
//           <div className="space-y-3">
//             <div className="relative">
//               <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
//               <input
//                 className={`w-full py-2 px-3 pl-10  border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all`}
//                 type="email"
//                 value={email || ''}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter Dice.com email"
//                 // className="w-full py-2 px-3 border border-gray-200 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                 required={true}
//               />
//             </div>
//
//             {/* Password input */}
//             <div className="relative">
//               <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
//               <input
//                 type="password"
//                 value={password}
//                 onChange={(e) => {
//                   setPassword(e.target.value);
//                   setError(false);
//                 }}
//                 placeholder="Dice Account Password"
//                 required
//                 autoFocus
//                 disabled={loading || validating}
//                 className={`w-full py-2 pl-10 pr-3 border
//                                 ${error ? 'border-red-500 bg-red-50' : ''}
//                                 ${!error && validating ? 'border-green-500 bg-green-50' : 'border-gray-200'}
//                                 rounded-md text-gray-800 placeholder-gray-400
//                                 focus:outline-none focus:ring-2
//                                 ${error ? 'focus:ring-red-500' : 'focus:ring-blue-500'}
//                                 focus:border-transparent
//                                 disabled:bg-gray-50 disabled:cursor-not-allowed
//                                 transition-all duration-200`}
//               />
//             </div>
//           </div>
//
//           {/* Dice.com links */}
//           <div className="flex items-center justify-center gap-4 text-sm text-blue-500">
//             <a
//               href="https://www.dice.com/dashboard/login"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center gap-1 hover:text-blue-600 transition-colors"
//             >
//               Dice Login <ExternalLink size={14} />
//             </a>
//             <span className="text-gray-300">•</span>
//             <a
//               href="https://www.dice.com/dashboard/profiles/resume"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="flex items-center gap-1 hover:text-blue-600 transition-colors"
//             >
//               View Profile <ExternalLink size={14} />
//             </a>
//           </div>
//         </div>
//
//         <AnimatePresence>
//           {(loading || validating) && loadingStep && <LoadingMessage message={loadingStep} />}
//         </AnimatePresence>
//
//         <div className="space-y-3">
//           <button
//             type="submit"
//             className="w-full py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-md hover:from-blue-600 hover:to-purple-600 disabled:opacity-50 transition-colors"
//             disabled={loading || validating}
//           >
//             {loading || validating ? (
//               <span className="flex items-center justify-center space-x-2">
//                 <Loader className="animate-spin" size={16} />
//                 <span>{validating ? 'Validating...' : 'Connecting...'}</span>
//               </span>
//             ) : (
//               'Validate Account'
//             )}
//           </button>
//
//           <button
//             type="button"
//             onClick={onBack}
//             className="w-full py-2 flex items-center justify-center space-x-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50 transition-colors"
//             disabled={loading || validating}
//           >
//             <ArrowLeft size={16} />
//             <span>Back to Profile Creation</span>
//           </button>
//         </div>
//       </form>
//     </motion.div>
//   );
// };
