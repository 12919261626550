// src/components/SiteSelector.js
import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle2 } from 'lucide-react';
// src/config/jobSites.js
// src/config/jobSites.js
// src/config/jobSites.js
export const SITE_CONFIG = {
    indeed: {  // Changed to lowercase
        name: 'Indeed',
        logo: '/indeed-logo.png',
        color: 'bg-blue-100',
        textColor: 'text-blue-700',
        borderColor: 'border-blue-500',
        hoverColor: 'hover:bg-blue-50',
        enabled: true,
        defaultSelected: true,
        priority: 1,
        isPremium: false
    },
    linkedin: {  // Changed to lowercase
        name: 'LinkedIn',
        logo: '/linkedin-logo.png',
        color: 'bg-amber-100',
        textColor: 'text-amber-700',
        borderColor: 'border-amber-500',
        hoverColor: 'hover:bg-amber-50',
        enabled: true,
        defaultSelected: false,
        priority: 2,
        isPremium: true
    },
    google: {  // Changed to lowercase
        name: 'Google Jobs',
        logo: '/google-logo.png',
        color: 'bg-purple-100',
        textColor: 'text-purple-700',
        borderColor: 'border-purple-500',
        hoverColor: 'hover:bg-purple-50',
        enabled: true,
        defaultSelected: true,
        priority: 3,
        isPremium: false
    },
    glassdoor: {  // Changed to lowercase
        name: 'Glassdoor',
        logo: '/glassdoor-logo.png',
        color: 'bg-teal-100',
        textColor: 'text-teal-700',
        borderColor: 'border-teal-500',
        hoverColor: 'hover:bg-teal-50',
        enabled: true,
        defaultSelected: false,
        priority: 4,
        isPremium: false
    },
    zip_recruiter: {  // Added with correct underscore format
        name: 'ZipRecruiter',
        logo: '/ziprecruiter-logo.png',
        color: 'bg-green-100',
        textColor: 'text-green-700',
        borderColor: 'border-green-500',
        hoverColor: 'hover:bg-green-50',
        enabled: true,
        defaultSelected: false,
        priority: 5,
        isPremium: false
    }
};
export const SiteSelector = ({ selectedSites, onSiteChange, disabled }) => {
    return (
        <div className="flex gap-2 items-center">
            <span className="text-sm text-gray-600 font-medium">Job Sites:</span>
            <div className="flex flex-wrap gap-2">
                {Object.entries(SITE_CONFIG)
                    .filter(([_, config]) => config.enabled)
                    .sort((a, b) => a[1].priority - b[1].priority)
                    .map(([siteKey, config]) => (
                        <motion.button
                            key={siteKey}
                            onClick={() => !disabled && onSiteChange(siteKey)}
                            className={`flex items-center px-3 py-1.5 rounded-full text-sm 
                transition-all duration-200 border ${selectedSites.includes(siteKey)
                                    ? `${config.color} ${config.textColor} ${config.borderColor}`
                                    : 'bg-gray-100 text-gray-600 border-gray-200'
                                } ${disabled ? 'opacity-50 cursor-not-allowed' : config.hoverColor}`}
                            whileHover={!disabled ? { scale: 1.02 } : undefined}
                            whileTap={!disabled ? { scale: 0.98 } : undefined}
                        >
                            <img
                                src={config.logo}
                                alt={config.name}
                                className="w-4 h-4 mr-2"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/favicon.ico';
                                }}
                            />
                            {config.name}
                            {selectedSites.includes(siteKey) && (
                                <CheckCircle2 className="w-4 h-4 ml-1" />
                            )}
                        </motion.button>
                    ))}
            </div>
        </div>
    );
};