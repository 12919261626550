import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { RoboApplyLogo } from '../../concrete/RoboApplyLogo/RoboApplyLogo';
import { NavLinks } from '../../concrete/Header/NavLinks';
import { UserProfileButton } from '../../concrete/Header/UserProfileButton';
import { MobileNavMenu } from '../../concrete/Header/MobileNavMenu';
import { HamburgerPill } from '../../concrete/Header/HamburgerPill';
import { useHeaderContext } from '../../contexts/useHeaderContext/useHeaderContext';
import { Menu, X } from 'lucide-react';
import { signOut } from '../../../api/supabaseClient';
import { Link } from 'react-router';

const HeaderBaseLogo = forwardRef((props, forwardedRef) => {
  const { children = null, ...restProps } = props;
  return children ? (
    <Link to={'/'} ref={forwardedRef} {...restProps} data-comp-id="custom-logo">
      {children}
    </Link>
  ) : (
    <Link to={'/'} data-comp-id="custom-logo">
      <RoboApplyLogo ref={forwardedRef} {...restProps} />
    </Link>
  );
});
HeaderBaseLogo.displayName = 'Logo';

const HeaderBaseNav = forwardRef((props, forwardedRef) => {
  const { children, userId, credits, ...restProps } = props;
  return (
    <nav ref={forwardedRef} {...restProps} role="navigation">
      {children || <NavLinks userId={userId} credits={credits} />}
    </nav>
  );
});
HeaderBaseNav.displayName = 'Nav';

const HeaderBaseMobileMenuToggle = forwardRef((props, forwardedRef) => {
  const { isAuthenticated } = props;
  const { isMenuOpen: contextIsMenuOpen, setIsMenuOpen, credits } = useHeaderContext();
  const { isMenuOpen = contextIsMenuOpen, className, children = null, ...restProps } = props;

  if (children) {
    return (
      <button ref={forwardedRef} className={className} {...restProps} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {children}
      </button>
    );
  }

  if (isAuthenticated) {
    return (
      <div className={twMerge('menu-toggle-container md:hidden', className)}>
        <HamburgerPill
          ref={forwardedRef}
          isMenuOpen={isMenuOpen}
          credits={credits}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          {...restProps}
        />
      </div>
    );
  }
  return (
    <button
      ref={forwardedRef}
      className={twMerge('menu-toggle-anon md:hidden', className)}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      {...restProps}
    >
      {isMenuOpen ? <X /> : <Menu />}
    </button>
  );
});
HeaderBaseMobileMenuToggle.displayName = 'MobileMenuToggle';

const HeaderBaseMobileMenu = forwardRef((props, forwardedRef) => {
  const { userId, credits, avatarUrl, username, email, jobTitle, children = null, ...restProps } = props;
  const { hasUserProfile, setIsMenuOpen, isMenuOpen } = useHeaderContext();
  console.log('HeaderBaseMobileMenu => isMenuOpen: ', isMenuOpen);
  if (!isMenuOpen) return null;
  return children ? (
    <div ref={forwardedRef} {...restProps}>
      children
    </div>
  ) : (
    <MobileNavMenu
      userId={userId}
      credits={credits}
      avatarUrl={avatarUrl}
      username={username}
      email={email}
      jobTitle={jobTitle}
      setIsMenuOpen={setIsMenuOpen}
      handleLogOut={signOut}
      ref={forwardedRef}
      {...restProps}
    />
  );
});

HeaderBaseMobileMenu.displayName = 'MobileMenu';

const HeaderBaseActions = forwardRef((props, forwardedRef) => {
  const { children = null, ...restProps } = props;
  return (
    <div ref={forwardedRef} {...restProps}>
      {children}
    </div>
  );
});
HeaderBaseActions.displayName = 'Actions';

const HeaderBase = forwardRef((props, forwardedRef) => {
  const { children = null, className, ...restProps } = props;

  // const headerClassName = twMerge(' z-50  grid grid-cols-6 grid-rows-1 gap-4 items-center', className);
  return (
    <header ref={forwardedRef} className={className} {...restProps}>
      {children}
    </header>
  );
});

HeaderBase.displayName = 'HeaderBase';
HeaderBase.Root = HeaderBase;
HeaderBase.Logo = HeaderBaseLogo;
HeaderBase.Nav = NavLinks;
HeaderBase.MobileMenu = HeaderBaseMobileMenu;
HeaderBase.MobileMenuToggle = HeaderBaseMobileMenuToggle;
HeaderBase.Actions = HeaderBaseActions;
HeaderBase.Profile = UserProfileButton;

export { HeaderBase };
