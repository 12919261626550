import { ModalProvider } from '../useModalContext/useModalContext';
import { HeaderProvider } from '../useHeaderContext/useHeaderContext';
import { ToastProvider } from '../useToastContext/useToastContext';

// This is where you would add any new UI component related context providers.
export const UIProviders = ({ children }) => {
  return (
    <HeaderProvider>
      <ModalProvider>{children}</ModalProvider>
    </HeaderProvider>
  );
};
