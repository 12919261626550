import { showToast } from './toastService';

/**
 * Handles errors and optionally logs them or shows a toast notification.
 * @param {Object} options - Options for handling the error.
 * @param {Error | string} options.error - The error object or message.
 * @param {boolean} [options.showToast=true] - Whether to display a toast notification.
 * @param {boolean} [options.logError=true] - Whether to log the error to the console.
 * @param {string} [options.toastType='error'] - The type of toast to display ('error', 'info', 'success', etc.).
 * @param {string} [options.message] - A custom message to show in the toast. Defaults to the error message.
 */

const handleErrorDefaults = {
  showToast: true,
  logError: true,
  toastType: 'error',
  error: null,
};
export const handleError = (args) => {
  let data = typeof args === 'string' ? { ...handleErrorDefaults, message: args } : { ...handleErrorDefaults, ...args };
  const { error, showToast: shouldShowToast, logError, toastType, message } = { ...handleErrorDefaults, ...data };
  const errorMessage = error?.message || error?.toString() || 'An unknown error occurred';

  // Log the error to the console
  if (logError) {
    console.error('[Error Handler]:', error);
  }

  // Show a toast notification if enabled
  if (shouldShowToast) {
    showToast(message || errorMessage, toastType, {
      persistent: false,
      duration: 5000,
    });
  }
};
