// src/components/SearchInput.js
import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const SearchInput = ({
    value,
    onChange,
    placeholder,
    suggestions = [],
    icon: Icon,
    onSearch,
    onKeyPress, // Add new prop for handling Enter key
    disabled,
    className = ''
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef(null);

    const filteredSuggestions = suggestions
        .filter(s => s.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 5);

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setHighlightedIndex(prev =>
                    prev < filteredSuggestions.length - 1 ? prev + 1 : prev
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setHighlightedIndex(prev => prev > 0 ? prev - 1 : -1);
                break;
            case 'Enter':
                e.preventDefault();
                if (highlightedIndex >= 0) {
                    onChange(filteredSuggestions[highlightedIndex]);
                    setIsOpen(false);
                }
                // Call both onSearch and onKeyPress if provided
                onSearch?.();
                onKeyPress?.(e);
                break;
            case 'Escape':
                setIsOpen(false);
                break;
            default:
                break;
        }
    };

    const handleChange = (e) => {
        onChange(e.target.value);
        // Only show suggestions dropdown when typing
        setIsOpen(true);
        // Reset highlighted index when input changes
        setHighlightedIndex(-1);
    };

    return (
        <div className="relative">
            <div className="relative">
                {Icon && (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Icon className="w-5 h-5 text-gray-400" />
                    </div>
                )}
                <input
                    ref={inputRef}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setTimeout(() => setIsOpen(false), 200)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    disabled={disabled}
                    className={`w-full ${Icon ? 'pl-10' : 'pl-3'} pr-3 py-2 border border-gray-300 
                        rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 
                        focus:border-blue-500 ${disabled ? 'bg-gray-100' : ''} ${className}`}
                />
            </div>

            <AnimatePresence>
                {isOpen && filteredSuggestions.length > 0 && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute z-50 w-full mt-1 bg-white shadow-lg rounded-lg 
                            border border-gray-200 max-h-60 overflow-auto"
                    >
                        {filteredSuggestions.map((suggestion, index) => (
                            <div
                                key={suggestion}
                                className={`px-4 py-2 cursor-pointer ${index === highlightedIndex ? 'bg-blue-50' : ''
                                    } hover:bg-blue-50`}
                                onClick={() => {
                                    onChange(suggestion);
                                    setIsOpen(false);
                                    // Trigger search when clicking a suggestion
                                    onSearch?.();
                                }}
                            >
                                {suggestion}
                            </div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};