import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Bot,
  Target,
  Send,
  ExternalLink,
  X,
  Loader,
  ArrowRight,
  FileText,
  Briefcase,
  Rocket,
  Star,
  Crown,
  Zap,
  Check,
  AlertTriangle,
} from 'lucide-react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { createClient } from '@supabase/supabase-js';
import { useToast } from '../../Toast';
import confetti from 'canvas-confetti';
import { supabase } from '../../../supabaseConfig';
import { Button } from '../../base/Button/Button';
import { toast } from 'react-hot-toast';
const FloatingIcon = ({ Icon, label, color, delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      duration: 0.8,
      delay,
      type: 'spring',
      stiffness: 100,
    }}
    className="relative flex flex-col items-center"
  >
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`w-16 h-16 md:w-20 md:h-20 ${color} rounded-2xl shadow-xl flex items-center justify-center relative overflow-hidden group`}
    >
      <motion.div
        className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-white/10"
        initial={false}
        animate={{ scale: [1, 2], opacity: [0.5, 0] }}
        transition={{ duration: 1, repeat: Infinity }}
      />
      <Icon className="w-8 h-8 md:w-10 md:h-10 text-white" />
    </motion.div>
    <motion.span
      className="mt-2 md:mt-4 text-xs md:text-sm font-medium text-gray-600 text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: delay + 0.3 }}
    >
      {label}
    </motion.span>
  </motion.div>
);

const PulseLine = ({ direction = 'horizontal', className }) => {
  const isHorizontal = direction === 'horizontal';

  return (
    <motion.div
      className={`${className} ${isHorizontal ? 'h-0.5' : 'w-0.5'}`}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8, delay: 0.5 }}
      style={{ transformOrigin: isHorizontal ? 'left' : 'top' }}
    >
      <div className="relative w-full h-full">
        <div
          className={`absolute inset-0 ${isHorizontal
            ? 'bg-gradient-to-r from-blue-500/30 to-purple-500/30'
            : 'bg-gradient-to-b from-blue-500/30 to-purple-500/30'
            }`}
        />
        <motion.div
          className={`absolute ${isHorizontal ? 'h-full w-1/4 bg-gradient-to-r' : 'w-full h-1/4 bg-gradient-to-b'
            } from-blue-500 to-purple-500`}
          animate={isHorizontal ? { x: ['0%', '400%'], opacity: [0, 1, 0] } : { y: ['0%', '400%'], opacity: [0, 1, 0] }}
          transition={{
            duration: 3,
            repeat: Infinity,
            ease: 'linear',
          }}
        />
        <motion.div
          className={`absolute ${isHorizontal ? 'right-0 top-1/2 -translate-y-1/2' : 'bottom-0 left-1/2 -translate-x-1/2'
            } w-2 h-2 md:w-3 md:h-3 rounded-full bg-purple-500`}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: 'easeInOut',
          }}
        />
      </div>
    </motion.div>
  );
};

const RoboApplyIntro = ({ onStart }) => {
  return (
    <div className="relative">
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="text-center mb-8 md:mb-16">
        <h2 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Welcome to RoboApply.ai
        </h2>
        <p className="mt-3 md:mt-4 text-lg md:text-xl text-gray-600">How we get you the job!</p>
      </motion.div>

      {/* Increased height for mobile */}
      <div className="relative h-96 md:h-80 mb-12 md:mb-16">
        {/* Desktop Layout - unchanged */}
        <div className="hidden md:flex absolute inset-0 justify-between items-center px-20">
          <FloatingIcon
            Icon={Bot}
            label="Roboapply.ai"
            color="bg-gradient-to-br from-blue-500 to-blue-600"
            delay={0.2}
          />
          <FloatingIcon
            Icon={Target}
            label="Auto Applications"
            color="bg-gradient-to-br from-purple-500 to-purple-600"
            delay={0.4}
          />
          <FloatingIcon
            Icon={Send}
            label="Dice.com"
            color="bg-gradient-to-br from-indigo-500 to-indigo-600"
            delay={0.6}
          />

          <PulseLine direction="horizontal" className="absolute left-[25%] right-[58%] top-1/2 block" />
          <PulseLine direction="horizontal" className="absolute left-[58%] right-[25%] top-1/2 block" />
        </div>

        {/* Mobile Layout - more vertical space */}
        <div className="md:hidden absolute inset-0 flex flex-col justify-between items-center py-16">
          <div className="relative">
            <FloatingIcon
              Icon={Bot}
              label="Roboapply.ai"
              color="bg-gradient-to-br from-blue-500 to-blue-600"
              delay={0.2}
            />
          </div>

          <div className="relative">
            <FloatingIcon
              Icon={Target}
              label="Auto Applications"
              color="bg-gradient-to-br from-purple-500 to-purple-600"
              delay={0.4}
            />
          </div>

          <div className="relative">
            <FloatingIcon
              Icon={Send}
              label="Dice.com"
              color="bg-gradient-to-br from-indigo-500 to-indigo-600"
              delay={0.6}
            />
          </div>

          <PulseLine direction="vertical" className="absolute top-[25%] bottom-[58%] left-1/2 block" />
          <PulseLine direction="vertical" className="absolute top-[58%] bottom-[25%] left-1/2 block" />
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
        className="space-y-6 md:space-y-8" // Increased base spacing
      >
        <div className="flex flex-col sm:flex-row justify-center gap-4 md:gap-4">
          {' '}
          {/* Increased gap */}
          <motion.a
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            href="https://www.dice.com/dashboard/login"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative px-6 md:px-8 py-3 md:py-3 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium text-base md:text-lg inline-flex items-center justify-center overflow-hidden"
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity"
              initial={false}
              animate={{ y: ['100%', '-100%'] }}
              transition={{ duration: 1, repeat: Infinity }}
            />
            <span className="relative">Dice Login</span>
            <ExternalLink className="ml-2 w-4 h-4 md:w-5 md:h-5" />
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            href="https://www.dice.com/register/new-profile"
            target="_blank"
            rel="noopener noreferrer"
            className="group relative px-6 md:px-8 py-3 md:py-3 rounded-xl bg-gradient-to-r from-purple-500 to-blue-500 text-white font-medium text-base md:text-lg inline-flex items-center justify-center overflow-hidden"
          >
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity"
              initial={false}
              animate={{ y: ['100%', '-100%'] }}
              transition={{ duration: 1, repeat: Infinity }}
            />
            <span className="relative">Create Account</span>
            <ExternalLink className="ml-2 w-4 h-4 md:w-5 md:h-5" />
          </motion.a>
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onStart}
          className="w-full max-w-md mx-auto mt-8 md:mt-8 px-6 md:px-8 py-3 md:py-4 rounded-xl bg-gradient-to-r from-blue-500 via-purple-500 to-blue-500 text-white font-medium text-lg md:text-xl flex items-center justify-center space-x-2 relative overflow-hidden group"
        >
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-blue-600 via-purple-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity"
            initial={false}
            animate={{ x: ['100%', '-100%'] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
          <span className="relative">Start Campaign</span>
          <ArrowRight className="w-5 h-5 md:w-6 md:h-6" />
        </motion.button>
      </motion.div>
    </div>
  );
};

const PricingModal = React.memo(({ credits = 0, userId = null, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('three_day_trial');
  const [isSpinning, setIsSpinning] = useState(false);

  const plans = useMemo(
    () => ({
      monthly_pro: {
        sku: 'monthly_pro',
        name: 'Monthly Pro',
        icon: <Rocket className="w-6 h-6 text-purple-500" />,
        period: 'month',
        pitch: 'Start landing interviews this week',
        basePrice: 49,
        price: 29,
        discount: 41,
        pricePerApp: 0.39,
        applicationsPerPeriod: 150,
        averageSuccessRate: '25%+',
        responseTime: '24-48h',
        highlight: 'Quick Start',
        features: [
          { text: '150 AI Jobs Monthly', detail: 'Fresh opportunities every day' },
          { text: 'AI-Powered Job Matching', detail: '92% matching accuracy' },
          { text: 'Smart Resume Analysis', detail: 'Real-time optimization tips' },
          { text: '30-Day Money-Back Guarantee', detail: 'No questions asked' },
        ],
      },
      quarterly_plus: {
        sku: 'quarterly_plus',
        name: 'Quarterly Plus',
        icon: <Star className="w-6 h-6 text-blue-500" />,
        period: 'quarter',
        pitch: '3 months of premium job hunting',
        basePrice: 89,
        price: 19,
        discount: 34,
        pricePerApp: 0.24,
        applicationsPerMonth: 250,
        monthsIncluded: 3,
        totalApplications: 750,
        averageSuccessRate: '35%+',
        responseTime: '12-24h',
        highlight: 'Best Value',
        features: [
          { text: '250 AI Jobs Monthly', detail: '3x more opportunities' },
          { text: 'Priority Applications', detail: 'Get seen first by employers' },
          { text: 'Advanced Job Matching', detail: '95% matching accuracy' },
          { text: 'Quarterly Payment Savings', detail: 'Most flexible option' },
        ],
      },
      lifetime_elite: {
        sku: 'lifetime_elite',
        name: 'Lifetime Elite',
        icon: <Crown className="w-6 h-6 text-amber-500" />,
        period: 'lifetime',
        pitch: '1500 jobs monthly, forever',
        basePrice: 299,
        price: 109,
        discount: 64,
        pricePerApp: 'Unlimited',
        applicationsPerMonth: 1000,
        highlight: 'Best Long-term',
        features: [
          { text: '1500 Jobs Monthly Forever', detail: 'Maximum monthly applications' },
          { text: 'VIP Application Priority', detail: 'Front of the line access' },
          { text: 'Executive Job Matching', detail: '98% matching accuracy' },
          { text: 'One-Time Payment', detail: 'Lifetime access, no renewals' },
        ],
      },
      three_day_trial: {
        sku: 'three_day_trial',
        name: '50 Applications',
        icon: <Zap className="w-6 h-6 text-green-500" />,
        period: 'once',
        pitch: '50 applications - test the power of robo',
        basePrice: 30,
        price: 19.99,
        discount: 30,
        pricePerApp: 0.7,
        applicationsPerPeriod: 50,
        averageSuccessRate: '20%+',
        responseTime: '48h',
        highlight: 'Quick Boost',
        features: [
          { text: '50 Applications One-Time', detail: 'Use anytime' },
          { text: 'AI-Powered Job Matching', detail: '90% matching accuracy' },
          { text: 'Basic Resume Analysis', detail: 'Essential optimization tips' },
          // { text: 'Money-Back Guarantee', detail: '' },
        ],
      },
    }),
    [],
  );

  const handlePurchase = useCallback(
    async (sku) => {
      setIsSpinning(true);
      setLoading(true);
      setError(null);
      try {
        const checkoutUserId = userId || localStorage.getItem('userId');
        if (!checkoutUserId) throw new Error('Please log in to continue');

        const response = await fetch('https://payment-backend-sage.vercel.app/create-checkout-session', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sku, quantity: 1, user_id: checkoutUserId }),
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.detail?.[0]?.msg || 'Checkout failed');
        if (data.checkout_url) {
          onSuccess?.(plans[sku].applicationsPerPeriod);
          window.location.href = data.checkout_url;
        } else throw new Error('No checkout URL received');
      } catch (err) {
        setError(err.message);
        console.error('Checkout error:', err);
        setIsSpinning(false);
      } finally {
        setLoading(false);
      }
    },
    [userId, onSuccess, plans],
  );

  const renderAnimatedIcon = (icon) => (
    <div className={`transform transition-all duration-700 ${isSpinning ? 'animate-spin-slow' : ''}`}>{icon}</div>
  );

  const currentPlan = plans[selectedPlan];

  const getPlanLabel = (planKey) => {
    switch (planKey) {
      case 'monthly_pro':
        return 'Monthly';
      case 'three_day_trial':
        return <span className="font-bold">3-Day Trial</span>;
      case 'quarterly_plus':
        return 'Quarterly';
      case 'lifetime_elite':
        return 'Lifetime';
      default:
        return '';
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <style jsx global>{`
        @keyframes spin-slow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .animate-spin-slow {
          animation: spin-slow 1s linear infinite;
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f3f4f6;
          border-radius: 3px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #d1d5db;
          border-radius: 3px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #9ca3af;
        }
      `}</style>

      <div className="min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 bg-gradient-to-br from-purple-600 via-pink-500 to-red-500 opacity-90"
          onClick={onClose}
        />

        <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-6">
          <button onClick={onClose} className="absolute right-6 top-6 text-white hover:text-gray-200 transition-colors">
            <X size={24} />
          </button>

          {credits <= 0 && (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-8 rounded-r max-w-lg w-full text-center">
              <p className="text-yellow-700">
                You have 0 credits remaining. Please purchase credits to continue applying for jobs.
              </p>
            </div>
          )}

          <div className="text-center mb-8">
            <h1 className="text-4xl font-bold text-white mb-3">RoboApply Premium</h1>
            <p className="text-white/90 text-lg">Automated job applications with proven results</p>
          </div>

          <div className="flex space-x-2 bg-white/10 backdrop-blur-lg rounded-lg p-1.5 mb-8 shadow-xl">
            {Object.keys(plans).map((planKey) => (
              <button
                key={planKey}
                onClick={() => setSelectedPlan(planKey)}
                className={`px-6 py-2.5 rounded-md transition-all text-sm ${selectedPlan === planKey ? 'bg-white text-purple-600 shadow-lg' : 'text-white hover:bg-white/10'
                  }`}
              >
                {getPlanLabel(planKey)}
              </button>
            ))}
          </div>

          <div className="w-full max-w-lg">
            <div className="relative bg-white rounded-2xl shadow-2xl overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500 via-pink-500 via-red-500 via-yellow-500 via-green-500 via-blue-500 to-purple-500 animate-gradient" />
              <div className="absolute inset-0.5 bg-white rounded-2xl" />

              <div className="relative p-8 flex flex-col items-center">
                <div className="flex flex-col items-center mb-8 text-center">
                  <div className="mb-3">{renderAnimatedIcon(currentPlan.icon)}</div>
                  <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent mb-2">
                    {currentPlan.name}
                  </h3>
                  <span className="bg-purple-100 text-purple-600 px-4 py-1 rounded-full text-sm font-medium border border-purple-200 shadow-sm">
                    {currentPlan.highlight}
                  </span>
                </div>

                <p className="text-gray-600 mb-8 text-lg text-center">{currentPlan.pitch}</p>

                <div className="mb-8 text-center">
                  <div className="flex items-center justify-center gap-4 mb-2">
                    <span className="text-3xl text-gray-400 line-through font-medium">${currentPlan.basePrice}</span>
                    <span className="text-5xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
                      ${currentPlan.price}
                    </span>
                    <div className="inline-flex items-center bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm font-medium border border-green-200 shadow-sm animate-pulse">
                      Save {currentPlan.discount}%
                    </div>
                  </div>
                  <p className="text-gray-600">
                    {currentPlan.period === 'lifetime'
                      ? 'One-time payment'
                      : currentPlan.period === 'quarter'
                        ? 'per quarter'
                        : currentPlan.period === 'once'
                          ? 'one-time fee'
                          : `per ${currentPlan.period}`}
                  </p>
                </div>

                <ul className="space-y-5 mb-8 w-full max-w-md">
                  {currentPlan.features.map((feature, index) => (
                    <li key={index} className="flex flex-col items-center text-center">
                      <div className="flex items-center justify-center gap-2 mb-1">
                        <Check className="w-5 h-5 text-green-500" />
                        <div className="font-medium">{feature.text}</div>
                      </div>
                      <div className="text-sm text-gray-500">{feature.detail}</div>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => handlePurchase(currentPlan.sku)}
                  disabled={loading}
                  className="w-full max-w-md bg-gradient-to-r from-purple-600 to-pink-600 text-white py-4 rounded-lg hover:from-purple-700 hover:to-pink-700 transition-all transform hover:scale-[1.02] disabled:opacity-50 font-medium shadow-lg"
                >
                  {loading ? 'Processing...' : 'Get Started Now →'}
                </button>

                {error && (
                  <div className="mt-6 p-3 bg-red-50 text-red-700 rounded-lg text-center max-w-md w-full">
                    <div className="flex items-center justify-center text-sm">
                      <AlertTriangle size={16} className="mr-2" />
                      {error}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const LoginForm = ({ onSubmit, loading, onClose }) => {
  const [animationParent] = useAutoAnimate();
  const [credentials, setCredentials] = useState({
    email: localStorage.getItem('dice_email') || '',
    password: localStorage.getItem('dice_password') || '',
  });
  const [rememberCredentials, setRememberCredentials] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (rememberCredentials) {
      localStorage.setItem('dice_email', credentials.email);
      localStorage.setItem('dice_password', credentials.password);
    }
    onSubmit(credentials);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white/90 backdrop-blur-xl rounded-3xl shadow-2xl p-4 md:p-8"
      ref={animationParent}
    >
      <div className="flex justify-between items-center mb-4 md:mb-6">
        <h2 className="text-xl md:text-2xl font-bold text-gray-800">Connect Your Dice Account</h2>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700"
        >
          <X className="w-5 h-5 md:w-6 md:h-6" />
        </motion.button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Dice.com Email</label>
          <motion.input
            whileFocus={{ scale: 1.01 }}
            type="email"
            value={credentials.email}
            onChange={(e) => setCredentials((prev) => ({ ...prev, email: e.target.value }))}
            className="w-full p-2.5 md:p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all text-sm md:text-base"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Dice.com Password</label>
          <motion.input
            whileFocus={{ scale: 1.01 }}
            type="password"
            value={credentials.password}
            onChange={(e) => setCredentials((prev) => ({ ...prev, password: e.target.value }))}
            className="w-full p-2.5 md:p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all text-sm md:text-base"
            required
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="remember"
            checked={rememberCredentials}
            onChange={(e) => setRememberCredentials(e.target.checked)}
            className="w-4 h-4 text-blue-500 rounded border-gray-300 focus:ring-blue-500"
          />
          <label htmlFor="remember" className="ml-2 text-gray-600 text-sm">
            Remember my credentials
          </label>
        </div>

        <motion.button
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
          type="submit"
          disabled={loading}
          className="w-full py-2.5 md:py-3 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white font-medium text-base md:text-lg relative overflow-hidden group"
        >
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity"
            initial={false}
            animate={{ x: ['100%', '-100%'] }}
            transition={{ duration: 2, repeat: Infinity }}
          />
          <span className="relative flex items-center justify-center">
            {loading ? (
              <>
                <Loader className="animate-spin mr-2 w-4 h-4 md:w-5 md:h-5" />
                <span>Connecting...</span>
              </>
            ) : (
              'Start Applying'
            )}
          </span>
        </motion.button>
      </form>
    </motion.div>
  );
};

const DiceLoginModal = ({ isOpen, onClose, onSubmit, loading }) => {
  const [showIntro, setShowIntro] = useState(true);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 bg-black/60 backdrop-blur-sm"
          onClick={onClose}
        />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="min-h-full flex items-center justify-center p-4">
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: 'spring', duration: 0.5 }}
              className="relative w-full max-w-4xl"
            >
              <div className="bg-white/90 backdrop-blur-xl rounded-3xl shadow-2xl p-6 md:p-12">
                {showIntro ? (
                  <RoboApplyIntro onStart={() => setShowIntro(false)} />
                ) : (
                  <LoginForm onSubmit={onSubmit} loading={loading} onClose={onClose} />
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

// Success Animation Component
const SuccessOverlay = ({ message }) => (
  <motion.div
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.8, opacity: 0 }}
    className="fixed inset-0 flex items-center justify-center z-50"
  >
    <div className="bg-white rounded-xl p-8 text-center shadow-2xl max-w-md mx-4">
      <motion.div
        animate={{
          y: [0, -10, 0],
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
        className="mb-4"
      >
        <Rocket className="w-16 h-16 text-blue-500 mx-auto" />
      </motion.div>
      <h3 className="text-2xl font-bold text-gray-800 mb-3">Campaign Launched! 🚀</h3>
      <p className="text-gray-600">
        {message || "We're starting your applications now. You can track progress in your dashboard."}
      </p>
      <div className="mt-4 flex justify-center space-x-2">
        <Star className="w-5 h-5 text-yellow-400 animate-pulse" />
        <Star className="w-5 h-5 text-yellow-400 animate-pulse delay-100" />
        <Star className="w-5 h-5 text-yellow-400 animate-pulse delay-200" />
      </div>
    </div>
  </motion.div>
);

const FirstTimeCampaignModal = ({
  isOpen,
  onClose,
  onSubmit,
  loading,
  userEmail,
  userProfile,
  user,
  dicePassword,
  currentResume,
  userId, // Add userId to props
}) => {
  const { addToast } = useToast();
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [formData, setFormData] = useState({
    userId: userProfile?.id || userId, // Add both possible sources
    jobType: '',
    customJobType: '',
    jobLevel: '',
    numJobs: 20,
    selectedCity: '',
    email: userProfile?.dice_specific_email || userProfile?.email || '',
    password: userProfile?.dice_password || '',
    resumeUrl: userProfile?.most_recent_resume_url || '',
  });

  const [remainingCredits, setRemainingCredits] = useState(userProfile.credits || 0);
  const [profile, setProfile] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const triggerSuccessAnimation = () => {
    // Center burst
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ['#5F3DC4', '#4FD1C5', '#63B3ED', '#48BB78', '#805AD5'],
      zIndex: 9999,
      startVelocity: 45,
      gravity: 0.8,
      shapes: ['circle', 'square'],
      ticks: 200,
    });

    // Left burst
    setTimeout(() => {
      confetti({
        particleCount: 60,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 0.6 },
        colors: ['#5F3DC4', '#4FD1C5', '#63B3ED', '#48BB78', '#805AD5'],
        zIndex: 9999,
        startVelocity: 45,
        gravity: 0.8,
        shapes: ['circle', 'square'],
        ticks: 200,
      });
    }, 250);

    // Right burst
    setTimeout(() => {
      confetti({
        particleCount: 60,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 0.6 },
        colors: ['#5F3DC4', '#4FD1C5', '#63B3ED', '#48BB78', '#805AD5'],
        zIndex: 9999,
        startVelocity: 45,
        gravity: 0.8,
        shapes: ['circle', 'square'],
        ticks: 200,
      });
    }, 500);

    // Show success overlay and toast
    setShowSuccess(true);
    setHasSubmitted(true);

    addToast({
      message: {
        title: '🚀 Campaign Started Successfully!',
        description: `Launching ${formData.numJobs} applications for ${formData.jobType} positions in ${formData.selectedCity}`,
      },
      type: 'success',
      duration: 6000,
      style: {
        background: 'linear-gradient(135deg, #48BB78 0%, #38A169 100%)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        color: 'white',
        boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
      },
    });

    // Delayed close
    setTimeout(() => {
      setShowSuccess(false);
      onClose();
    }, 3000);
  };

  // // Fetch user profile and credits
  // const fetchUserProfile = async () => {
  //   try {
  //     // Get userId from props or localStorage
  //     const checkoutUserId = userId || localStorage.getItem('userId');
  //
  //     if (!checkoutUserId) {
  //       throw new Error('No user ID found');
  //     }
  //
  //     const { data: profileData, error: profileError } = await supabase
  //       .from('profiles')
  //       .select('*')
  //       .eq('id', checkoutUserId) // Now correctly using user ID
  //       .single();
  //
  //     if (profileError) {
  //       throw profileError;
  //     }
  //
  //     setProfile(profileData);
  //     setRemainingCredits(profileData.credits);
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       userId: checkoutUserId,
  //       most_recent_resume_url: profileData.most_recent_resume_url,
  //       email: profileData.dice_specific_email || userEmail,
  //       password: profileData.dice_password || dicePassword || '',
  //     }));
  //   } catch (error) {
  //     console.error('Error fetching profile:', error.message);
  //     addToast({
  //       message: {
  //         title: 'Error Loading Profile',
  //         description: 'Unable to load your profile and credits. Please try logging in again.',
  //       },
  //       type: 'error',
  //       duration: 5000,
  //     });
  //   }
  // };
  //
  //

  // const handleFileChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;
  //
  //   try {
  //     const fileName = `${Date.now()}-${file.name}`;
  //     const { data, error } = await supabase.storage.from('resumes').upload(`public/${fileName}`, file, {
  //       upsert: true,
  //       contentType: file.type,
  //     });
  //
  //     if (error) {
  //       throw error;
  //     }
  //
  //     const { publicURL } = supabase.storage.from('resumes').getPublicUrl(`public/${fileName}`);
  //
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       resumeUrl: publicURL,
  //     }));
  //
  //     // Update the profile with new resume URL
  //     const { error: updateError } = await supabase
  //       .from('profiles')
  //       .update({ most_recent_resume_url: publicURL })
  //       .eq('id', formData.userId);
  //
  //     if (updateError) {
  //       throw updateError;
  //     }
  //
  //     addToast({
  //       message: {
  //         title: 'Resume Uploaded',
  //         description: 'Your resume has been successfully updated.',
  //       },
  //       type: 'success',
  //       duration: 4000,
  //       style: {
  //         background: 'linear-gradient(135deg, #48BB78 0%, #38A169 100%)',
  //         color: 'white',
  //       },
  //     });
  //   } catch (error) {
  //     console.error('Error uploading resume:', error.message);
  //     addToast({
  //       message: {
  //         title: 'Upload Failed',
  //         description: 'Failed to upload resume. Please try again.',
  //       },
  //       type: 'error',
  //       duration: 5000,
  //     });
  //   }
  // };
  const handleResumeUpload = (e) => {
    const file = e?.target?.files[0];
    if (!file) return;

    const allowedTypes = ['.pdf', '.doc', '.docx'];
    const fileExt = `.${file.name.split('.').pop().toLowerCase()}`;

    if (!allowedTypes.includes(fileExt)) {
      toast.error('Please upload a PDF, DOC, or DOCX file');
      console.error('Please upload a PDF, DOC, or DOCX file');
      return;
    }

    setFormData((prev) => ({ ...prev, resumeUrl: file }));
    toast.success('Resume selected successfully!');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (remainingCredits <= 0) {
      setShowPricingModal(true);
      setIsSubmitting(false);
      return;
    }

    try {
      // Ensure we have userId
      if (!formData.userId) {
        throw new Error('User ID is missing');
      }

      // Update only dice_specific_email if not set - don't update password
      if (!userProfile?.dice_specific_email) {
        const { error: updateError } = await supabase
          .from('profiles')
          .update({
            dice_specific_email: formData.email,
          })
          .eq('id', formData.userId);

        if (updateError) {
          console.error('Profile update error:', updateError);
          throw new Error('Failed to update dice email');
        }
      }

      // Submit application
      const applicationData = {
        user_id: formData.userId,
        email: userProfile?.dice_specific_email || formData.email, // Use existing dice email if available
        password: userProfile.dice_password, // Always use the existing password
        job_type: formData.jobType === 'Other (Custom)' ? formData.customJobType : formData.jobType,
        job_level: formData.jobLevel,
        num_jobs: formData.numJobs,
        location: formData.selectedCity,
        resume_url: formData.resumeUrl || userProfile.most_recent_resume_url,
      };

      const response = await fetch(process.env.REACT_APP_JOB_APPLICATION_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(applicationData),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: 'Network response was not ok' }));
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      // Update credits
      const { error: creditsError } = await supabase
        .from('profiles')
        .update({
          credits: remainingCredits - formData.numJobs,
        })
        .eq('id', formData.userId);

      if (creditsError) {
        throw new Error('Failed to update credits');
      }

      // Mark first campaign as complete
      localStorage.setItem('hasVisitedApplyPage', 'true');

      // Trigger success animations and close
      triggerSuccessAnimation();

      // Call parent submit handler
      onSubmit();
    } catch (error) {
      console.error('Error in campaign submission:', error);
      addToast({
        message: {
          title: 'Campaign Launch Failed',
          description: error.message || 'Unable to start your campaign. Please try again.',
        },
        type: 'error',
        duration: 6000,
      });
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    localStorage.setItem('hasVisitedApplyPage', 'true');
    onClose();
  };

  // Show PricingCard if needed
  if (showPricingModal) {
    return (
      <PricingModal
        credits={remainingCredits}
        userId={formData.userId}
        onClose={() => setShowPricingModal(false)}
        onSuccess={(newCredits) => {
          setRemainingCredits((prevCredits) => prevCredits + newCredits);
          setShowPricingModal(false);
        }}
      />
    );
  }

  if (!isOpen) {
    return null;
  }

  const jobTypes = [
    'Software Engineer',
    'Senior Software Engineer',
    'Full Stack Developer',
    'Frontend Developer',
    'Backend Developer',
    'DevOps Engineer',
    'Data Scientist',
    'Data Analyst',
    'Machine Learning Engineer',
    'Product Manager',
    'Project Manager',
    'UI/UX Designer',
    'Quality Assurance Engineer',
    'Business Analyst',
    'Systems Analyst',
    'Cloud Engineer',
    'Network Engineer',
    'Security Engineer',
    'Technical Writer',
    'Web Developer',
    'Mobile Developer',
    'Game Developer',
    'Data Engineer',
    'DevSecOps Engineer',
    'Research Scientist',
    'Blockchain Developer',
    'AI Engineer',
    'Embedded Systems Engineer',
    'Site Reliability Engineer',
    'Game Designer',
    'IT Support Specialist',
    'Chief Technology Officer (CTO)',
    'Technical Project Manager',
    'Product Designer',
    'Digital Marketing Manager',
    'SEO Specialist',
    'Content Marketing Strategist',
    'Social Media Manager',
    'Email Marketing Specialist',
    'PPC Specialist',
    'Growth Hacker',
    'Brand Manager',
    'Marketing Analyst',
    'Product Marketing Manager',
    'Marketing Coordinator',
    'Affiliate Marketing Manager',
    'Public Relations Specialist',
    'Event Marketing Manager',
    'Influencer Marketing Manager',
    'Creative Director',
    'Art Director',
    'Graphic Designer',
    'Copywriter',
    'Content Strategist',
    'Web Analytics Specialist',
    'User Experience Researcher',
    'Customer Experience Manager',
    'Sales Manager',
    'Account Executive',
    'Sales Development Representative',
    'Business Development Manager',
    'Sales Operations Manager',
    'Inside Sales Representative',
    'Outside Sales Representative',
    'Territory Sales Manager',
    'Field Sales Representative',
    'Regional Sales Manager',
    'Sales Engineer',
    'Key Account Manager',
    'Customer Success Manager',
    'Lead Generation Specialist',
    'Proposal Manager',
    'Channel Sales Manager',
    'Sales Trainer',
    'Revenue Operations Manager',
    'Sales Enablement Manager',
    'Director of Sales',
    'Chief Sales Officer (CSO)',
    'Chief Marketing Officer (CMO)',
    'Head of Growth',
    'Other (Custom)',
  ];

  const jobLevels = [
    'Entry Level',
    'Junior',
    'Mid-Level',
    'Senior Level',
    'Lead',
    'Manager',
    'Director',
    'Vice President',
    'Executive',
    'Intern',
    'Contract',
  ];

  const cities = [
    'Remote',
    'San Francisco, CA, USA',
    'New York, NY, USA',
    'Los Angeles, CA, USA',
    'Chicago, IL, USA',
    'Houston, TX, USA',
    'Phoenix, AZ, USA',
    'Philadelphia, PA, USA',
    'San Antonio, TX, USA',
    'San Diego, CA, USA',
    'Dallas, TX, USA',
    'San Jose, CA, USA',
    'Austin, TX, USA',
    'Jacksonville, FL, USA',
    'San Francisco Bay Area, CA, USA',
    'Columbus, OH, USA',
    'Fort Worth, TX, USA',
    'Indianapolis, IN, USA',
    'Charlotte, NC, USA',
    'Seattle, WA, USA',
    'Denver, CO, USA',
    'Washington, D.C., USA',
    'Boston, MA, USA',
    'El Paso, TX, USA',
    'Detroit, MI, USA',
    'Nashville, TN, USA',
    'Portland, OR, USA',
    'Oklahoma City, OK, USA',
    'Las Vegas, NV, USA',
    'Milwaukee, WI, USA',
    'Albuquerque, NM, USA',
    'Tucson, AZ, USA',
    'Sacramento, CA, USA',
    'Kansas City, MO, USA',
    'Atlanta, GA, USA',
    'Miami, FL, USA',
    'Raleigh, NC, USA',
    'Minneapolis, MN, USA',
    'Cleveland, OH, USA',
    'Orlando, FL, USA',
    'St. Louis, MO, USA',
    'Pittsburgh, PA, USA',
    'Cincinnati, OH, USA',
    'Salt Lake City, UT, USA',
    'Tampa, FL, USA',
    'Boulder, CO, USA',
    'Hybrid (Remote/In-office)',
    'International (Various Locations)',
    'United Kingdom',
    'Germany',
    'Canada',
    'Australia',
    'Singapore',
  ];

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      >
        {showSuccess && (
          <SuccessOverlay
            message={`Launching ${formData.numJobs} applications for ${formData.jobType} positions in ${formData.selectedCity}`}
          />
        )}

        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className={`bg-white rounded-3xl w-full max-w-2xl overflow-y-auto max-h-[90vh] relative ${showSuccess ? 'opacity-50' : ''}`}
        >
          <div className="sticky top-0 bg-white z-10 px-6 py-4 border-b border-gray-200 flex justify-between items-center rounded-t-3xl">
            <div className="flex items-center space-x-3">
              <Rocket className="w-6 h-6 text-blue-500" />
              <h2 className="text-2xl font-bold text-gray-800">Welcome to RoboApply! 🎉</h2>
            </div>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700 transition-colors">
              <X className="w-6 h-6" />
            </button>
          </div>
          {/* Campaign Information Box */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl p-6 space-y-3"
          >
            <h3 className="font-semibold text-blue-800 flex items-center gap-2">
              <Star className="w-5 h-5" />
              Your First Campaign Includes:
            </h3>
            <ul className="text-blue-700 space-y-2">
              <motion.li
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 }}
                className="flex items-center gap-2"
              >
                <Check className="w-4 h-4 text-blue-500" />
                20 free job applications
              </motion.li>
              <motion.li
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-center gap-2"
              >
                <Check className="w-4 h-4 text-blue-500" />
                Automated application submission
              </motion.li>
              <motion.li
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
                className="flex items-center gap-2"
              >
                <Check className="w-4 h-4 text-blue-500" />
                Real-time progress tracking
              </motion.li>
              <motion.li
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
                className="flex items-center gap-2"
              >
                <Check className="w-4 h-4 text-blue-500" />
                Application analytics dashboard
              </motion.li>
            </ul>
          </motion.div>
          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Job Type Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Job Type</label>
              <div className="relative">
                <select
                  name="jobType"
                  value={formData.jobType}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      jobType: e.target.value,
                      customJobType: e.target.value === 'Other (Custom)' ? prev.customJobType : '',
                    }))
                  }
                  className="w-full p-3 border border-gray-300 rounded-xl bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 appearance-none text-gray-900"
                  required
                >
                  <option value="">Select Job Type</option>
                  {jobTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none text-gray-500">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Custom Job Type Input */}
            <AnimatePresence>
              {formData.jobType === 'Other (Custom)' && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="overflow-hidden"
                >
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Specify Custom Job Type</label>
                    <input
                      type="text"
                      name="customJobType"
                      value={formData.customJobType}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          customJobType: e.target.value,
                        }))
                      }
                      className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                      placeholder="Enter your custom job type"
                      required
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Experience Level Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Experience Level</label>
              <div className="relative">
                <select
                  name="jobLevel"
                  value={formData.jobLevel}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      jobLevel: e.target.value,
                    }))
                  }
                  className="w-full p-3 border border-gray-300 rounded-xl bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 appearance-none text-gray-900"
                  required
                >
                  <option value="">Select Experience Level</option>
                  {jobLevels.map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none text-gray-500">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Location Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Location</label>
              <div className="relative">
                <select
                  name="selectedCity"
                  value={formData.selectedCity}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      selectedCity: e.target.value,
                    }))
                  }
                  className="w-full p-3 border border-gray-300 rounded-xl bg-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 appearance-none text-gray-900"
                  required
                >
                  <option value="">Select Location</option>
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none text-gray-500">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Resume Upload Section */}
            <div className="space-y-4">
              {/* Current Resume Display */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Current Resume</label>
                <div className="bg-gray-50 rounded-xl p-4">
                  {formData.resumeUrl ? (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <FileText className="w-5 h-5 text-blue-500" />
                        <span className="text-sm text-gray-600">Resume uploaded</span>
                      </div>
                      <a
                        href={formData.resumeUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm text-blue-500 hover:text-blue-600 flex items-center space-x-1"
                      >
                        <span>View</span>
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2 text-gray-500">
                      <AlertTriangle className="w-5 h-5" />
                      <span className="text-sm">No resume uploaded yet</span>
                    </div>
                  )}
                </div>
              </div>

              {/* New Resume Upload */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Upload New Resume</label>
                <div className="relative">
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleResumeUpload}
                    className="w-full p-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                </div>
                <p className="text-xs text-gray-500">Accepted formats: PDF, DOC, DOCX (Max 5MB)</p>
              </div>
            </div>

            {/* Submit Button */}
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.button
                type="submit"
                disabled={isSubmitting}
                className={`
                    w-full py-4 rounded-xl font-medium text-lg relative
                    overflow-hidden group transition-all duration-200
                    ${isSubmitting
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600'
                  }
                `}
                onClick={handleSubmit}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity"
                  initial={false}
                  animate={{ x: ['100%', '-100%'] }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
                <span className="relative flex items-center justify-center space-x-2 text-white">
                  {isSubmitting ? (
                    <>
                      <Loader className="w-5 h-5 animate-spin" />
                      <span>Launching Your Campaign...</span>
                    </>
                  ) : (
                    <>
                      <Rocket className="w-5 h-5" />
                      <span>Launch First Campaign</span>
                    </>
                  )}
                </span>
              </motion.button>

              {/* Credits Warning */}
              {remainingCredits <= 0 && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-red-50 text-red-600 p-3 rounded-lg flex items-center space-x-2"
                >
                  <AlertTriangle className="w-5 h-5 flex-shrink-0" />
                  <span className="text-sm">You need credits to start a campaign. Purchase credits to continue.</span>
                </motion.div>
              )}

              {/* Terms and Privacy */}
              <p className="text-center text-sm text-gray-500">
                By starting your campaign, you agree to our{' '}
                <a href="/terms" className="text-blue-500 hover:text-blue-600 underline">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy" className="text-blue-500 hover:text-blue-600 underline">
                  Privacy Policy
                </a>
              </p>
            </motion.div>
          </form>
        </motion.div>

        {/* Loading Overlay */}
        <AnimatePresence>
          {isSubmitting && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center overflow-hidden"
            >
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="bg-white rounded-2xl p-6 shadow-xl relative"
              >
                {[...Array(50)].map((_, i) => (
                  <motion.div
                    key={i}
                    className="absolute w-2 h-2 rounded-full"
                    initial={{ top: '50%', left: `${Math.random() * 100}%`, scale: 0 }}
                    animate={{
                      top: '-20%',
                      scale: [0, 1, 1, 0],
                      x: Math.random() > 0.5 ? [-50, 50] : [50, -50],
                    }}
                    transition={{
                      duration: 2,
                      delay: Math.random() * 2,
                      ease: 'easeOut',
                      repeat: Infinity,
                    }}
                    style={{
                      backgroundColor: ['#4F46E5', '#EC4899', '#3B82F6'][Math.floor(Math.random() * 3)],
                    }}
                  />
                ))}
                <div className="flex flex-col items-center space-y-3">
                  <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                  <p className="text-gray-700 font-medium text-center">
                    🎉 Congratulations! You've started your first robo-apply campaign!
                    <br />
                    <span className="text-sm text-gray-500">
                      Please wait 5-10 minutes and watch the emails move in , feel free to close this window!
                    </span>
                    <Button className={'bg-blue-500 text-white text-nowrap w-full max-w-2xl'} onClick={onClose}>
                      Go back to Dashboard
                    </Button>
                  </p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};

export { FirstTimeCampaignModal, DiceLoginModal };
