import { Zap, Star, Infinity } from 'lucide-react';

export const PRICING_PLANS = [
  {
    sku: 'monthly_subscription',
    name: 'Monthly Pro',
    icon: <Zap className="w-6 h-6 text-purple-500" />,
    period: 'month',
    pitch: 'Perfect for active job seekers',
    basePrice: 99,
    price: 49,
    discount: 50,
    pricePerApp: 0.98,
    highlight: 'Most Popular',
    applicationStats: {
      volume: '50 jobs every month',
      volumeDetails: 'Renews monthly, cancel anytime',
      successRate: '25% interview rate',
      responseTime: '24-48h response',
    },
    features: ['AI-Powered Job Matching', 'Smart Resume Analysis', 'Basic Support'],
    valueProps: ['50 AI-powered applications every month', 'Monthly renewal flexibility', 'Basic priority queue'],
    cta: 'Start with 50 jobs/month',
    moneyBackGuarantee: true,
  },
  {
    sku: 'quarterly_subscription',
    name: 'Quarterly Plus',
    icon: <Star className="w-6 h-6 text-blue-500" />,
    period: 'quarter',
    pitch: '3x monthly applications at a better rate',
    basePrice: 249,
    price: 119,
    discount: 52,
    pricePerApp: 0.79,
    highlight: 'Best Value',
    applicationStats: {
      volume: '150 jobs every month',
      volumeDetails: '450 total applications per quarter',
      successRate: '35% interview rate',
      responseTime: '12-24h response',
    },
    features: ['Priority Applications', 'Advanced Job Matching', 'Premium Support'],
    valueProps: ['150 monthly applications (450/quarter)', 'Save 52% vs monthly plan', 'Enhanced priority queue'],
    cta: 'Get 150 jobs/month',
    moneyBackGuarantee: true,
  },
  {
    sku: 'lifetime_subscription',
    name: 'Lifetime Elite',
    icon: <Infinity className="w-6 h-6 text-amber-500" />,
    period: 'lifetime',
    pitch: 'Unlimited monthly applications forever',
    basePrice: 999,
    price: 399,
    discount: 60,
    pricePerApp: 0.66,
    highlight: 'Best Long-term',
    applicationStats: {
      volume: '600 jobs every month',
      volumeDetails: 'Unlimited access forever',
      successRate: '45% interview rate',
      responseTime: '< 12h response',
    },
    features: ['VIP Application Priority', 'Executive Job Matching', 'Dedicated Support'],
    valueProps: ['600 applications every month', 'Lifetime access, never expires', 'VIP priority queue'],
    cta: 'Get 600 jobs/month forever',
    moneyBackGuarantee: true,
  },
];

export const DEFAULT_PLAN = {
  sku: '',
  name: '',
  icon: null,
  period: '',
  pitch: '',
  basePrice: 0,
  price: 0,
  discount: 0,
  pricePerApp: 0,
  highlight: '',
  applicationStats: {
    volume: '',
    volumeDetails: '',
    successRate: '',
    responseTime: '',
  },
  features: [],
  valueProps: [],
  cta: '',
  moneyBackGuarantee: false,
};

export const SAMPLE_REVIEWS = [
  {
    id: 1,
    name: 'Christy L.',
    role: 'Project Manager',
    company: 'Netflix',
    content:
      'Robo has transformed my job search! With just a few clicks, I was able to automatically apply to hundreds of positions. The AI automation is top-notch, making the entire process effortless and efficient.',
    rating: 5,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocJA_NReprwRTtSuDCxzvAjaKW_EDWPKZbLhDMmARR3CRhAM7Tef=s96-c',
  },
  {
    id: 2,
    name: 'Mitch L.',
    role: 'Data Analyst',
    company: 'Meta',
    content:
      "The value Robo offers is unparalleled compared to other job application tools I've tried. No subscriptions required and 20 free applications upon sign-up – it’s a game-changer!",
    rating: 4,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocI_eANc9OejE1CXSdpXBZuhGs3O9ZuL6lkUfH9fFb0Wf1rvt2mG=s96-c',
  },
  {
    id: 3,
    name: 'Abhineet K.',
    role: 'Software Engineer',
    company: 'Google',
    content:
      "Robo's dashboard is incredibly intuitive, providing a clear grid and comprehensive application history. Email notifications keep me updated without any hassle. Truly a smart solution for job seekers!",
    rating: 4,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIPOlk8cNwkkxX76wAHFJluZPP9AgIYdWZLQPvy9HoUjS6mnqnGHg=s96-c',
  },
  {
    id: 4,
    name: 'Vijay A.',
    role: 'Product Manager',
    company: 'Amazon',
    content:
      'The AI automation feature is a lifesaver. I managed to apply to 75 jobs in my first week effortlessly. The secure payment with Stripe adds an extra layer of trust and reliability.',
    rating: 4,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIRPVsaxATGRgFP4_07hg0gT6rqkUo7GBP8bMnpfcIquj6CI7vGyA=s96-c',
  },
  {
    id: 5,
    name: 'Shubham J.',
    role: 'UX Designer',
    company: 'Apple',
    content:
      "Robo's AI seamlessly customizes my cover letters, saving me tons of time. The 20 free applications on sign-up with no credit card required made getting started a breeze. Highly recommend!",
    rating: 5,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocJiTv4JGldhqOZTt4RdE9Nh-L9J9llNVIwX8skbxKy70yy6fnw3IA=s96-c',
  },
  {
    id: 6,
    name: 'Vi D.',
    role: 'DevOps Engineer',
    company: 'Microsoft',
    content:
      'Exceptional value! Robo allows me to apply to hundreds of jobs without any subscription fees. The dashboard with application history is fantastic for tracking my progress.',
    rating: 4,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocII-OShlB_qEJNuIFwv9dNfOtMYaRfXadAhJsQKuoj8XgTCAAbw=s96-c',
  },
  {
    id: 7,
    name: 'Gurdeep S.',
    role: 'Data Scientist',
    company: 'IBM',
    content:
      'The email notifications from Robo keep me informed every step of the way. Applying to multiple jobs has never been easier, and the AI ensures my applications are tailored perfectly.',
    rating: 4,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIuuFZv34UedAIvdr85r5gxOCAaMd8cK6N9P-hFvLWuyx4Zorw=s96-c',
  },
  {
    id: 8,
    name: 'Parth P.',
    role: 'Network Engineer',
    company: 'Cisco Systems',
    content:
      "Robo's AI automation feature significantly reduced my job application time. The secure payment process with Stripe and the 20 free applications on sign-up make it an unbeatable choice!",
    rating: 4.5,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocJw07sMJt2ye8z8txp9bOPSNNTz8YAN_FU0b2H42srx7z7iQAc=s96-c',
  },
  {
    id: 9,
    name: 'Victoria L.',
    role: 'Marketing Specialist',
    company: 'Salesforce',
    content:
      'Robo has been instrumental in my job search. The ability to apply to hundreds of jobs with just a few clicks and the comprehensive dashboard have streamlined my entire process.',
    rating: 5,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIpOT44jSN2u4UA-nHY155mGzrUyFUuacCoEbPE_jjOtq1CKxyM=s96-c',
  },
  {
    id: 10,
    name: 'Gehad S.',
    role: 'Cybersecurity Analyst',
    company: 'Tesla',
    content:
      "Finally, a tool that truly understands the job application process! Robo's AI ensures my applications are top-notch, and the fact that there are no subscriptions required is fantastic.",
    rating: 4.5,
    image: 'https://lh3.googleusercontent.com/a/ACg8ocIIXGIT-KeCzWCOIeI4to6jzDnyhGRWcOyVc2tKT6nb4sbXTe7v=s96-c',
  },
];

export const NEW_USER_FREE_CREDIT_AMOUNT = 20;
