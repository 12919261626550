import React, { useEffect, useState } from 'react';
import { supabase } from '../../../supabaseConfig';
import { toast } from 'react-hot-toast';
import { AnimatePresence, motion } from 'framer-motion';
import { signIn } from '../../../api/supabaseClient';
import { RefreshCwIcon } from 'lucide-react';
import { Button } from '../../base/Button/Button';
import { twMerge } from 'tailwind-merge';

export const PaywallCard = ({ onComplete, className }) => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState('EMAIL');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resendTimeout, setResendTimeout] = useState(0);

  useEffect(() => {
    let interval;
    if (resendTimeout > 0) {
      interval = setInterval(() => {
        setResendTimeout((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [resendTimeout]);

  const handleMagicLink = async (email) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: true,
        },
      });

      if (error) throw error;

      setStep('MAGIC_LINK_SENT');
      setResendTimeout(60);
      toast.success('Check your email for the magic link!', {
        description: 'Make sure to check your spam folder',
        duration: 5000,
      });
    } catch (error) {
      console.error('Magic link error:', error);
      setError(error.message);
      toast.error('Authentication failed', {
        description: error.message || 'Please try again later',
        duration: 4000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleGoogleSignIn = async () => {
  //   try {
  //     const { data, error } = await supabase.auth.signInWithOAuth({
  //       provider: 'google',
  //     });
  //     if (error) throw error;
  //   } catch (error) {
  //     console.error('Google auth error:', error);
  //     setError(error.message);
  //     toast.error('Google sign in failed', {
  //       description: error.message || 'Please try again later',
  //       duration: 4000,
  //     });
  //   }
  // };

  // const handleEmailSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!email) {
  //     setError('Email is required');
  //     return;
  //   }
  //   await handleMagicLink(email);
  // };
  const handleSignIn = async () => {
    const data = await signIn();
    if (data) {
      console.log('GoogleSignInData: ', data);
      onComplete(data);
    }
  };
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className={twMerge(
          `bg-white rounded-2xl shadow-xl p-8 max-w-md w-full mx-auto relative overflow-hidden`,
          className,
        )}
      >
        <AnimatePresence mode="wait">
          {step === 'EMAIL' && (
            <motion.div
              key="email-step"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-6"
            >
              <div className="text-center space-y-2">
                <h2 className="text-2xl font-bold text-gray-800">Welcome to RoboApply</h2>
                <p className="text-gray-600">Magic Link Coming Soon !</p>
              </div>

              {/*<form onSubmit={handleEmailSubmit} className="space-y-4">*/}
              {/*  <Input*/}
              {/*                      icon={MailIcon}*/}
              {/*                      type="email"*/}
              {/*                      placeholder="Enter your email"*/}
              {/*                      value={email}*/}
              {/*                      onChange={(e) => setEmail(e.target.value)}*/}
              {/*                      disabled={isLoading}*/}
              {/*                      error={error}*/}
              {/*                      required*/}
              {/*                  /> */}
              {/*  */}
              {/* <Button*/}
              {/*                      type="submit"*/}
              {/*                      isLoading={isLoading}*/}
              {/*                      className="w-full"*/}
              {/*                  >*/}
              {/*                      Send Magic Link (Coming Soon)*/}
              {/*                      <ArrowRightIcon className="ml-2 w-5 h-5" />*/}
              {/*                  </Button> */}
              {/*</form>*/}

              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">please continue with</span>
                </div>
              </div>

              <Button
                variant="secondary"
                onClick={handleSignIn}
                className="w-full text-nowrap flex flex-nowrap items-center justify-center gap-2"
              >
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  className="w-5 h-5 mr-2"
                />
                Continue with Google
              </Button>
            </motion.div>
          )}

          {step === 'MAGIC_LINK_SENT' && (
            <motion.div
              key="magic-link-step"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-center space-y-6"
            >
              <div className="space-y-2">
                <h2 className="text-2xl font-bold text-gray-800">Check your email</h2>
                <p className="text-gray-600">We've sent a magic link to</p>
                <p className="font-medium text-gray-800">{email}</p>
              </div>

              <div className="space-y-4">
                <button
                  onClick={() => handleMagicLink(email)}
                  disabled={resendTimeout > 0 || isLoading}
                  className={`
                                        text-sm text-green-500 hover:text-green-600
                                        flex items-center justify-center gap-2
                                        disabled:opacity-50 disabled:cursor-not-allowed
                                    `}
                >
                  <RefreshCwIcon className="w-4 h-4" />
                  {resendTimeout > 0 ? `Resend in ${resendTimeout}s` : 'Resend magic link'}
                </button>

                <button onClick={() => setStep('EMAIL')} className="text-sm text-gray-500 hover:text-gray-600">
                  Use a different email
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};
