import React from 'react';

const colorSchemes = {
    jobType: {
        bg: 'bg-[#DCFCE7]',
        text: 'text-gray-800',
        iconColor: 'text-gray-600',
    },
    experienceLevel: {
        bg: 'bg-purple-100 hover:bg-purple-200',
        text: 'text-purple-800',
        iconColor: 'text-purple-600',
    },
    workplaceType: {
        bg: 'bg-[#8DDBD5]',
        text: 'text-gray-800',
        iconColor: 'text-gray-600',
    },
    technologies: {
        bg: 'bg-[#F3E8FF]',
        text: 'text-purple-800',
        iconColor: 'text-purple-600',
    },
    default: {
        bg: 'bg-gray-100 hover:bg-gray-200',
        text: 'text-gray-800',
        iconColor: 'text-gray-600',
    },
};

const shimmerStyles = `
  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .badge-shimmer {
    background-size: 200% 100%;
    transition: all 0.3s ease;
  }

  .badge-shimmer:hover {
    animation: shimmer 4s linear infinite;
  }
`;

const Badge = ({ type, icon: Icon, text }) => {
    const scheme = colorSchemes[type] || colorSchemes['default'];

    return (
        <>
            <style>{shimmerStyles}</style>
            <span
                className={`
                    inline-flex items-center px-4 py-2 
                    rounded-2xl text-sm font-medium
                    ${scheme.bg} ${scheme.text}
                    transition-all duration-300
                    hover:shadow-md badge-shimmer
                    hover:scale-[1.02] active:scale-[0.98]
                `}
                title={text}
            >
                {Icon && <Icon className={`w-4 h-4 mr-2 ${scheme.iconColor}`} />}
                <span>{text}</span>
            </span>
        </>
    );
};

export default Badge;