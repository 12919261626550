import React, { forwardRef, useState } from 'react';
import { Button } from '../../base/Button/Button';
import { LogOut } from 'lucide-react';
import { UserProfileCard } from './UserProfileCard';
import { NavLink } from 'react-router';
import { signIn } from '../../../api/supabaseClient';
import { Modal } from '../../base/Modal/Modal';
import { PricingCard } from '../PricingCard/PricingCard';

export const MobileNavMenu = forwardRef((props, forwardedRef) => {
  const {
    userId,
    avatarUrl,
    username,
    email,
    jobTitle,
    credits,
    isMenuOpen,
    setIsMenuOpen,
    handleLogOut,
    ...restProps
  } = props;
  const [showPricing, setShowPricing] = useState(false);
  //TODO: use React Router for links
  // const handleNavLinkClick = () => {
  //   setIsMenuOpen(false); // Close the menu when a link is clicked
  // };
  const handleLogoutAndCloseMenu = () => {
    setIsMenuOpen(false);
    handleLogOut();
  };

  return (
    <div
      ref={forwardedRef}
      className="md:hidden fixed top-[3.5rem] left-0 right-0 flex flex-col space-y-4 w-full p-4 bg-white rounded-lg shadow-lg z-[60] mx-auto max-w-6xl border-t border-gray-100"
      {...restProps}
    >
      <NavLink to="/" className="text-gray-700 hover:text-gray-900">
        Home
      </NavLink>
      <button onClick={() => setShowPricing(true)} className="text-gray-700 text-left hover:text-gray-900">
        Pricing
      </button>

      {userId ? (
        <>
          <NavLink
            to={'/dashboard'}
            className=" px-4 py-2  border rounded-lg hover:shadow-md hover:shadow-blue-200 hover:border-blue-200 text-black text-sm text-nowrap transition-all duration-300"
          >
            Go to Dashboard
          </NavLink>
          <UserProfileCard
            className={'w-full'}
            userId={userId}
            avatarUrl={avatarUrl}
            username={username}
            email={email}
            jobTitle={jobTitle}
            credits={credits}
          />
          <div className={'flex flex-wrap sm:flex-nowrap gap-3'}>
            <Button
              onClick={handleLogoutAndCloseMenu}
              className={
                'flex flex-nowrap items-center justify-center py-3 w-full sm:w-1/2 rounded-md bg-gradient-to-r from-red-400 to-red-500 text-white hover:from-red-500 hover:to-red-600'
              }
            >
              <LogOut className="w-5 h-4 mr-1" />
              <span className={'leading-4 '}>Log Out</span>
            </Button>
          </div>
        </>
      ) : (
        <Button
          onClick={() => signIn(true)}
          className="text-nowrap bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600 w-full"
        >
          Sign In
        </Button>
      )}
      <Modal
        className={'bg-transparent p-0 w-full max-w-full'}
        isOpen={showPricing}
        onClose={() => setShowPricing(false)}
        style={{ marginTop: 0 }}
      >
        <PricingCard
          userId={userId}
          onClose={() => setShowPricing(false)}
          onSuccess={(newCredits) => {
            // Handle success - maybe refresh credits count
            setShowPricing(false);
          }}
        />
      </Modal>
    </div>
  );
});
