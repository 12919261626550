import { Card } from '../../base/Card/Card';
import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

const fullText = [
  'Running RoboApply ✓',
  'Finding jobs ✓',
  'Generating resume ✓',
  'Applying ✓',
  'Repeating 1000x times ✓',
];

export const RoboApplyProcessCard = ({ className }) => {
  const [typedText, setTypedText] = useState('');
  const [currentLine, setCurrentLine] = useState(0);
  const [currentChar, setCurrentChar] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (currentLine < fullText.length) {
        if (currentChar < fullText[currentLine].length) {
          setTypedText((prev) => prev + fullText[currentLine][currentChar]);
          setCurrentChar((prev) => prev + 1);
        } else {
          setTypedText((prev) => prev + '\n');
          setCurrentLine((prev) => prev + 1);
          setCurrentChar(0);
        }
      } else {
        // Reset to start over
        setTypedText('');
        setCurrentLine(0);
        setCurrentChar(0);
      }
    }, 50);

    return () => clearInterval(typingInterval);
  }, [currentLine, currentChar]);

  return (
    <Card className={twMerge('bg-white p-6 rounded-lg shadow-lg mb-6 overflow-hidden', className)}>
      <div className="flex items-center gap-3 mb-4">
        <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse" />
        <h2 className="text-xl font-semibold">RoboApply Process</h2>
      </div>
      <div className="h-[140px] overflow-hidden">
        <pre className="font-mono text-lg whitespace-pre-wrap text-black">{typedText}</pre>
      </div>
    </Card>
  );
};

export default RoboApplyProcessCard;
