import axios from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const registerUserProfile = async (formData) => {
  // const registrationData = {
  //   first_name: FormDataEntries.firstName,
  //   last_name: FormDataEntries.lastName,
  //   email: FormDataEntries.email,
  //   user_id: userId,
  //   resume: FormDataEntries.resume,
  // };
  //get raw data from formdata
  return await axios.post('https://68d4-45-8-19-36.ngrok-free.app/api/v1/register', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  // return await axios.post('https://68d4-45-8-19-36.ngrok-free.app/api/v1/register', formData);
};

export const postApplication = async (formData) => {
  if (!formData?.user_id) {
    return { error: 'Invalid User ID. Please refresh and try again.' };
  }
  const response = await fetch(process.env.REACT_APP_JOB_APPLICATION_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });

  if (response && response?.ok === true) {
    return { success: true, campaign: response?.data };
  }

  return { error: 'Failed to create campaign.' };
};

export const validateDiceCredentials = async (email, password) => {
  const isEmailValid = email.includes('@');
  if (!email || !isEmailValid || !password) {
    console.log("Email or password can't be empty", email, password);
    return { error: { message: "Email or password can't be empty" } };
  }
  const response = await axios.post('https://68d4-45-8-19-36.ngrok-free.app/validate-dice-credentials', {
    email: email,
    password: password,
  });

  if (response) {
    return response;
  }
};

export const postPurchaseCredits = async ({ sku, userId }) =>
  await fetch('https://payment-backend-git-main-samthedatamans-projects.vercel.app/create-checkout-session', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      sku,
      quantity: 1,
      user_id: userId,
    }),
  });

export const verifyPaymentSuccess = async ({ sessionId, userId }) =>
  await fetch(
    `https://payment-backend-git-main-samthedatamans-projects.vercel.app/verify-session?session_id=${sessionId}&user_id=${userId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

export const warmupRoute = async ({ url = 'https://68d4-45-8-19-36.ngrok-free.app/' }) =>
  await axios.get(url, {
    timeout: 5000,
  });

// Optionally add interceptors for auth tokens, logging, etc.
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  console.log('api interceptor token', token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
