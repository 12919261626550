import React, { useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    Download,
    Star,
    CheckCircle,
    Plus,
    X,
    AlertCircle,
    Eye
} from 'lucide-react';

import SortableResumeSection from '../resumepage/SortableResumeSection';
import LoadingSpinner from '../resumepage/LoadingSpinner';
import ThemeSelector from '../resumepage/ThemeSelector';
import TextEditor from '../resumepage/TextEditor';
import WorkExperienceItem from '../resumepage/WorkExperienceItem';
import EducationItem from '../resumepage/EducationItem';
import SkillsSection from '../resumepage/SkillsSection';
import ProjectItem from '../resumepage/ProjectItem';
import ResumePreviewPanel from '../resumepage/ResumePreviewPanel';
import ResumePreview from './ResumePreview';




const TABS = [
    { id: 'resume', label: 'Resume Editor' },
    { id: 'cover', label: 'Cover Letter' },
    { id: 'pitch', label: 'Candidate Pitch' }
];
const API_BASE = process.env.REACT_APP_API_BASE || 'https://e0a0-74-88-7-3.ngrok-free.app';
const API_ENDPOINTS = {
    THEMES: '/api/themes',
    PREVIEW: '/api/preview',
    EXPORT: '/api/export-pdf'
};

const ResumeEditorPanel = ({
    error,
    resumeScore,
    selectedTheme,
    onThemeChange,
    onExport,
    isProcessing,
    sections,
    expandedSections,
    onSectionToggle,
    onDragEnd,
    onSave,
    sensors,
    formData,
    onFormUpdate,
    coverLetter = '',
    onCoverLetterChange,
    candidatePitch = '',
    onCandidatePitchChange,
    onSaveCoverLetter,
    onSavePitch,
    fileUrl,
    fileType
}) => {
    const [activeTab, setActiveTab] = useState('resume');
    const [showPreview, setShowPreview] = useState(false);
    const [previewHtml, setPreviewHtml] = useState('');
    const [localError, setLocalError] = useState(null);

    const ErrorMessage = ({ message }) => (
        <div className="flex items-center gap-2 mb-4 p-4 border border-red-300 bg-red-50 rounded">
            <AlertCircle className="h-5 w-5 text-red-600" />
            <span className="text-red-800 text-sm font-medium">{message}</span>
        </div>
    );

    const updatePreview = async (data = formData) => {
        if (!data?.basics?.name) return;

        try {
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({
                    resume_data: data,
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) throw new Error('Preview generation failed');
            const html = await response.text();
            setPreviewHtml(html);
        } catch (error) {
            console.error('Error updating preview:', error);
            setLocalError('Failed to update preview');
        }
    };

    const handlePreview = async () => {
        await updatePreview();
        setShowPreview(true);
    };

    const renderSectionContent = (section) => {
        if (!formData) return null;

        switch (section) {
            case 'basics':
                return (
                    <div className="space-y-4">
                        <input
                            type="text"
                            value={formData.basics?.name || ''}
                            onChange={(e) => onFormUpdate('basics', 'name', e.target.value)}
                            placeholder="Full Name"
                            className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                        />
                        <input
                            type="text"
                            value={formData.basics?.label || ''}
                            onChange={(e) => onFormUpdate('basics', 'label', e.target.value)}
                            placeholder="Professional Title"
                            className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                        />
                        <input
                            type="email"
                            value={formData.basics?.email || ''}
                            onChange={(e) => onFormUpdate('basics', 'email', e.target.value)}
                            placeholder="Email"
                            className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                        />
                        <input
                            type="tel"
                            value={formData.basics?.phone || ''}
                            onChange={(e) => onFormUpdate('basics', 'phone', e.target.value)}
                            placeholder="Phone"
                            className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                        />
                        <div className="grid grid-cols-2 gap-4">
                            <input
                                type="text"
                                value={formData.basics?.location?.city || ''}
                                onChange={(e) => onFormUpdate('basics', 'location', {
                                    ...formData.basics?.location,
                                    city: e.target.value
                                })}
                                placeholder="City"
                                className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                            />
                            <input
                                type="text"
                                value={formData.basics?.location?.region || ''}
                                onChange={(e) => onFormUpdate('basics', 'location', {
                                    ...formData.basics?.location,
                                    region: e.target.value
                                })}
                                placeholder="State/Region"
                                className="w-full p-2 border rounded bg-white text-gray-900 border-gray-300"
                            />
                        </div>
                        <TextEditor
                            content={formData.basics?.summary || ''}
                            onChange={(value) => onFormUpdate('basics', 'summary', value)}
                            placeholder="Professional Summary"
                        />
                    </div>
                );

            case 'work':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('work', null, [
                                ...(formData.work || []),
                                {
                                    url: '',
                                    name: '',
                                    position: '',
                                    startDate: '',
                                    endDate: '',
                                    location: {
                                        city: '',
                                        state: '',
                                        country: ''
                                    },
                                    highlights: []
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Work Experience
                        </button>
                        {(formData.work || []).map((experience, index) => (
                            <WorkExperienceItem
                                key={experience.id || index}
                                experience={experience}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.work || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('work', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('work', null,
                                        formData.work.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            case 'education':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('education', null, [
                                ...(formData.education || []),
                                {
                                    institution: '',
                                    studyType: '',
                                    area: '',
                                    startDate: '',
                                    endDate: '',
                                    gpa: '',
                                    courses: []
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Education
                        </button>
                        {(formData.education || []).map((education, index) => (
                            <EducationItem
                                key={education.id || index}
                                education={education}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.education || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('education', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('education', null,
                                        formData.education.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            case 'skills':
                return (
                    <SkillsSection
                        skills={formData.skills || []}
                        onUpdate={(newSkills) => onFormUpdate('skills', null, newSkills)}
                    />
                );

            case 'projects':
                return (
                    <div className="space-y-4">
                        <button
                            onClick={() => onFormUpdate('projects', null, [
                                ...(formData.projects || []),
                                {
                                    name: '',
                                    url: '',
                                    summary: '',
                                    highlights: []
                                }
                            ])}
                            className="flex items-center gap-2 px-4 py-2 bg-blue-50 text-blue-600 rounded-lg"
                        >
                            <Plus className="w-4 h-4" />
                            Add Project
                        </button>
                        {(formData.projects || []).map((project, index) => (
                            <ProjectItem
                                key={project.id || index}
                                project={project}
                                onUpdate={(field, value) => {
                                    const updated = [...(formData.projects || [])];
                                    updated[index] = { ...updated[index], [field]: value };
                                    onFormUpdate('projects', null, updated);
                                }}
                                onDelete={() => {
                                    onFormUpdate('projects', null,
                                        formData.projects.filter((_, idx) => idx !== index)
                                    );
                                }}
                            />
                        ))}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="w-full bg-white p-4">
            {(error || localError) && <ErrorMessage message={error || localError} />}

            {showPreview && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                    <div className="bg-white w-11/12 h-5/6 rounded-lg p-4 relative">
                        <button
                            onClick={() => setShowPreview(false)}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        >
                            <X className="w-5 h-5" />
                        </button>
                        <div className="h-full overflow-auto">
                            {/* Custom Preview Panel */}
                            <div
                                className="w-full h-full p-4 bg-white"
                                dangerouslySetInnerHTML={{ __html: previewHtml }}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-col space-y-4">
                {/* <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        <h3 className="text-xl font-bold text-gray-900">
                            Edit Resume
                        </h3>
                        <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
                            <Star className="w-4 h-4 text-blue-500" />
                            <span className="text-blue-600 font-medium">
                                {resumeScore}%
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <ThemeSelector
                            selectedTheme={selectedTheme}
                            onThemeChange={onThemeChange}
                        />
                        <ResumePreview
                            formData={formData}
                            selectedTheme={selectedTheme}
                            API_BASE={API_BASE}
                            API_ENDPOINTS={API_ENDPOINTS}
                        />
                        <button
                            onClick={onExport}
                            disabled={isProcessing}
                            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 flex items-center gap-2 disabled:opacity-50"
                        >
                            {isProcessing ? (
                                <LoadingSpinner />
                            ) : (
                                <Download className="w-5 h-5" />
                            )}
                            Export PDF
                        </button>
                    </div>
                </div> */}

                <div className="border-b border-gray-200">
                    <nav className="flex space-x-8" aria-label="Tabs">
                        {TABS.map(tab => (
                            <button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm
                                    ${activeTab === tab.id
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700'
                                    }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>

            <div className="mt-6">
                {activeTab === 'resume' ? (
                    <DndContext
                        sensors={sensors}
                        onDragEnd={onDragEnd}
                    >
                        <SortableContext items={sections} strategy={verticalListSortingStrategy}>
                            <div className="space-y-4">
                                {sections.map((section) => (
                                    <SortableResumeSection
                                        key={section}
                                        id={section}
                                        title={section}
                                        data={formData}
                                        formContent={renderSectionContent(section)}
                                        isExpanded={expandedSections[section]}
                                        onToggle={() => onSectionToggle(section)}
                                    />
                                ))}
                            </div>
                        </SortableContext>
                    </DndContext>
                ) : activeTab === 'cover' ? (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Cover Letter
                            </h2>
                            <button
                                onClick={onSaveCoverLetter}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Save Cover Letter
                            </button>
                        </div>
                        <TextEditor
                            content={coverLetter}
                            onChange={onCoverLetterChange}
                            placeholder="Write your cover letter..."
                        />
                    </div>
                ) : (
                    <div className="space-y-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Candidate Pitch
                            </h2>
                            <button
                                onClick={onSavePitch}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Save Pitch
                            </button>
                        </div>
                        <TextEditor
                            content={candidatePitch}
                            onChange={onCandidatePitchChange}
                            placeholder="Write your candidate pitch..."
                        />
                    </div>
                )}
            </div>

            {activeTab === 'resume' && (
                <div className="sticky bottom-0 bg-white p-4 border-t border-gray-200 mt-4">
                    <button
                        onClick={onSave}
                        disabled={isProcessing}
                        className="w-full px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 disabled:opacity-50 flex items-center justify-center gap-2 transition-colors"
                    >
                        {isProcessing ? (
                            <>
                                <LoadingSpinner />
                                <span>Saving...</span>
                            </>
                        ) : (
                            <>
                                <CheckCircle className="w-5 h-5" />
                                <span>Save Changes</span>
                            </>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResumeEditorPanel;