import { Star } from 'lucide-react';
import { getCompanyStyle } from '../../../utilities/getCompanyStyle';
import React from 'react';
import { Card } from '../../base/Card/Card';
import { Button } from '../../base/Button/Button';
// import {
//   Carousel,
//   CarouselContent,
//   CarouselItem,
//   CarouselNext,
//   CarouselPrevious,
// } from "@/components/ui/carousel"
import { Avatar, AvatarImage, AvatarFallback } from '../../base/Avatar/Avatar';
import { twMerge } from 'tailwind-merge';

export const ReviewCard = ({ job: testimonial, index, onCTAClick, className, style }) => {
  return (
    <div
      key={index}
      className={twMerge('absolute top-0 left-0 w-full transition-all duration-500 ease-in-out', className)}
      style={style}
    >
      <div key={testimonial.id} className=" md:basis-1/2 lg:basis-1/3">
        <div className="">
          <Card className="relative bg-white shadow-lg overflow-hidden p-6 h-full flex flex-col">
            <div className="absolute top-0 right-0 w-20 h-20 bg-gradient-to-br from-green-300 to-blue-300  rounded-bl-full" />

            <div className="flex items-start space-x-4 mb-4">
              <Avatar className="h-12 w-12 border-2 border-gray-400">
                <AvatarImage src={testimonial.image} alt={testimonial.name} />
                <AvatarFallback>{testimonial.name.split(' ')[0][0]}</AvatarFallback>
              </Avatar>
              <div>
                <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
              </div>
            </div>

            <div className="flex mb-4">
              {Array.from({ length: 5 }).map((_, i) => (
                <Star
                  key={i}
                  className={twMerge(
                    'w-4 h-4',
                    i < testimonial.rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-200 fill-gray-200',
                  )}
                />
              ))}
            </div>

            <blockquote className="flex-grow">
              <p className="text-gray-700 leading-relaxed mb-4  line-clamp-3 md:line-clamp-3">
                "{testimonial.content}"
              </p>
            </blockquote>

            <div className="mt-auto pt-4">
              <Button onClick={onCTAClick} className="w-fit radius-md bg-primary bg-green-100 text-black">
                Accelerate your job search!
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
ReviewCard.displayName = 'ReviewCard';
