// src/components/resumepage/ResumeActionButton.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Edit2 as EditIcon,
    Eye as EyeIcon,
    Download as DownloadIcon,
    X as CloseIcon,
    ExternalLink as ExternalLinkIcon
} from 'lucide-react';

import { supabase } from '../../supabaseConfig';
import { toast } from 'sonner';

const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

const ResumeActionButton = ({ resume }) => {
    const navigate = useNavigate();
    const [showPreview, setShowPreview] = useState(false);

    // Return the user ID (or fallback) if dev
    const getUserId = async () => {
        if (isDev) {
            console.log('Development mode: Using fallback UUID');
            return DEV_FALLBACK_UUID;
        } else {
            const {
                data: { user },
                error
            } = await supabase.auth.getUser();
            if (error || !user) {
                console.error('User not authenticated.');
                toast.error('User not authenticated.');
                return null;
            }
            console.log(`Authenticated user ID: ${user.id}`);
            return user.id;
        }
    };

    // Handle the "Edit" button: navigates to /editor/{userId}/{resume.id}
    const handleEditClick = async () => {
        console.log('Edit button clicked for resume ID:', resume.id);
        const userId = await getUserId();
        if (userId) {
            const editPath = `/editor/${userId}/${resume.id}`;
            console.log(`Navigating to: ${editPath}`);
            navigate(editPath);
        }
    };

    // Show the inline preview modal
    const handlePreviewClick = () => {
        console.log('Preview button clicked for resume ID:', resume.id);
        if (resume.file_url) {
            setShowPreview(true);
            toast.info('Opening resume preview.');
        } else {
            console.error('No file URL available for preview.');
            toast.error('No file URL available for preview.');
        }
    };

    // Download the resume
    const handleDownloadClick = async () => {
        try {
            console.log('Download button clicked for resume ID:', resume.id);
            const response = await fetch(resume.file_url);
            if (!response.ok) throw new Error('Download failed');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = resume.file_name || 'resume.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            console.log('Resume downloaded successfully:', resume.file_name);
            toast.success('Resume downloaded successfully!');
        } catch (error) {
            console.error('Download error:', error);
            toast.error('Failed to download resume.');
        }
    };

    const handleClosePreview = () => {
        console.log('Closing preview modal for resume ID:', resume.id);
        setShowPreview(false);
    };

    return (
        <>
            {/* 3 bold, colorful buttons */}
            <div className="flex items-center gap-2">
                {/* Download */}
                <button
                    onClick={handleDownloadClick}
                    className="inline-flex items-center gap-1 px-3 py-2 bg-blue-100 text-blue-700
                     rounded-md text-sm font-semibold
                     transition-colors hover:bg-blue-200"
                    title="Download Resume"
                    aria-label="Download Resume"
                >
                    <DownloadIcon className="w-4 h-4" />
                    <span>Download</span>
                </button>

                {/* Preview */}
                <button
                    onClick={handlePreviewClick}
                    className="inline-flex items-center gap-1 px-3 py-2 bg-purple-100 text-purple-700
                     rounded-md text-sm font-semibold
                     transition-colors hover:bg-purple-200"
                    title="View Resume"
                    aria-label="View Resume"
                >
                    <EyeIcon className="w-4 h-4" />
                    <span>Preview</span>
                </button>

                {/* Edit */}
                <button
                    onClick={handleEditClick}
                    className="inline-flex items-center gap-1 px-3 py-2 bg-green-100 text-green-700
                     rounded-md text-sm font-semibold
                     transition-colors hover:bg-green-200"
                    title="Edit Resume"
                    aria-label="Edit Resume"
                >
                    <EditIcon className="w-4 h-4" />
                    <span>Edit</span>
                </button>
            </div>

            {/* Preview Modal */}
            {showPreview && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white rounded-xl shadow-xl w-full max-w-3xl h-[95vh] flex flex-col">
                        {/* Header */}
                        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                            <h3 className="text-lg font-semibold text-gray-900">
                                Resume Preview
                            </h3>
                            <div className="flex items-center gap-2">
                                <a
                                    href={resume.file_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors"
                                    title="Open in new tab"
                                    aria-label="Open in new tab"
                                >
                                    <ExternalLinkIcon className="w-5 h-5" />
                                </a>
                                <button
                                    onClick={handleClosePreview}
                                    className="p-2 text-gray-600 hover:text-red-600 transition-colors"
                                    title="Close Preview"
                                    aria-label="Close Preview"
                                >
                                    <CloseIcon className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                        {/* iFrame Content */}
                        <div className="flex-1 overflow-hidden">
                            <iframe
                                src={resume.file_url}
                                className="w-full h-full border-0"
                                title="Resume Preview"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResumeActionButton;
