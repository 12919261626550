// import React, { useState, useEffect } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { Filter } from 'lucide-react';
// import { PATTERNS } from '../utilities/description-utils';

// const FilterSection = ({ jobs, filters, onChange }) => {
//     const [isOpen, setIsOpen] = useState(false);
//     const [availableFilters, setAvailableFilters] = useState({
//         jobType: [],
//         experienceLevel: [],
//         salary: [],
//         workplaceType: [],
//         technologies: []
//     });

//     // Parse job descriptions to extract filter options
//     useEffect(() => {
//         if (jobs?.length > 0) {
//             const extractedFilters = {
//                 jobType: new Set(),
//                 experienceLevel: new Set(['Entry Level', 'Mid Level', 'Senior Level']),
//                 salary: new Set(['$0-50K', '$50-100K', '$100-150K', '$150K+']),
//                 workplaceType: new Set(['Remote', 'Hybrid', 'On-site']),
//                 technologies: new Set()
//             };

//             jobs.forEach(job => {
//                 const description = job.description || '';

//                 // Extract job types
//                 const jobTypeMatches = description.match(PATTERNS.highlights.jobType) || [];
//                 jobTypeMatches.forEach(type => extractedFilters.jobType.add(type));

//                 // Extract technologies
//                 const techMatches = description.match(PATTERNS.highlights.tech) || [];
//                 techMatches.forEach(tech => extractedFilters.technologies.add(tech));
//             });

//             // Convert Sets to sorted arrays
//             setAvailableFilters({
//                 jobType: [...extractedFilters.jobType].sort(),
//                 experienceLevel: [...extractedFilters.experienceLevel],
//                 salary: [...extractedFilters.salary],
//                 workplaceType: [...extractedFilters.workplaceType],
//                 technologies: [...extractedFilters.technologies].sort()
//             });
//         }
//     }, [jobs]);

//     const filterLabels = {
//         jobType: 'Job Type',
//         experienceLevel: 'Experience',
//         salary: 'Salary Range',
//         workplaceType: 'Workplace',
//         technologies: 'Technologies'
//     };

//     return (
//         <div className="relative">
//             <motion.button
//                 whileHover={{ scale: 1.09 }}
//                 whileTap={{ scale: 0.98 }}
//                 onClick={() => setIsOpen(!isOpen)}
//                 className="px-40 py-2 border border-gray-300 rounded-lg text-gray-700 
//           hover:bg-gray-50 flex items-center space-x-2"
//             >
//                 <Filter className="w-4 h-4" />
//                 <span>Filters</span>

//                 {Object.values(filters).some(f => f.length > 0) && (
//                     <span className="bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center ml-auto">
//                         {Object.values(filters).reduce((acc, curr) => acc + curr.length, 0)}
//                     </span>
//                 )}
//                 <motion.span
//                     animate={{ rotate: isOpen ? 180 : 0 }}
//                     className="transform transition-transform"
//                 >
//                     ▼
//                 </motion.span>
//             </motion.button>

//             <AnimatePresence>
//                 {isOpen && (
//                     <motion.div
//                         initial={{ opacity: 0, y: -10 }}
//                         animate={{ opacity: 1, y: 0 }}
//                         exit={{ opacity: 0, y: -10 }}
//                         className="absolute z-50 mt-2 p-4 bg-white rounded-lg shadow-lg border 
//               border-gray-200 grid grid-cols-2 gap-4 min-w-[600px] left-0"
//                     >
//                         {Object.entries(availableFilters).map(([category, options]) => (
//                             options.length > 0 && (
//                                 <div key={category} className="space-y-2">
//                                     <h3 className="font-semibold text-gray-900">
//                                         {filterLabels[category]}
//                                     </h3>
//                                     <div className="space-y-2 max-h-40 overflow-y-auto">
//                                         {options.map(option => (
//                                             <label key={option} className="flex items-center space-x-2">
//                                                 <input
//                                                     type="checkbox"
//                                                     checked={filters[category]?.includes(option)}
//                                                     onChange={() => {
//                                                         const currentFilters = filters[category] || [];
//                                                         const newFilters = currentFilters.includes(option)
//                                                             ? currentFilters.filter(f => f !== option)
//                                                             : [...currentFilters, option];
//                                                         onChange(category, newFilters);
//                                                     }}
//                                                     className="rounded border-gray-300 text-blue-600 
//                             focus:ring-blue-500"
//                                                 />
//                                                 <span className="text-gray-700">{option}</span>
//                                             </label>
//                                         ))}
//                                     </div>
//                                 </div>
//                             )
//                         ))}
//                     </motion.div>
//                 )}
//             </AnimatePresence>
//         </div>
//     );
// };

// export default FilterSection;

// FilterSection.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Filter } from 'lucide-react';

const FilterSection = ({ jobs, filters, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    // We'll store local changes in `localFilters` until user hits "Apply Filters"
    const [localFilters, setLocalFilters] = useState({ ...filters });

    // Example filter categories
    const filterOptions = {
        jobType: ['Full-time', 'Part-time', 'Contract', 'Internship'],
        experienceLevel: ['Entry Level', 'Mid Level', 'Senior Level'],
        salary: ['$0-50K', '$50-100K', '$100-150K', '$150K+'],
        workplaceType: ['On-site', 'Remote', 'Hybrid'],
    };

    // Toggle a value in localFilters
    const handleToggle = (category, option) => {
        const currentVals = localFilters[category] || [];
        const newVals = currentVals.includes(option)
            ? currentVals.filter(v => v !== option)
            : [...currentVals, option];
        setLocalFilters(prev => ({ ...prev, [category]: newVals }));
    };

    const handleApply = () => {
        // Now call onChange for each category
        Object.keys(localFilters).forEach((cat) => {
            onChange(cat, localFilters[cat]);
        });
        setIsOpen(false);
    };

    return (
        <div className="relative">
            <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setIsOpen(!isOpen)}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 
          hover:bg-gray-50 flex items-center space-x-2"
            >
                <Filter className="w-4 h-4" />
                <span>Filters</span>
                <motion.span
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    className="transform transition-transform"
                >
                    ▼
                </motion.span>
            </motion.button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="absolute z-50 mt-2 p-4 bg-white rounded-lg shadow-lg border 
              border-gray-200 grid grid-cols-2 gap-4 min-w-[400px]"
                    >
                        {Object.entries(filterOptions).map(([category, options]) => (
                            <div key={category} className="space-y-2">
                                <h3 className="font-semibold text-gray-900 capitalize">
                                    {category.replace(/([A-Z])/g, ' $1').trim()}
                                </h3>
                                <div className="space-y-2">
                                    {options.map(option => (
                                        <label key={option} className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={localFilters[category]?.includes(option)}
                                                onChange={() => handleToggle(category, option)}
                                                className="rounded border-gray-300 text-blue-600 
                          focus:ring-blue-500"
                                            />
                                            <span className="text-gray-700">{option}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        ))}

                        {/* Single "Apply Filters" button spanning entire width */}
                        <div className="col-span-2 mt-2">
                            <motion.button
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                onClick={handleApply}
                                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                  transition-colors"
                            >
                                Apply Filters
                            </motion.button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FilterSection;
