'use client';

import React, { useEffect } from 'react';
import { useNavigate, useLoaderData, useNavigation } from 'react-router';
import { Sparkles } from 'lucide-react';
import confetti from 'canvas-confetti';
import { Button } from '../base/Button/Button';

const SuccessPage = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const data = useLoaderData();

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      colors: ['#4CAF50', '#2196F3', '#FFC107'],
    });
  };

  useEffect(() => {
    if (data.credits) {
      triggerConfetti();
      const timer = setTimeout(() => {
        navigate('/dashboard');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [data.credits, navigate]);

  if (navigation.state === 'loading') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-50 to-blue-50">
        <div className="bg-white p-8 rounded-xl shadow-lg text-center space-y-4">
          <div className="animate-spin">
            <Sparkles className="text-blue-400 w-12 h-12" />
          </div>
          <p className="text-lg text-gray-600 font-medium">Verifying your payment...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-green-50 to-blue-50">
      <div className="bg-white p-10 rounded-xl shadow-lg text-center space-y-6 max-w-md">
        <Sparkles className="text-green-400 w-16 h-16 mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-blue-600">Payment Successful! 🎉</h1>
        <div className="space-y-4">
          <p className="text-xl text-gray-600">Congratulations! Your payment has been processed successfully.</p>
          <div className="bg-green-50 p-4 rounded-lg">
            <p className="text-2xl font-bold text-green-600">+{data?.credits} Credits Added</p>
          </div>
          <p className="text-lg text-gray-600">Redirecting to dashboard in 5 seconds...</p>
        </div>
        <Button
          onClick={() => navigate('/dashboard')}
          className="w-full bg-gradient-to-r from-green-400 to-blue-500 text-white text-lg py-3 rounded-lg hover:from-green-500 hover:to-blue-600 transition-all transform hover:scale-105"
        >
          Go to Dashboard Now
        </Button>
      </div>
    </div>
  );
};

export default SuccessPage;
