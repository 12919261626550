// import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router';
// import { MainLayout } from './components/template/MainLayout/MainLayout';
// import { JobApplyAILandingPage } from './components/page/JobApplyAILandingPage';
// import { DashboardPage } from './components/page/DashboardPage';
// import { AutoApplyPage } from './components/page/AutoApplyPage';
// import { ApplicationHistoryPage } from './components/page/ApplicationHistoryPage';
// import { BillingContent, NotificationSettings, PersonalDetails, SettingsContent } from './components/Settings';
// import SuccessPage from './components/page/SuccessPage';
// import CancelPage from './components/page/CancelPage';
// import EnhancedResumeTool from './components/ResumeEnhancement';
// import JobCampaignDAG from './components/DAG';
// import ResumeDashboard from './components/resumepage/ResumeDashboard';
// import ChromeExtensionPage from './components/page/ChromeExtensionPage';
// // import { DevTestPage } from './components/page/DevTestPage';
// import JobSearchPortal from './components/JobSearchPortal';
// import InterviewDashboard from './components/page/InterviewDashboard';
// import ApplicationHub from '/Users/samsavage/Documents/GitHub/robo-apply-js/src/components/appkit/ApplicationHub'
// // import ApplicationHub from './components/page/appkit/ApplicationHub';

// import ResumeSelectionModal from './components/resumepage/ResumeSelectionModal';

// import {
//   authLoader,
//   authAndApplicationsLoader,
//   paymentVerificationLoader,
//   applicationsLoader,
// } from './router/routerLoaders';
// import {
//   postCampaignAction,
//   createUserProfileAction,
//   purchaseCreditsAction,
//   verifyPurchaseAction,
//   postExistingDiceLoginAction,
// } from './router/routerActions';

// import { CreateProfileCard } from './components/concrete/CreateProfileCard/CreateProfileCard';
// import { DevTestPage } from './components/page/DevTestPage';
// import { PricingPage } from './components/page/PricingPage';
// import ResumeEditor from './components/resumepage/ResumeEditor';

// export const router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path="/" id="router-root" loader={authAndApplicationsLoader} element={<MainLayout />}>
//       {/* Public Routes */}
//       <Route index element={<JobApplyAILandingPage />} />
//       <Route path="jobs" element={<JobSearchPortal />} />
//       {/* <Route path="resume" element={<ResumeBuilder />} /> */}
//       <Route path="resume" element={<ResumeDashboard />} />
//       <Route path="editor" element={<ResumeEditor />} />

//       <Route path="hub" element={<ApplicationHub />} />

//       <Route path={'dev'} element={<DevTestPage />} />
//       {/* Protected Routes */}
//       <Route loader={authLoader}>
//         <Route
//           path="dashboard"
//           loader={authAndApplicationsLoader}
//           action={postCampaignAction}
//           element={<DashboardPage />}
//         >
//           <Route path="profile/create" action={createUserProfileAction} element={<CreateProfileCard />} />
//           <Route path="profile/existing-dice-login" action={postExistingDiceLoginAction} />
//           {/*<Route path="profile/create" action={postExistingDiceLoginAction} element={<CreateProfileCard />} />*/}
//         </Route>
//         <Route
//           path="auto-apply"
//           loader={authAndApplicationsLoader}
//           action={postCampaignAction}
//           element={<AutoApplyPage />}
//         />
//         <Route path="pricing" action={purchaseCreditsAction} element={<PricingPage />} />
//         <Route path="history" loader={authAndApplicationsLoader} element={<ApplicationHistoryPage />} />
//         <Route path="settings/billing" element={<BillingContent />} action={purchaseCreditsAction} />
//         <Route loader={authLoader} path="settings/personal" element={<PersonalDetails />} />
//         <Route path="notifications" element={<NotificationSettings />} />
//         <Route path="settings" element={<SettingsContent />}></Route>
//         <Route path="extension" element={<ChromeExtensionPage />}></Route>
//         <Route path="copilot" element={<InterviewDashboard />}></Route>
//         <Route path="jobs" element={<JobSearchPortal />}></Route>

//         <Route
//           path="success"
//           loader={paymentVerificationLoader}
//           action={verifyPurchaseAction}
//           element={<SuccessPage />}
//         />
//         <Route path="cancel" element={<CancelPage />} />
//         <Route path="enhance" element={<EnhancedResumeTool />} />
//         <Route path="roboflow" element={<JobCampaignDAG />} />
//         <Route path="dag" element={<JobCampaignDAG />} />
//       </Route>

//       <Route path="*" element={<Navigate to="/" replace />} />
//     </Route>,
//   ),
// );

// src/router/router.js
import { createBrowserRouter, createRoutesFromElements, Route, Navigate } from 'react-router';
import { MainLayout } from './components/template/MainLayout/MainLayout';
import { JobApplyAILandingPage } from './components/page/JobApplyAILandingPage';
import { DashboardPage } from './components/page/DashboardPage';
import { AutoApplyPage } from './components/page/AutoApplyPage';
import { ApplicationHistoryPage } from './components/page/ApplicationHistoryPage';
import { BillingContent, NotificationSettings, PersonalDetails, SettingsContent } from './components/Settings';
import SuccessPage from './components/page/SuccessPage';
import CancelPage from './components/page/CancelPage';
import EnhancedResumeTool from './components/ResumeEnhancement';
import JobCampaignDAG from './components/DAG';
import ResumeDashboard from './components/resumepage/ResumeDashboard';
import ChromeExtensionPage from './components/page/ChromeExtensionPage';
import JobSearchPortal from './components/JobSearchPortal';
import InterviewDashboard from './components/page/InterviewDashboard';
import ApplicationHub from './components/appkit/ApplicationHub'; // Corrected import path
import ResumeSelectionModal from './components/resumepage/ResumeSelectionModal';
import ResumeEditor from './components/resumepage/ResumeEditor';

import {
  authLoader,
  authAndApplicationsLoader,
  paymentVerificationLoader,
  applicationsLoader,
} from './router/routerLoaders';
import {
  postCampaignAction,
  createUserProfileAction,
  purchaseCreditsAction,
  verifyPurchaseAction,
  postExistingDiceLoginAction,
} from './router/routerActions';

import { CreateProfileCard } from './components/concrete/CreateProfileCard/CreateProfileCard';
import { DevTestPage } from './components/page/DevTestPage';
import { PricingPage } from './components/page/PricingPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" id="router-root" loader={authAndApplicationsLoader} element={<MainLayout />}>
      {/* Public Routes */}
      <Route index element={<JobApplyAILandingPage />} />
      <Route path="jobs" element={<JobSearchPortal />} />
      <Route path="resume" element={<ResumeDashboard />} />
      <Route path="editor/:user_id/:resume_id" element={<ResumeEditor />} /> {/* Updated Route */}
      <Route path="hub" element={<ApplicationHub />} />
      <Route path="dev" element={<DevTestPage />} />

      {/* Protected Routes */}
      <Route loader={authLoader}>
        {/* Dashboard Routes */}
        <Route
          path="dashboard"
          loader={authAndApplicationsLoader}
          action={postCampaignAction}
          element={<DashboardPage />}
        >
          {/* Nested Profile Routes */}
          <Route path="profile/create" action={createUserProfileAction} element={<CreateProfileCard />} />
          <Route path="profile/existing-dice-login" action={postExistingDiceLoginAction} />
        </Route>

        {/* Auto Apply Routes */}
        <Route
          path="auto-apply"
          loader={authAndApplicationsLoader}
          action={postCampaignAction}
          element={<AutoApplyPage />}
        />

        {/* Pricing Route */}
        <Route path="pricing" action={purchaseCreditsAction} element={<PricingPage />} />

        {/* Application History Route */}
        <Route path="history" loader={authAndApplicationsLoader} element={<ApplicationHistoryPage />} />

        {/* Settings Routes */}
        <Route path="settings">
          <Route path="billing" element={<BillingContent />} action={purchaseCreditsAction} />
          <Route path="personal" element={<PersonalDetails />} />
          <Route path="notifications" element={<NotificationSettings />} />
          <Route index element={<SettingsContent />} />
        </Route>

        {/* Other Protected Routes */}
        <Route path="extension" element={<ChromeExtensionPage />} />
        <Route path="copilot" element={<InterviewDashboard />} />
        {/* Removed duplicate 'jobs' route here */}
        <Route path="success" loader={paymentVerificationLoader} action={verifyPurchaseAction} element={<SuccessPage />} />
        <Route path="cancel" element={<CancelPage />} />
        <Route path="enhance" element={<EnhancedResumeTool />} />
        <Route path="roboflow" element={<JobCampaignDAG />} />
        <Route path="dag" element={<JobCampaignDAG />} />
      </Route>

      {/* Fallback Route */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  )
);
