import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

export const CampaignInProgressMessage = ({ isVisible, className }) => {
  if (!isVisible) return null;

  return (
    <div className="flex items-center justify-center w-full h-full">
      <motion.div
        initial={{ scale: 0.9, y: -20 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 25 }}
        className="bg-white rounded-lg p-8 max-w-md text-center shadow-lg"
      >
        <AlertCircle className="w-16 h-16 text-blue-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Campaign in Progress</h2>
        <p className="text-gray-600 mb-6">
          A campaign is currently active. For optimal results, please wait for its completion before initiating a new one.
        </p>
        <p className="text-sm text-gray-500">Thank you for your patience and understanding.</p>
      </motion.div>
    </div>
  );
};