import React, { useState, useEffect } from 'react';
import { Eye, Plus, Lock, Check, Info, Sparkles, Loader2, Brain, X, Link, Wand2 } from 'lucide-react';

// Modal Component
const JOB_SITES = [
    { name: 'LinkedIn', domain: 'linkedin.com' },
    { name: 'Indeed', domain: 'indeed.com' },
    { name: 'Glassdoor', domain: 'glassdoor.com' },
    { name: 'Greenhouse', domain: 'greenhouse.io' },
    { name: 'Lever', domain: 'lever.co' },
];



const NewApplicationModal = ({ isOpen, onClose, onSubmit }) => {
    const [jobInput, setJobInput] = useState('');
    const [inputType, setInputType] = useState('link');
    const [isLoading, setIsLoading] = useState(false);
    const [jobData, setJobData] = useState(null);
    const [aiTuning, setAiTuning] = useState(2); // Default middle value

    const handleJobDataFetch = async () => {
        if (!jobInput.trim()) return;

        setIsLoading(true);
        try {
            // Simulated API call - replace with actual implementation
            const response = await fetch('API_ENDPOINT', {
                method: 'POST',
                body: JSON.stringify({
                    input: jobInput,
                    type: inputType,
                    aiTuning: aiTuning,
                }),
            });

            const data = await response.json();
            setJobData(data);
        } catch (error) {
            console.error('Error fetching job data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAiTuningLabel = () => {
        switch (aiTuning) {
            case 0:
                return 'Modest (Factual and Direct)';
            case 1:
                return 'Slight (Minimal Embellishment)';
            case 2:
                return 'Balanced (Professional Tone)';
            case 3:
                return 'Enhanced (Polished and Impactful)';
            case 4:
                return 'Bold (Confident and Assertive)';
            case 5:
                return 'Maximum (Highly Embellished)';
            default:
                return 'Balanced (Professional Tone)';
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-2xl w-full max-w-2xl p-6 max-h-[90vh] overflow-y-auto">
                {/* Header */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold">Create new application kit</h2>
                    <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
                        <X className="w-5 h-5" />
                    </button>
                </div>

                {/* Main content */}
                <div className="space-y-6">
                    {/* Input Type Toggle */}
                    <div className="flex gap-2 p-1 bg-gray-100 rounded-lg w-fit">
                        <button
                            onClick={() => setInputType('link')}
                            className={`px-4 py-2 rounded-md ${inputType === 'link' ? 'bg-white shadow text-blue-600' : 'text-gray-600'
                                }`}
                        >
                            <div className="flex items-center gap-2">
                                <Link className="w-4 h-4" />
                                Paste Link
                            </div>
                        </button>
                        <button
                            onClick={() => setInputType('paste')}
                            className={`px-4 py-2 rounded-md ${inputType === 'paste' ? 'bg-white shadow text-blue-600' : 'text-gray-600'
                                }`}
                        >
                            Job Description
                        </button>
                    </div>

                    {/* Input Area */}
                    {inputType === 'link' ? (
                        <div>
                            <input
                                type="url"
                                placeholder="Paste job posting URL here..."
                                className="w-full p-4 border rounded-lg"
                                value={jobInput}
                                onChange={(e) => setJobInput(e.target.value)}
                            />

                            <div className="mt-3 flex flex-wrap gap-2">
                                {JOB_SITES.map((site) => (
                                    <span
                                        key={site.name}
                                        className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-600"
                                    >
                                        {site.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <textarea
                            placeholder="Paste job description here..."
                            className="w-full h-48 p-4 border rounded-lg resize-none"
                            value={jobInput}
                            onChange={(e) => setJobInput(e.target.value)}
                        />
                    )}

                    {/* Job Preview */}
                    {jobData && (
                        <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                            <h3 className="font-medium mb-2 flex items-center gap-2">
                                <Check className="w-4 h-4 text-green-600" />
                                Job Details Extracted
                            </h3>
                            <div className="space-y-2">
                                <p className="text-lg font-medium">{jobData.title}</p>
                                <p className="text-gray-600">{jobData.company}</p>
                                <p className="text-gray-600">{jobData.location}</p>
                            </div>
                        </div>
                    )}

                    {/* Puffery Level Slider */}
                    <div className="bg-blue-50 rounded-lg p-4">
                        <div className="flex items-center gap-3 mb-4">
                            <Wand2 className="w-5 h-5 text-blue-600" />
                            <label className="text-sm font-medium text-blue-900">
                                Resume Puffery Level
                                <span className="ml-2 relative group">
                                    <Info className="w-4 h-4 text-blue-400 cursor-pointer" />
                                    <div className="absolute hidden group-hover:block bg-white p-3 rounded-lg shadow-lg text-sm text-gray-700 w-64 z-50 -left-32">
                                        <p>
                                            <strong>Puffery</strong> refers to the level of exaggeration or embellishment in your resume.
                                            Higher levels make your achievements sound more impressive, while lower levels keep things
                                            factual and modest.
                                        </p>
                                    </div>
                                </span>
                            </label>
                        </div>

                        <div className="relative pt-1">
                            <input
                                type="range"
                                min="0"
                                max="5"
                                value={aiTuning}
                                onChange={(e) => setAiTuning(parseInt(e.target.value))}
                                className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
                            />

                            <div className="flex justify-between mt-2">
                                {[0, 1, 2, 3, 4, 5].map((level) => (
                                    <div key={level} className="text-center flex-1">
                                        <div className="h-1 w-1 bg-blue-400 mx-auto mb-1"></div>
                                        <span className="text-xs text-blue-700">
                                            {['Modest', 'Slight', 'Balanced', 'Enhanced', 'Bold', 'Maximum'][level]}
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-2 text-sm text-blue-800 bg-blue-100/50 p-3 rounded-lg">
                                <p className="flex items-center gap-2">
                                    <Brain className="w-4 h-4" />
                                    {getAiTuningLabel()}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-between pt-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600 hover:text-gray-900"
                        >
                            Close
                        </button>
                        <button
                            onClick={handleJobDataFetch}
                            disabled={!jobInput.trim() || isLoading}
                            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center gap-2"
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="w-4 h-4 animate-spin" />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <Sparkles className="w-4 h-4" />
                                    Generate
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Main ResumeHub Component
const ResumeHub = () => {
    const [resumes, setResumes] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Simulate fetching resumes
        setResumes([
            {
                id: 1,
                status: 'Interested',
                job: {
                    title: 'Quality Software Engineer',
                    company: 'PAYPAL'
                },
                coverLetter: 'ready',
                resume: 'pending',
                followUp: 'pending',
                applicationKit: 'ready'
            },
            {
                id: 2,
                status: 'Interested',
                job: {
                    title: 'Data Engineer',
                    company: 'N/A'
                },
                coverLetter: 'ready',
                resume: 'pending',
                followUp: 'pending',
                applicationKit: 'ready'
            },
            {
                id: 3,
                status: 'Interested',
                job: {
                    title: 'Python Developer',
                    company: 'N/A'
                },
                coverLetter: 'ready',
                resume: 'pending',
                followUp: 'pending',
                applicationKit: 'ready'
            }
        ]);
        setLoading(false);
    }, []);

    const handleNewApplication = (jobData) => {
        const newResume = {
            id: resumes.length + 1,
            status: 'Interested',
            job: {
                title: jobData.title,
                company: 'N/A',
            },
            coverLetter: 'pending',
            resume: 'pending',
            followUp: 'pending',
            applicationKit: 'pending'
        };
        setResumes([newResume, ...resumes]);
    };

    const ActionButton = ({ type, status }) => {
        if (status === 'pending') {
            return (
                <button className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white bg-green-500 hover:bg-green-600">
                    <Lock className="w-4 h-4 mr-2" />
                    Upgrade
                </button>
            );
        }
        return (
            <button className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600">
                <Eye className="w-4 h-4 mr-2" />
                Open
            </button>
        );
    };

    const StatusButton = ({ type }) => (
        <button className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white bg-[#7C3AED]">
            <Check className="w-4 h-4 mr-2" />
            Open
        </button>
    );

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Job Hub</h1>
                        <p className="text-gray-600 mt-1">
                            manage your application's,resume's,coverletter's,and follow-up's in one place
                        </p>
                    </div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="inline-flex items-center gap-2 px-6 py-2.5 bg-[#7C3AED] hover:bg-[#6D28D9] 
                     text-white font-medium rounded-lg transition-colors"
                    >
                        <Plus className="w-5 h-5" />
                        New Application Kit
                    </button>
                </div>

                {/* Table */}
                <div className="bg-white rounded-lg shadow-sm mt-8">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr className="bg-gray-50">
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Status</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Job</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Cover Letter</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Resume</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Follow-up</th>
                                <th className="px-6 py-4 text-left text-sm font-medium text-gray-500">Application Kit</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {resumes.map((resume) => (
                                <tr key={resume.id} className="hover:bg-gray-50">
                                    <td className="px-6 py-4">
                                        <span className="text-indigo-600 font-medium">{resume.status}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div>
                                            <div className="font-medium text-gray-900">{resume.job.title}</div>
                                            <div className="text-gray-500 text-sm">{resume.job.company}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <StatusButton type="cover-letter" />
                                    </td>
                                    <td className="px-6 py-4">
                                        <ActionButton type="resume" status={resume.resume} />
                                    </td>
                                    <td className="px-6 py-4">
                                        <ActionButton type="followup" status={resume.followUp} />
                                    </td>
                                    <td className="px-6 py-4">
                                        <ActionButton type="kit" status={resume.applicationKit} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            <NewApplicationModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleNewApplication}
            />
        </div>
    );
};

export default ResumeHub;