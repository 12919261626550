import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseConfig';

// Development mode flag - set to true to bypass auth
const DEV_MODE = false;

// --------------------- SVG ICONS ---------------------
const LinkedInIcon = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 448 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* LinkedIn logo path */}
        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1c-29.62 0-53.79-24.45-53.79-54.58C0 24.4 24.17 0 53.79 0c29.67 0 53.84 24.4 53.84 53.52 0 30.13-24.17 54.58-53.84 54.58zm394.2 339.9h-92.68V302.4c0-34.7-.7-79.3-48.28-79.3-48.34 0-55.8 37.8-55.8 76.8v148.1h-92.68V148.9h88.96v40.8h1.28c12.41-23.5 42.6-48.3 87.66-48.3 93.8 0 111 61.8 111 142.3v164.3z" />
    </svg>
);

const IndeedIcon = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 448 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Placeholder “I” icon */}
        <path d="M224 32C103.59 32 0 135.59 0 256s103.59 224 224 224 224-103.59 224-224S344.41 32 224 32zm55 345c0 6.63-5.37 12-12 12h-86c-6.63 0-12-5.37-12-12V135c0-6.63 5.37-12 12-12h86c6.63 0 12 5.37 12 12v242z" />
    </svg>
);

const MonsterIcon = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Placeholder “M” icon */}
        <path d="M256 8C119.03 8 8 119.03 8 256s111.03 248 248 248 248-111.03 248-248S392.97 8 256 8zm64 384h-32V247.64l-64 96-64-96V392H128V120h32l64 96 64-96h32v272z" />
    </svg>
);

const GlassdoorIcon = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 384 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Placeholder “G” icon */}
        <path d="M72 0C32.3 0 0 32.3 0 72v368c0 39.7 32.3 72 72 72h240c39.7 0 72-32.3 72-72V312c0-13.3-10.7-24-24-24s-24 10.7-24 24v128c0 13.2-10.8 24-24 24H72c-13.2 0-24-10.8-24-24V72c0-13.2 10.8-24 24-24h240c13.2 0 24 10.8 24 24v88c0 13.3 10.7 24 24 24s24-10.7 24-24V72c0-39.7-32.3-72-72-72H72z" />
    </svg>
);

const WorkdayIcon = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 512 512"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {/* Placeholder “W” icon */}
        <path d="M256 8C119.043 8 8 119.041 8 256s111.041 248 248 248 248-111.043 248-248S392.959 8 256 8zm59.63 313.57l-21.27 21.38c-5.45 5.5-14.41 5.5-19.86 0L216 285.42l-58.5 58.53c-5.45 5.5-14.4 5.5-19.86 0l-21.27-21.38c-5.45-5.5-5.45-14.42 0-19.92l100.69-100.92c5.45-5.5 14.4-5.5 19.86 0l100.69 100.92c5.44 5.5 5.44 14.42-.02 19.92z" />
    </svg>
);

// -------------- Collapsible Section for Progressive Disclosure --------------
const CollapsibleSection = ({ title, children, defaultOpen = false }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="border-t border-gray-200 pt-8">
            <button
                onClick={toggle}
                className="flex items-center w-full text-left text-lg font-medium text-gray-900 mb-4 focus:outline-none"
            >
                <span className="mr-2">{isOpen ? '▼' : '►'}</span>
                {title}
            </button>
            {isOpen && <div className="mt-2 space-y-6">{children}</div>}
        </div>
    );
};

const ChromeExtensionPage = () => {
    const [activeTab, setActiveTab] = useState('LinkedIn');
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false); // Set to false initially in dev mode
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        phone_number: '',
        require_visa: '',
        us_citizenship: '',
        website: '',
        linkedin: '',
        years_of_experience: '',
        notice_period: '',
        desired_salary: '',
        current_ctc: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        gender: '',
        ethnicity: '',
        disability_status: '',
        veteran_status: ''
    });

    useEffect(() => {
        if (!DEV_MODE) {
            fetchUserData();
        }
    }, []);

    const fetchUserData = async () => {
        if (DEV_MODE) return;

        try {
            setLoading(true);
            const {
                data: { user: authUser },
                error: authError
            } = await supabase.auth.getUser();
            if (authError) throw authError;

            const [profileResponse, preferencesResponse] = await Promise.all([
                supabase.from('profiles').select('*').eq('id', authUser.id).single(),
                supabase.from('application_preferences').select('*').eq('user_id', authUser.id).single()
            ]);

            if (profileResponse.error) throw profileResponse.error;

            setProfileData(profileResponse.data);
            if (preferencesResponse.data) {
                setFormData(prev => ({
                    ...prev,
                    ...preferencesResponse.data
                }));
            }
        } catch (err) {
            console.error('Error fetching data:', err);
            if (!DEV_MODE) {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFormChange = e => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const saveApplicationPreferences = async () => {
        if (DEV_MODE) {
            console.log('Development mode: Form data would be saved:', formData);
            alert('Development mode: Settings saved (mock)');
            return;
        }

        try {
            const {
                data: { user: authUser }
            } = await supabase.auth.getUser();

            const { error } = await supabase.from('application_preferences').upsert({
                user_id: authUser.id,
                ...formData,
                updated_at: new Date().toISOString()
            });

            if (error) throw error;

            alert('Settings saved successfully!');
        } catch (err) {
            console.error('Error saving settings:', err);
            alert('Error saving settings');
        }
    };

    // -------------- SKELETON LOADER --------------
    if (loading) {
        return (
            <div className="p-4">
                <div className="space-y-4">
                    {/* Tab bar skeleton */}
                    <div className="flex items-center space-x-4 mb-4">
                        <div className="h-8 w-20 bg-gray-200 rounded"></div>
                        <div className="h-8 w-20 bg-gray-200 rounded"></div>
                        <div className="h-8 w-20 bg-gray-200 rounded"></div>
                        <div className="h-8 w-20 bg-gray-200 rounded"></div>
                        <div className="h-8 w-20 bg-gray-200 rounded"></div>
                    </div>
                    {/* Form skeleton */}
                    <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                    <div className="space-y-3">
                        <div className="h-8 bg-gray-200 rounded"></div>
                        <div className="h-8 bg-gray-200 rounded"></div>
                        <div className="h-8 bg-gray-200 rounded"></div>
                        <div className="h-8 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        );
    }

    // -------------- TAB CONTENT --------------
    const renderTabContent = () => {
        switch (activeTab) {
            case 'LinkedIn':
                return (
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-4">
                        <div className="px-6 py-4 border-b border-gray-200">
                            <h2 className="text-xl font-semibold text-gray-900">LinkedIn Profile Autofill</h2>
                            <p className="mt-2 text-sm text-gray-600">
                                Coming Soon...
                            </p>
                        </div>
                        <div className="p-6 space-y-8">
                            {/* Basic Information */}
                            <CollapsibleSection title="Basic Information" defaultOpen>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">First Name</label>
                                        <input
                                            type="text"
                                            id="first_name"
                                            value={formData.first_name}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter first name"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                        <input
                                            type="text"
                                            id="last_name"
                                            value={formData.last_name}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter last name"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Email</label>
                                        <input
                                            type="email"
                                            value={profileData?.email || ''}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
                                            placeholder="Email"
                                            readOnly={!DEV_MODE}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                                        <input
                                            type="tel"
                                            id="phone_number"
                                            value={formData.phone_number}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="(555) 555-5555"
                                        />
                                    </div>
                                </div>
                            </CollapsibleSection>

                            {/* Address Information */}
                            <CollapsibleSection title="Address">
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">Street Address</label>
                                        <input
                                            type="text"
                                            id="street"
                                            value={formData.street}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter street address"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">City</label>
                                        <input
                                            type="text"
                                            id="city"
                                            value={formData.city}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter city"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">State</label>
                                        <input
                                            type="text"
                                            id="state"
                                            value={formData.state}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter state"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Zip Code</label>
                                        <input
                                            type="text"
                                            id="zipcode"
                                            value={formData.zipcode}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter zip code"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Country</label>
                                        <input
                                            type="text"
                                            id="country"
                                            value={formData.country}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter country"
                                        />
                                    </div>
                                </div>
                            </CollapsibleSection>

                            {/* Professional Details */}
                            <CollapsibleSection title="Professional Details">
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Years of Experience
                                        </label>
                                        <input
                                            type="number"
                                            id="years_of_experience"
                                            value={formData.years_of_experience}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            min="0"
                                            placeholder="Enter years of experience"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Notice Period (days)
                                        </label>
                                        <input
                                            type="number"
                                            id="notice_period"
                                            value={formData.notice_period}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            min="0"
                                            placeholder="Enter notice period"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Current Salary (CTC)</label>
                                        <input
                                            type="number"
                                            id="current_ctc"
                                            value={formData.current_ctc}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter current salary"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Desired Salary</label>
                                        <input
                                            type="number"
                                            id="desired_salary"
                                            value={formData.desired_salary}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="Enter desired salary"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">LinkedIn Profile</label>
                                        <input
                                            type="url"
                                            id="linkedin"
                                            value={formData.linkedin}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="https://linkedin.com/in/username"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Portfolio Website</label>
                                        <input
                                            type="url"
                                            id="website"
                                            value={formData.website}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                            placeholder="https://yourportfolio.com"
                                        />
                                    </div>
                                </div>
                            </CollapsibleSection>

                            {/* Work Authorization */}
                            <CollapsibleSection title="Work Authorization">
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Need Visa Sponsorship
                                        </label>
                                        <select
                                            id="require_visa"
                                            value={formData.require_visa}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        >
                                            <option value="Non-citizen seeking work authorization">
                                                Non-citizen seeking work authorization
                                            </option>
                                            <option value="Canadian Citizen/Permanent Resident">
                                                Canadian Citizen/Permanent Resident
                                            </option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </CollapsibleSection>

                            {/* Equal Opportunity Information */}
                            <CollapsibleSection title="Equal Opportunity Information">
                                <p className="text-sm text-gray-500 mb-4">
                                    This information is used for diversity tracking purposes only
                                </p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Gender</label>
                                        <select
                                            id="gender"
                                            value={formData.gender}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                            <option value="Decline">Decline to Answer</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Ethnicity</label>
                                        <select
                                            id="ethnicity"
                                            value={formData.ethnicity}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        >
                                            <option value="">Select Ethnicity</option>
                                            <option value="Hispanic/Latino">Hispanic/Latino</option>
                                            <option value="American Indian or Alaska Native">
                                                American Indian or Alaska Native
                                            </option>
                                            <option value="Asian">Asian</option>
                                            <option value="Black or African American">
                                                Black or African American
                                            </option>
                                            <option value="Native Hawaiian or Other Pacific Islander">
                                                Native Hawaiian or Other Pacific Islander
                                            </option>
                                            <option value="White">White</option>
                                            <option value="Other">Other</option>
                                            <option value="Decline">Decline to Answer</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Veteran Status</label>
                                        <select
                                            id="veteran_status"
                                            value={formData.veteran_status}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                            <option value="Decline">Decline to Answer</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Disability Status</label>
                                        <select
                                            id="disability_status"
                                            value={formData.disability_status}
                                            onChange={handleFormChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
                                        >
                                            <option value="">Select Status</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                            <option value="Decline">Decline to Answer</option>
                                        </select>
                                    </div>
                                </div>
                            </CollapsibleSection>

                            {/* Save Button Section */}
                            <section className="border-t border-gray-200 pt-8">
                                <div className="flex justify-end space-x-4">
                                    <button
                                        onClick={saveApplicationPreferences}
                                        className="px-6 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors duration-200 flex items-center gap-2"
                                    >
                                        Save Settings
                                    </button>
                                </div>
                            </section>
                        </div>
                    </div>
                );
            case 'Indeed':
                return (
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-4 p-6">
                        <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
                            <IndeedIcon className="w-5 h-5" />
                            Indeed Profile Autofill
                        </h2>
                        <p className="mt-2 text-gray-600">Coming soon...</p>
                    </div>
                );
            case 'Monster':
                return (
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-4 p-6">
                        <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
                            <MonsterIcon className="w-5 h-5" />
                            Monster Profile Autofill
                        </h2>
                        <p className="mt-2 text-gray-600">Coming soon...</p>
                    </div>
                );
            case 'Glassdoor':
                return (
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-4 p-6">
                        <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
                            <GlassdoorIcon className="w-5 h-5" />
                            Glassdoor Profile Autofill
                        </h2>
                        <p className="mt-2 text-gray-600">Coming soon...</p>
                    </div>
                );
            case 'Workday':
                return (
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mt-4 p-6">
                        <h2 className="text-xl font-semibold text-gray-900 flex items-center gap=2">
                            <WorkdayIcon className="w-5 h-5" />
                            Workday Profile Autofill
                        </h2>
                        <p className="mt-2 text-gray-600">Coming soon...</p>
                    </div>
                );
            default:
                return null;
        }
    };

    // -------------- RENDER --------------
    return (
        <div className="container mx-auto p-4 max-w-6xl">
            {DEV_MODE && (
                <div className="mb-4 p-2 bg-yellow-100 border border-yellow-400 rounded-lg text-yellow-800">
                    Development Mode: Auth checks disabled
                </div>
            )}

            {/* Tab Navigation */}
            <div className="flex space-x-4 border-b border-gray-200 pb-2">
                {/* LinkedIn Tab */}
                <button
                    onClick={() => setActiveTab('LinkedIn')}
                    className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === 'LinkedIn' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
                        }`}
                >
                    <LinkedInIcon className="w-5 h-5 mr-2" />
                    LinkedIn
                </button>
                {/* Indeed Tab */}
                <button
                    onClick={() => setActiveTab('Indeed')}
                    className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === 'Indeed' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
                        }`}
                >
                    <IndeedIcon className="w-5 h-5 mr-2" />
                    Indeed
                </button>
                {/* Monster Tab */}
                <button
                    onClick={() => setActiveTab('Monster')}
                    className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === 'Monster' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
                        }`}
                >
                    <MonsterIcon className="w-5 h-5 mr-2" />
                    Monster
                </button>
                {/* Glassdoor Tab */}
                <button
                    onClick={() => setActiveTab('Glassdoor')}
                    className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === 'Glassdoor' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
                        }`}
                >
                    <GlassdoorIcon className="w-5 h-5 mr-2" />
                    Glassdoor
                </button>
                {/* Workday Tab */}
                <button
                    onClick={() => setActiveTab('Workday')}
                    className={`flex items-center px-4 py-2 rounded-t-lg ${activeTab === 'Workday' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
                        }`}
                >
                    <WorkdayIcon className="w-5 h-5 mr-2" />
                    Workday
                </button>
            </div>

            {/* Tab Content */}
            {renderTabContent()}
        </div>
    );
};

export default ChromeExtensionPage;
