let showToastFunction = null;

/**
 * Sets the function to handle showing toasts globally.
 * @param {function} showToastFn - The function to display a toast.
 */
export const setShowToastFunction = (showToastFn) => {
  showToastFunction = showToastFn;
};

/**
 * Calls the globally set `showToast` function.
 * @param {string|Object} messageData - The message to show in the toast.
 * @param {string} type - The type of toast (e.g., 'info', 'success', 'error').
 * @param {Object} options - Additional options like `persistent` or `duration`.
 */
export const showToast = (messageData, type = 'info', options = {}) => {
  if (!showToastFunction) {
    console.error('[ToastService]: showToastFunction is not set. MessageData:', messageData);
    return;
  }

  showToastFunction(messageData, type, options);
};
