import React from 'react';

export const GlobalLoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="text-center">
        <div className="inline-block animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        <h2 className="text-xl font-semibold text-white mt-4">Loading...</h2>
        <p className="text-sm text-gray-300 mt-2">Please wait while we prepare your content.</p>
      </div>
    </div>
  );
};
