import { Button } from '../../base/Button/Button';
import { UserProfileCard } from './UserProfileCard';
import { twMerge } from 'tailwind-merge';
import { LogOut, LayoutDashboard } from 'lucide-react';
import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router';

export const UserProfileButton = forwardRef((props, forwardedRef) => {
  const {
    userId,
    avatarUrl,
    username,
    email,
    credits,
    jobTitle,
    handleLogOut,
    className,
    ...restProps
  } = props;

  const [showUserActions, setShowUserActions] = useState(false);

  return (
    <div
      ref={forwardedRef}
      role={'button'}
      onClick={() => setShowUserActions(!showUserActions)}
      {...restProps}
      className={twMerge('user-profile-container flex items-center h-100 gap-3 cursor-pointer', className)}
    >
      <div className={'profile-card-container relative'}>
        <UserProfileCard
          avatarUrl={avatarUrl}
          username={username}
          email={email}
          jobTitle={jobTitle}
          credits={credits}
        />
        <div
          className={twMerge(
            'action-bar absolute bottom-auto left-0 flex gap-2 w-full transition-transform duration-300 ease-in-out',
            showUserActions ? 'translate-y-0 opacity-100' : 'translate-y-auto opacity-0',
          )}
        >
          <Link
            to={'/dashboard'}
            className={
              'flex flex-nowrap items-center justify-center py-2 w-1/2 rounded-sm rounded-bl-xl bg-gradient-to-r from-green-400 to-green-500 text-white hover:from-green-500 hover:to-green-600 transform transition-all duration-200 hover:scale-105'
            }
          >
            <LayoutDashboard className="w-5 h-4 mr-1" />
            Dashboard
          </Link>
          <Link
            onClick={handleLogOut}
            to={'/login'}
            className={
              'flex flex-nowrap items-center justify-center py-2 w-1/2 rounded-sm rounded-br-xl bg-gradient-to-r from-red-400 to-red-500 text-white hover:from-red-500 hover:to-red-600 transform transition-all duration-200 hover:scale-105'
            }
          >
            <LogOut className="w-5 h-4 mr-1" />
            Log Out
          </Link>
        </div>
      </div>
    </div>
  );
});

UserProfileButton.displayName = 'UserProfileButton';