import { useAuth } from '../contexts/useAuth/useAuth';
import { PricingCard } from '../concrete/PricingCard/PricingCard';
import { useNavigate } from 'react-router';

export const PricingPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const isAuthenticated = !!user?.id;
  const handleOnClose = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
      return;
    }

    navigate('/');
  };

  const handleOnSuccess = () => {
    navigate('/auto-apply');
  };

  return (
    <div>
      <PricingCard onClose={handleOnClose} onSuccess={handleOnSuccess} userId={user?.id} />
    </div>
  );
};
