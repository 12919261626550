import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { RefreshCw, User, CreditCard, Briefcase, TrendingUp, Sparkles, ChevronUp, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, formatInTimeZone } from 'date-fns-tz';
import { createClient } from '@supabase/supabase-js';
import confetti from 'canvas-confetti';
import { supabase } from '../supabaseConfig';
import { twMerge } from 'tailwind-merge';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFetcher, useRouteLoaderData } from 'react-router';

// Cache configuration
const cache = {
  profile: new Map(),
  applications: new Map(),
  lastUpdate: new Map(),
};

const POLLING_INTERVAL = 5000; // 5 seconds
const CACHE_DURATION = 30000; // 30 seconds

const AnimatedNumber = ({ value, className }) => {
  const [displayValue, setDisplayValue] = useState(value);
  const previousValue = useRef(value);

  useEffect(() => {
    if (value === undefined || value === null) return;

    let startValue = previousValue.current;
    const endValue = value;
    const duration = 500;
    const steps = 30;
    const stepValue = (endValue - startValue) / steps;
    let currentStep = 0;

    const interval = setInterval(() => {
      currentStep++;
      if (currentStep <= steps) {
        setDisplayValue(Math.round(startValue + stepValue * currentStep));
      } else {
        clearInterval(interval);
        setDisplayValue(endValue);
      }
    }, duration / steps);

    previousValue.current = value;
    return () => clearInterval(interval);
  }, [value]);

  return <span className={className}>{displayValue}</span>;
};

const fetchInitialData = async (userId) => {
  try {
    const [profileData, applicationsData, recentAppData] = await Promise.all([
      supabase.from('profiles').select('*').eq('id', userId).single(),
      supabase
        .from('applications')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', userId)
        .eq('status', 'Successful'),
      supabase
        .from('applications')
        .select('*')
        .eq('user_id', userId)
        .order('time_applied', { ascending: false })
        .limit(1)
        .single(),
    ]);

    const result = {
      profile: profileData.data,
      successfulCount: applicationsData.count || 0,
      recentApp: recentAppData.data
        ? {
            ...recentAppData.data,
            formatted_time: formatInTimeZone(
              new Date(recentAppData.data.time_applied),
              'America/New_York',
              'MMM d, yyyy HH:mm:ss zzz',
            ),
          }
        : null,
      timestamp: Date.now(),
    };

    // Update cache
    cache.profile.set(userId, result.profile);
    cache.applications.set(userId, {
      successfulCount: result.successfulCount,
      recentApp: result.recentApp,
    });
    cache.lastUpdate.set(userId, result.timestamp);

    return result;
  } catch (error) {
    console.error('Error fetching initial data:', error);
    return null;
  }
};

const CreditTrackingMonitor = forwardRef(
  (
    { userId, initialCredits = 0, onInvalidCredentials, showToast, onCreditsUpdated, setShowPricingScreen, className },
    forwardedRef,
  ) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [currentCredits, setCurrentCredits] = useState(initialCredits);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [lastCreditChange, setLastCreditChange] = useState(Date.now());
    const [jobsApplied, setJobsApplied] = useState(0);
    const [successfulJobs, setSuccessfulJobs] = useState(0);
    const [profile, setProfile] = useState(null);
    const [recentActivity, setRecentActivity] = useState([]);
    const [creditChangeRate, setCreditChangeRate] = useState(0);
    const [isInitializing, setIsInitializing] = useState(true);
    const [sessionActive, setSessionActive] = useState(false);
    const [lastApplication, setLastApplication] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const monitoringIntervalRef = useRef(null);
    const applicationRateRef = useRef(null);
    const lastFetchedCreditsRef = useRef(initialCredits);
    const sessionIdRef = useRef(Date.now().toString());
    const previousSuccessfulJobsRef = useRef(successfulJobs);
    const data = useRouteLoaderData('dashboard');
    const fetcher = useFetcher();

    useEffect(() => {
      if (!fetcher.data && !fetcher.state) {
        reloadData();
      }
      console.log('useRouteLoaderData data:', data);
    }, [fetcher]);

    const checkExistingSession = async () => {
      try {
        const { data } = await supabase.from('active_sessions').select('*').eq('user_id', userId).single();

        if (data && data.session_id !== sessionIdRef.current) {
          showToast({
            title: '⚠️ Active Session Detected',
            description: 'Only one RoboApply session can run at a time. Please close other tabs.',
            type: 'warning',
            duration: 10000,
            style: {
              background: 'linear-gradient(135deg, #FFA500 0%, #FF6B6B 100%)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              boxShadow: '0 8px 32px rgba(31, 38, 135, 0.37)',
            },
          });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Session check error:', error);
        return false;
      }
    };

    const registerSession = async () => {
      const userId = localStorage.getItem('userId');
      if (!userId) return;

      try {
        await supabase.from('active_sessions').upsert({
          user_id: userId,
          session_id: sessionIdRef.current,
          last_active: new Date().toISOString(),
        });
        setSessionActive(true);
      } catch (error) {
        console.error('Session registration error:', error);
      }
    };

    const triggerConfetti = () => {
      const defaults = {
        origin: { y: 0.7 },
        zIndex: 9999,
        spread: 360,
        ticks: 50,
        gravity: 0.8,
        decay: 0.94,
        startVelocity: 30,
        colors: ['#5F3DC4', '#4FD1C5', '#63B3ED', '#48BB78', '#805AD5'],
      };

      confetti({
        ...defaults,
        particleCount: 40,
        scalar: 1.2,
        shapes: ['circle', 'square'],
      });

      setTimeout(() => {
        confetti({
          ...defaults,
          particleCount: 30,
          scalar: 0.75,
          shapes: ['circle'],
        });
      }, 150);
    };

    const addActivityItem = (message) => {
      setRecentActivity((previousActivity) => {
        const newActivity = [
          {
            message,
            timestamp: formatInTimeZone(new Date(), 'America/New_York', 'HH:mm:ss'),
            id: Date.now(),
          },
          ...previousActivity,
        ];
        return newActivity.slice(0, 5);
      });
    };

    // Initialize the fetcher

    // Function to reload data
    const reloadData = () => {
      // Trigger the loader again
      fetcher.load('dashboard');
    };

    // Determine the current state
    const isLoading = fetcher.state === 'loading';

    const updateData = async () => {
      if (!userId) return;

      try {
        const data = await fetchInitialData(userId);
        if (data) {
          if (data.profile) {
            setProfile(data.profile);
            setCurrentCredits(data.profile.credits || initialCredits);
            lastFetchedCreditsRef.current = data.profile.credits;
          }

          // Only update successfulJobs if the new value is greater
          if (data.successfulCount > previousSuccessfulJobsRef.current) {
            setSuccessfulJobs(data.successfulCount);
            previousSuccessfulJobsRef.current = data.successfulCount;
          }

          if (data.recentApp && (!lastApplication || data.recentApp.time_applied > lastApplication.time_applied)) {
            setLastApplication(data.recentApp);
          }
        }
      } catch (error) {
        console.error('Error updating data:', error);
      }
    };

    const startPeriodicCheck = () => {
      monitoringIntervalRef.current = setInterval(updateData, POLLING_INTERVAL);

      applicationRateRef.current = setInterval(() => {
        if (isMonitoring && lastCreditChange) {
          const timeDiff = (Date.now() - lastCreditChange) / 1000;
          const rate = successfulJobs / (timeDiff / 60);
          setCreditChangeRate(rate);
        }
      }, 1000);
    };

    useEffect(() => {
      let isMounted = true;

      const initializeMonitor = async () => {
        setIsInitializing(true);
        const userId = localStorage.getItem('userId');

        if (!userId) {
          setIsInitializing(false);
          return;
        }

        // Load cached data first if available
        const cachedProfile = cache.profile.get(userId);
        const cachedApplications = cache.applications.get(userId);
        const lastUpdate = cache.lastUpdate.get(userId);

        if (cachedProfile && cachedApplications && lastUpdate && Date.now() - lastUpdate < CACHE_DURATION) {
          setProfile(cachedProfile);
          setCurrentCredits(cachedProfile.credits || initialCredits);
          setSuccessfulJobs(cachedApplications.successfulCount);
          setLastApplication(cachedApplications.recentApp);
          previousSuccessfulJobsRef.current = cachedApplications.successfulCount;
          setIsDataLoaded(true);
        }

        // Fetch fresh data
        const data = await fetchInitialData(userId);

        if (isMounted && data) {
          setProfile(data.profile);
          setCurrentCredits(data.profile.credits || initialCredits);
          if (data.successfulCount > (previousSuccessfulJobsRef.current || 0)) {
            setSuccessfulJobs(data.successfulCount);
            previousSuccessfulJobsRef.current = data.successfulCount;
          }
          setLastApplication(data.recentApp);
          setIsDataLoaded(true);
        }

        const hasExistingSession = await checkExistingSession();
        if (hasExistingSession) {
          setIsInitializing(false);
          return;
        }

        await registerSession();

        if (isMounted) {
          startPeriodicCheck();
          setIsInitializing(false);
        }
      };

      initializeMonitor();

      return () => {
        isMounted = false;
        if (monitoringIntervalRef.current) clearInterval(monitoringIntervalRef.current);
        if (applicationRateRef.current) clearInterval(applicationRateRef.current);

        const cleanupSession = async () => {
          const userId = localStorage.getItem('userId');
          if (userId) {
            await supabase
              .from('active_sessions')
              .delete()
              .match({ user_id: userId, session_id: sessionIdRef.current });
          }
        };
        cleanupSession();
      };
    }, []);

    useImperativeHandle(forwardedRef, () => ({
      startMonitoring: () => {
        setIsMonitoring(true);
        setLastCreditChange(Date.now());
        addActivityItem('Started monitoring applications');
      },
      stopMonitoring: () => {
        setIsMonitoring(false);
        addActivityItem('Stopped monitoring applications');
      },
      updateCredits: async (newCredits) => {
        if (newCredits !== currentCredits) {
          setCurrentCredits(newCredits);
          onCreditsUpdated(newCredits);
          setJobsApplied((prevJobs) => prevJobs + 1);

          if (newCredits < currentCredits) {
            triggerConfetti();
          }
        }
      },
    }));

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={twMerge(
          'fixed z-40 w-full xs:max-w-xs bottom-0 left-0 right-0 bg-white bg-opacity-90 rounded-lg shadow-lg border border-blue-100',
          className,
        )}
      >
        <div
          className="p-2 cursor-pointer flex justify-between items-center border-b border-blue-100"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex items-center space-x-2">
            <User size={16} className="text-blue-600" />
            <span className="text-sm font-medium text-gray-800 truncate">
              {`${profile?.username || 'Loading...'} ${profile?.subscription_plan ? `• ${profile?.subscription_plan}` : ''} ${profile?.job_title ? `• ${profile?.job_title}` : ''}`}
            </span>
          </div>
          {isExpanded ? (
            <ChevronDown size={16} className="text-blue-600" />
          ) : (
            <ChevronUp size={16} className="text-blue-600" />
          )}
        </div>

        <AnimatePresence>
          {isExpanded && (
            <div className={'w-full p-5'}>
              <div className="text-teal-800 font-medium flex flex-nowrap items-baseline gap-2">
                Your campaign is running.
                <div className="flex gap-1">
                  <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.32s]" />
                  <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.16s]" />
                  <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite]" />
                </div>
              </div>
              <p className="text-teal-700 text-sm mt-2">
                Refresh this page occasionally to see updates. Or just wait a few minutes and see your emails roll in!
              </p>
            </div>
            // <motion.div
            //   initial={{ height: 0, opacity: 0 }}
            //   animate={{ height: 'auto', opacity: 1 }}
            //   exit={{ height: 0, opacity: 0 }}
            //   className="p-4"
            // >
            //   {isInitializing && (
            //     <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-r from-teal-500/20 to-blue-500/20 rounded-lg backdrop-blur-sm">
            //       <div className="text-center">
            //         <Sparkles className="w-8 h-8 text-teal-500 mx-auto mb-2 animate-pulse" />
            //         <p className="text-teal-800 font-medium">Setting up RoboApply...</p>
            //       </div>
            //     </div>
            //   )}
            //
            //   <div className="space-y-4">
            //     <motion.div
            //       className="flex items-center justify-between bg-blue-50 p-3 rounded-lg"
            //       animate={{ scale: [1, 1.02, 1] }}
            //       transition={{ duration: 0.3 }}
            //     >
            //       <div className="flex items-center space-x-2">
            //         <CreditCard size={20} className="text-blue-600" />
            //         <span className="text-sm font-medium">Credits</span>
            //       </div>
            //       <div className="text-right">
            //         {isDataLoaded ? (
            //           <AnimatedNumber value={currentCredits} className="text-xl font-bold text-blue-600" />
            //         ) : (
            //           <div className="flex items-center gap-2 h-7 justify-end">
            //             <div className="flex gap-1">
            //               <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.32s]" />
            //               <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.16s]" />
            //               <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite]" />
            //             </div>
            //           </div>
            //         )}
            //         {creditChangeRate > 0 && (
            //           <div className="text-xs text-gray-500">{creditChangeRate.toFixed(1)} apps/min</div>
            //         )}
            //       </div>
            //     </motion.div>
            //
            //     <div className="grid grid-cols-2 gap-4">
            //       <motion.div
            //         className="bg-green-50 p-3 rounded-lg"
            //         animate={{ scale: [1, 1.02, 1] }}
            //         transition={{ duration: 0.3 }}
            //       >
            //         <div className="flex items-center justify-between">
            //           <div className="flex items-center space-x-2">
            //             <Briefcase size={20} className="text-green-600" />
            //             <span className="text-sm font-medium">Current</span>
            //           </div>
            //           <AnimatedNumber value={jobsApplied} className="text-xl font-bold text-green-600" />
            //         </div>
            //       </motion.div>
            //
            //       <motion.div
            //         className="bg-blue-50 p-3 rounded-lg"
            //         animate={{ scale: [1, 1.02, 1] }}
            //         transition={{ duration: 0.3 }}
            //       >
            //         <div className="flex items-center justify-between">
            //           <div className="flex items-center space-x-2">
            //             <TrendingUp size={20} className="text-blue-600" />
            //             {isDataLoaded ? (
            //               <span className="text-sm font-medium">Total</span>
            //             ) : (
            //               <div className="flex items-center gap-2">
            //                 <span className="text-sm font-medium">Total</span>
            //                 <div className="flex gap-1">
            //                   <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.32s]" />
            //                   <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite_-0.16s]" />
            //                   <div className="h-1.5 w-1.5 rounded-full bg-blue-300 animate-[bounce_1s_ease-in-out_infinite]" />
            //                 </div>
            //               </div>
            //             )}
            //           </div>
            //           {isDataLoaded ? (
            //             <AnimatedNumber value={successfulJobs} className="text-xl font-bold text-blue-600" />
            //           ) : (
            //             <div className="h-7 flex items-center justify-end">
            //               <div className="w-8 h-4 bg-blue-100 rounded animate-pulse" />
            //             </div>
            //           )}
            //         </div>
            //       </motion.div>
            //     </div>
            //
            //     {lastApplication && (
            //       <div className="bg-gray-50 p-3 rounded-lg">
            //         <h4 className="text-sm font-medium text-gray-600 mb-2">Last Application</h4>
            //         <a
            //           href={lastApplication.job_link}
            //           target="_blank"
            //           rel="noopener noreferrer"
            //           className="text-sm text-blue-600 hover:text-blue-800 block truncate"
            //         >
            //           {lastApplication.job_title} - {lastApplication.company}
            //         </a>
            //         <span className="text-xs text-gray-500">{lastApplication.formatted_time}</span>
            //       </div>
            //     )}
            //
            //     <div className="mt-4">
            //       <h4 className="text-sm font-medium text-gray-600 mb-2">Recent Activity</h4>
            //       <div className="space-y-2 max-h-32 overflow-y-auto">
            //         <AnimatePresence mode="popLayout">
            //           {recentActivity.map((activity) => (
            //             <motion.div
            //               key={activity.id}
            //               initial={{ opacity: 0, height: 0 }}
            //               animate={{ opacity: 1, height: 'auto' }}
            //               exit={{ opacity: 0, height: 0 }}
            //               className="text-xs text-gray-600 flex justify-between"
            //             >
            //               <span>{activity.message}</span>
            //               <span className="text-gray-400">{activity.timestamp}</span>
            //             </motion.div>
            //           ))}
            //         </AnimatePresence>
            //       </div>
            //     </div>
            //
            //     {isMonitoring && (
            //       <div className="text-xs text-gray-500 text-center mt-2">
            //         Last update: {formatInTimeZone(new Date(lastCreditChange), 'America/New_York', 'HH:mm:ss zzz')}
            //       </div>
            //     )}
            //   </div>
            // </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  },
);

CreditTrackingMonitor.displayName = 'CreditTrackingMonitor';

export default CreditTrackingMonitor;
