import ApplicationsGrid from '../ApplicationsGrid';
import { useLoaderData, useNavigate } from 'react-router';
import { useAuth } from '../contexts/useAuth/useAuth';
import CreateApplicationForm from '../concrete/CreateApplicationForm/CreateApplicationForm';
import { useToastContext } from '../contexts/useToastContext/useToastContext';

export const ApplicationHistoryPage = () => {
  const loaderData = useLoaderData();
  const { showToast } = useToastContext();
  const navigate = useNavigate();

  const { applications } = loaderData?.applicationData || {};
  const applicationsAreValid = !!applications && Array.isArray(applications) && applications.length >= 0;
  if (!applicationsAreValid) {
    showToast('No applications found. Try creating an application instead.', 'info');
    navigate('/auto-apply');
  }
  return (
    <div className="p-6 bg-white rounded-lg shadow-sm">
      <h2 className="text-3xl font-bold text-gray-800 w-full flex justify-center mb-6">Application History</h2>
      {!applicationsAreValid && (
        <div>
          <p className="text-gray-600">No applications found. Try creating an application instead.</p>
        </div>
      )}
      {applicationsAreValid && <ApplicationsGrid applications={applications} />}
    </div>
  );
};
