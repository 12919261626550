import React from 'react';
import { twMerge } from 'tailwind-merge';

export const ResumeUploadButton = ({ required, hasError, onResumeUpload, className }) => {
  const borderClass = hasError ? 'border-red-500' : 'border-gray-300';
  const componentClass = twMerge('space-y-2', className);

  return (
    <div className={componentClass}>
      <label htmlFor={'upload-resume'} className="block text-sm font-medium text-gray-700 relative">
        Upload Resume
        {required && <span className={'required-asterisk absolute -top-1 -left-2 text-red-600'}>*</span>}
      </label>
      <div className="relative">
        <input
          id={'upload-resume'}
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={onResumeUpload}
          className={`w-full p-3 border ${borderClass} rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100`}
        />
      </div>
      <p className="text-xs text-gray-500">Accepted formats: PDF, DOC, DOCX (Max 5MB)</p>
    </div>
  );
};
