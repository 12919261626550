import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { router } from './routerConfig';
import './index.css';
import App from './App';
import './globals.css';
import { ErrorBoundary } from './components/base/ErrorBoundary/ErrorBoundary'; // Tailwind CSS styles
const root = document.getElementById('root');

ReactDOM.createRoot(root).render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>,
);

// ReactDOM.render(document.getElementById('root'));
