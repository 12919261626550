/**
 * Converts a camelCase string to snake_case.
 *
 * @param {string} str - The camelCase string to convert.
 * @returns {string} The converted snake_case string.
 */
function camelToSnake(str) {
  return str
    .replace(/([A-Z])/g, '_$1') // Insert underscore before uppercase letters
    .toLowerCase(); // Convert the entire string to lowercase
}

/**
 * Recursively converts all keys in an object (or objects within arrays)
 * from camelCase to snake_case.
 *
 * @param {*} data - The input data (object or array) to process.
 * @returns {*} A new object or array with snake_cased keys.
 */
export const convertKeysToSnakeCase = (data) => {
  if (Array.isArray(data)) {
    // If the data is an array, recursively process each element
    return data.map((item) => convertKeysToSnakeCase(item));
  } else if (data !== null && typeof data === 'object') {
    // If the data is an object, process each key-value pair
    return Object.keys(data).reduce((accumulator, key) => {
      const snakeKey = camelToSnake(key);
      accumulator[snakeKey] = convertKeysToSnakeCase(data[key]);
      return accumulator;
    }, {});
  }
  // If the data is a primitive value, return it unchanged
  return data;
};

// Example Usage:

const camelCasedObject = {
  userName: 'john_doe',
  userDetails: {
    firstName: 'John',
    lastName: 'Doe',
    contactInfo: {
      emailAddress: 'john@example.com',
      phoneNumber: '123-456-7890',
    },
  },
  userRoles: ['admin', 'editor'],
  projects: [
    {
      projectName: 'Project Alpha',
      projectStatus: 'active',
    },
    {
      projectName: 'Project Beta',
      projectStatus: 'completed',
    },
  ],
};

const snakeCasedObject = convertKeysToSnakeCase(camelCasedObject);

console.log(snakeCasedObject);

/*
Output:

{
  user_name: 'john_doe',
  user_details: {
    first_name: 'John',
    last_name: 'Doe',
    contact_info: {
      email_address: 'john@example.com',
      phone_number: '123-456-7890'
    }
  },
  user_roles: ['admin', 'editor'],
  projects: [
    {
      project_name: 'Project Alpha',
      project_status: 'active'
    },
    {
      project_name: 'Project Beta',
      project_status: 'completed'
    }
  ]
}
*/
