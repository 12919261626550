import {
  clearAlreadyRedirected,
  getStoredLastCampaign,
  getStoredSession,
  hasAlreadyRedirected,
  removeTokensFromLocalStorage,
  setAlreadyRedirected,
  storeLastCampaign,
  storeTotalApplications,
} from '../api/localStorageClient';
import { redirect } from 'react-router';
import { getAuthenticatedSession } from '../api/getAuthenticatedSession';
import { getUserProfile, getApplicationsByUserId, getLastCampaign } from '../api/supabaseClient';
import { verifyPaymentSuccess } from '../api/apiClient';

const defaultData = {
  user: null,
  userProfile: null,
  lastCampaign: null,
  applicationData: {
    applications: [],
    applicationProgress: {
      successCount: 0,
      failureCount: 0,
    },
    cumulativeApplicationData: [],
    totalJobsApplied: 0,
  },
};

export const authLoader = async () => {
  const session = await getStoredSession();
  const userId = session?.user?.id;
  const isUserAuthenticated = !!userId;
  const userHasAlreadyRedirected = hasAlreadyRedirected();

  if (!isUserAuthenticated) {
    removeTokensFromLocalStorage();
    if (!userHasAlreadyRedirected) {
      setAlreadyRedirected();
      return redirect('/');
    }
    return { user: defaultData.user, userProfile: defaultData.userProfile };
  }

  const { userProfile } = await getUserProfile(userId);
  clearAlreadyRedirected();

  // where campaigns table = username. if id= pending, then it is a pending campaign. if id=completed, then it is a completed campaign.

  // const timestamp = new Date().toISOString();
  // localStorage.setItem('lastFetchedProfile', timestamp);

  return { ...session, userProfile };
};

export const applicationsLoader = async (params) => {
  console.log('applicationsLoader PARAMS: ', params);
  const userId = params?.userId || getAuthenticatedSession().user?.id;

  if (!userId) {
    return defaultData.applicationData;
  }

  const { data: applications } = await getApplicationsByUserId(userId);
  const { data: currentCampaign } = await getLastCampaign(userId);
  const successCount = applications.filter((app) => app.status === 'Successful')?.length;

  const storedLastCampaign = getStoredLastCampaign();

  if (!!currentCampaign) {
    storeLastCampaign(JSON.stringify(currentCampaign));
  }
  const lastCampaign = storedLastCampaign || currentCampaign;
  console.log('currentCampaign: ', currentCampaign);
  console.log('applicationsLoader applications: ', applications);

  return {
    applications,
    applicationProgress: {
      successCount: successCount,
      failureCount: applications.length - successCount,
    },
    cumulativeApplicationData: applications.map((app, index) => ({
      name: `App ${index + 1}`,
      Total: index + 1,
    })),
    totalJobsApplied: applications.length,
    hasActiveCampaign: !!currentCampaign,
    currentCampaign: currentCampaign || null,
    lastCampaign: lastCampaign,
  };
};

export const authAndApplicationsLoader = async ({ params = null }) => {
  console.log(' authAndApplicationsLoader PARAMS: ', params);
  const authData = await authLoader();
  console.log('AUTH DATA: ', authData);

  const { user } = authData;
  const applicationData = await applicationsLoader({ userId: user?.id });
  // console.log('APPLICATION DATA: ', applicationData);
  // const applicationData = {};
  return { ...authData, applicationData };
};

export const paymentVerificationLoader = async ({ request }) => {
  const url = new URL(request.url);
  const sessionId = url.searchParams.get('session_id');
  const userId = url.searchParams.get('user_id');

  if (!sessionId || !userId) {
    throw new Error('Session ID or User ID is missing from the URL.');
  }

  const response = await verifyPaymentSuccess({ sessionId, userId });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.detail || 'Payment verification failed');
  }

  return data;
};
