// import React from 'react';
// import { User, FileText, Clock, Medal, Wallet, Download, Mail, Shield, Eye, EyeOff } from 'lucide-react';
// import { useLoaderData } from 'react-router';

// // Personal Details Component
// const PersonalDetails = () => {
//   const [showPassword, setShowPassword] = React.useState(false);
//   const loaderData = useLoaderData();
//   console.log('loaderData: ', loaderData);
//   const user = loaderData?.user;

//   const maskPassword = (password) => {
//     if (!password) return '';
//     return '•'.repeat(8);
//   };

//   return (
//     <div className="bg-white rounded-lg shadow-sm border border-gray-200">
//       <div className="px-6 py-4 border-b border-gray-200">
//         <h2 className="text-xl font-semibold text-gray-900">Personal Details</h2>
//       </div>
//       <div className="p-6 space-y-6">
//         <div className="grid grid-cols-2 gap-6">
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Full Name</label>
//             <input
//               type="text"
//               defaultValue={user?.full_name}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Email</label>
//             <input
//               type="email"
//               defaultValue={user?.email}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Job Title</label>
//             <input
//               type="text"
//               defaultValue={user?.job_title}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Resume URL</label>
//             <input
//               type="url"
//               defaultValue={user?.resume_url}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Dice Username</label>
//             <input
//               type="text"
//               defaultValue={user?.dice_specific_email}
//               readOnly
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
//             />
//           </div>
//           <div>
//             <label className="block text-sm font-medium text-gray-700">Dice Password</label>
//             <div className="mt-1 relative">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 value={maskPassword(user?.dice_password)}
//                 readOnly
//                 className="block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
//               />
//               <button
//                 type="button"
//                 onClick={() => setShowPassword(!showPassword)}
//                 className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
//               >
//                 {showPassword ? (
//                   <EyeOff className="h-5 w-5" />
//                 ) : (
//                   <Eye className="h-5 w-5" />
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// // Credits & Billing Component
// const BillingContent = ({ userData }) => {
//   const loaderData = useLoaderData();
//   const campaign = loaderData?.campaign;
//   const user = loaderData?.user;

//   return (
//     <div className="space-y-6">
//       {/* Credit Statistics */}
//       <div className="grid grid-cols-4 gap-4">
//         {[
//           { label: 'Available Credits', value: user?.credits || 0, icon: Wallet },
//           { label: 'Jobs Processed', value: campaign?.total_jobs_processed || 0, icon: FileText },
//           { label: 'Success Rate', value: `${((campaign?.successful_applications / campaign?.total_jobs_processed) * 100 || 0).toFixed(1)}%`, icon: Medal },
//           { label: 'Campaign Time', value: `${campaign?.time_spent_minutes?.toFixed(1) || 0}m`, icon: Clock },
//         ].map((stat, index) => (
//           <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
//             <div className="flex items-center justify-between">
//               <stat.icon className="w-5 h-5 text-gray-400" />
//               <span className="text-sm text-gray-500">{stat.label}</span>
//             </div>
//             <div className="mt-2 text-2xl font-semibold text-gray-900">{stat.value}</div>
//           </div>
//         ))}
//       </div>

//       {/* Campaign History */}
//       <div className="bg-white rounded-lg shadow-sm border border-gray-200">
//         <div className="px-6 py-4 border-b border-gray-200">
//           <h2 className="text-xl font-semibold text-gray-900">Campaign History</h2>
//         </div>
//         <div className="overflow-x-auto">
//           <table className="w-full">
//             <thead>
//               <tr className="bg-gray-50">
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Job Type</th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Location</th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Success</th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Jobs</th>
//                 <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
//               </tr>
//             </thead>
//             <tbody className="divide-y divide-gray-200">
//               {campaign && (
//                 <tr>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                     {campaign.job_type}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//                     {campaign.location}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                     {campaign.successful_applications}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//                     {campaign.total_jobs_processed}
//                   </td>
//                   <td className="px-6 py-4 whitespace-nowrap text-sm">
//                     <span className={`px-2 py-1 rounded-full text-xs font-medium ${campaign.status === 'completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
//                       }`}>
//                       {campaign.status}
//                     </span>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// // Notifications Settings
// const NotificationSettings = ({ userData }) => (
//   <div className="bg-white rounded-lg shadow-sm border border-gray-200">
//     <div className="px-6 py-4 border-b border-gray-200">
//       <h2 className="text-xl font-semibold text-gray-900">Notification Preferences</h2>
//     </div>
//     <div className="p-6 space-y-6">
//       <div className="space-y-4">
//         {[
//           {
//             id: 'application_updates',
//             label: 'Application Updates',
//             description: 'Get notified about the status of your job applications',
//           },
//           {
//             id: 'credit_alerts',
//             label: 'Credit Alerts',
//             description: 'Receive alerts when your credits are running low',
//           },
//           {
//             id: 'job_recommendations',
//             label: 'Job Recommendations',
//             description: 'Get personalized job recommendations based on your profile',
//           },
//           {
//             id: 'product_updates',
//             label: 'Product Updates',
//             description: 'Stay informed about new features and improvements',
//           },
//         ].map((setting) => (
//           <div key={setting.id} className="flex items-start space-x-4">
//             <input
//               type="checkbox"
//               id={setting.id}
//               defaultChecked={userData?.notifications?.[setting.id]}
//               className="mt-1"
//             />
//             <div>
//               <label htmlFor={setting.id} className="block text-sm font-medium text-gray-900">
//                 {setting.label}
//               </label>
//               <p className="text-sm text-gray-500">{setting.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
// );

// // Settings Content Component
// const SettingsContent = ({ activeSection, userData }) => {
//   switch (activeSection) {
//     case 'personal':
//       return <PersonalDetails userData={userData} />;
//     case 'billing':
//       return <BillingContent userData={userData} />;
//     case 'notifications':
//       return <NotificationSettings userData={userData} />;
//     case 'security':
//       return (
//         <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
//           <h2 className="text-xl font-semibold text-gray-900 mb-4">Security Settings</h2>
//           <div className="space-y-6">
//             <div>
//               <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
//               <div className="mt-4 space-y-4">
//                 <input
//                   type="password"
//                   placeholder="Current Password"
//                   className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
//                 />
//                 <input
//                   type="password"
//                   placeholder="New Password"
//                   className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
//                 />
//                 <input
//                   type="password"
//                   placeholder="Confirm New Password"
//                   className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
//                 />
//                 <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700">
//                   Update Password
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     default:
//       return null;
//   }
// };

// export { SettingsContent, BillingContent, PersonalDetails, NotificationSettings };

import React, { useEffect, useState } from 'react';
import { User, FileText, Clock, Medal, Wallet, Download, Mail, Shield, Eye, EyeOff } from 'lucide-react';
import { supabase } from '../supabaseConfig';
import { formatDuration, intervalToDuration } from 'date-fns'; // Importing date-fns for duration formatting

// Personal Details Component
const PersonalDetails = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
        if (authError) throw authError;




        // Fetch user profile data
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select(`
            id,
            full_name,
            email,
            job_title,
            resume_url,
            dice_password,
            dice_specific_email,
            credits,
            avatar_url,
            has_profile,
            last_sign_in,
            subscription_status,
            current_period_end
          `)
          .eq('id', authUser.id)
          .single();

        if (profileError) throw profileError;
        setProfileData(profile);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const updateProfile = async (field, value) => {
    try {
      if (!profileData?.id) return;

      const { error } = await supabase
        .from('profiles')
        .update({ [field]: value })
        .eq('id', profileData.id);

      if (error) throw error;
      setProfileData(prev => ({ ...prev, [field]: value }));
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  const maskPassword = (password) => {
    if (!password) return '';
    return '•'.repeat(8);
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            <div className="h-8 bg-gray-200 rounded"></div>
            <div className="h-8 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="text-red-600">Error loading profile: {error}</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-900">Personal Details</h2>
      </div>
      <div className="p-6 space-y-6">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              type="text"
              defaultValue={profileData?.full_name}
              onChange={(e) => updateProfile('full_name', e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              defaultValue={profileData?.email}
              readOnly
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Job Title</label>
            <input
              type="text"
              defaultValue={profileData?.job_title}
              onChange={(e) => updateProfile('job_title', e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Resume URL</label>
            <input
              type="url"
              defaultValue={profileData?.resume_url}
              readOnly
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Dice Username</label>
            <input
              type="text"
              defaultValue={profileData?.dice_specific_email}
              readOnly
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Dice Password</label>
            <div className="mt-1 relative">
              <input
                type={showPassword ? "text" : "password"}
                value={maskPassword(profileData?.dice_password)}
                readOnly
                className="block w-full px-3 py-2 border border-gray-300 rounded-lg bg-gray-50"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5" />
                ) : (
                  <Eye className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// ... [Other Components: PersonalDetails, NotificationSettings, etc.] ...

// Billing & Campaigns Component
const BillingContent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Get authenticated user
        const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
        if (authError) throw authError;


        // Add the total time calculation here, just before the return statement:
        const totalMinutesSaved = campaignData.reduce((sum, campaign) => {
          if (campaign.status === 'completed' && campaign.time_spent_minutes) {
            return sum + campaign.time_spent_minutes;
          }
          if (campaign.status === 'in_progress' && campaign.start_time) {
            const start = new Date(campaign.start_time);
            const now = new Date();
            const minutes = (now - start) / (1000 * 60);
            return sum + minutes;
          }
          return sum;
        }, 0);

        const formatTotalTime = (minutes) => {
          const days = Math.floor(minutes / (24 * 60));
          const hours = Math.floor((minutes % (24 * 60)) / 60);
          const remainingMinutes = Math.floor(minutes % 60);

          if (days > 0) {
            return `${days}d ${hours}h ${remainingMinutes}m`;
          }
          if (hours > 0) {
            return `${hours}h ${remainingMinutes}m`;
          }
          return `${remainingMinutes}m`;
        };

        const totalTimeFormatted = formatTotalTime(totalMinutesSaved);

        // Fetch user profile data
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('credits, subscription_status, current_period_end')
          .eq('id', authUser.id)
          .single();

        if (profileError) throw profileError;

        // Fetch campaign data
        const { data: campaigns, error: campaignError } = await supabase
          .from('campaigns')
          .select(`
            campaign_id,
            status,
            start_time,
            end_time,
            job_type,
            location,
            num_jobs,
            successful_applications,
            failed_applications,
            total_jobs_processed,
            error_message,
            time_spent_minutes
          `)
          .eq('user_id', authUser.id)
          .order('start_time', { ascending: false });

        if (campaignError) throw campaignError;

        setUserData(profile);
        setCampaignData(campaigns);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);




  if (loading) {
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-4 gap-4">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
              <div className="animate-pulse space-y-4">
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                <div className="h-8 bg-gray-200 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="text-red-600">Error loading billing data: {error}</div>
      </div>
    );
  }

  // Determine the latest campaign
  const latestCampaign = campaignData[0] || {};

  // Function to calculate duration if campaign is in progress
  const calculateDuration = (startTime, status) => {
    if (status === 'in_progress') {
      const start = new Date(startTime);
      const now = new Date();
      const duration = intervalToDuration({ start, end: now });
      const formattedDuration = formatDuration(duration, { format: ['days', 'hours', 'minutes'] });
      return formattedDuration;
    } else {
      return (latestCampaign?.time_spent_minutes?.toFixed(1) || '0') + 'm';
    }
  };

  // Calculate duration for the latest campaign
  const latestCampaignDuration = calculateDuration(latestCampaign.start_time, latestCampaign.status) || '0m';

  // Calculate aggregated statistics
  const totalSuccessful = campaignData.reduce((sum, campaign) => sum + (campaign.successful_applications || 0), 0);
  const totalProcessed = campaignData.reduce((sum, campaign) => sum + (campaign.total_jobs_processed || 0), 0);
  const successRate = totalProcessed ? ((totalSuccessful / totalProcessed) * 100).toFixed(1) : 0;

  return (
    <div className="space-y-6">
      {/* Credit Statistics */}
      <div className="grid grid-cols-4 gap-4">
        {[
          {
            label: 'Available Credits',
            value: userData?.credits || 0,
            icon: Wallet
          },
          {
            label: 'Applications Success',
            value: `${successRate}%`,
            icon: Medal
          },
          {
            label: 'Total Automated Jobs',
            value: totalProcessed,
            icon: FileText
          },
          {
            label: 'Total Minutes Saved',
            value: latestCampaignDuration,
            icon: Clock
          },
        ].map((stat, index) => (
          <div key={index} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
            <div className="flex items-center justify-between">
              <stat.icon className="w-5 h-5 text-gray-400" />
              <span className="text-sm text-gray-500">{stat.label}</span>
            </div>
            <div className="mt-2 text-2xl font-semibold text-gray-900">{stat.value}</div>
          </div>
        ))}
      </div>

      {/* Campaign History */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Campaign History</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Start Time</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Job Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Success/Total</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Duration</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {campaignData.map((campaign) => (
                <tr key={campaign.campaign_id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(campaign.start_time).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {campaign.job_type}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {campaign.location}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {campaign.successful_applications}/{campaign.total_jobs_processed}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {campaign.status === 'in_progress'
                      ? calculateDuration(campaign.start_time, campaign.status)
                      : `${campaign.time_spent_minutes?.toFixed(1) || '0'}m`}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${campaign.status === 'completed'
                      ? 'bg-green-100 text-green-800'
                      : campaign.status === 'failed'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-yellow-100 text-yellow-800'
                      }`}>
                      {campaign.status}
                    </span>
                  </td>
                </tr>
              ))}
              {campaignData.length === 0 && (
                <tr>
                  <td colSpan="6" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    No campaign history available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Notifications Settings Component
const NotificationSettings = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      try {
        setLoading(true);
        const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
        if (authError) throw authError;

        const { data, error: settingsError } = await supabase
          .from('notification_settings')
          .select('*')
          .eq('user_id', authUser.id)
          .single();

        if (settingsError && settingsError.code !== 'PGRST116') throw settingsError;
        setSettings(data || {});
      } catch (err) {
        setError(err.message);
        console.error('Error fetching notification settings:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationSettings();
  }, []);

  const updateSetting = async (settingId, value) => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();

      const { error } = await supabase
        .from('notification_settings')
        .upsert({
          user_id: authUser.id,
          [settingId]: value,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      setSettings(prev => ({
        ...prev,
        [settingId]: value
      }));
    } catch (err) {
      console.error('Error updating notification setting:', err);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="p-6">
          <div className="animate-pulse space-y-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="h-10 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="text-red-600">Error loading notification settings: {error}</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="px-6 py-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-900">Notification Preferences</h2>
      </div>
      <div className="p-6 space-y-6">
        <div className="space-y-4">
          {[
            {
              id: 'application_updates',
              label: 'Application Updates',
              description: 'Get notified about the status of your job applications',
            },
            {
              id: 'credit_alerts',
              label: 'Credit Alerts',
              description: 'Receive alerts when your credits are running low',
            },
            {
              id: 'job_recommendations',
              label: 'Job Recommendations',
              description: 'Get personalized job recommendations based on your profile',
            },
            {
              id: 'product_updates',
              label: 'Product Updates',
              description: 'Stay informed about new features and improvements',
            },
          ].map((setting) => (
            <div key={setting.id} className="flex items-start space-x-4">
              <input
                type="checkbox"
                id={setting.id}
                checked={settings[setting.id] || false}
                onChange={(e) => updateSetting(setting.id, e.target.checked)}
                className="mt-1"
              />
              <div>
                <label htmlFor={setting.id} className="block text-sm font-medium text-gray-900">
                  {setting.label}
                </label>
                <p className="text-sm text-gray-500">{setting.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Settings Content Component
const SettingsContent = ({ activeSection }) => {
  switch (activeSection) {
    case 'personal':
      return <PersonalDetails />;
    case 'billing':
      return <BillingContent />;
    case 'notifications':
      return <NotificationSettings />;
    case 'security':
      return <SecuritySettings />;
    default:
      return null;
  }
};

// Security Settings Component
const SecuritySettings = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setMessage(null);

      const { error } = await supabase.auth.updateUser({
        password: formData.newPassword
      });

      if (error) throw error;

      setMessage('Password updated successfully');
      setFormData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Security Settings</h2>
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Change Password</h3>
          <form onSubmit={handlePasswordChange} className="mt-4 space-y-4">
            <input
              type="password"
              placeholder="Current Password"
              value={formData.currentPassword}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                currentPassword: e.target.value
              }))}
              className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
            />
            <input
              type="password"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                newPassword: e.target.value
              }))}
              className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
            />
            <input
              type="password"
              placeholder="Confirm New Password"
              value={formData.confirmPassword}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                confirmPassword: e.target.value
              }))}
              className="block w-full px-3 py-2 border border-gray-300 rounded-lg"
            />
            {error && (
              <div className="text-red-600 text-sm">{error}</div>
            )}
            {message && (
              <div className="text-green-600 text-sm">{message}</div>
            )}
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 disabled:opacity-50"
            >
              {loading ? 'Updating...' : 'Update Password'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export { SettingsContent, BillingContent, PersonalDetails, NotificationSettings };