import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../../concrete/Header/Header';
import { Footer } from '../../concrete/Footer/Footer';
import {
  Outlet,
  useLoaderData,
  useNavigation,
  useLocation,
  useNavigate,
  useFetcher,
  useRevalidator,
} from 'react-router';
import { SideBar } from '../../concrete/Sidebar/SideBar';
import { PaywallCard } from '../../concrete/PaywallCard/PaywallCard';
import { GlobalLoadingSpinner } from '../../base/GlobalLoadingSpinner/GlobalLoadingSpinner';
import CreditTrackingMonitor from '../../CreditTrackingMonitor';
import { useToastContext } from '../../contexts/useToastContext/useToastContext';
import { ToastContainer } from '../../concrete/Toast/ToastContainer';
import { subscribeToAuthChanges } from '../../../api/supabaseClient';
import { removeTokensFromLocalStorage, storeSession } from '../../../api/localStorageClient';
import { handleError } from '../../../utilities/handleError';
import { useAuth } from '../../contexts/useAuth/useAuth';
import { Modal } from '../../base/Modal/Modal';
import { PricingCard } from '../../concrete/PricingCard/PricingCard';

export const MainLayout = (props) => {
  const { setSession, ...session } = useAuth();
  const loaderData = useLoaderData();
  const fetcher = useFetcher();
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState('default');
  const [isPaywallOpen, setIsPaywallOpen] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const navigation = useNavigation();
  const revalidator = useRevalidator();
  const isNavigating = Boolean(navigation.location);
  const { showToast } = useToastContext();

  useEffect(() => {
    setSession(loaderData);
  }, [loaderData, setSession]);

  const handleOnSignOut = useCallback(() => {
    removeTokensFromLocalStorage();
    localStorage.removeItem('redirectPath');
    setSession(null);
    showToast({ title: 'Logged out successfully', description: 'Come back soon!' }, 'success');
    revalidator.revalidate();
    navigate('/', { replace: true });
  }, [setSession, showToast, navigate, fetcher]);

  const handleOnSignIn = useCallback(
    async (newSession) => {
      try {
        const wasLoggedOut = !session?.user;
        const mergedSession = { ...session, ...newSession };
        storeSession(mergedSession);
        setSession(mergedSession);

        if (wasLoggedOut) {
          const redirectPath = localStorage.getItem('redirectPath') || '/dashboard';
          localStorage.removeItem('redirectPath');
          navigate(redirectPath);
        }

        const timestamp = new Date().toISOString();
        const oldTimeStamp = mergedSession?.userProfile?.last_sign_in;
        const isNewUser = !oldTimeStamp;
        const fourHours = 1000 * 60 * 60 * 4;

        if (!isNewUser && oldTimeStamp && new Date(timestamp) - new Date(oldTimeStamp) > fourHours) {
          showToast(`Welcome ${isNewUser ? 'to RoboApply!' : 'back to RoboApply!'}`, 'success');
        }

        revalidator.revalidate();
      } catch (error) {
        handleError({ message: 'Error signing in. Please try again.', error });
      }
    },
    [navigate, session, setSession, showToast]
  );

  useEffect(() => {
    const { data: authListener } = subscribeToAuthChanges({
      onSignIn: handleOnSignIn,
      onSignOut: handleOnSignOut,
    });

    const handleStorageChange = () => {
      removeTokensFromLocalStorage();
      localStorage.removeItem('redirectPath');
      window.addEventListener('storage', handleStorageChange);
    };

    return () => {
      authListener?.subscription?.unsubscribe();
      window.removeEventListener('storage', handleStorageChange);
      localStorage.removeItem('redirectPath');
    };
  }, [handleOnSignIn, handleOnSignOut]);

  useEffect(() => {
    const firstSegment = location.pathname.split('/')[1];
    const isHome = location.pathname === '/' || location.pathname === '';

    if (isHome) {
      setView('home');
    } else if (view !== firstSegment) {
      setView(firstSegment);
    }
  }, [location.pathname, view]);

  useEffect(() => {
    return () => {
      if (!location.pathname.includes('login')) {
        localStorage.removeItem('redirectPath');
      }
    };
  }, [location]);

  const { user = null, userProfile = null, applicationData = null } = loaderData || {};
  const userId = session.user?.id;
  const { hasActiveCampaign = false } = loaderData?.applicationData || {};
  const pagesWithoutSidebar = ['home', 'pricing', 'about', 'contact', 'terms', 'privacy', 'faq'];
  const hasSideBar = !pagesWithoutSidebar.includes(view);

  const handleSetPricingModal = (show) => {
    setShowPricingModal(show ?? !showPricingModal);
  };

  return (
    <div className={`min-h-screen w-full grid ${hasSideBar ? 'grid-rows-[auto_1fr_auto]' : 'grid-rows-1'}`}>
      <Header onShowPricingModal={handleSetPricingModal} userId={user?.id} userProfile={userProfile} variant={view} />
      {isNavigating && <GlobalLoadingSpinner />}
      <div className={`grid ${hasSideBar ? 'grid-cols-[auto_1fr]' : 'grid-cols-1'}`}>
        {hasSideBar && <SideBar hasCurrentCampaign={loaderData?.applicationData?.currentCampaign} />}
        <main id="main-tag" className="relative">
          <Modal
            className="bg-transparent shadow-none border-none"
            isOpen={isPaywallOpen}
            onClose={() => setIsPaywallOpen(false)}
          >
            <PaywallCard onComplete={() => setIsPaywallOpen(false)} />
          </Modal>
          <Modal
            className="bg-transparent p-0 w-full max-w-full"
            isOpen={showPricingModal}
            onClose={() => setShowPricingModal(false)}
          >
            <PricingCard
              userId={user?.id}
              onClose={() => setShowPricingModal(false)}
              onSuccess={() => setShowPricingModal(false)}
            />
          </Modal>
          {hasActiveCampaign && (
            <CreditTrackingMonitor
              initialCredits={userProfile?.credits || 0}
              onInvalidCredentials={() => removeTokensFromLocalStorage()}
            />
          )}
          <Outlet />
          <ToastContainer />
        </main>
      </div>
      <Footer />
    </div>
  );
};