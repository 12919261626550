import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router'; // Added Navigate here
import { inject } from '@vercel/analytics'; // Import inject for advanced config

import posthog from './posthogConfig';

import { UIProviders } from './components/contexts/UIProviders/UIProviders';
import { ErrorBoundary } from './components/base/ErrorBoundary/ErrorBoundary';
import { ToastProvider } from './components/contexts/useToastContext/useToastContext';

import { router } from './routerConfig';
import { AuthProvider } from './components/contexts/useAuth/useAuth';

// Advanced Vercel Analytics configuration
inject({
  mode: process.env.NODE_ENV === 'development' ? 'development' : 'production',
  debug: process.env.NODE_ENV === 'development',
  beforeSend: (event) => {
    // Exclude any events with '/private' in the URL
    if (event.url.includes('/private')) {
      return null;
    }
    return event;
  },
});

function App() {
  useEffect(() => {
    // Track page views or other custom events on mount
    posthog.capture('app_loaded');

    if (process.env.NODE_ENV !== 'development') {
      console.log('NODE_ENV', process.env.NODE_ENV);
      console.log('Production mode');
      console.log = () => {};
      console.count = () => {};
      console.trace = () => {};
      console.log('Console log is disabled in production mode');
    }
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <ToastProvider>
          <UIProviders>
            <RouterProvider router={router} />
          </UIProviders>
        </ToastProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
