import React, { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { NavLink } from 'react-router';
import { Modal } from '../../base/Modal/Modal';
import { PricingModal } from '../../Header';

export const defaultNavLinks = [
  // { href: '/#features', text: 'Features' },
  // { href: '/jobs', text: 'Jobs' },
  // { href: '/resume', text: 'Resume Builder' },
];
const getLinks = (links, activeLink, activeLinkClass, setShowPricing) => {
  return links.map((link) => {
    const { href, text, key, ...restLinkProps } = link;
    const linkClassName = twMerge(
      'hidden xs:inline-block text-gray-700 hover:text-gray-900',
      activeLink === text ? activeLinkClass : '',
    );
    return (
      <li key={key} className={linkClassName}>
        <NavLink to={href} {...restLinkProps}>
          {text}
        </NavLink>
      </li>
    );
  });
};

export const NavLinks = forwardRef((props, forwardedRef) => {
  const {
    className,
    userId,
    credits,
    children,
    links = defaultNavLinks,
    activeLink = '',
    activeLinkClass = '',
    ...restProps
  } = props;

  const [showPricing, setShowPricing] = useState(false);
  const navLinks = React.useMemo(
    () => {
      return getLinks(links, activeLink, activeLinkClass, setShowPricing);
    },
    [links, activeLink, activeLinkClass], // Dependencies for memoization
  );

  // const containerClassName = twMerge('hidden md:grid grid-flow-col gap-4 items-center', className);

  return (
    <>
      <ul ref={forwardedRef} className={className} {...restProps}>
        {navLinks}
        {children}
      </ul>

      <Modal isOpen={showPricing} onClose={() => setShowPricing(false)}>
        <PricingModal
          credits={credits}
          userId={userId}
          onClose={() => setShowPricing(false)}
          onSuccess={(newCredits) => {
            // Handle success - maybe refresh credits count
            setShowPricing(false);
          }}
        />
      </Modal>
    </>
  );
});
NavLinks.displayName = 'NavLinks';
