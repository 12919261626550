// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
// import { supabase } from '../../supabaseConfig';
// import { toast } from 'sonner';

// // Import subcomponents
// import ResumeSelectionModal from './ResumeSelectionModal';
// import ResumePreviewPanel from './ResumePreviewPanel';
// import ResumeEditorPanel from './ResumeEditorPanel';
// import JobDescriptionModal from './JobDescriptionModal';

// const API_BASE = process.env.REACT_APP_API_BASE || 'https://robo-resumeably-backend.onrender.com';
// const API_ENDPOINTS = {
//     THEMES: '/api/v1/themes',
//     PREVIEW: '/api/v1/preview',
//     EXPORT: '/api/v1/export-pdf'
// };

// const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
// const isDev = process.env.NODE_ENV === 'development';

// const parseResumeData = (resumeJson) => {
//     let skillsData = { tools: [], skills: [] };
//     if (resumeJson.skills) {
//         const input = resumeJson.skills;
//         if (Array.isArray(input.keywords)) {
//             skillsData = {
//                 tools: [],
//                 skills: input.keywords
//             };
//         } else if (Array.isArray(input.tools) && Array.isArray(input.skills)) {
//             skillsData = input;
//         }
//     }

//     return {
//         basics: {
//             name: resumeJson.basics?.name || '',
//             email: resumeJson.basics?.email || '',
//             phone: resumeJson.basics?.phone || '',
//             location: resumeJson.basics?.location || {},
//             summary: resumeJson.basics?.summary || '',
//             label: resumeJson.basics?.label || '',
//             url: resumeJson.basics?.url || '',
//             profiles: resumeJson.basics?.profiles || []
//         },
//         work: (resumeJson.work || []).map(work => ({
//             id: work.id || Date.now().toString(),
//             company: work.name || '',
//             title: work.position || '',
//             location: work.location || '',
//             startDate: work.startDate || '',
//             endDate: work.endDate || '',
//             description: (work.highlights || []).join('\n')
//         })),
//         education: (resumeJson.education || []).map(edu => ({
//             id: edu.id || Date.now().toString(),
//             institution: edu.institution || '',
//             degree: `${edu.studyType || ''} ${edu.area || ''}`.trim(),
//             location: edu.location || '',
//             startDate: edu.startDate || '',
//             endDate: edu.endDate || '',
//             gpa: edu.gpa || '',
//             description: edu.description || ''
//         })),
//         skills: skillsData,
//         projects: (resumeJson.projects || []).map(project => ({
//             id: project.id || Date.now().toString(),
//             name: project.name || '',
//             description: project.summary || '',
//             startDate: project.startDate || '',
//             endDate: project.endDate || '',
//             url: project.url || '',
//             technologies: project.technologies || []
//         })),
//         awards: (resumeJson.awards || []).map(award => ({
//             id: award.id || Date.now().toString(),
//             title: award.title || '',
//             issuer: award.issuer || '',
//             dateReceived: award.date || '',
//             description: award.description || ''
//         }))
//     };
// };

// const ResumeEditor = () => {
//     const { user_id, resume_id } = useParams();
//     const navigate = useNavigate();

//     // State management
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [coverLetter, setCoverLetter] = useState('');
//     const [candidatePitch, setCandidatePitch] = useState('');
//     const [showSelectionModal, setShowSelectionModal] = useState(!resume_id);
//     const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [resumeScore, setResumeScore] = useState(85);
//     const [conversionId, setConversionId] = useState(null);
//     const [previewHtml, setPreviewHtml] = useState('');
//     const [selectedTheme, setSelectedTheme] = useState('stackoverflow');
//     const [originalFileUrl, setOriginalFileUrl] = useState(null);
//     const [fileType, setFileType] = useState(null);

//     const [sections, setSectionsState] = useState([
//         'basics', 'work', 'education', 'skills', 'projects', 'awards'
//     ]);

//     const [expandedSections, setExpandedSections] = useState({
//         basics: true,
//         work: true,
//         education: true,
//         skills: true,
//         projects: true,
//         awards: true
//     });

//     const [formData, setFormData] = useState({
//         basics: {
//             url: "",
//             name: "",
//             email: "",
//             image: "",
//             label: "",
//             phone: "",
//             summary: "",
//             location: {
//                 city: "",
//                 region: "",
//                 address: "",
//                 postalCode: "",
//                 countryCode: ""
//             },
//             profiles: []
//         },
//         work: [],
//         education: [],
//         skills: {
//             tools: [],
//             skills: []
//         },
//         projects: [],
//         awards: []
//     });

//     const [jobDescription, setJobDescription] = useState({
//         companyName: '',
//         roleTitle: '',
//         department: '',
//         location: '',
//         salaryRange: '',
//         status: 'ACTIVE'
//     });

//     // DND Kit sensors
//     const sensors = useSensors(
//         useSensor(PointerSensor, {
//             activationConstraint: { distance: 8 }
//         }),
//         useSensor(KeyboardSensor, {
//             coordinateGetter: sortableKeyboardCoordinates
//         })
//     );

//     const updateResumeConversion = async (conversionId, updates) => {
//         try {
//             let userId = isDev ? DEV_FALLBACK_UUID : null;

//             if (!isDev) {
//                 const { data: { user }, error: userError } = await supabase.auth.getUser();
//                 if (userError || !user) throw new Error('Not authenticated');
//                 userId = user.id;
//             }

//             const { data: conversion, error } = await supabase
//                 .from('resume_conversions')
//                 .update({
//                     ...updates,
//                     last_modified_by: userId,
//                     updated_at: new Date().toISOString()
//                 })
//                 .eq('id', conversionId)
//                 .select()
//                 .single();

//             if (error) throw error;
//             return conversion;
//         } catch (error) {
//             console.error('Error updating resume conversion:', error);
//             throw error;
//         }
//     };

//     const handleConversionUpdate = useCallback((payload) => {
//         const { eventType, new: newData } = payload;
//         if (eventType === 'UPDATE' && newData.id === conversionId) {
//             switch (newData.status) {
//                 case 'completed':
//                     setResumeScore(100);
//                     toast.success('Resume conversion completed successfully!');
//                     break;
//                 case 'in_progress':
//                     setResumeScore(50);
//                     toast.info('Resume conversion is in progress.');
//                     break;
//                 case 'failed':
//                     setResumeScore(0);
//                     toast.error(`Resume conversion failed: ${newData.error_message || 'Unknown error'}`);
//                     break;
//                 default:
//                     break;
//             }
//         }
//     }, [conversionId]);

//     useEffect(() => {
//         if (!user_id) return;

//         const channelName = `resume_conversions_user_${user_id}`;
//         const channel = supabase.channel(channelName)
//             .on(
//                 'postgres_changes',
//                 {
//                     event: '*',
//                     schema: 'public',
//                     table: 'resume_conversions',
//                     filter: `user_id=eq.${user_id}`
//                 },
//                 handleConversionUpdate
//             )
//             .subscribe();

//         return () => {
//             supabase.removeChannel(channel);
//         };
//     }, [user_id, handleConversionUpdate]);

//     const updatePreview = useCallback(async () => {
//         if (!formData.basics.name) return;

//         try {
//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: formData,
//                     theme_name: selectedTheme
//                 })
//             });

//             if (!response.ok) {
//                 const errorText = await response.text();
//                 throw new Error(`Preview failed: ${errorText}`);
//             }

//             const html = await response.text();
//             setPreviewHtml(html);
//         } catch (error) {
//             console.error('Error updating preview:', error);
//             setError('Failed to update preview');
//             toast.error('Failed to update preview.');
//         }
//     }, [formData, selectedTheme]);

//     const initializeResumeConversion = useCallback(async (originalResumeId) => {
//         try {
//             setLoading(true);
//             let userId = isDev ? DEV_FALLBACK_UUID : null;

//             if (!isDev) {
//                 const { data: { user }, error: userError } = await supabase.auth.getUser();
//                 if (userError || !user) throw new Error('Not authenticated');
//                 userId = user.id;
//             }

//             const { data: originalResume, error: originalError } = await supabase
//                 .from('resume_uploads')
//                 .select('*')
//                 .eq('id', originalResumeId)
//                 .eq('user_id', userId)
//                 .single();

//             if (originalError || !originalResume) {
//                 throw new Error('Original resume not found');
//             }

//             const parsedData = originalResume.resume_json || {};

//             const { data: conversion, error } = await supabase
//                 .from('resume_conversions')
//                 .insert({
//                     original_resume_id: originalResumeId,
//                     user_id: userId,
//                     candidate_name: parsedData.basics?.name || '',
//                     file_url: originalResume.file_url,
//                     file_type: originalResume.file_type,
//                     file_name: originalResume.file_name,
//                     file_path: originalResume.file_path,
//                     resume_text: originalResume.resume_text || '',
//                     resume_json: parsedData,
//                     created_by: userId,
//                     last_modified_by: userId,
//                     status: 'pending'
//                 })
//                 .select()
//                 .single();

//             if (error) throw error;
//             return conversion;
//         } catch (error) {
//             console.error('Error initializing resume conversion:', error);
//             toast.error('Failed to initialize resume conversion.');
//             throw error;
//         } finally {
//             setLoading(false);
//         }
//     }, []);

//     const handleResumeSelect = useCallback(async (resume) => {
//         try {
//             setShowSelectionModal(false);

//             const conversion = await initializeResumeConversion(resume.id);
//             setConversionId(conversion.id);
//             setOriginalFileUrl(resume.file_url);
//             setFileType(resume.file_type);

//             if (resume.resume_json) {
//                 setFormData(parseResumeData(resume.resume_json));
//             }

//             navigate(`/editor/${user_id}/${resume.id}`, { replace: true });
//             await updatePreview();
//             setShowJobDescriptionModal(true);
//         } catch (error) {
//             console.error('Error loading resume:', error);
//             setError('Failed to load resume data');
//             toast.error('Failed to load resume data.');
//         }
//     }, [user_id, navigate, initializeResumeConversion, updatePreview]);

//     const handleSectionToggle = (sectionId) => {
//         setExpandedSections(prev => ({
//             ...prev,
//             [sectionId]: !prev[sectionId]
//         }));
//     };

//     const handleDragEnd = (event) => {
//         const { active, over } = event;
//         if (over && active.id !== over.id) {
//             const oldIndex = sections.indexOf(active.id);
//             const newIndex = sections.indexOf(over.id);
//             setSectionsState(prev => arrayMove(prev, oldIndex, newIndex));
//         }
//     };

//     const handleFormUpdate = (section, field, value) => {
//         setFormData(prev => ({
//             ...prev,
//             [section]: field ? {
//                 ...prev[section],
//                 [field]: value
//             } : value
//         }));
//     };

//     const handleSave = async () => {
//         try {
//             setIsProcessing(true);
//             await updateResumeConversion(conversionId, {
//                 resume_json: formData,
//                 status: 'in_progress'
//             });
//             await updatePreview();
//             toast.success('Changes saved successfully.');
//             await updateResumeConversion(conversionId, {
//                 status: 'completed'
//             });
//         } catch (error) {
//             console.error('Error saving resume:', error);
//             setError('Failed to save changes');
//             toast.error('Failed to save changes.');
//             try {
//                 await updateResumeConversion(conversionId, {
//                     status: 'failed',
//                     error_message: error.message
//                 });
//             } catch (updateError) {
//                 console.error('Error updating status to failed:', updateError);
//             }
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleJobDescriptionSave = async (descriptionData) => {
//         try {
//             let userId = isDev ? DEV_FALLBACK_UUID : null;

//             if (!isDev) {
//                 const { data: { user }, error: userError } = await supabase.auth.getUser();
//                 if (userError || !user) throw new Error('Not authenticated');
//                 userId = user.id;
//             }

//             const { data: jobDesc, error } = await supabase
//                 .from('open_roles')
//                 .insert({
//                     recruiter_id: userId,
//                     company_name: descriptionData.companyName,
//                     role_title: descriptionData.roleTitle,
//                     department: descriptionData.department,
//                     location: descriptionData.location,
//                     salary_range: descriptionData.salaryRange,
//                     status: 'ACTIVE',
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//                 .select()
//                 .single();

//             // ... [Previous code remains the same until handleJobDescriptionSave]

//             if (error) throw error;
//             setJobDescription(jobDesc);
//             toast.success('Job description saved successfully.');
//             setShowJobDescriptionModal(false); // Close modal after successful save
//         } catch (error) {
//             console.error('Error saving job description:', error);
//             setError('Failed to save job description');
//             toast.error('Failed to save job description.');
//         }
//     };

//     const handleExport = async () => {
//         try {
//             setIsProcessing(true);
//             const exportData = {
//                 resume_data: {
//                     work: formData.work.map(work => ({
//                         url: work.url || "",
//                         name: work.company || "",
//                         endDate: work.endDate || null,
//                         position: work.title || "",
//                         startDate: work.startDate || "",
//                         highlights: typeof work.description === 'string'
//                             ? work.description.split('\n').filter(Boolean)
//                             : []
//                     })),
//                     basics: {
//                         url: "",
//                         name: formData.basics.name || "",
//                         email: formData.basics.email || "",
//                         image: "",
//                         label: formData.basics.label || "",
//                         phone: formData.basics.phone || "",
//                         summary: formData.basics.summary || "",
//                         location: {
//                             city: formData.basics.location?.city || "",
//                             region: formData.basics.location?.region || "",
//                             address: formData.basics.location?.address || "",
//                             postalCode: formData.basics.location?.postalCode || "",
//                             countryCode: formData.basics.location?.countryCode || "US"
//                         },
//                         profiles: formData.basics.profiles || []
//                     },
//                     projects: formData.projects.map(project => ({
//                         url: project.url || "",
//                         name: project.name || "",
//                         endDate: project.endDate || null,
//                         summary: project.description || "",
//                         startDate: project.startDate || "",
//                         highlights: project.description ? project.description.split('\n') : []
//                     })),
//                     education: formData.education.map(edu => ({
//                         gpa: edu.gpa || "",
//                         area: "",
//                         courses: [],
//                         endDate: edu.endDate || "",
//                         startDate: edu.startDate || "",
//                         studyType: (edu.degree || "").split(' ')[0] || "",
//                         institution: edu.institution || ""
//                     })),
//                     skills: formData.skills
//                 },
//                 theme_name: selectedTheme
//             };

//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/pdf'
//                 },
//                 body: JSON.stringify(exportData)
//             });

//             if (!response.ok) {
//                 const errorText = await response.text();
//                 throw new Error(`Export failed: ${errorText}`);
//             }

//             const blob = await response.blob();
//             if (blob.size === 0) {
//                 throw new Error('Generated PDF is empty');
//             }

//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = `${formData.basics.name || 'resume'}_${selectedTheme}.pdf`;
//             document.body.appendChild(a);
//             a.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(a);

//             toast.success('PDF exported successfully!');
//         } catch (error) {
//             console.error('Export error:', error);
//             setError(error.message);
//             toast.error(`Export failed: ${error.message}`);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     // Effects
//     useEffect(() => {
//         const loadResume = async () => {
//             if (resume_id && !conversionId) {
//                 try {
//                     await handleResumeSelect({ id: resume_id });
//                 } catch (error) {
//                     console.error('Error loading resume:', error);
//                     if (error.message === 'Not authenticated' && !isDev) {
//                         navigate('/login');
//                     }
//                 }
//             }
//         };
//         loadResume();
//     }, [resume_id, conversionId, handleResumeSelect, navigate]);

//     useEffect(() => {
//         const previewTimeout = setTimeout(() => {
//             if (formData.basics.name) {
//                 updatePreview();
//             }
//         }, 500);
//         return () => clearTimeout(previewTimeout);
//     }, [formData, selectedTheme, updatePreview]);

//     // JSX
//     return (
//         <>
//             {loading && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
//                 </div>
//             )}

//             <ResumeSelectionModal
//                 isVisible={showSelectionModal}
//                 onClose={() => navigate('/dashboard')}
//                 onSelectResume={handleResumeSelect}
//             />

//             <div className="flex h-screen bg-slate-50 dark:bg-gray-900 overflow-hidden">
//                 <div className="w-1/2 border-r border-gray-200 dark:border-gray-700 overflow-auto">
//                     <ResumePreviewPanel
//                         fileUrl={originalFileUrl}
//                         fileType={fileType}
//                         previewHtml={previewHtml}
//                         conversionId={conversionId}
//                         resumeScore={resumeScore}
//                     />
//                 </div>
//                 <div className="w-1/2 overflow-auto">
//                     <ResumeEditorPanel
//                         error={error}
//                         resumeScore={resumeScore}
//                         selectedTheme={selectedTheme}
//                         onThemeChange={setSelectedTheme}
//                         onExport={handleExport}
//                         isProcessing={isProcessing}
//                         sections={sections}
//                         expandedSections={expandedSections}
//                         onSectionToggle={handleSectionToggle}
//                         onDragEnd={handleDragEnd}
//                         onSave={handleSave}
//                         sensors={sensors}
//                         formData={formData}
//                         onFormUpdate={handleFormUpdate}
//                         coverLetter={coverLetter}
//                         onCoverLetterChange={setCoverLetter}
//                         candidatePitch={candidatePitch}
//                         onCandidatePitchChange={setCandidatePitch}
//                         onSaveCoverLetter={() => {
//                             // TODO: Implement cover letter save functionality
//                             toast.info('Cover letter save functionality coming soon');
//                         }}
//                         onSavePitch={() => {
//                             // TODO: Implement candidate pitch save functionality
//                             toast.info('Candidate pitch save functionality coming soon');
//                         }}
//                     />
//                 </div>
//             </div>

//             <JobDescriptionModal
//                 isVisible={showJobDescriptionModal}
//                 onClose={() => setShowJobDescriptionModal(false)}
//                 onSave={handleJobDescriptionSave}
//                 initialData={jobDescription}
//             />
//         </>
//     );
// };

// export default ResumeEditor;

// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams, useNavigate } from 'react-router';
// import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
// import { supabase } from '../../supabaseConfig';
// import { toast } from 'sonner';

// import ResumePreviewPanel from './ResumePreviewPanel';
// import ResumeEditorPanel from './ResumeEditorPanel';
// import JobDescriptionModal from './JobDescriptionModal';

// const API_BASE = process.env.REACT_APP_API_BASE || 'https://robo-resumeably-backend.onrender.com';
// const API_ENDPOINTS = {
//     THEMES: '/api/v1/themes',
//     PREVIEW: '/api/v1/preview',
//     EXPORT: '/api/v1/export-pdf'
// };

// const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
// const isDev = process.env.NODE_ENV === 'development';

// const ResumeEditor = () => {
//     const { resume_id } = useParams();
//     const navigate = useNavigate();

//     // State management with DEV fallbacks
//     const [userId, setUserId] = useState(isDev ? DEV_FALLBACK_UUID : null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [resumeScore, setResumeScore] = useState(85);
//     const [conversionId, setConversionId] = useState(null);

//     // UI State
//     const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
//     const [coverLetter, setCoverLetter] = useState('');
//     const [candidatePitch, setCandidatePitch] = useState('');
//     const [previewHtml, setPreviewHtml] = useState('');
//     const [selectedTheme, setSelectedTheme] = useState('stackoverflow');
//     const [originalFileUrl, setOriginalFileUrl] = useState(null);
//     const [fileType, setFileType] = useState(null);

//     // Content organization
//     const [sections, setSectionsState] = useState([
//         'basics', 'work', 'education', 'skills', 'projects', 'awards'
//     ]);

//     const handleJobDescriptionSave = async (descriptionData) => {
//         try {
//             const { data: jobDesc, error } = await supabase
//                 .from('open_roles')
//                 .insert({
//                     recruiter_id: userId,
//                     company_name: descriptionData.companyName,
//                     role_title: descriptionData.roleTitle,
//                     department: descriptionData.department,
//                     location: descriptionData.location,
//                     salary_range: descriptionData.salaryRange,
//                     status: 'ACTIVE',
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//                 .select()
//                 .single();

//             if (error) throw error;
//             setShowJobDescriptionModal(false);
//             toast.success('Job description saved successfully.');
//         } catch (error) {
//             console.error('Error saving job description:', error);
//             toast.error('Failed to save job description.');
//         }
//     };

//     const handleSectionToggle = (sectionId) => {
//         setExpandedSections(prev => ({
//             ...prev,
//             [sectionId]: !prev[sectionId]
//         }));
//     };

//     const handleDragEnd = (event) => {
//         const { active, over } = event;
//         if (over && active.id !== over.id) {
//             const oldIndex = sections.indexOf(active.id);
//             const newIndex = sections.indexOf(over.id);
//             setSectionsState(prev => arrayMove(prev, oldIndex, newIndex));
//         }
//     };

//     const handleFormUpdate = (section, field, value) => {
//         setFormData(prev => ({
//             ...prev,
//             [section]: field ? {
//                 ...prev[section],
//                 [field]: value
//             } : value
//         }));
//     };

//     const handleSave = async () => {
//         try {
//             setIsProcessing(true);
//             const { error } = await supabase
//                 .from('resume_conversions')
//                 .update({
//                     resume_json: formData,
//                     status: 'in_progress',
//                     updated_at: new Date().toISOString(),
//                     last_modified_by: userId
//                 })
//                 .eq('id', conversionId);

//             if (error) throw error;

//             await updatePreview();
//             toast.success('Changes saved successfully.');

//             await supabase
//                 .from('resume_conversions')
//                 .update({
//                     status: 'completed',
//                     updated_at: new Date().toISOString()
//                 })
//                 .eq('id', conversionId);

//         } catch (error) {
//             console.error('Error saving changes:', error);
//             toast.error('Failed to save changes.');

//             try {
//                 await supabase
//                     .from('resume_conversions')
//                     .update({
//                         status: 'failed',
//                         error_message: error.message,
//                         updated_at: new Date().toISOString()
//                     })
//                     .eq('id', conversionId);
//             } catch (updateError) {
//                 console.error('Error updating status:', updateError);
//             }
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleExport = async () => {
//         try {
//             setIsProcessing(true);

//             const exportData = {
//                 resume_data: {
//                     work: formData.work.map(work => ({
//                         url: work.url || "",
//                         name: work.company || "",
//                         endDate: work.endDate || null,
//                         position: work.title || "",
//                         startDate: work.startDate || "",
//                         highlights: typeof work.description === 'string'
//                             ? work.description.split('\n').filter(Boolean)
//                             : []
//                     })),
//                     basics: {
//                         url: "",
//                         name: formData.basics.name || "",
//                         email: formData.basics.email || "",
//                         image: "",
//                         label: formData.basics.label || "",
//                         phone: formData.basics.phone || "",
//                         summary: formData.basics.summary || "",
//                         location: {
//                             city: formData.basics.location?.city || "",
//                             region: formData.basics.location?.region || "",
//                             address: formData.basics.location?.address || "",
//                             postalCode: formData.basics.location?.postalCode || "",
//                             countryCode: formData.basics.location?.countryCode || "US"
//                         },
//                         profiles: formData.basics.profiles || []
//                     },
//                     projects: formData.projects.map(project => ({
//                         url: project.url || "",
//                         name: project.name || "",
//                         endDate: project.endDate || null,
//                         summary: project.description || "",
//                         startDate: project.startDate || "",
//                         highlights: project.description ? project.description.split('\n') : []
//                     })),
//                     education: formData.education.map(edu => ({
//                         gpa: edu.gpa || "",
//                         area: "",
//                         courses: [],
//                         endDate: edu.endDate || "",
//                         startDate: edu.startDate || "",
//                         studyType: (edu.degree || "").split(' ')[0] || "",
//                         institution: edu.institution || ""
//                     })),
//                     skills: formData.skills
//                 },
//                 theme_name: selectedTheme
//             };

//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/pdf'
//                 },
//                 body: JSON.stringify(exportData)
//             });

//             if (!response.ok) {
//                 const errorText = await response.text();
//                 throw new Error(`Export failed: ${errorText}`);
//             }

//             const blob = await response.blob();
//             if (blob.size === 0) {
//                 throw new Error('Generated PDF is empty');
//             }

//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = `${formData.basics.name || 'resume'}_${selectedTheme}.pdf`;
//             document.body.appendChild(a);
//             a.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(a);

//             toast.success('PDF exported successfully!');
//         } catch (error) {
//             console.error('Export error:', error);
//             toast.error(`Export failed: ${error.message}`);
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const [expandedSections, setExpandedSections] = useState({
//         basics: true,
//         work: true,
//         education: true,
//         skills: true,
//         projects: true,
//         awards: true
//     });

//     const [formData, setFormData] = useState({
//         basics: {
//             url: "",
//             name: "",
//             email: "",
//             label: "",
//             phone: "",
//             summary: "",
//             location: {
//                 city: "",
//                 region: "",
//                 address: "",
//                 postalCode: "",
//                 countryCode: ""
//             },
//             profiles: []
//         },
//         work: [],
//         education: [],
//         skills: {
//             tools: [],
//             skills: []
//         },
//         projects: [],
//         awards: []
//     });

//     // Initialize user session
//     useEffect(() => {
//         const initializeSession = async () => {
//             if (isDev) {
//                 setUserId(DEV_FALLBACK_UUID);
//                 return;
//             }

//             const { data: { session }, error } = await supabase.auth.getSession();
//             if (error || !session) {
//                 navigate('/login', {
//                     state: { returnTo: `/editor/${resume_id}` }
//                 });
//                 return;
//             }
//             setUserId(session.user.id);
//         };

//         initializeSession();
//     }, [navigate, resume_id]);

//     // DND Kit sensors
//     const sensors = useSensors(
//         useSensor(PointerSensor, {
//             activationConstraint: { distance: 8 }
//         }),
//         useSensor(KeyboardSensor, {
//             coordinateGetter: sortableKeyboardCoordinates
//         })
//     );

//     const fetchResumeData = useCallback(async (resumeId) => {
//         try {
//             setLoading(true);

//             const { data: resume, error } = await supabase
//                 .from('resume_uploads')  // Changed from 'resumes' to 'resume_uploads'
//                 .select(`
//                     *,
//                     agency_members (
//                         agency_id,
//                         user_id
//                     )
//                 `)
//                 .eq('id', resumeId)
//                 .single();

//             if (error) {
//                 console.error('Supabase error:', error);
//                 throw new Error('Failed to fetch resume data');
//             }

//             if (!resume) {
//                 throw new Error('Resume not found');
//             }

//             // In dev mode, don't check user_id match
//             if (!isDev && resume.user_id !== userId) {
//                 throw new Error('Unauthorized access to resume');
//             }

//             return resume;
//         } catch (error) {
//             console.error('Error fetching resume:', error);
//             toast.error(error.message || 'Failed to load resume data');
//             throw error;
//         } finally {
//             setLoading(false);
//         }
//     }, [userId]);

//     const initializeResumeConversion = useCallback(async (resumeId) => {
//         try {
//             const originalResume = await fetchResumeData(resumeId);

//             // First check if a conversion already exists
//             const { data: existingConversion } = await supabase
//                 .from('resume_conversions')
//                 .select('*')
//                 .eq('original_resume_id', resumeId)
//                 .eq('user_id', userId)
//                 .eq('is_latest', true)
//                 .single();

//             if (existingConversion) {
//                 console.log('Found existing conversion:', existingConversion);
//                 return existingConversion;
//             }

//             // Create new conversion if none exists
//             const { data: conversion, error } = await supabase
//                 .from('resume_conversions')
//                 .insert({
//                     original_resume_id: resumeId,
//                     user_id: userId,
//                     agency_id: isDev ? null : originalResume.agency_members?.[0]?.agency_id,
//                     candidate_name: originalResume.candidate_name || '',
//                     file_url: originalResume.file_url,
//                     file_type: originalResume.file_type,
//                     file_name: originalResume.file_name,
//                     file_path: originalResume.file_path,
//                     resume_text: originalResume.resume_text || '',
//                     resume_json: originalResume.resume_json || {},
//                     status: 'pending',
//                     is_latest: true,
//                     created_by: userId,
//                     last_modified_by: userId,
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//                 .select()
//                 .single();

//             if (error) {
//                 console.error('Supabase error:', error);
//                 throw new Error('Failed to initialize resume conversion');
//             }

//             return conversion;
//         } catch (error) {
//             console.error('Error initializing conversion:', error);
//             toast.error(error.message || 'Failed to initialize resume conversion');
//             throw error;
//         }
//     }, [userId, fetchResumeData]);

//     const handleConversionUpdate = useCallback((payload) => {
//         const { eventType, new: newData } = payload;
//         if (eventType === 'UPDATE' && newData.id === conversionId) {
//             switch (newData.status) {
//                 case 'completed':
//                     setResumeScore(100);
//                     toast.success('Resume conversion completed!');
//                     break;
//                 case 'in_progress':
//                     setResumeScore(50);
//                     toast.info('Processing resume...');
//                     break;
//                 case 'failed':
//                     setResumeScore(0);
//                     toast.error(`Conversion failed: ${newData.error_message || 'Unknown error'}`);
//                     break;
//                 default:
//                     break;
//             }
//         }
//     }, [conversionId]);

//     useEffect(() => {
//         if (!userId) return;

//         const channel = supabase.channel(`resume_conversions_${userId}`)
//             .on(
//                 'postgres_changes',
//                 {
//                     event: '*',
//                     schema: 'public',
//                     table: 'resume_conversions',
//                     filter: `user_id=eq.${userId}`
//                 },
//                 handleConversionUpdate
//             )
//             .subscribe();

//         return () => {
//             supabase.removeChannel(channel);
//         };
//     }, [userId, handleConversionUpdate]);

//     const updatePreview = useCallback(async () => {
//         if (!formData.basics.name) return;

//         try {
//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({
//                     resume_data: formData,
//                     theme_name: selectedTheme
//                 })
//             });

//             if (!response.ok) throw new Error('Preview generation failed');

//             const html = await response.text();
//             setPreviewHtml(html);
//         } catch (error) {
//             console.error('Preview error:', error);
//             toast.error('Failed to update preview');
//         }
//     }, [formData, selectedTheme]);

//     const loadResume = useCallback(async () => {
//         if (!resume_id || !userId) return;

//         try {
//             setLoading(true);

//             // Try to load existing conversion first
//             const { data: existingConversion } = await supabase
//                 .from('resume_conversions')
//                 .select('*')
//                 .eq('original_resume_id', resume_id)
//                 .eq('user_id', userId)
//                 .eq('is_latest', true)
//                 .single();

//             if (existingConversion) {
//                 setConversionId(existingConversion.id);
//                 setOriginalFileUrl(existingConversion.file_url);
//                 setFileType(existingConversion.file_type);

//                 if (existingConversion.resume_json) {
//                     setFormData(existingConversion.resume_json);
//                 }
//             } else {
//                 // Initialize new conversion if none exists
//                 const resume = await fetchResumeData(resume_id);
//                 const conversion = await initializeResumeConversion(resume_id);

//                 setConversionId(conversion.id);
//                 setOriginalFileUrl(resume.file_url);
//                 setFileType(resume.file_type);

//                 if (resume.resume_json) {
//                     setFormData(resume.resume_json);
//                 }
//             }

//             await updatePreview();
//             setShowJobDescriptionModal(true);
//         } catch (error) {
//             console.error('Error loading resume:', error);

//             if (error.message.includes('Failed to fetch resume data') && isDev) {
//                 // In dev mode, show more detailed error
//                 toast.error('Resume not found in dev mode. Please ensure resume exists in resume_uploads table.');
//             } else {
//                 toast.error(error.message || 'Failed to load resume');
//             }

//             if (!isDev) {
//                 navigate('/dashboard');
//             }
//         } finally {
//             setLoading(false);
//         }
//     }, [resume_id, userId, fetchResumeData, initializeResumeConversion, updatePreview, navigate]);

//     useEffect(() => {
//         loadResume();
//     }, [loadResume]);

//     // Auto-update preview with debounce
//     useEffect(() => {
//         const timeout = setTimeout(updatePreview, 500);
//         return () => clearTimeout(timeout);
//     }, [formData, selectedTheme, updatePreview]);

//     if (!userId) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="text-center">
//                     <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
//                     <p className="mt-4 text-gray-600">Initializing editor...</p>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <>
//             {loading && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
//                 </div>
//             )}

//             <div className="flex h-screen bg-slate-50 dark:bg-gray-900 overflow-hidden">
//                 <div className="w-1/2 border-r border-gray-200 dark:border-gray-700 overflow-auto">
//                     <ResumePreviewPanel
//                         fileUrl={originalFileUrl}
//                         fileType={fileType}
//                         previewHtml={previewHtml}
//                         conversionId={conversionId}
//                         resumeScore={resumeScore}
//                     />
//                 </div>
//                 <div className="w-1/2 overflow-auto">
//                     <ResumeEditorPanel
//                         error={error}
//                         resumeScore={resumeScore}
//                         selectedTheme={selectedTheme}
//                         onThemeChange={setSelectedTheme}
//                         onExport={handleExport}
//                         isProcessing={isProcessing}
//                         sections={sections}
//                         expandedSections={expandedSections}
//                         onSectionToggle={handleSectionToggle}
//                         onDragEnd={handleDragEnd}
//                         onSave={handleSave}
//                         sensors={sensors}
//                         formData={formData}
//                         onFormUpdate={handleFormUpdate}
//                         coverLetter={coverLetter}
//                         onCoverLetterChange={setCoverLetter}
//                         candidatePitch={candidatePitch}
//                         onCandidatePitchChange={setCandidatePitch}
//                         onSaveCoverLetter={() => {
//                             toast.info('Cover letter save functionality coming soon');
//                         }}
//                         onSavePitch={() => {
//                             toast.info('Candidate pitch save functionality coming soon');
//                         }}
//                     />
//                 </div>
//             </div>

//             {showJobDescriptionModal && (
//                 <JobDescriptionModal
//                     isVisible={showJobDescriptionModal}
//                     onClose={() => setShowJobDescriptionModal(false)}
//                     onSave={handleJobDescriptionSave}
//                     userId={userId}
//                 />
//             )}
//         </>
//     );
// };

// export default ResumeEditor;


// import React, { useEffect, useState, useCallback } from 'react';
// import { useParams, useNavigate } from 'react-router';
// import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
// import { supabase } from '../../supabaseConfig';
// import { toast } from 'sonner';

// import ResumePreviewPanel from './ResumePreviewPanel';
// import ResumeEditorPanel from '../page/ResumeEditorPanel';

// import JobDescriptionModal from './JobDescriptionModal';

// const API_BASE = process.env.REACT_APP_API_BASE || 'https://robo-resumeably-backend.onrender.com';
// const API_ENDPOINTS = {
//     THEMES: '/api/themes',
//     PREVIEW: '/api/preview',
//     EXPORT: '/api/export-pdf'
// };

// const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
// const isDev = process.env.NODE_ENV === 'development';

// const ResumeEditor = () => {
//     const { resume_id } = useParams();
//     const navigate = useNavigate();

//     // State Management
//     const [userId, setUserId] = useState(isDev ? DEV_FALLBACK_UUID : null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [coverLetter, setCoverLetter] = useState('');
//     const [candidatePitch, setCandidatePitch] = useState('');
//     const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [resumeScore, setResumeScore] = useState(85);
//     const [conversionId, setConversionId] = useState(null);
//     const [previewHtml, setPreviewHtml] = useState('');
//     const [selectedTheme, setSelectedTheme] = useState('stackoverflow');
//     const [originalFileUrl, setOriginalFileUrl] = useState(null);
//     const [fileType, setFileType] = useState(null);

//     const [sections, setSectionsState] = useState([
//         'basics', 'work', 'education', 'skills', 'projects', 'awards'
//     ]);

//     const [expandedSections, setExpandedSections] = useState({
//         basics: true,
//         work: true,
//         education: true,
//         skills: true,
//         projects: true,
//         awards: true
//     });

//     const [formData, setFormData] = useState({
//         basics: {
//             url: "",
//             name: "",
//             email: "",
//             label: "",
//             phone: "",
//             summary: "",
//             location: {
//                 city: "",
//                 region: "",
//                 address: "",
//                 postalCode: "",
//                 countryCode: ""
//             },
//             profiles: []
//         },
//         work: [],
//         education: [],
//         skills: {
//             tools: [],
//             skills: []
//         },
//         projects: [],
//         awards: []
//     });

//     // Initialize user session
//     useEffect(() => {
//         const initializeSession = async () => {
//             if (isDev) {
//                 setUserId(DEV_FALLBACK_UUID);
//                 return;
//             }

//             const { data: { session }, error } = await supabase.auth.getSession();
//             if (error || !session) {
//                 navigate('/login', {
//                     state: { returnTo: `/resume/${resume_id}` }
//                 });
//                 return;
//             }
//             setUserId(session.user.id);
//         };

//         initializeSession();
//     }, [navigate, resume_id]);

//     // DND Kit sensors
//     const sensors = useSensors(
//         useSensor(PointerSensor, {
//             activationConstraint: { distance: 8 }
//         }),
//         useSensor(KeyboardSensor, {
//             coordinateGetter: sortableKeyboardCoordinates
//         })
//     );

//     const fetchResumeData = useCallback(async (resumeId) => {
//         try {
//             setLoading(true);
//             const { data: resume, error } = await supabase
//                 .from('resume_uploads')
//                 .select('*')
//                 .eq('id', resumeId)
//                 .single();

//             if (error) throw error;
//             if (!resume) throw new Error('Resume not found');

//             if (!isDev && resume.user_id !== userId) {
//                 throw new Error('Unauthorized access');
//             }

//             return resume;
//         } catch (error) {
//             console.error('Error fetching resume:', error);
//             toast.error('Failed to load resume data');
//             throw error;
//         } finally {
//             setLoading(false);
//         }
//     }, [userId]);

//     const initializeResumeConversion = useCallback(async (resumeId) => {
//         try {
//             let currentUserId = isDev ? DEV_FALLBACK_UUID : userId;

//             // Check for existing conversion first
//             const { data: existingConversion, error: existingError } = await supabase
//                 .from('resume_conversions')
//                 .select('*')
//                 .eq('original_resume_id', resumeId)
//                 .eq('user_id', currentUserId)
//                 .eq('is_latest', true)
//                 .single();

//             if (existingConversion) return existingConversion;

//             // Fetch original resume
//             const { data: originalResume, error: fetchError } = await supabase
//                 .from('resume_uploads')
//                 .select('*')
//                 .eq('id', resumeId)
//                 .single();

//             if (fetchError) throw fetchError;
//             if (!originalResume) throw new Error('Resume not found');

//             // Parse and prepare data
//             const parsedData = originalResume.resume_json || {};
//             const locationData = parsedData.basics?.location || {};
//             const currentWork = Array.isArray(parsedData.work) && parsedData.work[0] ? parsedData.work[0] : {};
//             const currentEducation = Array.isArray(parsedData.education) && parsedData.education[0] ? parsedData.education[0] : {};

//             // Create conversion data object
//             const conversionData = {
//                 original_resume_id: resumeId,
//                 user_id: currentUserId,
//                 file_name: originalResume.file_name,
//                 file_path: originalResume.file_path,
//                 file_url: originalResume.file_url,
//                 file_type: originalResume.file_type,
//                 resume_text: originalResume.resume_text || '',
//                 resume_json: parsedData,
//                 company: currentWork.name || '',
//                 role: currentWork.position || '',
//                 location: locationData.city ? `${locationData.city}, ${locationData.region || ''}` : '',
//                 department: '',  // Can be added if available in the data
//                 education_level: currentEducation.studyType || '',
//                 education_institution: currentEducation.institution || '',
//                 education_degree: currentEducation.studyType ? `${currentEducation.studyType} ${currentEducation.area || ''}` : '',
//                 education_major: currentEducation.area || '',
//                 education_gpa: currentEducation.gpa || '',
//                 education_start_date: currentEducation.startDate || null,
//                 education_end_date: currentEducation.endDate || null,
//                 current_position: currentWork.position || '',
//                 current_company: currentWork.name || '',
//                 current_start_date: currentWork.startDate || null,
//                 current_end_date: currentWork.endDate || null,
//                 highest_education: currentEducation.studyType || '',
//                 current_title: currentWork.position || '',
//                 education_history: JSON.stringify(parsedData.education || []),
//                 work_experience: JSON.stringify(parsedData.work || []),
//                 projects: JSON.stringify(parsedData.projects || []),
//                 volunteer: JSON.stringify(parsedData.volunteer || []),
//                 certifications: JSON.stringify(parsedData.certifications || []),
//                 languages: JSON.stringify(parsedData.languages || []),
//                 hobbies: JSON.stringify(parsedData.hobbies || []),
//                 awards: JSON.stringify(parsedData.awards || []),
//                 notes: '',
//                 custom_styling: JSON.stringify({}),
//                 version: 1,
//                 is_latest: true,
//                 created_at: new Date().toISOString(),
//                 updated_at: new Date().toISOString(),
//                 created_by: currentUserId,
//                 last_modified_by: currentUserId,
//                 status: 'draft',
//                 error_message: null,
//                 candidate_name: parsedData.basics?.name || ''
//             };

//             // Insert the conversion
//             const { data: conversion, error: insertError } = await supabase
//                 .from('resume_conversions')
//                 .insert(conversionData)
//                 .select()
//                 .single();

//             if (insertError) throw insertError;
//             return conversion;
//         } catch (error) {
//             console.error('Error initializing conversion:', error);
//             toast.error(`Failed to initialize resume conversion: ${error.message}`);
//             throw error;
//         }
//     }, [userId]);

//     const updatePreview = useCallback(async () => {
//         try {
//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'text/html'
//                 },
//                 body: JSON.stringify({
//                     resume_data: formData,
//                     theme_name: selectedTheme
//                 })
//             });

//             if (!response.ok) throw new Error('Preview generation failed');
//             const html = await response.text();
//             console.log('Received HTML:', html); // Add this log
//             setPreviewHtml(html);
//         } catch (error) {
//             console.error('Preview error:', error);
//             toast.error('Failed to update preview');
//         }
//     }, [formData, selectedTheme]);

//     // Debounced preview update effect
//     useEffect(() => {
//         let mounted = true;
//         const timeout = setTimeout(async () => {
//             if (mounted && formData.basics.name) {
//                 try {
//                     await updatePreview();
//                 } catch (error) {
//                     console.error('Preview update error:', error);
//                 }
//             }
//         }, 1000);

//         return () => {
//             mounted = false;
//             clearTimeout(timeout);
//         };
//     }, [formData.basics.name, selectedTheme, updatePreview]);

//     const handleConversionUpdate = useCallback((payload) => {
//         const { eventType, new: newData } = payload;
//         if (eventType === 'UPDATE' && newData.id === conversionId) {
//             switch (newData.status) {
//                 case 'completed':
//                     setResumeScore(100);
//                     toast.success('Resume conversion completed!');
//                     break;
//                 case 'in_progress':
//                     setResumeScore(50);
//                     toast.info('Processing resume...');
//                     break;
//                 case 'failed':
//                     setResumeScore(0);
//                     toast.error(`Conversion failed: ${newData.error_message || 'Unknown error'}`);
//                     break;
//                 default:
//                     break;
//             }
//         }
//     }, [conversionId]);

//     useEffect(() => {
//         if (!userId) return;

//         const channel = supabase.channel(`resume_conversions_${userId}`)
//             .on(
//                 'postgres_changes',
//                 {
//                     event: '*',
//                     schema: 'public',
//                     table: 'resume_conversions',
//                     filter: `user_id=eq.${userId}`
//                 },
//                 handleConversionUpdate
//             )
//             .subscribe();

//         return () => {
//             supabase.removeChannel(channel);
//         };
//     }, [userId, handleConversionUpdate]);

//     const handleSectionToggle = (sectionId) => {
//         setExpandedSections(prev => ({
//             ...prev,
//             [sectionId]: !prev[sectionId]
//         }));
//     };

//     const handleDragEnd = (event) => {
//         const { active, over } = event;
//         if (over && active.id !== over.id) {
//             const oldIndex = sections.indexOf(active.id);
//             const newIndex = sections.indexOf(over.id);
//             setSectionsState(prev => arrayMove(prev, oldIndex, newIndex));
//         }
//     };

//     const handleFormUpdate = (section, field, value) => {
//         setFormData(prev => ({
//             ...prev,
//             [section]: field ? {
//                 ...prev[section],
//                 [field]: value
//             } : value
//         }));
//     };

//     const handleSave = async () => {
//         try {
//             setIsProcessing(true);
//             await supabase
//                 .from('resume_conversions')
//                 .update({
//                     resume_json: formData,
//                     status: 'in_progress',
//                     updated_at: new Date().toISOString()
//                 })
//                 .eq('id', conversionId);

//             toast.success('Changes saved successfully');

//             await supabase
//                 .from('resume_conversions')
//                 .update({
//                     status: 'completed',
//                     updated_at: new Date().toISOString()
//                 })
//                 .eq('id', conversionId);
//         } catch (error) {
//             console.error('Error saving:', error);
//             toast.error('Failed to save changes');
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleExport = async () => {
//         try {
//             setIsProcessing(true);

//             const exportData = {
//                 resume_data: {
//                     work: formData.work.map(work => ({
//                         url: work.url || "",
//                         name: work.company || "",
//                         endDate: work.endDate || null,
//                         position: work.title || "",
//                         startDate: work.startDate || "",
//                         highlights: typeof work.description === 'string'
//                             ? work.description.split('\n').filter(Boolean)
//                             : []
//                     })),
//                     basics: {
//                         url: "",
//                         name: formData.basics.name || "",
//                         email: formData.basics.email || "",
//                         image: "",
//                         label: formData.basics.label || "",
//                         phone: formData.basics.phone || "",
//                         summary: formData.basics.summary || "",
//                         location: {
//                             city: formData.basics.location?.city || "",
//                             region: formData.basics.location?.region || "",
//                             address: formData.basics.location?.address || "",
//                             postalCode: formData.basics.location?.postalCode || "",
//                             countryCode: formData.basics.location?.countryCode || "US"
//                         },
//                         profiles: formData.basics.profiles || []
//                     },
//                     projects: formData.projects.map(project => ({
//                         url: project.url || "",
//                         name: project.name || "",
//                         endDate: project.endDate || null,
//                         summary: project.description || "",
//                         startDate: project.startDate || "",
//                         highlights: project.description ? project.description.split('\n') : []
//                     })),
//                     education: formData.education.map(edu => ({
//                         gpa: edu.gpa || "",
//                         area: "",
//                         courses: [],
//                         endDate: edu.endDate || "",
//                         startDate: edu.startDate || "",
//                         studyType: (edu.degree || "").split(' ')[0] || "",
//                         institution: edu.institution || ""
//                     })),
//                     skills: formData.skills
//                 },
//                 theme_name: selectedTheme
//             };

//             const response = await fetch(`${API_BASE}${API_ENDPOINTS.EXPORT}`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/pdf'
//                 },
//                 body: JSON.stringify(exportData)
//             });

//             if (!response.ok) throw new Error('Export failed');

//             const blob = await response.blob();
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = `${formData.basics.name || 'resume'}_${selectedTheme}.pdf`;
//             document.body.appendChild(a);
//             a.click();
//             window.URL.revokeObjectURL(url);
//             document.body.removeChild(a);

//             toast.success('PDF exported successfully!');
//         } catch (error) {
//             console.error('Export error:', error);
//             toast.error('Failed to export PDF');
//         } finally {
//             setIsProcessing(false);
//         }
//     };

//     const handleJobDescriptionSave = async (descriptionData) => {
//         try {
//             const { data: jobDesc, error } = await supabase
//                 .from('open_roles')
//                 .insert({
//                     recruiter_id: userId,
//                     company_name: descriptionData.companyName,
//                     role_title: descriptionData.roleTitle,
//                     department: descriptionData.department,
//                     location: descriptionData.location,
//                     salary_range: descriptionData.salaryRange,
//                     status: 'ACTIVE',
//                     created_at: new Date().toISOString(),
//                     updated_at: new Date().toISOString()
//                 })
//                 .select()
//                 .single();

//             if (error) throw error;
//             toast.success('Job description saved successfully');
//             setShowJobDescriptionModal(false);
//         } catch (error) {
//             console.error('Error saving job description:', error);
//             toast.error('Failed to save job description');
//         }
//     };

//     useEffect(() => {
//         const loadResume = async () => {
//             if (!resume_id || !userId) return;

//             try {
//                 setLoading(true);
//                 const conversion = await initializeResumeConversion(resume_id);
//                 setConversionId(conversion.id);
//                 setOriginalFileUrl(conversion.file_url);
//                 setFileType(conversion.file_type);

//                 if (conversion.resume_json) {
//                     setFormData(conversion.resume_json);
//                 }

//                 setShowJobDescriptionModal(true);
//             } catch (error) {
//                 console.error('Error loading resume:', error);
//                 toast.error('Failed to load resume data');
//                 if (!isDev) {
//                     navigate('/dashboard');
//                 }
//             } finally {
//                 setLoading(false);
//             }
//         };

//         loadResume();
//     }, [resume_id, userId, initializeResumeConversion, navigate]);

//     // Initial loading state
//     if (!userId) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="text-center">
//                     <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
//                     <p className="mt-4 text-gray-600">Initializing editor...</p>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <>
//             {loading && (
//                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                     <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
//                 </div>
//             )}

//             <div className="flex h-screen bg-slate-50 dark:bg-gray-900 overflow-hidden">
//                 {/* Preview Panel */}


//                 {/* Editor Panel */}
//                 <div className="w-1/2 overflow-auto">
//                     <ResumeEditorPanel
//                         error={error}
//                         resumeScore={resumeScore}
//                         selectedTheme={selectedTheme}
//                         onThemeChange={setSelectedTheme}
//                         onExport={handleExport}
//                         isProcessing={isProcessing}
//                         sections={sections}
//                         expandedSections={expandedSections}
//                         onSectionToggle={handleSectionToggle}
//                         onDragEnd={handleDragEnd}
//                         onSave={handleSave}
//                         sensors={sensors}
//                         formData={formData}
//                         onFormUpdate={handleFormUpdate}
//                         coverLetter={coverLetter}
//                         onCoverLetterChange={setCoverLetter}
//                         candidatePitch={candidatePitch}
//                         onCandidatePitchChange={setCandidatePitch}
//                         onSaveCoverLetter={() => {
//                             toast.info('Cover letter save functionality coming soon');
//                         }}
//                         onSavePitch={() => {
//                             toast.info('Candidate pitch save functionality coming soon');
//                         }}
//                     />
//                 </div>
//                 <div className="w-1/2 border-r border-gray-200 dark:border-gray-700 overflow-auto">
//                     <ResumePreviewPanel
//                         fileUrl={originalFileUrl}
//                         fileType={fileType}
//                         previewHtml={previewHtml}
//                         conversionId={conversionId}
//                         resumeScore={resumeScore}
//                     />
//                 </div>
//             </div>

//             {/* Job Description Modal */}
//             {/* {showJobDescriptionModal && (
//                 <JobDescriptionModal
//                     isVisible={showJobDescriptionModal}
//                     onClose={() => setShowJobDescriptionModal(false)}
//                     onSave={handleJobDescriptionSave}
//                     userId={userId}
//                 />
//             )} */}
//         </>
//     );
// };

// export default ResumeEditor;

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { supabase } from '../../supabaseConfig';
import { toast } from 'sonner';
import { Eye } from 'lucide-react';

import ResumePreviewPanel from './ResumePreviewPanel';
import ResumeEditorPanel from '../page/ResumeEditorPanel';

const API_BASE = process.env.REACT_APP_API_BASE || 'https://robo-resumeably-backend.onrender.com';
const API_ENDPOINTS = {
    THEMES: '/api/themes',
    PREVIEW: '/api/preview',
    EXPORT: '/api/export-pdf'
};

const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

// Internal Modal Component
const ResumePreviewModal = ({ isOpen, onClose, previewHtml }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg w-full max-w-4xl h-[80vh] flex flex-col relative">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-xl font-semibold">Resume Preview</h2>
                    <button
                        onClick={onClose}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        ✕
                    </button>
                </div>

                <div className="flex-1 overflow-auto p-4">
                    <div
                        dangerouslySetInnerHTML={{ __html: previewHtml }}
                        className="preview-content"
                    />
                </div>
            </div>
        </div>
    );
};

// Internal Preview Button Component
const PreviewButton = ({ onClick, isProcessing }) => {
    return (
        <button
            onClick={onClick}
            disabled={isProcessing}
            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
            <Eye className="w-4 h-4 mr-2" />
            {isProcessing ? 'Generating...' : 'Preview Resume'}
        </button>
    );
};

const ResumeEditor = () => {
    const { resume_id } = useParams();
    const navigate = useNavigate();

    // State Management
    const [userId, setUserId] = useState(isDev ? DEV_FALLBACK_UUID : null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [coverLetter, setCoverLetter] = useState('');
    const [candidatePitch, setCandidatePitch] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [resumeScore, setResumeScore] = useState(85);
    const [conversionId, setConversionId] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState('stackoverflow');
    const [originalFileUrl, setOriginalFileUrl] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewHtml, setPreviewHtml] = useState('');

    const [sections, setSectionsState] = useState([
        'basics', 'work', 'education', 'skills', 'projects', 'awards'
    ]);

    const [expandedSections, setExpandedSections] = useState({
        basics: true,
        work: true,
        education: true,
        skills: true,
        projects: true,
        awards: true
    });

    const [formData, setFormData] = useState({
        basics: {
            url: "",
            name: "",
            email: "",
            label: "",
            phone: "",
            summary: "",
            location: {
                city: "",
                region: "",
                address: "",
                postalCode: "",
                countryCode: ""
            },
            profiles: []
        },
        work: [],
        education: [],
        skills: {
            tools: [],
            skills: []
        },
        projects: [],
        awards: []
    });

    // Initialize user session
    useEffect(() => {
        const initializeSession = async () => {
            if (isDev) {
                setUserId(DEV_FALLBACK_UUID);
                return;
            }

            const { data: { session }, error } = await supabase.auth.getSession();
            if (error || !session) {
                navigate('/login', {
                    state: { returnTo: `/resume/${resume_id}` }
                });
                return;
            }
            setUserId(session.user.id);
        };

        initializeSession();
    }, [navigate, resume_id]);

    // DND Kit sensors
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (formData.basics.name) {  // Only update if we have some data
                handlePreviewClick();
            }
        }, 1000);  // Debounce for 1 second

        return () => clearTimeout(debounceTimer);
    }, [formData, selectedTheme]);

    const handlePreviewClick = async () => {
        try {
            setIsProcessing(true);
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/html'
                },
                body: JSON.stringify({
                    resume_data: formData,
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) throw new Error('Preview generation failed');
            const html = await response.text();
            console.log('Preview HTML:', html); // Add this to debug
            setPreviewHtml(html);
        } catch (error) {
            console.error('Preview error:', error);
            toast.error('Failed to generate preview');
        } finally {
            setIsProcessing(false);
        }
    };

    const initializeResumeConversion = useCallback(async (resumeId) => {
        try {
            let currentUserId = isDev ? DEV_FALLBACK_UUID : userId;

            const { data: existingConversion } = await supabase
                .from('resume_conversions')
                .select('*')
                .eq('original_resume_id', resumeId)
                .eq('user_id', currentUserId)
                .eq('is_latest', true)
                .single();

            if (existingConversion) return existingConversion;

            const { data: originalResume } = await supabase
                .from('resume_uploads')
                .select('*')
                .eq('id', resumeId)
                .single();

            if (!originalResume) throw new Error('Resume not found');

            const parsedData = originalResume.resume_json || {};
            const locationData = parsedData.basics?.location || {};
            const currentWork = Array.isArray(parsedData.work) && parsedData.work[0] ? parsedData.work[0] : {};
            const currentEducation = Array.isArray(parsedData.education) && parsedData.education[0] ? parsedData.education[0] : {};

            const conversionData = {
                original_resume_id: resumeId,
                user_id: currentUserId,
                file_name: originalResume.file_name,
                file_path: originalResume.file_path,
                file_url: originalResume.file_url,
                file_type: originalResume.file_type,
                resume_text: originalResume.resume_text || '',
                resume_json: parsedData,
                company: currentWork.name || '',
                role: currentWork.position || '',
                location: locationData.city ? `${locationData.city}, ${locationData.region || ''}` : '',
                status: 'draft',
                is_latest: true,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                created_by: currentUserId,
                last_modified_by: currentUserId
            };

            const { data: conversion, error: insertError } = await supabase
                .from('resume_conversions')
                .insert(conversionData)
                .select()
                .single();

            if (insertError) throw insertError;
            return conversion;
        } catch (error) {
            console.error('Error initializing conversion:', error);
            toast.error('Failed to initialize resume conversion');
            throw error;
        }
    }, [userId]);

    const handleSectionToggle = (sectionId) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = sections.indexOf(active.id);
            const newIndex = sections.indexOf(over.id);
            setSectionsState(prev => arrayMove(prev, oldIndex, newIndex));
        }
    };

    const handleFormUpdate = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: field ? {
                ...prev[section],
                [field]: value
            } : value
        }));
    };

    const handleSave = async () => {
        try {
            setIsProcessing(true);
            await supabase
                .from('resume_conversions')
                .update({
                    resume_json: formData,
                    status: 'completed',
                    updated_at: new Date().toISOString()
                })
                .eq('id', conversionId);

            toast.success('Changes saved successfully');
        } catch (error) {
            console.error('Error saving:', error);
            toast.error('Failed to save changes');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleExport = async () => {
        try {
            setIsProcessing(true);
            const response = await fetch(`${API_BASE}${API_ENDPOINTS.PREVIEW}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                body: JSON.stringify({
                    resume_data: formData,
                    theme_name: selectedTheme
                })
            });

            if (!response.ok) throw new Error('Export failed');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${formData.basics.name || 'resume'}_${selectedTheme}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            toast.success('PDF exported successfully!');
        } catch (error) {
            console.error('Export error:', error);
            toast.error('Failed to export PDF');
        } finally {
            setIsProcessing(false);
        }
    };

    useEffect(() => {
        const loadResume = async () => {
            if (!resume_id || !userId) return;

            try {
                setLoading(true);
                const conversion = await initializeResumeConversion(resume_id);
                setConversionId(conversion.id);
                setOriginalFileUrl(conversion.file_url);
                setFileType(conversion.file_type);

                if (conversion.resume_json) {
                    setFormData(conversion.resume_json);
                }
            } catch (error) {
                console.error('Error loading resume:', error);
                toast.error('Failed to load resume data');
                if (!isDev) {
                    navigate('/dashboard');
                }
            } finally {
                setLoading(false);
            }
        };

        loadResume();
    }, [resume_id, userId, initializeResumeConversion, navigate]);

    if (!userId) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
                    <p className="mt-4 text-gray-600">Initializing editor...</p>
                </div>
            </div>
        );
    }

    return (
        <>
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}

            <div className="flex h-screen bg-slate-50 dark:bg-gray-900 overflow-hidden">
                {/* Left Panel - Editor */}
                <div className="w-1/2 overflow-auto">
                    <ResumeEditorPanel
                        error={error}
                        resumeScore={resumeScore}
                        selectedTheme={selectedTheme}
                        onThemeChange={setSelectedTheme}
                        onExport={handleExport}
                        isProcessing={isProcessing}
                        sections={sections}
                        expandedSections={expandedSections}
                        onSectionToggle={handleSectionToggle}
                        onDragEnd={handleDragEnd}
                        onSave={handleSave}
                        sensors={sensors}
                        formData={formData}
                        onFormUpdate={handleFormUpdate}
                        coverLetter={coverLetter}
                        onCoverLetterChange={setCoverLetter}
                        candidatePitch={candidatePitch}
                        onCandidatePitchChange={setCandidatePitch}
                        onSaveCoverLetter={() => {
                            toast.info('Cover letter save functionality coming soon');
                        }}
                        onSavePitch={() => {
                            toast.info('Candidate pitch save functionality coming soon');
                        }}
                    />
                </div>

                {/* Right Panel - Preview */}
                <div className="w-1/2 border-l border-gray-200">
                    <ResumePreviewPanel
                        resume={{
                            file_url: originalFileUrl,
                            file_type: fileType,
                            candidate_name: formData.basics.name
                        }}
                        previewHtml={previewHtml}
                        onRefreshPreview={handlePreviewClick}
                    />
                </div>
            </div>
        </>
    );
};

export default ResumeEditor;