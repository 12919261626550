import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Toast } from './Toast';
import { useToastContext } from '../../contexts/useToastContext/useToastContext';

export const ToastContainer = (props) => {
  const { toasts, removeToast } = useToastContext();
  return (
    <div className="fixed bottom-4 right-4 z-50 flex flex-col-reverse gap-2">
      <AnimatePresence mode="popLayout">
        {toasts.map((toast) => (
          <Toast
            key={`${toast.id}${toast.message?.description || toast?.message}`}
            message={toast.message}
            type={toast.type}
            onClose={() => removeToast(toast.id)}
            persistent={toast.persistent}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};
