import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Star, Zap, Rocket, Target } from 'lucide-react';

import { getAllApplicationsCount } from '../../api/supabaseClient';
import { FeatureCard } from '../concrete/FeatureCard/FeatureCard';
import { ReviewCard } from '../concrete/ReviewCard/ReviewCard';
import { Button } from '../base/Button/Button';
import { RoboApplyProcessCard } from '../concrete/RoboApplyProcessCard/RoboApplyProcessCard';
import { Modal } from '../base/Modal/Modal';
import { PaywallCard } from '../concrete/PaywallCard/PaywallCard';
import { useAuth } from '../contexts/useAuth/useAuth';
import { Avatar, AvatarFallback, AvatarImage } from '../base/Avatar/Avatar';
import { SAMPLE_REVIEWS } from '../../utilities/constants';
import JobSearchPortal from '../JobSearchPortal';

const messages = [
  'Top companies - no manual work',
  'Accelerate your job search!',
  'Apply effortlessly to leading firms',
  'Your career, powered by RoboApply',
  'Seamlessly apply to tech giants',
];

export const JobApplyAILandingPage = () => {
  const { user = null } = useAuth();
  const [currentJobIndex, setCurrentJobIndex] = useState(0);
  const [isPaywallOpen, setIsPaywallOpen] = useState(false);
  const [applicationCount, setApplicationCount] = useState(0);
  const navigate = useNavigate();

  const fetchApplicationCount = async () => {
    try {
      const count = await getAllApplicationsCount();
      if (count !== null && count !== undefined) {
        setApplicationCount(count);
      } else {
        console.error('Error fetching application count.');
      }
    } catch (error) {
      console.error('Error fetching application count:', error);
    }
  };

  useEffect(() => {
    fetchApplicationCount();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentJobIndex((prevIndex) => (prevIndex + 1) % SAMPLE_REVIEWS.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleCTAClick = (e) => {
    console.log('handleCTAClick ', user);
    e.preventDefault();
    if (user?.id) {
      navigate('/auto-apply');
      return;
    }
    setIsPaywallOpen(true);
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-green-400 via-green-500 to-blue-500 flex flex-col items-center justify-between overflow-hidden">
      {/* Modal for Paywall */}
      <Modal
        className="bg-transparent shadow-none border-none"
        isOpen={isPaywallOpen}
        onClose={() => setIsPaywallOpen(false)}
      >
        <PaywallCard onComplete={() => setIsPaywallOpen(false)} />
      </Modal>

      {/* White Overlay */}
      <div className="absolute top-0 left-0 w-1/2 2xl:w-2/5 h-full z-0 bg-white rounded-br-[100px] md:rounded-br-[200px] pointer-events-none" />

      {/* Main Content */}
      <div className="relative w-full max-w-[90rem] mx-auto flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4 z-10 mt-8 px-0 sm:px-4 md:px-6 lg:px-8">
        {/* Left Side - Heading, Description, CTA, Avatars */}
        <div className="w-full md:w-3/5 space-y-12 px-4 sm:px-0 my-4">
          <h1 className="text-5xl md:text-5xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-blue-600">
            Automate Job Applications
            <br />
          </h1>

          <p className="text-xl text-gray-900 max-w-xl leading-relaxed whitespace-normal break-words">
            Tired of waisting your time applying to jobs?
          </p>

          <div className="flex flex-nowrap w-max mb-8">
            <Button
              onClick={handleCTAClick}
              className="bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600 text-white px-10 py-5 text-md sm:text-xl font-semibold shadow-lg hover:shadow-xl"
            >
              one click, 1000's of jobs, free →
            </Button>
          </div>

          <div className="flex flex-wrap justify-center sm:justify-start sm:flex-nowrap items-start gap-6 my-8 md:my-0">
            {/* User Avatars and Stars */}
            <div className="flex flex-col sm:text-start items-center gap-2">
              <div className="flex md:-space-x-2">
                {[
                  { src: '/person1.png', initials: 'JL' },
                  { src: '/person56.png', initials: 'TS' },
                  { src: '/person3.png', initials: 'SS' },
                  { src: '/person4.png', initials: 'PR' },
                ].map((avatarData, i) => (
                  <Avatar
                    key={i}
                    className="ring-4 ring-white border-2 border-white h-14 w-14 md:h-15 md:w-15"
                  >
                    <AvatarImage src={avatarData.src} alt={`User ${i + 1}`} />
                    <AvatarFallback>{avatarData.initials}</AvatarFallback>
                  </Avatar>
                ))}
              </div>
              {/* Stars */}
              <div className="flex items-center space-x-1">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-7 h-7 fill-yellow-400 stroke-yellow-400" />
                ))}
              </div>
            </div>

            {/* Application Count */}
            <div className="flex flex-col gap-3 text-center sm:text-left">
              <h3 className="text-2xl w-full font-bold tracking-tighter sm:text-3xl">
                Already used to send over {(applicationCount * 10).toLocaleString()} applications!
              </h3>
              <p className="max-w-[600px] text-gray-500 md:text-lg">
                Try your first 20 applications for free. No credit card required.
              </p>
            </div>
          </div>
        </div>

        {/* Right Side - Job Board */}
        <div className="w-full md:w-2/5 mt-8 md:mt-0">
          <div className="flex flex-col items-center md:block w-full min-w-[320px] space-y-6">
            {/* Robo Apply Process Card */}
            <RoboApplyProcessCard className="w-full max-w-md rounded-2xl shadow-lg bg-white z-20" />

            {/* Rotating Job Cards */}
            <div className="w-full max-w-md h-[400px] sm:h-[380px] relative overflow-hidden rounded-2xl shadow-lg bg-white z-20">
              <div className="absolute inset-0">
                {SAMPLE_REVIEWS.map((job, index) => (
                  <ReviewCard
                    job={job}
                    className="w-full"
                    isActive={index === currentJobIndex}
                    style={{
                      transform: `translateY(${(index - currentJobIndex) * 100}%)`,
                      opacity: index === currentJobIndex ? 1 : 0,
                      pointerEvents: index === currentJobIndex ? 'auto' : 'none',
                      transition: 'transform 0.5s ease, opacity 0.5s ease',
                    }}
                    message={messages[currentJobIndex % messages.length]}
                    key={`${job.first_name}-${job.last_initial}-${index}`}
                    onCTAClick={handleCTAClick}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section
        id="features"
        className="w-full max-w-[90rem] mx-auto md:pt-24 pb-8 lg:pt-32 lg:pb-10 relative z-10 px-6"
      >
        <h2 className="text-5xl font-bold text-center text-gray-800 mb-16">Supercharge Your Job Search</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <FeatureCard
            icon={<Zap className="w-16 h-16" />}
            title="Auto apply to 1000's of jobs"
            description="Apply to thousands of jobs in minutes, not hours - no more endless scrolling & clicking"
          />
          <FeatureCard
            icon={<Target className="w-16 h-16" />}
            title="Instant AI resume conversions - no more reformatting for ATS"
            description="We use the latest generative-ai technology to convert your resume that beats 95% of ATS systems"
          />
          <FeatureCard
            icon={<Rocket className="w-16 h-16" />}
            title="Job Aggregation & Tracking Tools"
            description="We update 100,000's of jobs per week from every major job board, so you don't have to go to multiple places"
          />
        </div>
        <div className="flex justify-end p-6 mt-6">
          <Button
            onClick={handleCTAClick}
            className="bg-green-400 text-black text-nowrap hover:from-green-600 hover:to-blue-600 px-10 py-5 text-xl font-semibold shadow-lg hover:shadow-xl"
          >
            Land a job now →
          </Button>
        </div>
      </section>

      {/* Platform Tutorial Section */}
      <section className="w-full max-w-[90rem] mx-auto relative z-10 px-6 pb-16">
        <div className="max-w-4xl mx-auto p-6">
          <h2 className="text-4xl font-bold text-black mb-8">Platform Tutorial</h2>
          <div className="relative w-full pt-[56.25%] mb-12">
            <iframe
              src="https://www.youtube.com/embed/BfXKvUXstcg"
              title="Platform Tutorial"
              className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </section>


      {/* Job Search Portal Section */}
      <section className="w-full max-w-[108rem] mx-auto px-4 mb-16 sticky top-0 z-50 bg-[#29BA79] bg-opacity-60 rounded-lg shadow-lg">
        <h2 className="text-4xl font-bold text-black mb-8"></h2>
        <JobSearchPortal />
      </section>
      {/* Commented Out Sections */}
      {/*<section id="pricing" className="w-full max-w-6xl mx-auto py-16 px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-3">Choose Your Career Accelerator</h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Join over 1000+ professionals who are hacking the system and getting jobs 350% faster!
        </p>
      </section>*/}
    </div>
  );
};
