import React, { createContext, useCallback, useContext, useState } from 'react';
import { setShowToastFunction } from '../../../utilities/toastService';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const addToast = useCallback(
    (toast) => {
      const { message } = toast;
      setToasts((prevToasts) => {
        const newId = `toast-${message.description.split(' ').join('')}`;

        // Check for existing toast with the same message and type
        const isDuplicate = prevToasts?.some((t) => t?.id === newId);

        if (isDuplicate) {
          // If duplicate exists, do not add the new toast
          return prevToasts;
        }

        // If no duplicate, add the new toast
        return [...prevToasts, { id: newId, ...toast }];
      });
    },
    [setToasts],
  );

  const showToast = useCallback(
    (messageData, type = 'info', { persistent = false, duration = 5000, ...restOptions } = {}) => {
      const message = typeof messageData === 'string' ? { description: messageData } : messageData;

      addToast({
        message,
        type,
        persistent,
        duration,
        ...restOptions,
      });
    },
    [addToast],
  );

  const removeToast = (id) => {
    console.log('Removing toast with id: ', id);
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Set the showToast function globally when the provider is initialized
  React.useEffect(() => {
    setShowToastFunction(showToast);
  }, [showToast]);

  return <ToastContext.Provider value={{ toasts, showToast, removeToast }}>{children}</ToastContext.Provider>;
};

export const useToastContext = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToastContext must be used within a ToastProvider');
  }
  return context;
};
