import React from 'react';
import { NavLink, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { HeaderBase } from '../../base/HeaderBase/HeaderBase';
import { Button } from '../../base/Button/Button';
import { signIn, signOut } from '../../../api/supabaseClient';
import { useHeaderContext } from '../../contexts/useHeaderContext/useHeaderContext';
import Badge from '../../Badge';
import { Bot, FileText, Briefcase, Rocket } from 'lucide-react';

const SITE_CONFIG = {
  primary: {
    name: 'Primary',
    color: 'bg-[#B0EDF1A3]',
    textColor: 'text-gray-800',
    hoverColor: 'hover:bg-[#A0C9D1A3]',
    gradient: 'from-[#B0EDF1A3] to-[#A0C9D1A3]',
    hoverGradient: 'hover:from-[#A0C9D1A3] hover:to-[#90B9C1A3]'
  },
  success: {
    name: 'Success',
    color: 'bg-emerald-100',
    textColor: 'text-emerald-800',
    hoverColor: 'hover:bg-emerald-50',
    gradient: 'from-emerald-400 to-teal-500',
    hoverGradient: 'hover:from-emerald-500 hover:to-teal-600'
  },
  feature: {
    name: 'Feature',
    color: 'bg-teal-100',
    textColor: 'text-teal-800',
    hoverColor: 'hover:bg-teal-50',
    gradient: 'from-teal-400 to-cyan-500',
    hoverGradient: 'hover:from-teal-500 hover:to-cyan-600'
  },
  accent: {
    name: 'Accent',
    color: 'bg-cyan-100',
    textColor: 'text-cyan-800',
    hoverColor: 'hover:bg-cyan-50',
    gradient: 'from-cyan-400 to-blue-500',
    hoverGradient: 'hover:from-cyan-500 hover:to-blue-600'
  }
};

const links = [
  {
    href: '/resume',
    text: 'AI Resume Builder',
    key: 'ai-resume-builder',
    type: 'jobType',
    icon: FileText,
    requiresAuth: true,
    style: SITE_CONFIG.primary
  },
  {
    href: '/jobs',
    text: '1m+ Jobs',
    key: 'jobs',
    type: 'jobType',
    icon: Briefcase,
    requiresAuth: false,
    style: SITE_CONFIG.feature
  },
  {
    href: '/auto-apply',
    text: 'Auto Apply',
    key: 'auto-apply',
    type: 'technologies',
    icon: Rocket,
    requiresAuth: true,
    style: SITE_CONFIG.success
  },
  {
    href: '/copilot',
    text: 'Interview Copilot',
    key: 'interview-copilot',
    type: 'experienceLevel',
    icon: Bot,
    requiresAuth: true,
    style: SITE_CONFIG.accent
  }
];

export const Header = ({
  userId = null,
  userProfile = null,
  onShowPricingModal,
  className,
  variant = 'default',
}) => {
  const {
    avatar_url = './favicon-96x96.png',
    username = null,
    email = null,
    job_title = 'Job Seeker',
    credits = 0,
  } = userProfile || {};

  const { isMenuOpen } = useHeaderContext();
  const navigate = useNavigate();

  const handleAuthRequiredClick = (e, path) => {
    e.preventDefault();
    if (!userId) {
      signIn();
    } else {
      navigate(path);
    }
  };

  const rootClassName = twMerge(
    'sticky top-0 z-50 grid grid-cols-[auto_1fr_auto] items-center gap-4 p-4 bg-white shadow-sm border-b border-gray-200',
    className,
  );

  const isDashboard = variant === 'dashboard';
  const isAuthenticated = !!userId;

  return (
    <HeaderBase.Root className={rootClassName}>
      <HeaderBase.Logo />
      <HeaderBase.Nav
        className="flex flex-nowrap flex-shrink gap-4 justify-end items-center"
        userId={userId}
        credits={credits}
        {...userProfile}
      >
        <div className="hidden sm:flex gap-4 items-center">
          {links.map((link) => (
            <NavLink
              key={link.key}
              to={link.href}
              onClick={(e) => link.requiresAuth && handleAuthRequiredClick(e, link.href)}
              className={({ isActive }) =>
                `${isAuthenticated ? 'p-2' : 'px-3 py-1.5'} rounded-lg transition-all duration-200 
                 ${link.style.color} ${link.style.textColor} ${link.style.hoverColor} 
                 ${isActive ? 'ring-2 ring-offset-2 ring-current' : ''}`
              }
            >
              <div className="flex items-center space-x-2">
                <link.icon className="w-4 h-4" />
                {(!isAuthenticated || !link.requiresAuth) && <span>{link.text}</span>}
              </div>
            </NavLink>
          ))}

          {isAuthenticated && !isDashboard && (
            <NavLink
              to={'/dashboard'}
              className={`p-2 bg-gradient-to-r ${SITE_CONFIG.success.gradient} text-white 
                text-sm rounded-lg border-none ${SITE_CONFIG.success.hoverGradient} 
                transition-all duration-300 hover:shadow-md`}
            >
              Dashboard
            </NavLink>
          )}

          {isAuthenticated ? (
            <Button
              onClick={() => onShowPricingModal(true)}
              className={`p-2 bg-gradient-to-r ${SITE_CONFIG.feature.gradient} 
                text-white text-sm rounded-lg border-none ${SITE_CONFIG.feature.hoverGradient} 
                transition-all duration-300 hover:shadow-md`}
            >
              Credits
            </Button>
          ) : (
            <Button
              onClick={() => onShowPricingModal(true)}
              className={`px-4 py-2 bg-[#B0EDF1A3] text-gray-800
                text-sm rounded-lg border-none hover:bg-[#A0C9D1A3]
                transition-all duration-300 hover:shadow-md`}
            >
              Pricing
            </Button>
          )}
        </div>

        <div className="block sm:hidden"></div>

        <div className={`flex gap-2 justify-self-end`}>
          {!isAuthenticated && (
            <Button
              onClick={signIn}
              className={`hidden md:block px-4 py-2 bg-[#B0EDF1A3] text-gray-800 rounded-lg
                border-none hover:bg-[#A0C9D1A3] transition-all duration-300 hover:shadow-md`}
            >
              <span className="text-md text-nowrap">Sign In</span>
            </Button>
          )}

          {isAuthenticated && (
            <HeaderBase.Profile
              className={'hidden md:block shadow-sm-md'}
              handleLogOut={signOut}
              {...userProfile}
            />
          )}

          <HeaderBase.MobileMenuToggle
            isAuthenticated={!!userId}
            userId={userId}
            className={'shadow-sm-md'}
            {...userProfile}
          />
          {isMenuOpen && (
            <HeaderBase.MobileMenu
              userId={userId}
              avatarUrl={avatar_url}
              username={username}
              jobTitle={job_title}
              credits={credits}
            />
          )}
        </div>
      </HeaderBase.Nav>
    </HeaderBase.Root>
  );
};