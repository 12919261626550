import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { StripeSVG } from '../StripeSVG/StripeSVG';
import { Button } from '../../base/Button/Button';
import { signIn } from '../../../api/supabaseClient';
import { useNavigate } from 'react-router';

export const PricingUI = ({ isAuthenticated, pricingPlans, loading = null, onSubmit, onClose, error = null }) => {
  const navigate = useNavigate();
  const [selectedTier, setSelectedTier] = useState(0);

  const currentTier = pricingPlans[selectedTier];
  const total = Math.round(currentTier.credits * currentTier.pricePerApp);

  const handleKeyPress = (event) => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const handleFreeSignup = () => {
    navigate('/dashboard');
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-green-400 via-emerald-500 to-teal-600 p-2 sm:p-4 md:p-6 flex items-center justify-center">
      <div className="w-full max-w-3xl mx-auto relative overflow-hidden bg-white/90 backdrop-blur-md shadow-[0_8px_32px_rgba(31,38,135,0.37)] rounded-2xl">
        <div onKeyUp={handleKeyPress} className="relative z-10 p-3 sm:p-6 md:p-8">
          <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>

          <motion.div
            className="flex items-center justify-center gap-2 mb-4 sm:mb-6"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl sm:text-3xl md:text-4xl pt-4 sm:pt-0 font-bold bg-gradient-to-r from-green-600 to-emerald-500 bg-clip-text text-transparent text-center">
              RoboApply Credit Pricing
            </h2>
          </motion.div>

          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="mb-6 p-4 rounded-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-lg"
          >
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <div className="text-center sm:text-left">
                <h3 className="text-xl sm:text-2xl font-bold mb-2">Start Free Today!</h3>
                <ul className="text-sm space-y-1">
                  <li className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span>20 apps on us!</span>
                  </li>
                </ul>
              </div>
              <button
                onClick={handleFreeSignup}
                className="px-4 py-1.5 text-sm bg-white text-purple-600 hover:bg-purple-50 font-semibold rounded-lg shadow transition-all duration-300"
              >
                Try Free
              </button>
            </div>
          </motion.div>

          <motion.div
            className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-4 sm:mb-6"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {pricingPlans.map((tier, index) => (
              <motion.button
                key={tier.credits}
                onClick={() => setSelectedTier(index)}
                className={`p-2 sm:p-3 rounded-lg transition-all duration-300 text-sm sm:text-base ${selectedTier === index
                  ? 'bg-gradient-to-br from-green-500 to-emerald-600 text-white shadow-lg scale-105'
                  : 'bg-green-100 hover:bg-green-200 text-green-700'
                  }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {tier.credits}
              </motion.button>
            ))}
          </motion.div>

          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTier}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="relative p-4 sm:p-6 rounded-xl bg-gradient-to-br from-green-100 via-white to-emerald-100 border border-green-200 shadow-inner mb-4 sm:mb-6"
            >
              <div className="flex flex-row flex-nowrap justify-between items-baseline mb-4 sm:mb-6">
                <div className="flex items-baseline gap-1.5 mb-2 sm:mb-0">
                  <span className="text-2xl sm:text-3xl md:text-6xl font-bold text-green-700">
                    {currentTier.credits}
                  </span>
                  <span className="text-lg sm:text-xl md:text-2xl text-green-600">Applications</span>
                </div>
                <div className="text-lg sm:text-xl md:text-2xl font-semibold text-emerald-600">
                  ${currentTier.pricePerApp.toFixed(2)}/app
                </div>
              </div>

              <ul className="space-y-2 sm:space-y-4">
                {currentTier.features.map((feature, index) => (
                  <motion.li
                    key={index}
                    className="flex items-center gap-2 sm:gap-3"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <div className="rounded-full p-1 bg-green-200">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 sm:h-5 sm:w-5 text-green-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-green-800 text-sm sm:text-base md:text-lg">{feature}</span>
                  </motion.li>
                ))}
              </ul>

              {!isAuthenticated && (
                <motion.div className="mt-5 text-left">
                  <span className="text-2xl md:text-3xl text-green-600 font-bold">
                    Total: ${total.toFixed(2)}
                  </span>
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>

          <motion.div className="w-full" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="rounded-lg bg-red-50 p-4 border border-red-200 mb-4"
              >
                <p className="text-red-500 text-center">{error}</p>
              </motion.div>
            )}
            <Button
              onClick={() => (isAuthenticated ? onSubmit(currentTier) : signIn())}
              disabled={loading}
              loading={loading}
              className="w-full text-base sm:text-lg md:text-xl h-12 sm:h-14 md:h-16 bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition-all duration-300 group overflow-hidden relative"
            >
              {isAuthenticated && (
                <span className="relative z-10 flex items-center justify-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 sm:h-6 sm:w-6 group-hover:rotate-6 transition-transform"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                  <span>Checkout ${total.toFixed(0)}</span>
                </span>
              )}
              {!isAuthenticated && <span>Sign up now to get started!</span>}
            </Button>
          </motion.div>

          <motion.div
            className="text-xs sm:text-sm text-center text-green-700 flex items-center justify-center gap-0.5 mt-4"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <span className="font-light">Powered By</span>
            <StripeSVG className="w-10 p-0 -mb-[3px]" />
          </motion.div>
        </div>
      </div>
    </div>
  );
};