import { motion } from 'framer-motion';
import { AlertCircle, AlertTriangle, Check, Info, RefreshCw, X } from 'lucide-react';
import React, { forwardRef } from 'react';

const toastTypes = {
  success: {
    icon: Check,
    className: 'bg-green-500 text-white',
    progressClassName: 'bg-green-400',
  },
  error: {
    icon: AlertCircle,
    className: 'bg-red-500 text-white',
    progressClassName: 'bg-red-400',
  },
  warning: {
    icon: AlertTriangle,
    className: 'bg-yellow-500 text-white',
    progressClassName: 'bg-yellow-400',
  },
  info: {
    icon: Info,
    className: 'bg-blue-500 text-white',
    progressClassName: 'bg-blue-400',
  },
  loading: {
    icon: RefreshCw,
    className: 'bg-purple-500 text-white',
    progressClassName: 'bg-purple-400',
  },
};

export const Toast = forwardRef(
  ({ message, type = 'info', onClose, duration = 5000, persistent = false }, forwardedRef) => {
    const { icon: Icon, className, progressClassName } = toastTypes[type] || toastTypes.info;

    const title = typeof message === 'object' ? message.title : '';
    const description = typeof message === 'object' ? message.description : message;

    return (
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
        className={`fixed bottom-4 right-4 flex flex-col w-96 shadow-lg rounded-lg overflow-hidden ${className}`}
        layout
        ref={forwardedRef}
      >
        <div className="flex items-start p-4">
          <div className="flex-shrink-0 pt-0.5">
            {type === 'loading' ? (
              <motion.div animate={{ rotate: 360 }} transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}>
                <Icon className="h-6 w-6" />
              </motion.div>
            ) : (
              <Icon className="h-6 w-6" />
            )}
          </div>

          <div className="ml-3 w-0 flex-1">
            {title && <p className="text-sm font-medium">{title}</p>}
            <p className="mt-1 text-sm">{description}</p>
          </div>

          <button onClick={onClose} className="ml-4 flex-shrink-0 flex">
            <X className="h-5 w-5" />
          </button>
        </div>

        {!persistent && (
          <motion.div
            className={`h-1 ${progressClassName}`}
            initial={{ width: '100%' }}
            animate={{ width: '0%' }}
            transition={{ duration: duration / 1000, ease: 'linear' }}
            onAnimationComplete={onClose}
          />
        )}
      </motion.div>
    );
  },
);
