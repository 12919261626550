import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();
export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalClassName, setModalClassName] = useState(null);
  const [modalContainerClassName, setModalContainerClassName] = useState(null);
  const [modalDestination, setModalDestination] = useState(null);

  const openModal = () => {
    setIsOpen(true);
    return true;
  };

  const closeModal = () => {
    setIsOpen(false);
    return true;
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        openModal,
        closeModal,
        modalContent,
        setModalContent,
        modalClassName,
        setModalClassName,
        modalContainerClassName,
        setModalContainerClassName,
        modalDestination,
        setModalDestination,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
export const useModalContext = () => useContext(ModalContext);
