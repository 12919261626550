import React, { useEffect } from 'react';
import { useModalContext } from '../../contexts/useModalContext/useModalContext';
import { twMerge } from 'tailwind-merge';
import * as Portal from '@radix-ui/react-portal';
import { X } from 'lucide-react';
import { AnimatePresence, motion } from 'framer-motion';
//The New Modal is Below but is a WIP. Using old for now
const NewModal = ({
  className = '',
  containerClassName = '',
  children,
  open = false,
  onOpenChange,
  modalDestination,
}) => {
  const {
    onClose = () => {},
    isOpen,
    setIsOpen,
    setModalContent,
    setModalClassName,
    setModalContainerClassName,
    setModalDestination,
    hasXButton = true,
  } = useModalContext();

  useEffect(() => {
    setIsOpen(open);
    onOpenChange?.(open);
  }, [open, onOpenChange]);

  useEffect(() => {
    setModalClassName(className);
    setModalContainerClassName(containerClassName);
    setModalDestination(modalDestination);
    setModalContent(children);
  }, [className, containerClassName, modalDestination, children]);

  if (!isOpen || !children) return null;
  if (open) {
    console.log('Modal open with children: ', children.type.name);
  }
  return (
    <Portal.Root container={modalDestination}>
      <div
        className={twMerge(
          'robo-modal fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center',
          containerClassName,
        )}
        onClick={() => setIsOpen(false)}
      >
        <div className={twMerge('bg-white p-8 rounded-xl shadow-2xl max-w-md w-full', className)}>
          {hasXButton && (
            <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          )}
          {children}
        </div>
      </div>
    </Portal.Root>
  );
};

//Old Modal comp:

export const Modal = ({ isOpen, onClose, children, className, scrimClassname, hasXButton = true, style }) => {
  const handleKeyPress = (event) => {
    // if pressed key is esc then close Modal
    if (event.key === 'Escape') {
      console.log('Escape key pressed');
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={twMerge(
            'fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center md:p-4',
            scrimClassname,
          )}
          onClick={onClose}
          style={style}
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className={twMerge(' shadow-2xl w-full max-w-fit overflow-y-auto', className)}
            onClick={(e) => e.stopPropagation()}
            onKeyUp={handleKeyPress}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
