// src/utils/description-utils.js
import { marked } from 'marked';
import { Star, Globe } from 'lucide-react'
// Configuration for description patterns
export const PATTERNS = {
    sections: {
        headers: /(?:^|\n)([A-Z][A-Z\s]+):\s*/g,
        subsections: /(?:^|\n)([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*):\s*/g
    },
    highlights: {
        experience: /(\d+\+?\s*(?:-\s*\d+)?\s+years?\s+(?:of\s+)?experience)/gi,
        tech: /\b(JavaScript|TypeScript|Python|Java|Kotlin|Swift|PHP|Ruby|Go|Rust|C\+\+|C#|Scala|R|React|Next\.js|Angular|Vue|Svelte|Node\.js|Express|Django|Flask|Spring|Laravel|Rails|HTML|CSS|SASS|LESS|Tailwind|Bootstrap|Material-UI|React Native|Flutter|iOS|Android|SwiftUI|Xamarin|AWS|Azure|GCP|Google Cloud|Terraform|Ansible|Jenkins|CircleCI|GitHub Actions|Docker|Kubernetes|ECS|Lambda|Serverless|CloudFront|S3|EC2|RDS|SQL|MySQL|PostgreSQL|MongoDB|DynamoDB|Redis|Elasticsearch|Cassandra|Oracle|REST|GraphQL|gRPC|WebSocket|OAuth|JWT|Git|GitHub|GitLab|Bitbucket|Jira|Confluence|Jest|Mocha|Cypress|Selenium|JUnit|pytest|TensorFlow|PyTorch|Pandas|NumPy|scikit-learn|Hadoop|Spark|Kafka|Airflow|API|CI\/CD|TDD|Agile|Scrum|Microservices|Architecture|Full[ -]Stack|Redux|Webpack|Babel|npm|yarn|Maven|Gradle)\b/g,
        salary: /(?:\$\d{1,3}(?:,\d{3})*(?:\.\d{2})?|\d{1,3}k|\d{2,3},\d{3}|\d{1,3})(?:\s*-\s*(?:\$\d{1,3}(?:,\d{3})*(?:\.\d{2})?|\d{1,3}k|\d{2,3},\d{3}|\d{1,3}))?\s*(?:\/\s*(?:hr|hour|yr|year|annual|annually)|k?\s*(?:per\s*(?:hour|year|annum))?)/gi,
        jobType: /\b(Full[ -]Time|Part[ -]Time|Contract|Temporary|Permanent|Freelance)\b/gi,
        location: /\b([A-Z][a-z]+(?:[\s-][A-Z][a-z]+)*,\s*[A-Z]{2})\b/g
    }
};

// Function to extract and aggregate parsed labels
export const parseJobDescription = (description) => {
    const parsedInfo = {
        experienceLevel: null,
        workplaceType: null,
        technologies: [],
        certifications: [],
        languages: [],
        additionalLabels: []
    };

    if (!description) return parsedInfo;

    // Extract Experience Level
    const experienceMatch = description.match(PATTERNS.highlights.experience);
    if (experienceMatch) {
        parsedInfo.experienceLevel = experienceMatch[0];
    }

    // Extract Technologies
    const techMatches = description.match(PATTERNS.highlights.tech);
    if (techMatches) {
        parsedInfo.technologies = Array.from(new Set(techMatches));
    }

    // Extract Salary
    const salaryMatch = description.match(PATTERNS.highlights.salary);
    if (salaryMatch) {
        parsedInfo.salary = salaryMatch[0];
    }

    // Extract Job Type
    const jobTypeMatch = description.match(PATTERNS.highlights.jobType);
    if (jobTypeMatch) {
        parsedInfo.jobType = Array.from(new Set(jobTypeMatch));
    }

    // Extract Location
    const locationMatch = description.match(PATTERNS.highlights.location);
    if (locationMatch) {
        parsedInfo.location = Array.from(new Set(locationMatch));
    }

    // Additional Parsing for Certifications
    const certifications = /Certified\s+[A-Z][A-Za-z\s]+/g;
    const certMatches = description.match(certifications);
    if (certMatches) {
        parsedInfo.certifications = Array.from(new Set(certMatches));
    }

    // Extract Languages
    const languages = /Languages?:\s*([A-Za-z, ]+)/i;
    const langMatch = description.match(languages);
    if (langMatch && langMatch[1]) {
        const langList = langMatch[1].split(',').map(lang => lang.trim());
        parsedInfo.languages = Array.from(new Set(langList));
    }

    // Extract Workplace Preferences
    const workplacePreferences = /(?:Workplace|Environment):\s*([A-Za-z, ]+)/i;
    const workplaceMatch = description.match(workplacePreferences);
    if (workplaceMatch && workplaceMatch[1]) {
        const prefs = workplaceMatch[1].split(',').map(pref => pref.trim());
        parsedInfo.workplaceType = Array.from(new Set(prefs));
    }

    // Aggregate All Labels into additionalLabels
    // Certifications
    parsedInfo.certifications.forEach(cert => {
        parsedInfo.additionalLabels.push({
            type: 'certification',
            icon: Star, // Replace with appropriate icon
            text: cert
        });
    });

    // Languages
    parsedInfo.languages.forEach(lang => {
        parsedInfo.additionalLabels.push({
            type: 'language',
            icon: Globe, // Replace with appropriate icon
            text: lang
        });
    });

    // Workplace Preferences
    if (parsedInfo.workplaceType) {
        parsedInfo.workplaceType.forEach(pref => {
            parsedInfo.additionalLabels.push({
                type: 'workplaceType',
                icon: Globe, // Replace with appropriate icon
                text: pref
            });
        });
    }

    return parsedInfo;
};

// Format job description
export const formatJobDescription = (description) => {
    if (!description) return '';

    let formattedDesc = description;

    // Format section headers
    formattedDesc = formattedDesc.replace(PATTERNS.sections.headers, '\n\n### $1\n');
    formattedDesc = formattedDesc.replace(PATTERNS.sections.subsections, '\n\n#### $1\n');

    // Format lists
    formattedDesc = formattedDesc
        .replace(/(?:^|\n)•\s*/g, '\n- ')
        .replace(/(?:^|\n)[\-\*]\s+/g, '\n- ')
        .replace(/(?:^|\n)(?:\d+\.|\w\.)\s+/g, '\n- ');

    // Highlight important information
    formattedDesc = formattedDesc
        .replace(PATTERNS.highlights.experience, '**$1**')
        .replace(PATTERNS.highlights.tech, '`$1`')
        .replace(PATTERNS.highlights.salary, '**$&**')
        .replace(PATTERNS.highlights.jobType, '**$1**')
        .replace(PATTERNS.highlights.location, '**$1**');

    // Clean up multiple newlines
    formattedDesc = formattedDesc.replace(/\n{3,}/g, '\n\n');

    return formattedDesc;
};

// Format salary for display
export const formatSalary = (salary) => {
    if (!salary) return null;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
    });

    if (typeof salary === 'number') {
        return formatter.format(salary);
    }

    const numbers = salary.match(/\d+/g);
    if (!numbers) return salary;

    if (numbers.length === 2) {
        return `${formatter.format(numbers[0])} - ${formatter.format(numbers[1])}`;
    }
    return formatter.format(numbers[0]);
};

// React component to render formatted description
export const FormattedJobDescription = ({ description, className = '' }) => {
    const formattedContent = formatJobDescription(description);

    return (
        <div className={`prose max-w-none ${className}`}>
            <div
                className="markdown-content"
                dangerouslySetInnerHTML={{
                    __html: marked(formattedContent)
                }}
            />
        </div>
    );
};

export default {
    formatJobDescription,
    formatSalary,
    FormattedJobDescription,
    PATTERNS,
    parseJobDescription
};
