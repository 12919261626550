// Memoized PricingCard component
import React, { useCallback, useState } from 'react';
import { useAuth } from '../../contexts/useAuth/useAuth';
import { useFetcher, useNavigate } from 'react-router';
import { PricingUI } from './PricingUI';
import { handleError } from '../../../utilities/handleError';
import { signIn } from '../../../api/supabaseClient';
import { purchaseCreditsAction } from '../../../router/routerActions';
import ReactConfetti from 'react-confetti';

const pricingPlans = [
  {
    sku: 'fifty_credits',
    credits: 50,
    pricePerApp: 0.2,
    features: [
      'You want to jump start your job search',
      '50 applications to use anytime',
      'AI powered job matching',
      'Unused credits carry over',
    ],
  },
  {
    sku: 'one_hundred_credits',
    credits: 100,
    pricePerApp: 0.2,
    features: [
      'You want to interview next week',
      '100 applications to use anytime',
      'AI powered job matching',
      'Unused credits carry over',
    ],
  },
  {
    sku: 'five_hundred_credits',
    credits: 500,
    pricePerApp: 0.18,
    features: [
      'Urgent hiring: Interview within 72 hours',
      '500 applications to use anytime',
      'AI powered job matching',
      'Unused credits carry over',
    ],
  },
  {
    sku: 'one_thousand_credits',
    credits: 1000,
    pricePerApp: 0.16,
    features: [
      'Emergency hiring: Next-day interviews',
      '1000 applications to use anytime',
      'AI powered job matching',
      'Unused credits carry over',
    ],
  },
];

export const PricingCard = React.memo(({ userId: userIdProp = null, onClose = () => { }, onSuccess = () => { } }) => {
  const auth = useAuth();
  const [selectedPlan, setSelectedPlan] = useState('three_day_trial');
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const userId = userIdProp || auth?.session?.user?.id;
  const fetcher = useFetcher();

  const handlePurchase = useCallback(
    async (selectedTier) => {
      setIsLoading(true);
      const checkoutUserId = userId || localStorage.getItem('userId');
      if (!checkoutUserId) {
        // setIsSpinning(false);

        const res = await signIn();
        //TODO: call signIn and then open pricing modal. Will probably require changing the handleSignIn in the auth subscriber to accept a callback or something
        if (res) {
          console.log('*Sign in response*: ', res);
        }
        return;
      }

      const { sku = null } = selectedTier || {};
      if (!sku) {
        handleError('No SKU provided');
        return;
      }
      setSelectedPlan(selectedTier);

      console.log('handlePurchase Callback: ', sku);

      const response = await purchaseCreditsAction({
        actionType: 'purchaseCredits',
        sku,
        userId: checkoutUserId,
      });
      if (response) {
        console.log('fetcherRes: ', response);
      }

      if (response?.error) {
        console.log('fetcherRes ERROR: ', response);
        handleError(response.error);
      }
      if (response?.data?.checkout_url) {
        console.log('Navigating to response.data.checkout_url: ', response.data.checkout_url);
        setTimeout(() => {
          window.location.href = response.data.checkout_url;
        }, 0); // Ensures navigation happens after other state updates
      }
    },
    [userId],
  );

  return (
    <>
      {showConfetti && <ReactConfetti numberOfPieces={500} recycle={false} gravity={0.2} />}

      <PricingUI
        isAuthenticated={!!userId}
        error={fetcher.data?.error}
        loading={loading}
        onSubmit={handlePurchase}
        onClose={onClose}
        pricingPlans={pricingPlans}
      />
    </>
  );
});

PricingCard.displayName = 'PricingCard';
