import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react'; //TODO: These are being deprecated. Find a better alternative.
import React from 'react';

export const Footer = () => {
  <footer className="w-full bg-gray-100 py-12 px-4 relative z-10">
    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
      <div>
        <h3 className="text-lg font-semibold mb-4">RoboApply.ai</h3>
        <p className="text-gray-600">Empowering careers with AI.</p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-4">Product</h3>
        <ul className="space-y-2">
          <li>
            <a href="#features" className="text-gray-600 hover:text-gray-900">
              Features
            </a>
          </li>
          <li>
            <a href="#jobs" className="text-gray-600 hover:text-gray-900">
              Jobs
            </a>
          </li>
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              FAQ
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-4">Company</h3>
        <ul className="space-y-2">
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              About Us
            </a>
          </li>
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Careers
            </a>
          </li>
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-4">Legal</h3>
        <ul className="space-y-2">
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Terms of Service
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="max-w-6xl mx-auto mt-8 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
      <p className="text-gray-600">&copy; 2023 RoboApply.ai. All rights reserved.</p>
      <div className="flex space-x-4 mt-4 md:mt-0">
        <a href="#" className="text-gray-600 hover:text-gray-900">
          <Facebook size={24} />
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
          <Twitter size={24} />
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
          <Linkedin size={24} />
        </a>
        <a href="#" className="text-gray-600 hover:text-gray-900">
          <Instagram size={24} />
        </a>
      </div>
    </div>
  </footer>;
};
