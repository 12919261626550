import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

// Reusable button component for pagination
const PaginationButton = ({ children, onClick, active, disabled, className = '', ariaLabel }) => (
    <motion.button
        whileHover={!disabled ? { scale: 1.02 } : {}}
        whileTap={!disabled ? { scale: 0.98 } : {}}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
        aria-current={active ? 'page' : undefined}
        className={`relative inline-flex items-center px-4 py-2 text-sm font-medium 
      transition-colors duration-200 ${active
                ? 'z-10 bg-blue-600 border-blue-600 text-white'
                : disabled
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
            } ${className}`}
    >
        {children}
    </motion.button>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        const pages = [];
        const showEllipsis = totalPages > 7;

        if (!showEllipsis) {
            // Show all pages if total pages <= 7
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Complex pagination with ellipsis
            if (currentPage <= 3) {
                for (let i = 1; i <= 4; i++) pages.push(i);
                pages.push('...');
                pages.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1);
                pages.push('...');
                for (let i = totalPages - 3; i <= totalPages; i++) pages.push(i);
            } else {
                pages.push(1);
                pages.push('...');
                for (let i = currentPage - 1; i <= currentPage + 1; i++) pages.push(i);
                pages.push('...');
                pages.push(totalPages);
            }
        }

        return pages;
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center justify-between py-3 border-t border-gray-200"
        >
            {/* Mobile pagination */}
            <div className="flex justify-between sm:hidden">
                <PaginationButton
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="rounded-md border"
                    ariaLabel="Previous page"
                >
                    Previous
                </PaginationButton>

                <PaginationButton
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="ml-3 rounded-md border"
                    ariaLabel="Next page"
                >
                    Next
                </PaginationButton>
            </div>

            {/* Desktop pagination */}
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700" role="status">
                        Showing page <span className="font-medium">{currentPage}</span> of{' '}
                        <span className="font-medium">{totalPages}</span>
                    </p>
                </div>

                <nav
                    className="inline-flex -space-x-px rounded-md shadow-sm isolate"
                    aria-label="Pagination"
                >
                    <PaginationButton
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="rounded-l-md border px-2"
                        ariaLabel="Previous page"
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                    </PaginationButton>

                    {getPageNumbers().map((page, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: index * 0.05 }}
                        >
                            {typeof page === 'number' ? (
                                <PaginationButton
                                    onClick={() => onPageChange(page)}
                                    active={page === currentPage}
                                    className="border"
                                    ariaLabel={`Page ${page}`}
                                >
                                    {page}
                                </PaginationButton>
                            ) : (
                                <span
                                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium 
                    text-gray-700 bg-white border border-gray-300"
                                    aria-hidden="true"
                                >
                                    ...
                                </span>
                            )}
                        </motion.div>
                    ))}

                    <PaginationButton
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="rounded-r-md border px-2"
                        ariaLabel="Next page"
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRight className="h-5 w-5" aria-hidden="true" />
                    </PaginationButton>
                </nav>
            </div>
        </motion.div>
    );
};

export default Pagination;