// import React, { useEffect, useState } from 'react';
// import { useFetcher, useLoaderData } from 'react-router';
// import CreateApplicationForm from '../concrete/CreateApplicationForm/CreateApplicationForm';
// import DashboardStats from '../DashboardStats';
// import ApplicationsGrid from '../ApplicationsGrid';
// import { useToastContext } from '../contexts/useToastContext/useToastContext';
// import { CampaignSuccessCard } from '../concrete/CampaignSuccessCard/CampaignSuccessCard';
// import { CreateProfileCard } from '../concrete/CreateProfileCard/CreateProfileCard';
// import { Modal } from '../Modals';
// import { FirstTimeCampaignModal } from '../concrete/FirstTimeFlow/FirstTimeFlow';
// import { getStoredIsNewUser, storeIsNewUser, storeLastCampaign, storeSession } from '../../api/localStorageClient';

// export const DashboardPage = () => {
//   const loaderData = useLoaderData();
//   const fetcher = useFetcher();
//   const { showToast, addToast } = useToastContext();
//   const [activelyApplying, setActivelyApplying] = useState(false);
//   const [successDetails, setSuccessDetails] = useState(null);
//   const [showSuccessModal, setShowSuccessModal] = useState(false);
//   const [showCreateProfileModal, setShowCreateProfileModal] = useState(false);
//   const [showFirstTimeCampaignModal, setShowFirstTimeCampaignModal] = useState(false);
//   const { user, userProfile, applicationData, lastCampaign } = loaderData;
//   const { applicationProgress, totalJobsApplied, applications, hasActiveCampaign, currentCampaign } = applicationData;

//   useEffect(() => {
//     const needsToCreateProfile = !userProfile?.username;
//     if (needsToCreateProfile) {
//       setShowCreateProfileModal(true);
//     }
//     if (userProfile?.username && userProfile?.dice_specific_email) {
//       setShowCreateProfileModal(false);
//     }
//   }, [userProfile]);

//   useEffect(() => {
//     if (fetcher.data) {
//       setActivelyApplying(false);
//       if (fetcher.data.error) {
//         showToast({ title: 'Error', description: fetcher.data.error }, 'error');
//       } else if (fetcher.data.success) {
//         setSuccessDetails({
//           jobType: fetcher.data.jobType,
//           numJobs: fetcher.data.numJobs,
//           location: fetcher.data.location,
//         });
//         setShowSuccessModal(true);
//       }
//     }
//   }, [fetcher.data, showToast]);

//   const handleSubmitApplication = async (formData) => {
//     setActivelyApplying(true);
//     storeIsNewUser(false);
//     fetcher.submit(formData, { method: 'post', action: '/dashboard' });
//   };

//   const handleSubmitCampaign = (event, formData) => {
//     if (!!currentCampaign) {
//       addToast({
//         message: {
//           title: 'You already have a campaign launched',
//           description: ' Please wait until that has completed.',
//         },
//         type: 'error',
//       });
//       return;
//     }
//     const campaign = {
//       ...formData,
//       timestamp: new Date().toISOString(),
//     };
//     storeLastCampaign(campaign);
//     handleSubmitApplication(formData);
//   };

//   // const handleCompleteProfile = (formData) => {
//   //   fetcher.submit({ ...formData, actionType: 'createProfile' }, { method: 'post', action: '/dashboard' });
//   // };

//   useEffect(() => {
//     // if (fetcher.data && fetcher.data.success && fetcher.data.actionType === 'createProfile') {
//     //   storeSession({ ...loaderData, userProfile: fetcher.data.userProfile });
//     // }
//     const userHasFinishedProfile = userProfile?.username && userProfile?.dice_specific_email;
//     const userIsNew = getStoredIsNewUser() === 'true';
//     if (userIsNew && userHasFinishedProfile && !lastCampaign && !activelyApplying && applications.length === 0) {
//       setShowFirstTimeCampaignModal(true);
//     }
//   }, [lastCampaign, activelyApplying, applications, user, userProfile]);

//   return (
//     <>
//       {lastCampaign && (
//         <div className="mb-8 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
//           <h3 className="text-lg font-semibold text-gray-800 mb-2">Last Campaign</h3>
//           <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//             <div>
//               <span className="text-gray-600">Job Type:</span>
//               <p className="font-medium">{lastCampaign?.job_type}</p>
//             </div>
//             <div>
//               <span className="text-gray-600">Location:</span>
//               <p className="font-medium">{lastCampaign?.location}</p>
//             </div>
//             <div>
//               <span className="text-gray-600">Applications:</span>
//               <p className="font-medium">{lastCampaign?.num_jobs}</p>
//             </div>
//             <div>
//               <button
//                 onClick={() => handleSubmitCampaign(lastCampaign)}
//                 className="text-blue-500 hover:text-blue-600 font-medium"
//               >
//                 Run Again
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
//         <Modal className={'w-min h-full max-w-md'} isOpen={showCreateProfileModal}>
//           <CreateProfileCard
//             user={user}
//             userProfile={userProfile}
//             // onComplete={handleCompleteProfile}
//             onClose={() => setShowCreateProfileModal(false)}
//           />
//         </Modal>
//         <Modal isOpen={showFirstTimeCampaignModal}>
//           <FirstTimeCampaignModal
//             isOpen={showFirstTimeCampaignModal}
//             onClose={() => {
//               setShowFirstTimeCampaignModal(false);
//               storeIsNewUser('false');
//             }}
//             onSubmit={handleSubmitCampaign}
//             currentResume={userProfile?.most_recent_resume_url}
//             loading={activelyApplying}
//             userProfile={userProfile}
//             userId={user?.id}
//             userEmail={userProfile?.dice_specific_email || user?.email}
//             dicePassword={userProfile?.dice_password}
//           />
//         </Modal>
//         {/*<CreateApplicationForm*/}
//         {/*  handleSubmit={handleSubmitCampaign}*/}
//         {/*  loading={fetcher.state !== 'idle'}*/}
//         {/*  activelyApplying={activelyApplying}*/}
//         {/*  userId={user?.id}*/}
//         {/*  most_recent_resume_link={userProfile?.most_recent_resume_url}*/}
//         {/*  userEmail={user?.email}*/}
//         {/*  diceEmail={userProfile?.dice_specific_email || user?.email}*/}
//         {/*  hasActiveCampaign={hasActiveCampaign}*/}
//         {/*/>*/}
//         <h2 className="text-2xl md:text-3xl text-center mt-4 lg:col-span-2 font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
//           Application Dashboard
//         </h2>
//         <ApplicationsGrid className={'lg:col-span-2'} applications={applications} />
//         <DashboardStats
//           activelyApplying={activelyApplying}
//           applicationProgress={applicationProgress}
//           totalJobsApplied={totalJobsApplied}
//           dataLoading={fetcher.state !== 'idle'}
//           dataError={fetcher.data?.error}
//           applicationData={applications}
//           userId={user?.id}
//         />
//         <CampaignSuccessCard
//           isOpen={showSuccessModal}
//           onClose={() => setShowSuccessModal(false)}
//           jobType={successDetails?.jobType}
//           numJobs={successDetails?.numJobs}
//           location={successDetails?.location}
//           hasCredits={userProfile?.credits > 0}
//         />
//       </div>
//     </>
//   );
// };

// // import React, { useEffect, useState, useMemo } from 'react';
// // import { useNavigate } from 'react-router';
// // import { useTable, useSortBy } from 'react-table';
// // import {
// //   PieChart,
// //   Pie,
// //   Cell,
// //   Tooltip,
// //   ResponsiveContainer,
// //   LineChart,
// //   Line,
// //   XAxis,
// //   YAxis,
// //   CartesianGrid,
// //   Legend,
// // } from 'recharts';
// // import { supabase } from '../../supabaseConfig'; // Adjust path as needed
// //
// // const DashboardPage = ({ user, credits, usedCredits }) => {
// //   const [applications, setApplications] = useState([]);
// //   const [successCount, setSuccessCount] = useState(0);
// //   const [failureCount, setFailureCount] = useState(0);
// //   const navigate = useNavigate();
// //
// //   // Fetch applicationsData when the component mounts
// //   useEffect(() => {
// //     const fetchApplications = async () => {
// //       const { data, error } = await supabase.from('applications').select('*').eq('user_id', user.id);
// //
// //       if (error) {
// //         console.error('Error fetching applicationsData:', error);
// //       } else {
// //         setApplications(data);
// //         const success = data.filter((app) => app.status === 'Successful').length;
// //         const failure = data.length - success;
// //         setSuccessCount(success);
// //         setFailureCount(failure);
// //       }
// //     };
// //
// //     if (user)
// //       fetchApplications(); // Ensure user exists before fetching
// //     else navigate('/'); // Redirect to homepage if user is not defined
// //   }, [user, navigate]);
// //
// //   const columns = useMemo(
// //     () => [
// //       {
// //         Header: 'Job Link',
// //         accessor: 'job_link',
// //         Cell: ({ value }) => (
// //           <a href={value} target="_blank" rel="noopener noreferrer" className="text-pink-300 underline">
// //             View Job
// //           </a>
// //         ),
// //       },
// //       { Header: 'Status', accessor: 'status' },
// //       { Header: 'Job Type', accessor: 'job_type' },
// //       {
// //         Header: 'Time Applied',
// //         accessor: 'time_applied',
// //         Cell: ({ value }) => new Date(value).toLocaleString(),
// //       },
// //     ],
// //     [],
// //   );
// //
// //   const data = applications || []; // Ensure data is defined even if applicationsData are empty
// //
// //   const tableInstance = useTable({ columns, data }, useSortBy);
// //   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
// //
// //   const creditData = [
// //     { name: 'Available', value: credits },
// //     { name: 'Used', value: usedCredits },
// //   ];
// //
// //   const COLORS = ['#00C49F', '#FF6384'];
// //
// //   const cumulativeData = applications.map((app, index) => ({
// //     name: `App ${index + 1}`,
// //     Total: index + 1,
// //   }));
// //
// //   return (
// //     <div className="min-h-screen p-8 bg-purple-900 text-white">
// //       <h1 className="text-5xl font-extrabold mb-8 text-pink-300 uppercase">Dashboard</h1>
// //
// //       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
// //         <div className="bg-purple-800 p-6 rounded-md shadow-lg">
// //           <h2 className="text-xl font-bold mb-4">Credit Usage</h2>
// //           <ResponsiveContainer width="100%" height={300}>
// //             <PieChart>
// //               <Pie data={creditData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} label>
// //                 {creditData.map((entry, index) => (
// //                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
// //                 ))}
// //               </Pie>
// //               <Tooltip />
// //               <Legend />
// //             </PieChart>
// //           </ResponsiveContainer>
// //         </div>
// //
// //         <div className="bg-purple-800 p-6 rounded-md shadow-lg">
// //           <h2 className="text-xl font-bold mb-4">Cumulative Applications</h2>
// //           <ResponsiveContainer width="100%" height={300}>
// //             <LineChart data={cumulativeData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
// //               <CartesianGrid strokeDasharray="3 3" />
// //               <XAxis dataKey="name" />
// //               <YAxis />
// //               <Tooltip />
// //               <Line type="monotone" dataKey="Total" stroke="#8884d8" />
// //             </LineChart>
// //           </ResponsiveContainer>
// //         </div>
// //       </div>
// //
// //       <div className="mt-12">
// //         <h2 className="text-2xl font-bold mb-4">Applications</h2>
// //         <table {...getTableProps()} className="w-full table-auto bg-purple-800 rounded-md shadow-lg">
// //           <thead>
// //             {headerGroups.map((headerGroup) => (
// //               <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-pink-500">
// //                 {headerGroup.headers.map((column) => (
// //                   <th
// //                     {...column.getHeaderProps(column.getSortByToggleProps())}
// //                     className="p-2 text-left uppercase text-pink-300 font-semibold"
// //                   >
// //                     {column.render('Header')}
// //                     <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
// //                   </th>
// //                 ))}
// //               </tr>
// //             ))}
// //           </thead>
// //           <tbody {...getTableBodyProps()}>
// //             {rows.map((row) => {
// //               prepareRow(row);
// //               return (
// //                 <tr {...row.getRowProps()} className="border-b border-purple-700 hover:bg-purple-700">
// //                   {row.cells.map((cell) => (
// //                     <td {...cell.getCellProps()} className="p-2">
// //                       {cell.render('Cell')}
// //                     </td>
// //                   ))}
// //                 </tr>
// //               );
// //             })}
// //           </tbody>
// //         </table>
// //       </div>
// //     </div>
// //   );
// // };
// //
// // export default DashboardPage;

import React, { useEffect, useState } from 'react';
import { useFetcher, useLoaderData } from 'react-router';
import CreateApplicationForm from '../concrete/CreateApplicationForm/CreateApplicationForm';
import DashboardStats from '../DashboardStats';
import ApplicationsGrid from '../ApplicationsGrid';
import { useToastContext } from '../contexts/useToastContext/useToastContext';
import { CampaignSuccessCard } from '../concrete/CampaignSuccessCard/CampaignSuccessCard';
import { CreateProfileCard } from '../concrete/CreateProfileCard/CreateProfileCard';
import { Modal } from '../base/Modal/Modal';
import { FirstTimeCampaignModal } from '../concrete/FirstTimeFlow/FirstTimeFlow';
import { getStoredIsNewUser, storeIsNewUser, storeLastCampaign, storeSession } from '../../api/localStorageClient';

export const DashboardPage = () => {
  const loaderData = useLoaderData();
  const fetcher = useFetcher();
  const { showToast, addToast } = useToastContext();
  const [activelyApplying, setActivelyApplying] = useState(false);
  const [successDetails, setSuccessDetails] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCreateProfileModal, setShowCreateProfileModal] = useState(false);
  const [showFirstTimeCampaignModal, setShowFirstTimeCampaignModal] = useState(false);
  const { user, userProfile, applicationData, lastCampaign } = loaderData;
  const { applicationProgress, totalJobsApplied, applications, hasActiveCampaign, currentCampaign } = applicationData;

  useEffect(() => {
    const needsToCreateProfile = !userProfile?.has_profile;
    if (needsToCreateProfile) {
      setShowCreateProfileModal(true);
    }
    if (userProfile?.has_profile && userProfile?.username && userProfile?.dice_specific_email) {
      setShowCreateProfileModal(false);
    }
  }, [userProfile]);

  useEffect(() => {
    if (fetcher.data) {
      setActivelyApplying(false);
      if (fetcher.data.error) {
        showToast({ title: 'Error', description: fetcher.data.error }, 'error');
      } else if (fetcher.data.success) {
        setSuccessDetails({
          jobType: fetcher.data.jobType,
          numJobs: fetcher.data.numJobs,
          location: fetcher.data.location,
        });
        setShowSuccessModal(true);
      }
    }
  }, [fetcher.data, showToast]);

  const handleSubmitApplication = async (formData) => {
    setActivelyApplying(true);
    storeIsNewUser('false');
    fetcher.submit(formData, { method: 'post', action: '/dashboard' });
  };

  const handleSubmitCampaign = (event, formData) => {
    if (!!currentCampaign) {
      addToast({
        message: {
          title: 'You already have a campaign launched',
          description: ' Please wait until that has completed.',
        },
        type: 'error',
      });
      return;
    }

    // Add the campaign timing notification
    addToast({
      message: {
        title: 'Campaign Started',
        description:
          'Your campaign has started. Please note that campaigns can take up to 40 minutes to complete, and data may take up to 5 minutes to appear. Thank you for your patience.',
      },
      type: 'info',
      duration: 10000, // Show for 10 seconds given the importance of the message
    });

    const campaign = {
      ...formData,
      timestamp: new Date().toISOString(),
    };
    storeLastCampaign(campaign);
    handleSubmitApplication(formData);
  };

  useEffect(() => {
    const hasBasicProfile = userProfile?.has_profile && userProfile?.username;
    const userIsNew = getStoredIsNewUser() === 'true';
    const isFirstTimeUser = !lastCampaign && !activelyApplying && applications.length === 0;

    if (userIsNew && hasBasicProfile && isFirstTimeUser) {
      setShowFirstTimeCampaignModal(true);
      // Force a re-render without full page refresh
      const event = new Event('forceUpdate');
      // fetcher.load('/dashboard');
      window.dispatchEvent(event);
    }
  }, [lastCampaign, activelyApplying, applications, user, userProfile]);
  return (
    <>
      {lastCampaign && (
        <div className="mb-8 p-4 bg-white rounded-lg shadow-sm border border-gray-200">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Last Campaign</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div>
              <span className="text-gray-600">Job Type:</span>
              <p className="font-medium">{lastCampaign?.job_type}</p>
            </div>
            <div>
              <span className="text-gray-600">Location:</span>
              <p className="font-medium">{lastCampaign?.location}</p>
            </div>
            <div>
              <span className="text-gray-600">Applications:</span>
              <p className="font-medium">{lastCampaign?.num_jobs}</p>
            </div>
            <div>
              <button
                onClick={() => handleSubmitCampaign(lastCampaign)}
                className="text-blue-500 hover:text-blue-600 font-medium"
              >
                Run Again
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <Modal className={''} isOpen={showCreateProfileModal}>
          <CreateProfileCard
            user={user}
            userProfile={userProfile}
            onComplete={() => {
              setShowCreateProfileModal(false);
              fetcher.load();
            }}
            onClose={() => setShowCreateProfileModal(false)}
          />
        </Modal>
        <Modal isOpen={showFirstTimeCampaignModal}>
          <FirstTimeCampaignModal
            isOpen={showFirstTimeCampaignModal}
            onClose={() => {
              setShowFirstTimeCampaignModal(false);
              storeIsNewUser('false');
              fetcher.load('/dashboard');
            }}
            onSubmit={handleSubmitCampaign}
            currentResume={userProfile?.most_recent_resume_url}
            loading={activelyApplying}
            userProfile={userProfile}
            userId={user?.id}
            userEmail={userProfile?.dice_specific_email || user?.email}
            dicePassword={userProfile?.dice_password}
          />
        </Modal>
        <h2 className="text-2xl md:text-3xl text-center mt-4 lg:col-span-2 font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
          Application Dashboard
        </h2>
        <ApplicationsGrid className={'lg:col-span-2'} applications={applications} />
        <DashboardStats
          activelyApplying={activelyApplying}
          applicationProgress={applicationProgress}
          totalJobsApplied={totalJobsApplied}
          dataLoading={fetcher.state !== 'idle'}
          dataError={fetcher.data?.error}
          applicationData={applications}
          userId={user?.id}
        />
        <CampaignSuccessCard
          isOpen={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          jobType={successDetails?.jobType}
          numJobs={successDetails?.numJobs}
          location={successDetails?.location}
          hasCredits={userProfile?.credits > 0}
        />
      </div>
    </>
  );
};
