import { Menu, Sparkle, X } from 'lucide-react';
import React, { forwardRef } from 'react';
import { Button } from '../../base/Button/Button';

export const HamburgerPill = forwardRef((props, forwardedRef) => {
  const { credits, isMenuOpen, onClick } = props;
  return (
    <Button
      ref={forwardedRef}
      className="hamburger-pill flex items-center gap-1 py-0 px-2 bg-green-100  cursor-pointer"
      onClick={onClick}
    >
      <div className="credit-count flex items-center gap-0.5 py-1 ">
        <span className="text-sm text-green-600 font-regular">{credits || 0}</span>
        <Sparkle className="w-4 h-4 text-green-700" />
      </div>
      <div className={'hamburger-menu-container border-l border-gray-300 py-0.5 text-green-950 pl-1'}>
        {isMenuOpen ? <X /> : <Menu />}
      </div>
    </Button>
  );
});
