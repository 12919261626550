// src/components/JobListItem.js
import React from 'react';
import {
    MapPin,
    Briefcase,
    Globe,
    DollarSign,
    Star,
    Share2,
    ExternalLink,
    Sparkles,
} from 'lucide-react';
import { motion } from 'framer-motion';
import { formatSalary } from '../utilities/description-utils';
import { supabase } from '../supabaseConfig';
import { SITE_CONFIG } from './SiteSelector';

const JobListItem = ({ job, isSelected, onClick, onShare }) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    // Grab any parsed info from the job
    const parsedInfo = job.parsedInfo || {};

    // Get site configuration based on job source
    const getSiteConfig = (sourceSite) => {
        const siteKey = sourceSite?.toLowerCase()?.replace(/[^a-z0-9]/g, '_');
        return SITE_CONFIG[siteKey] || {
            name: sourceSite?.toUpperCase() || 'Unknown',
            color: 'bg-gray-100',
            textColor: 'text-gray-600',
            borderColor: 'border-gray-300',
            hoverColor: 'hover:bg-gray-50',
            logo: '/favicon.ico'
        };
    };

    const siteConfig = getSiteConfig(job.source_site);

    // Handle Share Button Click
    const handleShareClick = (e) => {
        e.stopPropagation(); // Prevent triggering the parent onClick
        onShare?.(job);
    };

    // Handle Apply Now Button Click
    const handleApplyClick = (e) => {
        e.stopPropagation(); // Prevent triggering the parent onClick
        if (job?.job_url) {
            window.open(job.job_url, '_blank');
        }
    };

    // Handle Login with Supabase
    const handleLogin = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        try {
            const { error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: window.location.origin,
                }
            });
            if (error) throw error;
        } catch (error) {
            console.error('Error logging in:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            whileHover={{ scale: 1.01 }}
            onClick={() => onClick(job)}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            className={`relative bg-white cursor-pointer overflow-hidden
                ${isSelected ? 'border-l-4 border-l-blue-500' : ''} 
                border-b border-gray-200`}
        >
            {/* Background gradient effect on hover */}
            <motion.div
                className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5"
                initial={{ opacity: 0 }}
                animate={{ opacity: isHovered ? 1 : 0 }}
                transition={{ duration: 0.3 }}
            />

            <div className="relative p-4">
                {/* Company Logo and Title Section */}
                <div className="flex gap-4">
                    <div className="flex-shrink-0">
                        <img
                            src={job.company_logo || '/favicon-96x96.png'}
                            alt={job.company}
                            className="w-12 h-12 rounded-lg object-contain bg-gray-50"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/favicon-96x96.png';
                            }}
                        />
                    </div>

                    <div className="flex-grow">
                        <div className="mb-1">
                            <h3 className="text-lg font-semibold text-gray-900 line-clamp-2">
                                {job.title}
                            </h3>
                        </div>
                        <p className="text-md text-gray-700 mb-2">{job.company}</p>

                        {/* Location and basic info */}
                        <div className="flex flex-wrap gap-2 mb-2">
                            <span className="inline-flex items-center text-xs text-gray-500">
                                <MapPin className="w-3 h-3 mr-1" />
                                {[
                                    job.location?.city,
                                    job.location?.state,
                                    job.location?.country,
                                ]
                                    .filter(Boolean)
                                    .join(', ') || parsedInfo.location || 'Location not specified'}
                            </span>

                            {/* {(job.salary || parsedInfo.salary) && (
                                <span className="inline-flex items-center text-xs text-gray-500">
                                    <DollarSign className="w-3 h-3 mr-1" />
                                    {formatSalary(job.salary || parsedInfo.salary)}
                                </span>
                            )} */}
                        </div>

                        {/* Tags section */}
                        <div className="flex flex-wrap gap-2">
                            {/* Job Type tag */}
                            {(job.job_type || parsedInfo.workType) && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full 
                                    text-xs font-medium bg-blue-50 text-blue-600">
                                    <Briefcase className="w-3 h-3 mr-1" />
                                    {job.job_type || parsedInfo.workType}
                                </span>
                            )}

                            {/* Remote tag */}
                            {(job.is_remote || parsedInfo.workType?.toLowerCase().includes('remote')) && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full 
                                    text-xs font-medium bg-green-50 text-green-600">
                                    <Globe className="w-3 h-3 mr-1" />
                                    Remote
                                </span>
                            )}

                            {/* Experience tag */}
                            {parsedInfo.experience && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full 
                                    text-xs font-medium bg-purple-50 text-purple-600">
                                    <Star className="w-3 h-3 mr-1" />
                                    {parsedInfo.experience}
                                </span>
                            )}
                        </div>

                        {/* Technologies */}
                        {parsedInfo.technologies?.length > 0 && (
                            <div className="mt-2 flex flex-wrap gap-1.5">
                                {parsedInfo.technologies.slice(0, 5).map((tech, index) => (
                                    <span
                                        key={index}
                                        className="inline-block px-2 py-1 text-xs font-medium 
                                            bg-gray-100 text-gray-600 rounded-full"
                                    >
                                        {tech}
                                    </span>
                                ))}
                                {parsedInfo.technologies.length > 5 && (
                                    <span className="inline-block px-2 py-1 text-xs font-medium 
                                        bg-gray-100 text-gray-600 rounded-full">
                                        +{parsedInfo.technologies.length - 5} more
                                    </span>
                                )}
                            </div>
                        )}

                        {/* Match Score */}
                        {job.match_score && (
                            <div className="mt-2">
                                <div className="flex items-center">
                                    <div className="flex-1 h-1.5 bg-gray-200 rounded-full overflow-hidden">
                                        <div
                                            className="h-full bg-blue-500 rounded-full transition-all duration-300"
                                            style={{ width: `${job.match_score}%` }}
                                        />
                                    </div>
                                    <span className="ml-2 text-xs font-medium text-blue-600">
                                        {job.match_score}% Match
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Bottom action buttons */}
                <div className="mt-4 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        {/* Source Site Indicator */}
                        {job.source_site && (
                            <span className={`inline-flex items-center px-3 py-1.5 rounded-lg text-xs font-medium 
                                w-24 justify-center whitespace-nowrap
                                ${siteConfig.color} ${siteConfig.textColor} ${siteConfig.borderColor} border`}>
                                <img
                                    src={siteConfig.logo}
                                    alt={siteConfig.name}
                                    className="w-4 h-4 mr-1.5"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/favicon.ico';
                                    }}
                                />
                                {siteConfig.name}
                            </span>
                        )}

                        {/* Login/Save Button */}
                        <motion.button
                            onClick={handleLogin}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            disabled={isLoading}
                            className="p-2 rounded-lg bg-gray-50 text-gray-600 hover:bg-gray-100 
                                transition-colors flex items-center space-x-1"
                            aria-label="Login to Save"
                        >
                            <Star className="w-4 h-4" />
                            <span className="text-xs">
                                {isLoading ? 'Loading...' : 'Login to Save'}
                            </span>
                        </motion.button>

                        {/* Share Button */}
                        <motion.button
                            onClick={handleShareClick}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="p-2 rounded-lg bg-gray-50 text-gray-600 hover:bg-gray-100 
                                transition-colors flex items-center space-x-1"
                            aria-label="Share Job"
                        >
                            <Share2 className="w-4 h-4" />
                            <span className="text-xs">Share</span>
                        </motion.button>

                        {/* AI Assistant Button */}
                        <motion.button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleLogin(e); // Use same login flow for AI features
                            }}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="p-2 rounded-lg bg-gray-50 text-gray-600 hover:bg-gray-100 
                                transition-colors flex items-center space-x-1"
                            aria-label="AI Assistant"
                        >
                            <Sparkles className="w-4 h-4" />
                            <span className="text-xs">AI</span>
                        </motion.button>
                    </div>

                    {/* Apply Now Button */}
                    <motion.button
                        onClick={handleApplyClick}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="px-3 py-1.5 bg-[#B0EDF1A3] text-black text-xs rounded-lg 
                            hover:bg-[#A0C9D1A3] transition-colors flex items-center space-x-1"
                        aria-label="Apply Now"
                    >
                        <span>Apply</span>
                        <ExternalLink className="w-3 h-3" />
                    </motion.button>
                </div>
            </div>
        </motion.div>
    );
};

export default JobListItem;