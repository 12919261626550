import React, { useMemo } from 'react';
import { RefreshCw, ExternalLink, ChevronRight } from 'lucide-react';
import {
  ComposedChart,
  Area,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { format, parseISO, compareAsc, startOfDay } from 'date-fns';

const COLORS = [
  '#4CAF50',
  '#2196F3',
  '#FFC107',
  '#9C27B0',
  '#FF5722',
  '#3F51B5',
  '#E91E63',
  '#00BCD4',
  '#795548',
  '#607D8B',
  '#8BC34A',
  '#FF9800',
  '#9E9E9E',
  '#03A9F4',
  '#673AB7',
];

// Application Card Component for Tooltip
const ApplicationCard = ({ application }) => (
  <div className="bg-gray-50 p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 group">
    <div className="flex justify-between items-start">
      <div className="flex-1">
        <div className="font-medium text-gray-900">{application.company}</div>
        <div className="text-sm text-gray-600">{application.position}</div>
        <div className="flex items-center gap-2 mt-2">
          <span className="bg-blue-100 text-blue-800 text-xs px-2 py-0.5 rounded">{application.job_type}</span>
          <span className="text-xs text-gray-500">{application.time}</span>
        </div>
      </div>
      {application.job_link && (
        <a
          href={application.job_link}
          target="_blank"
          rel="noopener noreferrer"
          className="p-2 text-blue-600 hover:text-blue-800 transition-colors"
          onClick={(e) => e.stopPropagation()}
        >
          <ExternalLink className="w-4 h-4" />
        </a>
      )}
    </div>
  </div>
);

// Enhanced Chart Tooltip
const CustomChartTooltip = ({ active, payload }) => {
  if (!active || !payload || !payload.length) return null;

  const dayData = payload[0].payload;
  const applications = dayData.applications || [];

  return (
    <div className="bg-white rounded-lg shadow-xl border border-gray-200 max-w-md">
      {/* Fixed OLDHeader */}
      <div className="p-4 border-b border-gray-200">
        <div className="font-medium text-gray-900 text-lg mb-3">{dayData.formattedDate}</div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-blue-50 p-2 rounded">
            <div className="text-sm text-blue-600">New Applications</div>
            <div className="font-bold text-xl text-blue-700">{dayData.dailyCount || 0}</div>
          </div>
          <div className="bg-green-50 p-2 rounded">
            <div className="text-sm text-green-600">Total So Far</div>
            <div className="font-bold text-xl text-green-700">{dayData.cumulativeCount}</div>
          </div>
        </div>
      </div>

      {/* Scrollable Applications List */}
      {applications.length > 0 && (
        <div className="p-4">
          <div className="text-sm font-medium text-gray-500 mb-2">Applications on this day:</div>
          <div className="max-h-[300px] overflow-y-auto custom-scrollbar space-y-2">
            {applications.map((application, index) => (
              <ApplicationCard key={index} application={application} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Pie Chart Components
const PieCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return percent > 0.05 ? (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="text-xs font-medium"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  ) : null;
};

const CustomPieTooltip = ({ active, payload }) => {
  if (!active || !payload || !payload.length) return null;

  const data = payload[0].payload;
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
      <p className="font-medium text-gray-900">{data.name}</p>
      <p className="text-sm text-gray-600">
        {data.value} applications ({data.percentage}%)
      </p>
    </div>
  );
};

// Main Dashboard Component
const DashboardStats = ({ totalJobsApplied, dataLoading, dataError, applicationData }) => {
  const cumulativeData = useMemo(() => {
    if (!applicationData?.length) return [];

    // Find first application date
    const firstDate = applicationData.reduce((earliest, current) => {
      const currentDate = parseISO(current.time_applied);
      return !earliest || currentDate < earliest ? currentDate : earliest;
    }, null);

    // Process daily data starting from first application
    const dailyData = applicationData.reduce((accumulator, current) => {
      const dayKey = startOfDay(parseISO(current.time_applied)).toISOString();
      if (!accumulator[dayKey]) {
        accumulator[dayKey] = {
          date: dayKey,
          dailyCount: 0,
          formattedDate: format(parseISO(current.time_applied), 'MMM d, yyyy'),
          applications: [],
        };
      }
      accumulator[dayKey].dailyCount++;
      accumulator[dayKey].applications.push({
        company: current.company || 'Unknown Company',
        position: current.position || 'Unknown Position',
        job_type: current.job_type || 'Other',
        job_link: current.job_link || null,
        time: format(parseISO(current.time_applied), 'h:mm a'),
      });
      return accumulator;
    }, {});

    // Sort and calculate cumulative totals
    const sortedDays = Object.values(dailyData).sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)));

    let runningTotal = 0;
    return sortedDays.map((day) => ({
      ...day,
      cumulativeCount: (runningTotal += day.dailyCount),
    }));
  }, [applicationData]);

  const jobTypeData = useMemo(() => {
    if (!applicationData?.length) return [];

    const typeCounts = applicationData.reduce((accumulator, current) => {
      const jobType = current.job_type || 'Other';
      accumulator[jobType] = (accumulator[jobType] || 0) + 1;
      return accumulator;
    }, {});

    return Object.entries(typeCounts)
      .map(([name, value]) => ({
        name,
        value,
        percentage: ((value / applicationData.length) * 100).toFixed(1),
      }))
      .sort((a, b) => b.value - a.value);
  }, [applicationData]);
  const customScrollbarStyles = {
    WebkitScrollbar: { width: '6px' },
    WebkitScrollbarTrack: { background: '#f3f4f6', borderRadius: '3px' },
    WebkitScrollbarThumb: { background: '#d1d5db', borderRadius: '3px' },
    WebkitScrollbarThumbHover: { background: '#9ca3af' },
  };

  return (
    <div
      className="scrollbar lg:col-span-2 scrollbar-custom items-end bg-white p-8 rounded-2xl shadow-lg "
      style={customScrollbarStyles}
    >
      <p className="text-2xl font-bold text-center text-gray-800 mb-4">Total Jobs Applied: {totalJobsApplied}</p>

      {dataLoading ? (
        <div className="flex justify-center items-center h-64">
          <RefreshCw className="animate-spin h-8 w-8 text-blue-500" />
        </div>
      ) : dataError ? (
        <div className="text-center text-red-500">{dataError}</div>
      ) : (
        <div className="lg:grid lg:grid-cols-2">
          <div className="bg-white items-top rounded-lg p-4 shadow-md">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Application Progress Over Time</h3>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart data={cumulativeData} margin={{ top: 10, right: 30, left: 0, bottom: 80 }}>
                  <defs>
                    <linearGradient id="colorCumulative" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#2563EB" stopOpacity={0.3} />
                      <stop offset="95%" stopColor="#2563EB" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                  <XAxis
                    dataKey="formattedDate"
                    tickLine={false}
                    tick={{ fill: '#6B7280' }}
                    angle={-45}
                    textAnchor="end"
                    height={80}
                    interval={0}
                  />
                  <YAxis
                    tickLine={false}
                    tick={{ fill: '#6B7280' }}
                    width={80}
                    domain={[0, 'auto']}
                    allowDecimals={false}
                  />
                  <Tooltip content={CustomChartTooltip} wrapperStyle={{ zIndex: 1000 }} />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="cumulativeCount"
                    name="Total Applications"
                    stroke="#2563EB"
                    strokeWidth={3}
                    fill="url(#colorCumulative)"
                    dot={{
                      r: 3,
                      stroke: '#2563EB',
                      strokeWidth: 2,
                      fill: '#ffffff',
                    }}
                  />
                  <Bar
                    dataKey="dailyCount"
                    name="Daily Applications"
                    fill="#10B981"
                    opacity={0.8}
                    radius={[4, 4, 0, 0]}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white rounded-lg p-4 shadow-md">
            <h3 className="text-lg font-semibold mb-4 text-gray-800">Job Type Distribution</h3>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={jobTypeData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={140}
                    labelLine={false}
                    label={PieCustomLabel}
                  >
                    {jobTypeData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip content={CustomPieTooltip} />
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="middle"
                    formatter={(value) => <span className="text-sm font-medium text-gray-700">{value}</span>}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardStats;
