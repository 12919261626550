// src/components/resumepage/ResumePreviewPanel.jsx
import React, { useEffect, useState } from 'react';
import {
    PdfLoader,
    PdfHighlighter
} from 'react-pdf-highlighter';
import {
    Loader2,
    ZoomIn,
    ZoomOut,
    RefreshCw,
    FileText,
    File,
    Download as DownloadIcon,
    Star
} from 'lucide-react';
import { pdfjs } from 'react-pdf';
import html2pdf from 'html2pdf.js';  // <--- Import for client-side HTML -> PDF

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/** 
 * Simple skeleton loader for the "New Resume" tab
 * so the user sees a pulsing effect while loading. 
 */
const PreviewSkeleton = () => (
    <div className="p-4 w-full h-full flex flex-col gap-4 animate-pulse">
        <div className="bg-gray-200 rounded h-6 w-2/3" />
        <div className="bg-gray-200 rounded h-4 w-full" />
        <div className="bg-gray-200 rounded h-4 w-full" />
        <div className="bg-gray-200 rounded h-4 w-3/4" />
        <div className="bg-gray-200 rounded h-4 w-1/2" />
    </div>
);

const DEFAULT_THEMES = [
    { id: 'stackoverflow', label: 'Stack Overflow' },
    { id: 'proffesional', label: 'Standard' },
    { id: 'traditional', label: 'Traditional' },
    { id: 'tokyo', label: 'Tokyo' },
    { id: 'paper-with-certificates', label: 'Paper with Certificates' },
    { id: 'onepage', label: 'OnePageResume Alternative' },
    { id: 'standard', label: 'Standard Resume' },
    { id: 'vforesee', label: 'Business Card Vforesee' },
    { id: 'elegant-sargefork', label: 'Elegant Sargefork' },
    { id: 'elegant-sargefork-no-volunteer', label: 'Elegant Sargefork No Volunteer' },
    { id: 'murtaza', label: 'Murtaza' },
    { id: 'eloquent', label: 'Eloquent' },
    { id: 'modace', label: 'ModAce' }
];

const ResumePreviewPanel = ({
    resume,
    previewHtml,
    onRefreshPreview,
    selectedTheme,
    onThemeChange,
    resumeScore,
    isProcessing
}) => {
    const [scale, setScale] = useState(1);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('new');

    // Trigger the parent's onRefreshPreview once on mount
    useEffect(() => {
        if (onRefreshPreview) {
            console.log("ResumePreviewPanel mounted: Auto-triggering preview...");
            onRefreshPreview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // If user changes theme & no previewHtml yet, re-generate
    useEffect(() => {
        if (onRefreshPreview && !previewHtml) {
            console.log("Theme changed or no previewHtml -> regenerating preview...");
            onRefreshPreview();
        }
    }, [selectedTheme, previewHtml, onRefreshPreview]);

    /** 
     * Export the "New Resume" tab as PDF using html2pdf.js
     * We'll ensure the container is narrower & centered
     * so it shows up properly in the final PDF.
     */
    const handleExportAsPdf = async () => {
        const container = document.getElementById('resume-preview-container');
        if (!container) {
            console.error('No element #resume-preview-container to export.');
            return;
        }

        const opt = {
            margin: 0.25, // minimal margin
            filename: 'Resume.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        try {
            console.log('Exporting to PDF (client-side) ...');
            await html2pdf().from(container).set(opt).save();
            console.log('Export successful!');
        } catch (err) {
            console.error('Export PDF error:', err);
            setError('Failed to export PDF');
        }
    };

    const TabButton = ({ id, label, icon: Icon }) => (
        <button
            onClick={() => setActiveTab(id)}
            className={`flex items-center gap-2 px-4 py-2 ${activeTab === id
                    ? 'bg-white border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-600 hover:text-gray-800'
                }`}
        >
            <Icon className="w-4 h-4" />
            {label}
        </button>
    );

    return (
        <div className="flex flex-col h-full w-full bg-white">
            {/* Header Controls */}
            <div className="border-b border-gray-200 bg-white p-4">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-4">
                        <div className="flex items-center gap-2 bg-blue-50 px-3 py-1 rounded-full">
                            <Star className="w-4 h-4 text-blue-500" />
                            <span className="text-blue-600 font-medium">
                                {resumeScore}%
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        {/* Refresh Preview button */}
                        <button
                            onClick={onRefreshPreview}
                            className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                            disabled={isProcessing}
                        >
                            <RefreshCw
                                className={`w-4 h-4 mr-2 ${isProcessing ? 'animate-spin' : ''
                                    }`}
                            />
                            Preview
                        </button>

                        {/* Client-Side PDF Export */}
                        <button
                            onClick={handleExportAsPdf}
                            className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                            disabled={isProcessing}
                        >
                            <DownloadIcon className="w-4 h-4 mr-2" />
                            Export PDF
                        </button>
                    </div>
                </div>

                {/* Theme Selection */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Select Theme
                    </label>
                    <select
                        value={selectedTheme}
                        onChange={(e) => onThemeChange(e.target.value)}
                        className="block w-full border-gray-300 rounded-md shadow-sm
                       focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                        {DEFAULT_THEMES.map((theme) => (
                            <option key={theme.id} value={theme.id}>
                                {theme.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Tab Navigation */}
            <div className="flex items-center border-b border-gray-200 bg-gray-50">
                <TabButton id="new" label="New Resume" icon={FileText} />
                <TabButton id="original" label="Original Resume" icon={File} />
            </div>

            {/* NEW Resume Preview (HTML) */}
            <div className={`flex-1 ${activeTab === 'new' ? 'block' : 'hidden'} overflow-auto`}>
                {previewHtml ? (
                    // We wrap your HTML in #resume-preview-container
                    // + a custom class for centering + max-width for PDF 
                    <div
                        id="resume-preview-container"
                        className="preview-content p-4 my-center-container"
                    >
                        <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                    </div>
                ) : (
                    <PreviewSkeleton />
                )}
            </div>

            {/* ORIGINAL Resume Preview (PDF or image) */}
            <div className={`flex-1 ${activeTab === 'original' ? 'block' : 'hidden'}`}>
                <div className="flex items-center justify-between p-4 border-b border-gray-200 bg-white">
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => setScale((prev) => Math.max(0.5, prev - 0.1))}
                            className="p-2 rounded hover:bg-gray-100 text-gray-600"
                            title="Zoom Out"
                        >
                            <ZoomOut className="w-4 h-4" />
                        </button>
                        <span className="text-sm font-medium text-gray-700">
                            {Math.round(scale * 100)}%
                        </span>
                        <button
                            onClick={() => setScale((prev) => Math.min(2, prev + 0.1))}
                            className="p-2 rounded hover:bg-gray-100 text-gray-600"
                            title="Zoom In"
                        >
                            <ZoomIn className="w-4 h-4" />
                        </button>
                    </div>
                </div>

                <div className="flex-1 bg-gray-50 relative">
                    {resume?.file_url ? (
                        resume.file_type?.includes('pdf') ? (
                            <PdfLoader
                                url={resume.file_url}
                                beforeLoad={
                                    <div className="flex items-center justify-center h-full bg-white">
                                        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                                    </div>
                                }
                            >
                                {(pdfDocument) => (
                                    <PdfHighlighter
                                        pdfDocument={pdfDocument}
                                        enableAreaSelection={false}
                                        highlights={[]}
                                        scrollRef={() => { }}
                                        scale={scale}
                                    />
                                )}
                            </PdfLoader>
                        ) : (
                            <img
                                src={resume.file_url}
                                alt="Original Resume"
                                className="w-full h-auto"
                                style={{ transform: `scale(${scale})` }}
                            />
                        )
                    ) : (
                        <div className="flex items-center justify-center h-full text-gray-500">
                            No original resume file available
                        </div>
                    )}
                </div>
            </div>

            {error && (
                <div className="p-4 bg-red-50 border border-red-200 text-red-600 rounded m-4">
                    {error}
                </div>
            )}
        </div>
    );
};

export default ResumePreviewPanel;
