import React, { useState, useRef } from 'react';
import { PdfLoader, PdfHighlighter } from 'react-pdf-highlighter';
import { X, ExternalLink, Download, FileText, Image as ImageIcon, ZoomIn, ZoomOut, Loader2 } from 'lucide-react';
import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ResumePreviewModal = ({ isOpen, onClose, resume, previewHtml }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(false);
    const [scale, setScale] = useState(1);
    const [showOriginal, setShowOriginal] = useState(false);
    const scrollRef = useRef(null);

    if (!isOpen) return null;

    const getFileType = () => {
        const fileType = resume?.file_type?.toLowerCase() || '';
        if (fileType.includes('pdf')) return 'pdf';
        if (fileType.includes('png') || fileType.includes('jpg') || fileType.includes('jpeg')) return 'image';
        return 'other';
    };

    const renderContent = () => {
        if (showOriginal) {
            const type = getFileType();
            switch (type) {
                case 'pdf':
                    return (
                        <div className="h-full" style={{ transform: `scale(${scale})`, transformOrigin: 'top center' }}>
                            <PdfLoader
                                url={resume.file_url}
                                beforeLoad={
                                    <div className="flex items-center justify-center h-full bg-white">
                                        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                                    </div>
                                }
                                onLoad={() => setIsLoading(false)}
                            >
                                {(pdfDocument) => (
                                    <PdfHighlighter
                                        pdfDocument={pdfDocument}
                                        enableAreaSelection={false}
                                        highlights={[]}
                                        scrollRef={scrollRef}
                                    />
                                )}
                            </PdfLoader>
                        </div>
                    );

                case 'image':
                    return (
                        <div className="w-full h-full overflow-auto bg-gray-100 dark:bg-gray-900">
                            <img
                                src={resume.file_url}
                                alt="Resume Preview"
                                className="max-w-full h-auto mx-auto"
                                style={{ transform: `scale(${scale})`, transformOrigin: 'top center' }}
                                onLoad={() => setIsLoading(false)}
                                onError={() => {
                                    setIsLoading(false);
                                    setLoadError(true);
                                }}
                            />
                        </div>
                    );

                default:
                    return (
                        <div className="w-full h-full flex items-center justify-center bg-gray-50 dark:bg-gray-900">
                            <div className="text-center p-8">
                                <FileText className="w-16 h-16 text-gray-400 dark:text-gray-600 mx-auto mb-4" />
                                <p className="text-gray-600 dark:text-gray-400">
                                    Preview not available for this file type.
                                </p>
                            </div>
                        </div>
                    );
            }
        } else {
            // Render the HTML preview
            return (
                <div
                    className="h-full overflow-auto p-4 bg-white"
                    style={{ transform: `scale(${scale})`, transformOrigin: 'top center' }}
                >
                    <div
                        dangerouslySetInnerHTML={{ __html: previewHtml }}
                        className="shadow-lg rounded-lg"
                    />
                </div>
            );
        }
    };

    const renderIcon = () => {
        if (!showOriginal) {
            return <FileText className="w-5 h-5 text-blue-600 dark:text-blue-400" />;
        }
        const type = getFileType();
        switch (type) {
            case 'image':
                return <ImageIcon className="w-5 h-5 text-blue-600 dark:text-blue-400" />;
            default:
                return <FileText className="w-5 h-5 text-blue-600 dark:text-blue-400" />;
        }
    };

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-5xl max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                    <div className="flex items-center gap-3">
                        {renderIcon()}
                        <div>
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                {showOriginal ? 'Original Resume' : 'Preview Resume'}
                            </h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                {showOriginal ? getFileType().toUpperCase() : 'PREVIEW'}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => setShowOriginal(!showOriginal)}
                            className="px-3 py-1.5 text-sm bg-gray-100 hover:bg-gray-200 rounded-md flex items-center gap-2"
                        >
                            {showOriginal ? 'Show Preview' : 'Show Original'}
                        </button>
                        <div className="flex items-center gap-1">
                            <button
                                onClick={() => setScale((prev) => Math.max(0.5, prev - 0.1))}
                                className="p-2 text-gray-600 hover:text-blue-600 rounded-lg hover:bg-gray-100"
                                title="Zoom Out"
                            >
                                <ZoomOut className="w-4 h-4" />
                            </button>
                            <span className="text-sm font-medium text-gray-700 w-16 text-center">
                                {Math.round(scale * 100)}%
                            </span>
                            <button
                                onClick={() => setScale((prev) => Math.min(2, prev + 0.1))}
                                className="p-2 text-gray-600 hover:text-blue-600 rounded-lg hover:bg-gray-100"
                                title="Zoom In"
                            >
                                <ZoomIn className="w-4 h-4" />
                            </button>
                        </div>
                        {resume?.file_url && (
                            <>
                                <a
                                    href={resume.file_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors rounded-lg hover:bg-gray-100"
                                    title="Open in new tab"
                                >
                                    <ExternalLink className="w-5 h-5" />
                                </a>
                                <a
                                    href={resume.file_url}
                                    download
                                    className="p-2 text-gray-600 hover:text-blue-600 transition-colors rounded-lg hover:bg-gray-100"
                                    title="Download"
                                >
                                    <Download className="w-5 h-5" />
                                </a>
                            </>
                        )}
                        <button
                            onClick={onClose}
                            className="p-2 text-gray-600 hover:text-red-600 transition-colors rounded-lg hover:bg-gray-100"
                            title="Close"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>
                </div>

                {/* Content */}
                <div className="flex-1 overflow-hidden rounded-b-xl relative" ref={scrollRef}>
                    {renderContent()}

                    {/* Loading State */}
                    {isLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white/80 dark:bg-gray-800/80">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                        </div>
                    )}

                    {/* Error State */}
                    {loadError && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800">
                            <div className="text-center p-8">
                                <p className="text-red-600 dark:text-red-400 mb-4">
                                    Failed to load preview
                                </p>
                                {resume?.file_url && (
                                    <a
                                        href={resume.file_url}
                                        download
                                        className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                                    >
                                        <Download className="w-4 h-4" />
                                        Download Instead
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResumePreviewModal;