import { supabase } from '../supabaseConfig';
import { handleError } from '../utilities/handleError';
import { convertKeysToSnakeCase } from '../utilities/caseUtils';
const formatObjectKeys = (data) => {
  const isObject = (value) => value && typeof value === 'object' && value.constructor === Object;
  const areKeysSnakeCase = (obj) => Object.keys(obj).every((key) => key.includes('_') || key.toLowerCase() === key);
  const shouldConvertToSnakeCase = (values) => isObject(values) && !areKeysSnakeCase(values);
  const newData = shouldConvertToSnakeCase(data) ? convertKeysToSnakeCase(data) : data;
  if (!areKeysSnakeCase(newData)) {
    return;
  }
  return newData;
};
export const getUserProfile = async (userId) => {
  const { data, error } = await supabase.from('profiles').select('*').eq('id', userId).single();
  if (error) {
    console.error('Error fetching profile:', error);
    return { hasProfile: false, userProfile: null };
  }
  if (data) {
    const updatedData = { ...data, credits: data?.credits || 0 };
    return { hasProfile: data?.has_profile || false, userProfile: updatedData || null };
  }
};
export const updateUserProfile = async (userId, profileValues) => {
  if (!userId || !profileValues) {
    console.error('Missing required parameters in updateUserProfile');
    console.trace('updateUserProfile');
    return;
  }
  const newProfileData = formatObjectKeys(profileValues);
  if (!newProfileData) {
    return;
  }
  return supabase.from('profiles').update(newProfileData).eq('id', userId);
};
export const createUserProfile = async (data) => {
  // profileInformation should be an object with up to following keys in camelCase or snake_case(for backward compatibility):
  // 'user_id'
  // 'full_name'
  // 'email'
  // 'username'
  // 'job_title'
  // 'resume'
  return supabase.from('profiles').upsert(data);
};
export const subscribeToAuthChanges = ({ onSignIn, onSignOut }) => {
  return supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_IN' && session) {
      try {
        onSignIn(session);
      } catch (error) {
        console.error('Error signing in: ', error);
      }
    }
    if (event === 'SIGNED_OUT') {
      try {
        onSignOut();
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    }
  });
};
export const signIn = async (credentials = {}) => {
  console.count('signIn');
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });
    if (error) {
      // Handle specific Supabase error scenario
      // handleError({
      //   error,
      //   showToast: true,
      //   logError: true,
      //   toastType: 'error',
      //   toastMessage: { title: 'Google sign in failed', description: 'Please check your credentials and try again.' },
      // });
      console.error('Google sign in error:', error);
      return { error };
    }
    if (data) {
      console.log('sign in data:', data);
      return data;
    }
  } catch (error) {
    // Handle broader errors (e.g., network issues, unexpected exceptions)
    handleError({
      error,
      showToast: true,
      logError: true,
      toastType: 'error',
      toastMessage: { title: 'Sign in failed', description: 'An unexpected error occurred. Please try again.' },
    });
  }
};
export const signOut = async () => await supabase.auth.signOut();
// export const updateUserProfile = async ({ userId, formData: updates }) => {
//   console.log(':userId', userId);
//   console.log(':updates', updates);
//   return supabase
//     .from('profiles')
//     .upsert({
//       email: updates.email,
//       // dice_password: .data.password,
//       // already_had_dice_ind: false,
//       has_profile: true,
//       credits: 10,
//       username: updates.username,
//       job_title: updates.job_title,
//       full_name: updates.full_name,
//       avatar_url: updates.avatar_url,
//       resume: updates.resume,
//       updated_at: new Date().toISOString(),
//     })
//     .eq('id', userId);
// };
//
// const { error: updateError } = await supabase
//   .from('profiles')
//   .update({
//     email: user.email,
//     dice_password: response.data.password,
//     already_had_dice_ind: false, // This is a new account
//     has_profile: true, // This is a new account,
//     job_title: formData.jobTitle,
//     credits: 10,
//   })
//   .eq('id', user.id);
// };
/**
 * DO NOT STORE RESULTS IN A VARIABLE.Over 1MB on the network and resource size is 3.4MB
 * if you need the count, use getAllApplicationsCount() instead.
 * If you need user specific, use getAllApplications() instead.
 *
 * Fetches all applications from the database
 * @returns {Promise} - Returns a promise containing the applications
 */
export const getAllApplications = async () => await supabase.from('applications').select('*', { count: 'exact' }); // Fetch with an exact count
export const getAllApplicationsCount = async () => {
  const applications = await getAllApplications();
  return applications?.count || 1003486;
};
export const getLastCampaign = async (userId) =>
  await supabase
    .from('campaigns')
    .select('*')
    .eq('user_id', userId)
    .eq('status', 'in_progress')
    .order('start_time', { ascending: false })
    .limit(1)
    .single();
// export const cancelCurrentCampaign = async (campaignId) =>
// // Update campaign status
// // await supabase
//   .from('campaigns')
//   .update({
//     status: 'completed',
//     end_time: new Date().toISOString()
//   })
//   .eq('campaign_id', campaigns[0].campaign_id)
//   .eq('user_id', userId);
export const getApplicationsByUserId = async (userId) =>
  await supabase.from('applications').select('*').eq('user_id', userId).order('time_applied', { ascending: true });
export const getLatestApplications = async () =>
  await supabase.from('applications').select('*').order('time_applied', { ascending: false });
export const subscribeToApplicationsByUserId = async (userId, callBack) =>
  await supabase
    .channel('applications_changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'applications',
        filter: `user_id=eq.${userId}`,
      },
      () => {
        callBack();
      },
    )
    .subscribe();
export const getCreditsByUserId = async (userId) =>
  await supabase.from('profiles').select('credits').eq('id', userId).single();
export const getDicePasswordByUserId = async (userId) =>
  await supabase.from('profiles').select('dice_password').eq('id', userId).single();
export const getUserAvatar = async (filePath) => await supabase.storage.from('avatars').getPublicUrl(filePath);
export const postUserAvatar = async (filePath, file) =>
  await supabase.storage.from('avatars').upload(filePath, file, { upsert: true });

// import { supabase } from '../supabaseConfig';
// import { handleError } from '../utilities/handleError';
// import { convertKeysToSnakeCase } from '../utilities/caseUtils';
//
// const formatObjectKeys = (data) => {
//   const isObject = (value) => value && typeof value === 'object' && value.constructor === Object;
//   const areKeysSnakeCase = (obj) => Object.keys(obj).every((key) => key.includes('_') || key.toLowerCase() === key);
//   const shouldConvertToSnakeCase = (values) => isObject(values) && !areKeysSnakeCase(values);
//
//   const newData = shouldConvertToSnakeCase(data) ? convertKeysToSnakeCase(data) : data;
//
//   if (!areKeysSnakeCase(newData)) {
//     return;
//   }
//   return newData;
// };
//
// export const getUserProfile = async (userId) => {
//   const { data, error } = await supabase.from('profiles').select('*').eq('id', userId).single();
//
//   if (error) {
//     console.error('Error fetching profile:', error);
//     return { hasProfile: false, userProfile: null };
//   }
//
//   if (data) {
//     const updatedData = { ...data, credits: data?.credits || 0 };
//     return { hasProfile: data?.has_profile || false, userProfile: updatedData || null };
//   }
// };
//
// export const updateUserProfile = async (userId, profileValues) => {
//   if (!userId || !profileValues) {
//     console.error('Missing required parameters in updateUserProfile');
//     console.trace('updateUserProfile');
//     return;
//   }
//   const newProfileData = formatObjectKeys(profileValues);
//   if (!newProfileData) {
//     return;
//   }
//
//   return supabase.from('profiles').update(newProfileData).eq('id', userId);
// };
//
// export const createUserProfile = async (data) => {
//   // profileInformation should be an object with up to following keys in camelCase or snake_case(for backward compatibility):
//   // 'user_id'
//   // 'full_name'
//   // 'email'
//   // 'username'
//   // 'job_title'
//   // 'resume'
//
//   return supabase.from('profiles').upsert(data);
// };
//
// export const subscribeToAuthChanges = ({ onSignIn, onSignOut }) => {
//   return supabase.auth.onAuthStateChange((event, session) => {
//     if (event === 'SIGNED_IN' && session) {
//       try {
//         onSignIn(session);
//       } catch (error) {
//         console.error('Error signing in: ', error);
//       }
//     }
//     if (event === 'SIGNED_OUT') {
//       try {
//         onSignOut();
//       } catch (error) {
//         console.error('Error signing out: ', error);
//       }
//     }
//   });
// };
//
// export const signIn = async (credentials = {}) => {
//   console.count('signIn');
//   try {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//       provider: 'google',
//       redirectTo: window.location.origin,
//     });
//
//     if (error) {
//       // Handle specific Supabase error scenario
//       // handleError({
//       //   error,
//       //   showToast: true,
//       //   logError: true,
//       //   toastType: 'error',
//       //   toastMessage: { title: 'Google sign in failed', description: 'Please check your credentials and try again.' },
//       // });
//       console.error('Google sign in error:', error);
//       return { error };
//     }
//
//     if (data) {
//       console.log('sign in data:', data);
//       return data;
//     }
//   } catch (error) {
//     // Handle broader errors (e.g., network issues, unexpected exceptions)
//     handleError({
//       error,
//       showToast: true,
//       logError: true,
//       toastType: 'error',
//       toastMessage: { title: 'Sign in failed', description: 'An unexpected error occurred. Please try again.' },
//     });
//   }
// };
//
// export const signOut = async () => await supabase.auth.signOut();
//
// // export const updateUserProfile = async ({ userId, formData: updates }) => {
// //   console.log(':userId', userId);
// //   console.log(':updates', updates);
// //   return supabase
// //     .from('profiles')
// //     .upsert({
// //       email: updates.email,
// //       // dice_password: .data.password,
// //       // already_had_dice_ind: false,
// //       has_profile: true,
// //       credits: 10,
// //       username: updates.username,
// //       job_title: updates.job_title,
// //       full_name: updates.full_name,
// //       avatar_url: updates.avatar_url,
// //       resume: updates.resume,
// //       updated_at: new Date().toISOString(),
// //     })
// //     .eq('id', userId);
// // };
// //
//
// // const { error: updateError } = await supabase
// //   .from('profiles')
// //   .update({
// //     email: user.email,
// //     dice_password: response.data.password,
// //     already_had_dice_ind: false, // This is a new account
// //     has_profile: true, // This is a new account,
// //     job_title: formData.jobTitle,
// //     credits: 10,
// //   })
// //   .eq('id', user.id);
// // };
//
// /**
//  * DO NOT STORE RESULTS IN A VARIABLE.Over 1MB on the network and resource size is 3.4MB
//  * if you need the count, use getAllApplicationsCount() instead.
//  * If you need user specific, use getAllApplications() instead.
//  *
//  * Fetches all applications from the database
//  * @returns {Promise} - Returns a promise containing the applications
//  */
//
// export const getAllApplications = async () => await supabase.from('applications').select('*', { count: 'exact' }); // Fetch with an exact count
//
// export const getAllApplicationsCount = async () => {
//   const applications = await getAllApplications();
//   return applications?.count || 1003486;
// };
//
// export const getLastCampaign = async (userId) =>
//   await supabase
//     .from('campaigns')
//     .select('*')
//     .eq('user_id', userId)
//     .eq('status', 'in_progress')
//     .order('start_time', { ascending: false })
//     .limit(1)
//     .single();
//
// // export const cancelCurrentCampaign = async (campaignId) =>
// // // Update campaign status
// // // await supabase
// //   .from('campaigns')
// //   .update({
// //     status: 'completed',
// //     end_time: new Date().toISOString()
// //   })
// //   .eq('campaign_id', campaigns[0].campaign_id)
// //   .eq('user_id', userId);
//
// export const getApplicationsByUserId = async (userId) =>
//   await supabase.from('applications').select('*').eq('user_id', userId).order('time_applied', { ascending: true });
//
// export const getLatestApplications = async () =>
//   await supabase.from('applications').select('*').order('time_applied', { ascending: false });
//
// export const subscribeToApplicationsByUserId = async (userId, callBack) =>
//   await supabase
//     .channel('applications_changes')
//     .on(
//       'postgres_changes',
//       {
//         event: '*',
//         schema: 'public',
//         table: 'applications',
//         filter: `user_id=eq.${userId}`,
//       },
//       () => {
//         callBack();
//       },
//     )
//     .subscribe();
//
// export const getCreditsByUserId = async (userId) =>
//   await supabase.from('profiles').select('credits').eq('id', userId).single();
//
// export const getDicePasswordByUserId = async (userId) =>
//   await supabase.from('profiles').select('dice_password').eq('id', userId).single();
// export const getUserAvatar = async (filePath) => await supabase.storage.from('avatars').getPublicUrl(filePath);
// export const postUserAvatar = async (filePath, file) =>
//   await supabase.storage.from('avatars').upload(filePath, file, { upsert: true });
