// User userProfile Display Component
import React from 'react';
import { Sparkle } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

export const UserProfileCard = ({
  credits = 0,
  avatarUrl = './favicon-96x96.png',
  username,
  email,
  jobTitle = 'Job Seeker',
  className,
}) => {
  const containerClass = twMerge(
    'user-userProfile-card relative h-full px-3 py-2 min-w-44 w-max flex items-center hover:shadow-md hover:shadow-green-300 border border-green-200 hover:border-green-300 rounded-lg transition-all duration-300',
    className,
  );
  const name = username || email?.split('@')[0] || 'User';
  return (
    <div className={containerClass}>
      <div className={'layout-container flex flex-grow items-center h-full gap-4 pr-7'}>
        <img src={avatarUrl || '/favicon-96x96.png'} alt={'User Profile'} className="w-10 h-10 rounded-full" />
        <div className={'title-container pr-2 h-10 flex flex-col flex-grow min-w-0 '}>
          <span className="text-md text-gray-800 font-medium ">{name}</span>
          <span className="text-sm leading-3 text-gray-600 font-light">{jobTitle || 'Job Seeker'}</span>
        </div>
      </div>

      <div className="absolute top-0 right-0 flex items-center space-x-1  px-1 py-1 ">
        <span className="text-xs text-green-600 font-regular">{credits}</span>
        <Sparkle className="w-4 h-4 text-green-600" />
      </div>
    </div>
  );
};
