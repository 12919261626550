// // components/InterviewDashboard.jsx
// import React, { useState } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//     Plus,
//     Calendar as CalendarIcon,
//     Clock,
//     Video,
//     Building,
//     Users,
//     ChevronRight,
//     ChevronLeft,
//     X,
//     Upload,
//     ChevronDown
// } from 'lucide-react';
// import LiveInterviewManagement from './LiveInterviewManagement';
// import CreateInterviewModal from './CreateInterviewModal';
// import SessionSetupFlow from './SessionSetupFlow';


// // File: src/components/page/common/index.js
// const Card = ({ children, className = '' }) => (
//     <div className={`bg-white rounded-lg shadow-sm border border-gray-200 ${className}`}>
//         {children}
//     </div>
// );

// const Button = ({ children, variant = 'primary', className = '', ...props }) => {
//     const baseStyles = 'px-4 py-2 rounded-md font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2';
//     const variants = {
//         primary: 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500',
//         secondary: 'bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-gray-500',
//         outline: 'border border-gray-300 hover:bg-gray-50'
//     };

//     return (
//         <button
//             className={`${baseStyles} ${variants[variant]} ${className}`}
//             {...props}
//         >
//             {children}
//         </button>
//     );
// };

// const Badge = ({ children, variant = 'default' }) => {
//     const variants = {
//         default: 'bg-blue-100 text-blue-800',
//         secondary: 'bg-gray-100 text-gray-800',
//         success: 'bg-green-100 text-green-800'
//     };

//     return (
//         <span className={`px-2 py-1 rounded-full text-xs font-medium ${variants[variant]}`}>
//             {children}
//         </span>
//     );
// };


// const CreateInterviewButton = ({ onSelect }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const options = [
//         { id: 'general', label: 'General', icon: '🎯' },
//         { id: 'specialized', label: 'Specialized Skills', icon: '💡', disabled: true },
//         { id: 'coding', label: 'Coding Copilot', icon: '💻', disabled: true }
//     ];

//     return (
//         <div className="relative">
//             <Button
//                 onClick={() => setIsOpen(!isOpen)}
//                 className="flex items-center gap-2"
//             >
//                 <Plus className="w-4 h-4" />
//                 Create
//                 <ChevronDown className="w-4 h-4" />
//             </Button>

//             {isOpen && (
//                 <motion.div
//                     initial={{ opacity: 0, y: -10 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     className="absolute right-0 mt-2 w-64 rounded-lg bg-white shadow-lg border border-gray-200 py-2 z-50"
//                 >
//                     {options.map((option) => (
//                         <button
//                             key={option.id}
//                             className={`w-full px-4 py-3 text-left flex items-center gap-3 hover:bg-gray-50
//                   ${option.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
//                             onClick={() => {
//                                 if (!option.disabled) {
//                                     onSelect(option.id);
//                                     setIsOpen(false);
//                                 }
//                             }}
//                         >
//                             <span className="text-xl">{option.icon}</span>
//                             <span className="flex-1">{option.label}</span>
//                             {option.disabled && (
//                                 <span className="text-xs px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full">
//                                     Free
//                                 </span>
//                             )}
//                         </button>
//                     ))}
//                 </motion.div>
//             )}
//         </div>
//     );
// };


// export const SimpleCalendar = ({ selectedDate, onDateSelect }) => {
//     const [currentMonth, setCurrentMonth] = useState(new Date());

//     const daysInMonth = new Date(
//         currentMonth.getFullYear(),
//         currentMonth.getMonth() + 1,
//         0
//     ).getDate();

//     const firstDayOfMonth = new Date(
//         currentMonth.getFullYear(),
//         currentMonth.getMonth(),
//         1
//     ).getDay();

//     const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
//     const weeks = [];

//     days.forEach((day, index) => {
//         const dayIndex = (index + firstDayOfMonth) % 7;
//         const weekIndex = Math.floor((index + firstDayOfMonth) / 7);

//         if (!weeks[weekIndex]) {
//             weeks[weekIndex] = Array(7).fill(null);
//         }
//         weeks[weekIndex][dayIndex] = day;
//     });

//     return (
//         <div className="w-full">
//             <div className="flex justify-between items-center mb-4">
//                 <h3 className="font-medium">
//                     {currentMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
//                 </h3>
//                 <div className="flex gap-2">
//                     <Button
//                         variant="outline"
//                         onClick={() => setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)))}
//                     >
//                         <ChevronLeft className="w-4 h-4" />
//                     </Button>
//                     <Button
//                         variant="outline"
//                         onClick={() => setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)))}
//                     >
//                         <ChevronRight className="w-4 h-4" />
//                     </Button>
//                 </div>
//             </div>

//             <div className="grid grid-cols-7 gap-1">
//                 {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
//                     <div key={day} className="text-center text-sm text-gray-500 py-2">
//                         {day}
//                     </div>
//                 ))}

//                 {weeks.map((week, weekIndex) => (
//                     week.map((day, dayIndex) => (
//                         <button
//                             key={`${weekIndex}-${dayIndex}`}
//                             onClick={() => day && onDateSelect(
//                                 new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
//                             )}
//                             className={`
//                   p-2 text-center text-sm rounded-full
//                   ${day ? 'hover:bg-gray-100' : ''}
//                   ${day && selectedDate && day === selectedDate.getDate() &&
//                                     currentMonth.getMonth() === selectedDate.getMonth() &&
//                                     currentMonth.getFullYear() === selectedDate.getFullYear()
//                                     ? 'bg-blue-600 text-white hover:bg-blue-700'
//                                     : ''}
//                   ${!day ? 'cursor-default' : 'cursor-pointer'}
//                 `}
//                             disabled={!day}
//                         >
//                             {day}
//                         </button>
//                     ))
//                 ))}
//             </div>
//         </div>
//     );
// };

// const InterviewDashboard = () => {
//     const [selectedDate, setSelectedDate] = useState(new Date());
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [currentView, setCurrentView] = useState('dashboard'); // 'dashboard', 'setup', 'interview'
//     const [sessionType, setSessionType] = useState(null);
//     const [interviewData, setInterviewData] = useState(null);

//     // Interview Options
//     const interviewTypes = [
//         {
//             id: 'general',
//             label: 'General',
//             icon: '🎯',
//             description: 'Basic interview practice',
//             disabled: false
//         },
//         {
//             id: 'specialized',
//             label: 'Specialized Skills',
//             icon: '💡',
//             description: 'Domain-specific interview practice',
//             disabled: true,
//             premium: true
//         },
//         {
//             id: 'coding',
//             label: 'Coding Copilot',
//             icon: '💻',
//             description: 'Technical interview practice',
//             disabled: true,
//             premium: true
//         }
//     ];

//     const handleCreateClick = (type) => {
//         setSessionType(type);
//         setIsModalOpen(true);
//     };

//     const handleModalLaunch = (formData) => {
//         setInterviewData(formData);
//         setIsModalOpen(false);
//         setCurrentView('setup');
//     };

//     const handleSetupComplete = (sessionData) => {
//         setInterviewData(prev => ({
//             ...prev,
//             ...sessionData
//         }));
//         setCurrentView('interview');
//     };

//     const handleLeaveInterview = () => {
//         // Clean up streams and reset state
//         if (interviewData?.streams) {
//             Object.values(interviewData.streams).forEach(stream => {
//                 if (stream?.getTracks) {
//                     stream.getTracks().forEach(track => track.stop());
//                 }
//             });
//         }
//         setCurrentView('dashboard');
//         setSessionType(null);
//         setInterviewData(null);
//     };

//     // Render different views based on state
//     if (currentView === 'setup') {
//         return (
//             <SessionSetupFlow
//                 onComplete={handleSetupComplete}
//                 onCancel={() => {
//                     setCurrentView('dashboard');
//                     setInterviewData(null);
//                 }}
//                 initialData={interviewData}
//             />
//         );
//     }

//     if (currentView === 'interview') {
//         return (
//             <LiveInterviewManagement
//                 onLeave={handleLeaveInterview}
//                 sessionType={sessionType}
//                 interviewData={interviewData}
//             />
//         );
//     }

//     // Dashboard View
//     return (
//         <div className="p-8 bg-gray-50 min-h-screen">
//             <div className="max-w-7xl mx-auto">
//                 {/* Header */}
//                 <div className="flex justify-between items-center mb-8">
//                     <div>
//                         <h1 className="text-3xl font-bold">Interview Dashboard</h1>
//                         <p className="text-gray-500 mt-2">Manage your upcoming and past interviews</p>
//                     </div>
//                     <CreateInterviewButton
//                         options={interviewTypes}
//                         onSelect={handleCreateClick}
//                     />
//                 </div>

//                 {/* Main Content */}
//                 <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                     {/* Calendar and Stats */}
//                     <Card className="p-6">
//                         <h2 className="text-lg font-semibold mb-4">Calendar</h2>
//                         <SimpleCalendar
//                             selectedDate={selectedDate}
//                             onDateSelect={setSelectedDate}
//                         />

//                         <div className="mt-6 space-y-4">
//                             <h3 className="font-medium">Quick Stats</h3>
//                             <div className="grid grid-cols-2 gap-4">
//                                 <div className="p-4 bg-blue-50 rounded-lg">
//                                     <div className="text-2xl font-bold text-blue-600">3</div>
//                                     <div className="text-sm text-blue-600">Upcoming</div>
//                                 </div>
//                                 <div className="p-4 bg-green-50 rounded-lg">
//                                     <div className="text-2xl font-bold text-green-600">1</div>
//                                     <div className="text-sm text-green-600">Completed</div>
//                                 </div>
//                             </div>
//                         </div>
//                     </Card>

//                     {/* Live Interview Management */}
//                     <div className="md:col-span-2">
//                         <Card>
//                             <div className="p-6 border-b">
//                                 <h2 className="text-lg font-semibold">Live Interview Management</h2>
//                             </div>
//                             <div className="p-6">
//                                 <div className="space-y-4">
//                                     {/* Interview Options */}
//                                     {interviewTypes.map((type) => (
//                                         <div
//                                             key={type.id}
//                                             className={`flex items-center justify-between p-4 bg-gray-50 rounded-lg
//                         ${type.disabled ? 'opacity-75' : ''}`}
//                                         >
//                                             <div className="flex items-center gap-3">
//                                                 <span className="text-xl">{type.icon}</span>
//                                                 <div>
//                                                     <div className="flex items-center gap-2">
//                                                         <h3 className="font-medium">{type.label}</h3>
//                                                         {type.premium && (
//                                                             <Badge variant="secondary">Premium</Badge>
//                                                         )}
//                                                     </div>
//                                                     <p className="text-sm text-gray-600">{type.description}</p>
//                                                 </div>
//                                             </div>
//                                             <Button
//                                                 variant={type.disabled ? 'outline' : 'primary'}
//                                                 disabled={type.disabled}
//                                                 onClick={() => type.disabled ? null : handleCreateClick(type.id)}
//                                             >
//                                                 {type.disabled ? 'Coming Soon' : 'Start'}
//                                             </Button>
//                                         </div>
//                                     ))}

//                                     {/* Recent Sessions */}
//                                     <div className="mt-8">
//                                         <h3 className="font-medium mb-4">Recent Sessions</h3>
//                                         <div className="space-y-3">
//                                             <div className="flex items-center justify-between p-4 border rounded-lg">
//                                                 <div>
//                                                     <div className="flex items-center gap-2">
//                                                         <span className="font-medium">General Interview</span>
//                                                         <Badge variant="success">Completed</Badge>
//                                                     </div>
//                                                     <div className="flex items-center gap-3 mt-1 text-sm text-gray-600">
//                                                         <div className="flex items-center gap-1">
//                                                             <CalendarIcon className="w-4 h-4" />
//                                                             <span>Today</span>
//                                                         </div>
//                                                         <div className="flex items-center gap-1">
//                                                             <Clock className="w-4 h-4" />
//                                                             <span>45 minutes</span>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <Button variant="outline">View Summary</Button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </Card>
//                     </div>
//                 </div>
//             </div>

//             {/* Create Interview Modal */}
//             <CreateInterviewModal
//                 isOpen={isModalOpen}
//                 onClose={() => setIsModalOpen(false)}
//                 onLaunch={handleModalLaunch}
//                 type={sessionType}
//             />
//         </div>
//     );
// };

// export default InterviewDashboard;
// InterviewDashboard.jsx


import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseConfig';
import {
    Plus,
    Search,
    CalendarIcon,
    Clock,
    Filter,
    ArrowRight,
    AlertCircle
} from 'lucide-react';

import CreateInterviewModal from './CreateInterviewModal';
import SessionSetupFlow from './SessionSetupFlow';
import LiveInterviewManagement from './LiveInterviewManagement';

// Constants
const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

const Badge = ({ children, variant }) => {
    const variants = {
        default: 'bg-blue-100 text-blue-800',
        success: 'bg-green-100 text-green-800',
        warning: 'bg-yellow-100 text-yellow-800',
        error: 'bg-red-100 text-red-800',
        inProgress: 'bg-orange-100 text-orange-800'
    };

    return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${variants[variant]}`}>
            {children}
        </span>
    );
};

const InterviewDashboard = () => {
    // View Management
    const [currentView, setCurrentView] = useState('dashboard');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sessionData, setSessionData] = useState(null);
    const [error, setError] = useState(null);

    // Interview Data
    const [interviews, setInterviews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState({
        status: 'all',
        dateRange: 'all'
    });

    useEffect(() => {
        fetchInterviews();
        return () => {
            // Cleanup any active streams when dashboard unmounts
            cleanupStreams();
        };
    }, []);

    const cleanupStreams = () => {
        if (sessionData?.stream) {
            sessionData.stream.getTracks().forEach(track => track.stop());
        }
        if (sessionData?.displayStream) {
            sessionData.displayStream.getTracks().forEach(track => track.stop());
        }
    };

    const fetchInterviews = async () => {
        try {
            setError(null);
            const { data, error } = await supabase
                .from('interview_sessions')
                .select('*')
                .eq('user_id', isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id)
                .order('created_at', { ascending: false });

            if (error) throw error;
            setInterviews(data || []);
        } catch (error) {
            console.error('Error fetching interviews:', error);
            setError('Failed to load interviews. Please try again.');
            if (isDev) {
                setInterviews([{
                    id: 1,
                    company_name: 'Example Corp',
                    job_description: 'Senior Developer',
                    status: 'completed',
                    created_at: new Date().toISOString()
                }]);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Flow Handlers
    const handleModalComplete = async (formData) => {
        try {
            setError(null);
            setSessionData(formData);
            setIsModalOpen(false);
            setCurrentView('interview');
        } catch (error) {
            console.error('Error:', error);
            setError('Error creating interview. Please try again.');
        }
    };

    const handleSetupComplete = (setupData) => {
        try {
            setSessionData(prev => ({
                ...prev,
                ...setupData,
                status: 'in_progress',
                startedAt: new Date().toISOString()
            }));
            setCurrentView('interview');
        } catch (error) {
            console.error('Setup completion error:', error);
            setError('Error starting interview. Please try again.');
            handleInterviewEnd();
        }
    };

    const handleSetupCancel = () => {
        cleanupStreams();
        setCurrentView('dashboard');
        setSessionData(null);
    };

    const handleInterviewEnd = async () => {
        try {
            // Cleanup streams
            cleanupStreams();

            // Update interview status in Supabase
            if (sessionData?.dbSession?.id) {
                await supabase
                    .from('interview_sessions')
                    .update({
                        status: 'completed',
                        completed_at: new Date().toISOString()
                    })
                    .eq('id', sessionData.dbSession.id);
            }

            setCurrentView('dashboard');
            setSessionData(null);
            fetchInterviews(); // Refresh interview list
        } catch (error) {
            console.error('Error ending interview:', error);
            setError('Error ending interview. Your progress has been saved.');
        }
    };

    const handleContinueInterview = async (interview) => {
        try {
            setSessionData({
                ...interview,
                dbSession: interview
            });
            setCurrentView('setup');
        } catch (error) {
            console.error('Error continuing interview:', error);
            setError('Error resuming interview. Please try again.');
        }
    };

    // Filter interviews
    const filteredInterviews = interviews.filter(interview => {
        const matchesSearch =
            interview.company_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            interview.job_description?.toLowerCase().includes(searchQuery.toLowerCase());

        if (!matchesSearch) return false;

        if (filters.status !== 'all' && interview.status !== filters.status) return false;

        // Add date range filtering if needed
        return true;
    });

    // Render Different Views
    if (currentView === 'setup') {
        return (
            <SessionSetupFlow
                sessionData={sessionData}
                onComplete={handleSetupComplete}
                onCancel={handleSetupCancel}
            />
        );
    }

    if (currentView === 'interview') {
        return (
            <LiveInterviewManagement
                sessionData={sessionData}  // Pass the complete session data
                onEnd={handleInterviewEnd}
            />
        );
    }

    // Dashboard View
    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-2xl font-bold">Interview Sessions</h1>
                        <p className="text-gray-500 mt-1">Manage and track your interview practice</p>
                    </div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg 
                            hover:bg-orange-600 transition-colors"
                    >
                        <Plus className="w-4 h-4" />
                        New Interview
                    </button>
                </div>

                {/* Error Display */}
                {error && (
                    <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-lg">
                        <div className="flex items-start gap-3">
                            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
                            <p className="text-red-800">{error}</p>
                        </div>
                    </div>
                )}

                {/* Search and Filters */}
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mb-6">
                    <div className="flex gap-4">
                        <div className="flex-1 relative">
                            <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search by company or position..."
                                className="w-full pl-10 pr-4 py-2 border rounded-md"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <select
                            className="px-4 py-2 border rounded-md bg-white"
                            value={filters.status}
                            onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
                        >
                            <option value="all">All Status</option>
                            <option value="created">Created</option>
                            <option value="in_progress">In Progress</option>
                            <option value="completed">Completed</option>
                        </select>
                    </div>
                </div>

                {/* Interview Table */}
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                    <table className="w-full">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Company
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Position
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {isLoading ? (
                                <tr>
                                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                        Loading interviews...
                                    </td>
                                </tr>
                            ) : filteredInterviews.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                        No interviews found
                                    </td>
                                </tr>
                            ) : (
                                filteredInterviews.map((interview) => (
                                    <tr key={interview.id} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="font-medium text-gray-900">
                                                {interview.company_name}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-900">
                                                {interview.job_description}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center gap-1 text-sm text-gray-500">
                                                <CalendarIcon className="w-4 h-4" />
                                                {new Date(interview.created_at).toLocaleDateString()}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <Badge variant={
                                                interview.status === 'completed' ? 'success' :
                                                    interview.status === 'in_progress' ? 'inProgress' :
                                                        interview.status === 'cancelled' ? 'error' :
                                                            'default'
                                            }>
                                                {interview.status === 'in_progress' ? 'In Progress' :
                                                    interview.status.charAt(0).toUpperCase() + interview.status.slice(1)}
                                            </Badge>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right">
                                            {interview.status === 'completed' ? (
                                                <button
                                                    className="text-sm text-blue-600 hover:text-blue-800"
                                                    onClick={() => {/* Handle summary view */ }}
                                                >
                                                    View Summary
                                                </button>
                                            ) : (
                                                <button
                                                    className="text-sm text-orange-600 hover:text-orange-800"
                                                    onClick={() => handleContinueInterview(interview)}
                                                >
                                                    Continue
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Create Interview Modal */}
            <CreateInterviewModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onComplete={handleModalComplete}
            />
        </div>
    );
};

export default InterviewDashboard;