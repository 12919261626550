// // import React, { useState, useEffect, useLayoutEffect, useCallback, useMemo } from 'react';
// // import {
// //   Rocket,
// //   LayoutDashboard,
// //   Search,
// //   Settings,
// //   ChevronRight,
// //   ChevronLeft,
// //   History,
// //   FileText,
// //   Bot,
// //   Chrome,
// //   User,
// //   Wallet,
// //   Mail,
// //   Shield,
// //   Sparkles,
// //   LoaderCircle,
// //   BadgePlus
// // } from 'lucide-react';
// // import { SideBarMobileToggleButton } from './SideBarMobileToggleButton';
// // import { NavLink, useLocation, useNavigate } from 'react-router';
// // import { throttle } from '../../../utilities/throttle';
// // import { SideBarMenuItem } from './SideBarMenuItem';
// // import { Portal } from '@radix-ui/react-portal';

// // // SideBar UI States:
// // // 1. Expanded Desktop
// // // 2. Collapsed Desktop
// // // 3 Closed Desktop
// // // 4. Open Mobile
// // // 5. Closed Mobile
// // const layoutStateClassNames = {
// //   expandedDesktop: 'w-60 translate-x-0',
// //   collapsedDesktop: 'w-17 translate-x-0',
// //   closedDesktop: 'w-0 -translate-x-full',
// //   openMobile: 'w-60 translate-x-0',
// //   closedMobile: 'w-0 -translate-x-full',
// // };

// // const togglerLayoutClassNames = {
// //   expandedDesktop: 'absolute right-0 mr-1',
// //   collapsedDesktop: '',
// //   closedDesktop: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
// //   openMobile: '',
// //   closedMobile: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
// // };

// // export const SideBar = ({ onSideBarStateChange, hasCurrentCampaign }) => {
// //   const [isExpanded, setIsExpanded] = useState(true);
// //   const [isOpen, setIsOpen] = useState(true);
// //   const [isMobile, setIsMobile] = useState(false);
// //   const [openSubMenu, setOpenSubMenu] = useState(null);
// //   const isOpenResponsive = isMobile ? isOpen : isExpanded;
// //   const [showOverlay, setShowOverlay] = useState(false);
// //   const [activeView, setActiveView] = useState('default'); // Default active view

// //   const [layoutState, setLayoutState] = useState('closedDesktop');

// //   const toggleSubMenu = (id) => {
// //     setOpenSubMenu((prevState) => (prevState === id ? null : id));
// //   };
// //   const navigationItems = [
// //     {
// //       id: 'auto-apply',
// //       label: (
// //         <div className="flex items-center flex-nowrap gap-2">
// //           Auto Apply
// //           <BadgePlus className={'w-6 text-green-600'} />

// //         </div>
// //       ),
// //       icon: Rocket,
// //       icon2: hasCurrentCampaign && LoaderCircle,
// //       highlight: true,
// //       forceFirst: true,
// //     },
// //     {
// //       id: 'dashboard',
// //       label: 'Dashboard',
// //       icon: LayoutDashboard,
// //     },
// //     // {
// //     //   id: 'resume',
// //     //   label: 'Resume Builder',
// //     //   icon: FileText,
// //     // },
// //     {
// //       id: 'history',
// //       label: 'Application History',
// //       icon: History,
// //     },
// //     {
// //       id: 'robopilot',
// //       label: 'Interview Copilot',
// //       icon: Bot,
// //     },

// //     {
// //       id: 'extension',
// //       label: 'Chrome Extension (beta)',
// //       icon: Chrome,
// //     },
// //     {
// //       id: 'job-search',
// //       label: 'Job Search',
// //       icon: Search,
// //     },
// //     {
// //       id: 'settings',
// //       label: 'Settings',
// //       icon: Settings,
// //       subItems: [
// //         { id: 'personal', to: 'settings/personal', label: 'Personal Info', icon: User },
// //         { id: 'billing', to: 'settings/billing', label: 'Credits & Billing', icon: Wallet },
// //         { id: 'notifications', to: 'settings/notifications', label: 'Notifications', icon: Mail },
// //         // { id: 'security', label: 'Security', icon: Shield },
// //       ],
// //     },
// //   ].sort((a, b) => {
// //     if (a.forceFirst) return -1;
// //     if (b.forceFirst) return 1;
// //     return 0;
// //   });
// //   // Function to calculate the layout state
// //   const getLayoutState = useCallback(() => {
// //     let layoutState = '';
// //     if (isExpanded && !isMobile) layoutState = 'expandedDesktop';
// //     if (!isExpanded && !isMobile && isOpen) layoutState = 'collapsedDesktop';
// //     if (!isExpanded && !isMobile && !isOpen) layoutState = 'closedDesktop';
// //     if (isMobile && isOpen) layoutState = 'openMobile';
// //     if (isMobile && !isOpen) layoutState = 'closedMobile';
// //     onSideBarStateChange && onSideBarStateChange(layoutState);
// //     return layoutState;
// //   }, [isExpanded, isMobile, isOpen, onSideBarStateChange]);

// //   // Effect to call the onLayoutChange callback whenever layout state changes
// //   useEffect(() => {
// //     const newState = getLayoutState();
// //     setLayoutState(newState);
// //   }, [getLayoutState]);

// //   useLayoutEffect(() => {
// //     const handleResize = () => {
// //       if (window.innerWidth >= 1024) {
// //         // setIsExpanded(true);
// //         // setIsMobileMenuOpen(false);
// //         setIsMobile(false);
// //         return;
// //       }
// //       setIsMobile(true);
// //     };
// //     const debouncedHandleResize = throttle(handleResize, 100);

// //     window.addEventListener('resize', debouncedHandleResize);
// //     return () => window.removeEventListener('resize', handleResize);
// //   }, []);

// //   const location = useLocation();
// //   const firstSegment = useMemo(() => {
// //     const segments = location.pathname.split('/').filter(Boolean);
// //     return segments.length > 0 ? segments[0] : null;
// //   }, [location.pathname]);

// //   useEffect(() => {
// //     setActiveView(firstSegment);
// //   }, [location.pathname]);

// //   const handleViewChange = (id, hasSubItems = false) => {
// //     activeView === id ? setActiveView('default') : setActiveView(id);

// //     if (isMobile && !hasSubItems) {
// //       setIsOpen(false);
// //     }
// //     if (!isMobile && !hasSubItems) {
// //       setIsExpanded(false);
// //     }
// //   };

// //   const handleToggleClick = () => {
// //     console.log('handleToggleClick: isMobile, isExpanded, isOpen', isMobile, isExpanded, isOpen);
// //     if (isMobile) {
// //       // setIsExpanded(!isExpanded);
// //       // setShowOverlay(!isOpen);
// //       setIsOpen(!isOpen);
// //       return;
// //     }
// //     // set isOpen to true unless explicitly set to false( in handleToggleClose)
// //     setIsOpen(true);
// //     setIsExpanded(!isExpanded);
// //   };

// //   const handleToggleClose = () => {
// //     console.log('handleToggleClose: isMobile, isExpanded, isOpen', isMobile, isExpanded, isOpen);
// //     setIsExpanded(false);
// //     setIsOpen(false);
// //   };

// //   const navLinkIconClass = layoutState === 'closedDesktop' ? 'h-0 w-0' : 'h-6 w-6';
// //   // const containerClassName = `flex h-screen fixed z-40 transition-all duration-300 ease-in-out ${layoutClassName} `;
// //   const isCollapsed = layoutState === 'collapsedDesktop';
// //   return (
// //     <aside className={`z-40 relative transition-all duration-300 ease-in-out ${layoutStateClassNames[layoutState]}`}>
// //       {showOverlay && (
// //         <Portal container={document.getElementById('main-tag')}>
// //           <div
// //             className="lg:hidden absolute inset-0 bg-black bg-opacity-50 z-20"
// //             onClick={() => setShowOverlay(false)}
// //           />
// //         </Portal>
// //       )}
// //       <div className={'min-h-16 flex flex-nowrap w-full items-center justify-between'}>
// //         <SideBarMobileToggleButton
// //           onClick={handleToggleClose}
// //           className={`${layoutState === 'closedDesktop' ? 'hidden' : 'block'} ${isMobile && 'hidden'} w-10`}
// //           Icon={ChevronLeft}
// //           iconClassName={`transition-transform duration-300 `}
// //           doubleIcon
// //         />
// //         <SideBarMobileToggleButton
// //           onClick={handleToggleClick}
// //           className={togglerLayoutClassNames[layoutState]}
// //           iconClassName={`transition-transform duration-300 ${isOpenResponsive && 'rotate-180 text-blue-500 scale-150'}`}
// //         />
// //       </div>
// //       <div className={'h-full w-full mt-4 overflow-hidden'}>
// //         <nav>
// //           <ul className="space-y-2">
// //             {navigationItems.map((item) => (
// //               <li key={item.id}>
// //                 <SideBarMenuItem
// //                   {...item}
// //                   isOpen={openSubMenu === item.id}
// //                   onClick={() => {
// //                     toggleSubMenu(item.id); // Toggle the submenu
// //                     handleViewChange(item.id, !!item?.subItems); // Pass true if the item has subItems
// //                   }}
// //                   isCollapsed={isCollapsed}
// //                   activeView={activeView} // Pass activeView for active styles
// //                 />
// //                 {item.subItems && openSubMenu === item.id && (
// //                   <div className="ml-6 space-y-1">
// //                     {item.subItems.map((subItem) => (
// //                       <SideBarMenuItem
// //                         key={subItem.id}
// //                         {...subItem}
// //                         isCollapsed={isCollapsed}
// //                         onClick={() => {
// //                           toggleSubMenu(item.id);
// //                           handleViewChange(item.id);
// //                         }}
// //                         activeView={activeView} // Pass activeView for sub-items
// //                       />
// //                     ))}
// //                   </div>
// //                 )}
// //               </li>
// //             ))}
// //           </ul>
// //         </nav>
// //       </div>
// //     </aside>
// //   );
// // };
// import React, { useState, useEffect, useLayoutEffect, useCallback, useMemo } from 'react';
// import {
//   Rocket,
//   Search,
//   Settings,
//   ChevronRight,
//   ChevronLeft,
//   History,
//   FileText,
//   Bot,
//   Chrome,
//   User,
//   Wallet,
//   Mail,
//   Sparkles,
//   LoaderCircle,
//   BadgePlus,
//   Linkedin,
//   Globe,
//   Briefcase,
//   Building,
//   Home,
//   Pencil
// } from 'lucide-react';
// import { NavLink, useLocation } from 'react-router';
// import { throttle } from '../../../utilities/throttle';
// import { Portal } from '@radix-ui/react-portal';

// // Layout state configurations
// const layoutStateClassNames = {
//   expandedDesktop: 'w-60 translate-x-0',
//   collapsedDesktop: 'w-17 translate-x-0',
//   closedDesktop: 'w-0 -translate-x-full',
//   openMobile: 'w-60 translate-x-0',
//   closedMobile: 'w-0 -translate-x-full',
// };

// const togglerLayoutClassNames = {
//   expandedDesktop: 'absolute right-0 mr-1',
//   collapsedDesktop: '',
//   closedDesktop: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
//   openMobile: '',
//   closedMobile: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
// };

// // Mobile Toggle Button Component
// const SideBarMobileToggleButton = ({ onClick, className, Icon = ChevronRight, iconClassName, doubleIcon }) => (
//   <button
//     onClick={onClick}
//     className={`p-2 hover:bg-gray-100 rounded-lg transition-colors ${className}`}
//   >
//     {doubleIcon ? (
//       <div className="flex -space-x-1">
//         <Icon className={`h-5 w-5 ${iconClassName}`} />
//         <Icon className={`h-5 w-5 ${iconClassName}`} />
//       </div>
//     ) : (
//       <Icon className={`h-5 w-5 ${iconClassName}`} />
//     )}
//   </button>
// );

// // MenuItem Component
// const SideBarMenuItem = ({
//   label,
//   icon: Icon,
//   icon2: Icon2,
//   to,
//   isOpen,
//   onClick,
//   subItems,
//   isCollapsed,
//   activeView,
//   type,
//   highlight,
//   level = 0,
// }) => {
//   const isSection = type === 'section';
//   const isActive = activeView === (typeof label === 'string' ? label.toLowerCase() : '');
//   const isSubItem = level > 0;

//   if (isSection) {
//     return (
//       <div className="pt-5 pb-2 px-3">
//         <div className="text-xs font-semibold text-gray-400">
//           {typeof label === 'string' ? label : label}
//         </div>
//       </div>
//     );
//   }

//   const menuItemStyles = `
//     flex items-center w-full p-2 transition-colors duration-200
//     ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'}
//     ${isCollapsed ? 'justify-center' : 'justify-between'}
//     ${highlight ? 'bg-green-50 text-green-600 font-medium' : ''}
//     ${isSubItem ? `pl-${level * 4 + 5}` : ''}
//     rounded-lg
//   `;

//   const content = (
//     <>
//       <div className="flex items-center gap-3">
//         {Icon && (
//           <div className="relative">
//             <Icon className={`h-5 w-5 ${isActive ? 'text-blue-600' : ''}`} />
//             {Icon2 && <Icon2 className="absolute top-0 right-0 h-3 w-3 animate-spin text-blue-500" />}
//           </div>
//         )}
//         {!isCollapsed && (typeof label === 'string' ? <span>{label}</span> : label)}
//       </div>
//       {!isCollapsed && subItems && (
//         <ChevronRight
//           className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-90' : ''}`}
//         />
//       )}
//     </>
//   );

//   if (to) {
//     return (
//       <NavLink to={to} className={menuItemStyles}>
//         {content}
//       </NavLink>
//     );
//   }

//   return (
//     <button onClick={onClick} className={menuItemStyles}>
//       {content}
//     </button>
//   );
// };

// // Navigation Items Configuration
// const getNavigationItems = (hasCurrentCampaign) => [
//   {
//     id: 'hub',
//     label: 'Hub',
//     icon: Home,
//     to: '/hub',
//     forceFirst: true,
//   },

//   // Applications Section
//   {
//     id: 'applications-section',
//     label: 'APPLICATIONS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'resumes',
//         label: 'Resumes',
//         icon: FileText,
//         to: '/resume',
//       },
//       {
//         id: 'editor',
//         label: (
//           <div className="flex items-center flex-nowrap gap-2">
//             Editor
//             <BadgePlus className="w-4 h-4 text-green-600 animate-pulse" />
//           </div>
//         ),
//         icon: Pencil,
//         to: '/resume/editor',
//       },
//       {
//         id: 'history',
//         label: 'Application History',
//         icon: History,
//         to: '/history',
//       },
//       {
//         id: 'job-search',
//         label: 'Job Search',
//         icon: Search,
//         to: '/jobs',
//       },
//     ],
//   },

//   // Auto Apply Section
//   {
//     id: 'auto-apply-section',
//     label: 'AUTO APPLY',
//     type: 'section',
//     subItems: [
//       {
//         id: 'auto-apply',
//         label: (
//           <div className="flex items-center flex-nowrap gap-2">
//             Auto Apply
//             <BadgePlus className="w-6 text-green-600" />
//           </div>
//         ),
//         icon: Rocket,
//         icon2: hasCurrentCampaign && LoaderCircle,
//         highlight: true,
//         to: '/auto-apply',
//       },
//       {
//         id: 'extension',
//         label: 'Chrome Extension (beta)',
//         icon: Chrome,
//         subItems: [
//           {
//             id: 'linkedin-ext',
//             label: 'LinkedIn',
//             icon: Linkedin,
//             to: '/extension/linkedin',
//           },
//           {
//             id: 'monster-ext',
//             label: 'Monster',
//             icon: Briefcase,
//             to: '/extension/monster',
//           },
//           {
//             id: 'indeed-ext',
//             label: 'Indeed',
//             icon: Globe,
//             to: '/extension/indeed',
//           },
//           {
//             id: 'glassdoor-ext',
//             label: 'Glassdoor',
//             icon: Building,
//             to: '/extension/glassdoor',
//           },
//         ],
//       },
//     ],
//   },

//   // AI Tools Section
//   {
//     id: 'ai-tools-section',
//     label: 'AI TOOLS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'copilot',
//         label: 'Interview Copilot',
//         icon: Bot,
//         to: '/copilot',
//       },
//     ],
//   },

//   // Settings Section
//   {
//     id: 'settings-section',
//     label: 'SETTINGS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'settings',
//         label: 'Settings',
//         icon: Settings,
//         to: '/settings',
//         subItems: [
//           { id: 'personal', to: '/settings/personal', label: 'Personal Info', icon: User },
//           { id: 'billing', to: '/settings/billing', label: 'Credits & Billing', icon: Wallet },
//           { id: 'notifications', to: '/settings/notifications', label: 'Notifications', icon: Mail },
//         ],
//       },
//     ],
//   },
// ];

// // Main SideBar Component
// export const SideBar = ({ onSideBarStateChange, hasCurrentCampaign = false }) => {
//   const [isExpanded, setIsExpanded] = useState(true);
//   const [isOpen, setIsOpen] = useState(true);
//   const [isMobile, setIsMobile] = useState(false);
//   const [openSubMenu, setOpenSubMenu] = useState(null);
//   const [showOverlay, setShowOverlay] = useState(false);
//   const [activeView, setActiveView] = useState('default');
//   const [layoutState, setLayoutState] = useState('closedDesktop');

//   const isOpenResponsive = isMobile ? isOpen : isExpanded;
//   const navigationItems = useMemo(() => getNavigationItems(hasCurrentCampaign), [hasCurrentCampaign]);

//   const toggleSubMenu = (id) => {
//     setOpenSubMenu((prevState) => (prevState === id ? null : id));
//   };

//   const getLayoutState = useCallback(() => {
//     let state = '';
//     if (isExpanded && !isMobile) state = 'expandedDesktop';
//     if (!isExpanded && !isMobile && isOpen) state = 'collapsedDesktop';
//     if (!isExpanded && !isMobile && !isOpen) state = 'closedDesktop';
//     if (isMobile && isOpen) state = 'openMobile';
//     if (isMobile && !isOpen) state = 'closedMobile';
//     onSideBarStateChange?.(state);
//     return state;
//   }, [isExpanded, isMobile, isOpen, onSideBarStateChange]);

//   useEffect(() => {
//     setLayoutState(getLayoutState());
//   }, [getLayoutState]);

//   useLayoutEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 1024);
//     };
//     const debouncedHandleResize = throttle(handleResize, 100);

//     window.addEventListener('resize', debouncedHandleResize);
//     return () => window.removeEventListener('resize', debouncedHandleResize);
//   }, []);

//   const location = useLocation();
//   const firstSegment = useMemo(() => {
//     const segments = location.pathname.split('/').filter(Boolean);
//     return segments.length > 0 ? segments[0] : null;
//   }, [location.pathname]);

//   useEffect(() => {
//     setActiveView(firstSegment);
//   }, [firstSegment]);

//   const handleViewChange = (id, hasSubItems = false) => {
//     setActiveView(activeView === id ? 'default' : id);
//     if (isMobile && !hasSubItems) setIsOpen(false);
//     if (!isMobile && !hasSubItems) setIsExpanded(false);
//   };

//   const handleToggleClick = () => {
//     if (isMobile) {
//       setIsOpen(!isOpen);
//       return;
//     }
//     setIsOpen(true);
//     setIsExpanded(!isExpanded);
//   };

//   const handleToggleClose = () => {
//     setIsExpanded(false);
//     setIsOpen(false);
//   };

//   const isCollapsed = layoutState === 'collapsedDesktop';

//   const renderMenuItem = (item, level = 0) => (
//     <li key={item.id}>
//       <SideBarMenuItem
//         {...item}
//         isOpen={openSubMenu === item.id}
//         onClick={() => {
//           toggleSubMenu(item.id);
//           handleViewChange(item.id, !!item?.subItems);
//         }}
//         isCollapsed={isCollapsed}
//         activeView={activeView}
//         level={level}
//       />
//       {item.subItems && (openSubMenu === item.id || item.type === 'section') && (
//         <div className={`${item.type !== 'section' && level === 0 ? 'ml-6' : ''} space-y-1`}>
//           {item.subItems.map(subItem => renderMenuItem(subItem, level + 1))}
//         </div>
//       )}
//     </li>
//   );

//   return (
//     <aside className={`z-40 relative transition-all duration-300 ease-in-out ${layoutStateClassNames[layoutState]}`}>
//       {showOverlay && (
//         <Portal container={document.getElementById('main-tag')}>
//           <div
//             className="lg:hidden absolute inset-0 bg-black bg-opacity-50 z-20"
//             onClick={() => setShowOverlay(false)}
//           />
//         </Portal>
//       )}

//       <div className="min-h-16 flex flex-nowrap w-full items-center justify-between">
//         <SideBarMobileToggleButton
//           onClick={handleToggleClose}
//           className={`${layoutState === 'closedDesktop' ? 'hidden' : 'block'} ${isMobile && 'hidden'} w-10`}
//           Icon={ChevronLeft}
//           iconClassName="transition-transform duration-300"
//           doubleIcon
//         />
//         <SideBarMobileToggleButton
//           onClick={handleToggleClick}
//           className={togglerLayoutClassNames[layoutState]}
//           iconClassName={`transition-transform duration-300 ${isOpenResponsive && 'rotate-180 text-blue-500 scale-150'}`}
//         />
//       </div>

//       <div className="h-full w-full mt-4 overflow-hidden">
//         <nav>
//           <ul className="space-y-2">
//             {navigationItems.map(item => renderMenuItem(item))}
//           </ul>
//         </nav>
//       </div>

//       <style jsx global>{`
//         @keyframes pulse {
//           0%, 100% { opacity: 1; }
//           50% { opacity: 0.5; }
//         }
//         .animate-pulse {
//           animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
//         }
//       `}</style>
//     </aside>
//   );
// };

// export default SideBar;

// src/components/SideBar.jsx
// import React, { useEffect, useLayoutEffect, useCallback, useMemo, useState } from 'react';
// import {
//   Rocket,
//   Search,
//   Settings,
//   ChevronRight,
//   ChevronLeft,
//   History,
//   FileText,
//   Bot,
//   Chrome,
//   User,
//   Wallet,
//   Mail,
//   Sparkles,
//   LoaderCircle,
//   BadgePlus,
//   Linkedin,
//   Globe,
//   Briefcase,
//   Building,
//   Home,
//   Pencil
// } from 'lucide-react';
// import { NavLink, useLocation } from 'react-router'; // Updated import
// import { throttle } from '../../../utilities/throttle';
// import { Portal } from '@radix-ui/react-portal';

// import ResumeActionButton from '../../resumepage/ResumeActionButton';
// // Layout state configurations
// const layoutStateClassNames = {
//   expandedDesktop: 'w-60 translate-x-0',
//   collapsedDesktop: 'w-17 translate-x-0',
//   closedDesktop: 'w-0 -translate-x-full',
//   openMobile: 'w-60 translate-x-0',
//   closedMobile: 'w-0 -translate-x-full',
// };

// const togglerLayoutClassNames = {
//   expandedDesktop: 'absolute right-0 mr-1',
//   collapsedDesktop: '',
//   closedDesktop: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
//   openMobile: '',
//   closedMobile: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
// };

// // Mobile Toggle Button Component
// const SideBarMobileToggleButton = ({ onClick, className, Icon = ChevronRight, iconClassName, doubleIcon }) => (
//   <button
//     onClick={onClick}
//     className={`p-2 hover:bg-gray-100 rounded-lg transition-colors ${className}`}
//   >
//     {doubleIcon ? (
//       <div className="flex -space-x-1">
//         <Icon className={`h-5 w-5 ${iconClassName}`} />
//         <Icon className={`h-5 w-5 ${iconClassName}`} />
//       </div>
//     ) : (
//       <Icon className={`h-5 w-5 ${iconClassName}`} />
//     )}
//   </button>
// );

// // MenuItem Component
// const SideBarMenuItem = ({
//   label,
//   icon: Icon,
//   icon2: Icon2,
//   to,
//   isOpen,
//   onClick,
//   subItems,
//   isCollapsed,
//   activeView,
//   type,
//   highlight,
//   level = 0,
// }) => {
//   const isSection = type === 'section';
//   const isActive = activeView === (typeof label === 'string' ? label.toLowerCase() : '');
//   const isSubItem = level > 0;

//   if (isSection) {
//     return (
//       <div className="pt-5 pb-2 px-3">
//         <div className="text-xs font-semibold text-gray-400">
//           {typeof label === 'string' ? label : label}
//         </div>
//       </div>
//     );
//   }

//   const menuItemStyles = `
//     flex items-center w-full p-2 transition-colors duration-200
//     ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'}
//     ${isCollapsed ? 'justify-center' : 'justify-between'}
//     ${highlight ? 'bg-green-50 text-green-600 font-medium' : ''}
//     ${isSubItem ? `pl-${level * 4 + 5}` : ''}
//     rounded-lg
//   `;

//   const content = (
//     <>
//       <div className="flex items-center gap-3">
//         {Icon && (
//           <div className="relative">
//             <Icon className={`h-5 w-5 ${isActive ? 'text-blue-600' : ''}`} />
//             {Icon2 && <Icon2 className="absolute top-0 right-0 h-3 w-3 animate-spin text-blue-500" />}
//           </div>
//         )}
//         {!isCollapsed && (typeof label === 'string' ? <span>{label}</span> : label)}
//       </div>
//       {!isCollapsed && subItems && (
//         <ChevronRight
//           className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-90' : ''}`}
//         />
//       )}
//     </>
//   );

//   if (to) {
//     return (
//       <NavLink to={to} className={menuItemStyles}>
//         {content}
//       </NavLink>
//     );
//   }

//   return (
//     <button onClick={onClick} className={menuItemStyles}>
//       {content}
//     </button>
//   );
// };

// // Navigation Items Configuration
// const getNavigationItems = (hasCurrentCampaign) => [
//   {
//     id: 'hub',
//     label: 'Hub',
//     icon: Home,
//     to: '/hub',
//     forceFirst: true,
//   },

//   // Applications Section
//   {
//     id: 'applications-section',
//     label: 'APPLICATIONS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'resumes',
//         label: 'Resumes',
//         icon: FileText,
//         to: '/resume',
//       },
//       // Removed the static 'editor' link
//       {
//         id: 'application-history',
//         label: 'Application History',
//         icon: History,
//         to: '/history',
//       },
//       {
//         id: 'job-search',
//         label: 'Job Search',
//         icon: Search,
//         to: '/jobs',
//       },
//     ],
//   },

//   // Auto Apply Section
//   {
//     id: 'auto-apply-section',
//     label: 'AUTO APPLY',
//     type: 'section',
//     subItems: [
//       {
//         id: 'auto-apply',
//         label: (
//           <div className="flex items-center flex-nowrap gap-2">
//             Auto Apply
//             <BadgePlus className="w-6 text-green-600" />
//           </div>
//         ),
//         icon: Rocket,
//         icon2: hasCurrentCampaign && LoaderCircle,
//         highlight: true,
//         to: '/auto-apply',
//       },
//       {
//         id: 'extension',
//         label: 'Chrome Extension (beta)',
//         icon: Chrome,
//         subItems: [
//           {
//             id: 'linkedin-ext',
//             label: 'LinkedIn',
//             icon: Linkedin,
//             to: '/extension/linkedin',
//           },
//           {
//             id: 'monster-ext',
//             label: 'Monster',
//             icon: Briefcase,
//             to: '/extension/monster',
//           },
//           {
//             id: 'indeed-ext',
//             label: 'Indeed',
//             icon: Globe,
//             to: '/extension/indeed',
//           },
//           {
//             id: 'glassdoor-ext',
//             label: 'Glassdoor',
//             icon: Building,
//             to: '/extension/glassdoor',
//           },
//         ],
//       },
//     ],
//   },

//   // AI Tools Section
//   {
//     id: 'ai-tools-section',
//     label: 'AI TOOLS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'copilot',
//         label: 'Interview Copilot',
//         icon: Bot,
//         to: '/copilot',
//       },
//     ],
//   },

//   // Settings Section
//   {
//     id: 'settings-section',
//     label: 'SETTINGS',
//     type: 'section',
//     subItems: [
//       {
//         id: 'settings',
//         label: 'Settings',
//         icon: Settings,
//         to: '/settings',
//         subItems: [
//           { id: 'personal', to: '/settings/personal', label: 'Personal Info', icon: User },
//           { id: 'billing', to: '/settings/billing', label: 'Credits & Billing', icon: Wallet },
//           { id: 'notifications', to: '/settings/notifications', label: 'Notifications', icon: Mail },
//         ],
//       },
//     ],
//   },
// ];

// // Main SideBar Component
// export const SideBar = ({ onSideBarStateChange, hasCurrentCampaign = false }) => {
//   const [isExpanded, setIsExpanded] = useState(true);
//   const [isOpen, setIsOpen] = useState(true);
//   const [isMobile, setIsMobile] = useState(false);
//   const [openSubMenu, setOpenSubMenu] = useState(null);
//   const [showOverlay, setShowOverlay] = useState(false);
//   const [activeView, setActiveView] = useState('default');
//   const [layoutState, setLayoutState] = useState('closedDesktop');

//   const isOpenResponsive = isMobile ? isOpen : isExpanded;
//   const navigationItems = useMemo(() => getNavigationItems(hasCurrentCampaign), [hasCurrentCampaign]);

//   const toggleSubMenu = (id) => {
//     setOpenSubMenu((prevState) => (prevState === id ? null : id));
//   };

//   const getLayoutState = useCallback(() => {
//     let state = '';
//     if (isExpanded && !isMobile) state = 'expandedDesktop';
//     if (!isExpanded && !isMobile && isOpen) state = 'collapsedDesktop';
//     if (!isExpanded && !isMobile && !isOpen) state = 'closedDesktop';
//     if (isMobile && isOpen) state = 'openMobile';
//     if (isMobile && !isOpen) state = 'closedMobile';
//     onSideBarStateChange?.(state);
//     return state;
//   }, [isExpanded, isMobile, isOpen, onSideBarStateChange]);

//   useEffect(() => {
//     setLayoutState(getLayoutState());
//   }, [getLayoutState]);

//   useLayoutEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 1024);
//     };
//     const debouncedHandleResize = throttle(handleResize, 100);

//     window.addEventListener('resize', debouncedHandleResize);
//     handleResize(); // Initialize on mount
//     return () => window.removeEventListener('resize', debouncedHandleResize);
//   }, []);

//   const location = useLocation();
//   const firstSegment = useMemo(() => {
//     const segments = location.pathname.split('/').filter(Boolean);
//     return segments.length > 0 ? segments[0] : null;
//   }, [location.pathname]);

//   useEffect(() => {
//     setActiveView(firstSegment);
//   }, [firstSegment]);

//   const handleViewChange = (id, hasSubItems = false) => {
//     setActiveView(activeView === id ? 'default' : id);
//     if (isMobile && !hasSubItems) setIsOpen(false);
//     if (!isMobile && !hasSubItems) setIsExpanded(false);
//   };

//   const handleToggleClick = () => {
//     if (isMobile) {
//       setIsOpen(!isOpen);
//       setShowOverlay(!isOpen);
//       return;
//     }
//     setIsOpen(true);
//     setIsExpanded(!isExpanded);
//   };

//   const handleToggleClose = () => {
//     setIsExpanded(false);
//     setIsOpen(false);
//     setShowOverlay(false);
//   };

//   const isCollapsed = layoutState === 'collapsedDesktop';

//   const renderMenuItem = (item, level = 0) => (
//     <li key={item.id}>
//       <SideBarMenuItem
//         {...item}
//         isOpen={openSubMenu === item.id}
//         onClick={() => {
//           if (item.subItems) {
//             toggleSubMenu(item.id);
//           } else {
//             handleViewChange(item.id, !!item?.subItems);
//           }
//         }}
//         isCollapsed={isCollapsed}
//         activeView={activeView}
//         level={level}
//       />
//       {item.subItems && (openSubMenu === item.id || item.type === 'section') && (
//         <ul className={`${item.type !== 'section' && level === 0 ? 'ml-6' : ''} space-y-1`}>
//           {item.subItems.map(subItem => renderMenuItem(subItem, level + 1))}
//         </ul>
//       )}
//     </li>
//   );

//   return (
//     <>
//       {/* Overlay for Mobile */}
//       {showOverlay && (
//         <Portal container={document.getElementById('main-tag')}>
//           <div
//             className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-20"
//             onClick={() => {
//               setShowOverlay(false);
//               setIsOpen(false);
//             }}
//           />
//         </Portal>
//       )}

//       {/* SideBar */}
//       <aside className={`z-40 relative transition-all duration-300 ease-in-out ${layoutStateClassNames[layoutState]}`}>
//         <div className="min-h-16 flex flex-nowrap w-full items-center justify-between">
//           {/* Close Button for Expanded Desktop */}
//           <SideBarMobileToggleButton
//             onClick={handleToggleClose}
//             className={`${layoutState === 'closedDesktop' ? 'hidden' : 'block'} ${isMobile && 'hidden'} w-10`}
//             Icon={ChevronLeft}
//             iconClassName="transition-transform duration-300"
//             doubleIcon
//           />
//           {/* Toggle Button */}
//           <SideBarMobileToggleButton
//             onClick={handleToggleClick}
//             className={togglerLayoutClassNames[layoutState]}
//             iconClassName={`transition-transform duration-300 ${isOpenResponsive && 'rotate-180 text-blue-500 scale-150'}`}
//           />
//         </div>

//         <div className="h-full w-full mt-4 overflow-hidden">
//           <nav>
//             <ul className="space-y-2">
//               {navigationItems.map(item => renderMenuItem(item))}
//             </ul>
//           </nav>
//         </div>

//         <style jsx global>{`
//           @keyframes pulse {
//             0%, 100% { opacity: 1; }
//             50% { opacity: 0.5; }
//           }
//           .animate-pulse {
//             animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
//           }
//         `}</style>
//       </aside>
//     </>
//   );
// };

// export default SideBar;
import React, { useEffect, useLayoutEffect, useCallback, useMemo, useState } from 'react';
import {
  Rocket,
  Search,
  Settings,
  ChevronRight,
  ChevronLeft,
  History,
  FileText,
  Bot,
  Chrome,
  User,
  Wallet,
  Mail,
  LoaderCircle,
  BadgePlus,
  Linkedin,
  Globe,
  Briefcase,
  Building,
  Home,
  Pencil
} from 'lucide-react';
import { NavLink, useLocation } from 'react-router';
import { throttle } from '../../../utilities/throttle';
import { Portal } from '@radix-ui/react-portal';

// Layout state configurations
const layoutStateClassNames = {
  expandedDesktop: 'w-60 translate-x-0',
  collapsedDesktop: 'w-17 translate-x-0',
  closedDesktop: 'w-0 -translate-x-full',
  openMobile: 'w-60 translate-x-0',
  closedMobile: 'w-0 -translate-x-full',
};

const togglerLayoutClassNames = {
  expandedDesktop: 'absolute right-0 mr-1',
  collapsedDesktop: '',
  closedDesktop: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
  openMobile: '',
  closedMobile: 'absolute -right-10 top-0 border border-l-0 border-gray-200 rounded-lg bg-blue-50',
};

// Mobile Toggle Button Component
const SideBarMobileToggleButton = ({
  onClick,
  className,
  Icon = ChevronRight,
  iconClassName,
  doubleIcon
}) => (
  <button
    onClick={onClick}
    className={`p-2 hover:bg-gray-100 rounded-lg transition-colors ${className}`}
    aria-label={doubleIcon ? 'Toggle Sidebar' : 'Collapse Sidebar'}
  >
    {doubleIcon ? (
      <div className="flex -space-x-1">
        <Icon className={`h-5 w-5 ${iconClassName}`} />
        <Icon className={`h-5 w-5 ${iconClassName}`} />
      </div>
    ) : (
      <Icon className={`h-5 w-5 ${iconClassName}`} />
    )}
  </button>
);

// MenuItem Component
const SideBarMenuItem = ({
  label,
  icon: Icon,
  icon2: Icon2,
  to,
  isOpen,
  onClick,
  subItems,
  isCollapsed,
  activeView,
  type,
  highlight,
  level = 0,
}) => {
  const location = useLocation();
  const isSection = type === 'section';
  const isActive = to && location.pathname.startsWith(to);
  const isSubItem = level > 0;

  // For highlight + active state
  const pulsatingClass = isActive && highlight ? 'animate-pulse-green' : '';

  // If it's a "section" label
  if (isSection) {
    return (
      <div className="pt-5 pb-2 px-3">
        <div className="text-xs font-semibold text-gray-400 uppercase tracking-wider">
          {typeof label === 'string' ? label : label}
        </div>
      </div>
    );
  }

  // Compute the padding class for sub-items here to avoid nested template issues
  const paddingClass = isSubItem ? `pl-${level * 4 + 5}` : '';

  // Build the menu item classes safely without nested backticks
  const menuItemStyles = `
    flex items-center w-full p-2 transition-all duration-200
    ${isActive ? 'bg-blue-50 text-blue-600' : 'text-gray-600 hover:bg-gray-100'}
    ${isCollapsed ? 'justify-center' : 'justify-between'}
    ${highlight ? 'hover:bg-green-50 font-medium' : ''}
    ${paddingClass}
    ${pulsatingClass}
    rounded-lg
  `;

  // The main content of the menu item: icon(s) + label
  const content = (
    <>
      <div className="flex items-center gap-3">
        {Icon && (
          <div className="relative">
            <Icon className={`h-5 w-5 ${isActive ? 'text-blue-600' : ''} transition-colors`} />
            {Icon2 && (
              <Icon2 className="absolute top-0 right-0 h-3 w-3 animate-spin text-blue-500" />
            )}
          </div>
        )}
        {!isCollapsed && <span className="truncate">{label}</span>}
      </div>
      {!isCollapsed && subItems && (
        <ChevronRight
          className={`h-4 w-4 transition-transform duration-200 ${isOpen ? 'rotate-90' : ''}`}
        />
      )}
    </>
  );

  // If item has a `to`, it's a NavLink
  if (to) {
    return (
      <NavLink
        to={to}
        className={menuItemStyles}
        title={typeof label === 'string' ? label : ''}
      >
        {content}
      </NavLink>
    );
  }

  // Otherwise it's a button (likely toggling subItems)
  return (
    <button onClick={onClick} className={menuItemStyles}>
      {content}
    </button>
  );
};

// Complete navigation items configuration
const getNavigationItems = (hasCurrentCampaign) => [
  {
    id: 'hub',
    label: 'Hub',
    icon: Home,
    to: '/hub',
    forceFirst: true,
  },
  {
    id: 'applications-section',
    label: 'APPLICATIONS',
    type: 'section',
    subItems: [
      {
        id: 'resumes',
        label: 'Resumes',
        icon: FileText,
        to: '/resume',
        highlight: true,
      },
      {
        id: 'application-history',
        label: 'Application History',
        icon: History,
        to: '/history',
      },
      {
        id: 'job-search',
        label: 'Job Search',
        icon: Search,
        to: '/jobs',
      },
    ],
  },
  {
    id: 'auto-apply-section',
    label: 'AUTO APPLY',
    type: 'section',
    subItems: [
      {
        id: 'auto-apply',
        label: (
          <div className="flex items-center flex-nowrap gap-2">
            Auto Apply
            <BadgePlus className="w-5 h-5 text-green-600" />
          </div>
        ),
        icon: Rocket,
        icon2: hasCurrentCampaign && LoaderCircle,
        highlight: true,
        to: '/auto-apply',
      },
      {
        id: 'extension',
        label: 'Chrome Extension',
        icon: Chrome,
        subItems: [
          {
            id: 'linkedin-ext',
            label: 'LinkedIn',
            icon: Linkedin,
            to: '/extension',
          },
          {
            id: 'monster-ext',
            label: 'Monster',
            icon: Briefcase,
            to: '/extension',
          },
          {
            id: 'indeed-ext',
            label: 'Indeed',
            icon: Globe,
            to: '/extension',
          },
          {
            id: 'glassdoor-ext',
            label: 'Glassdoor',
            icon: Building,
            to: '/extension',
          },
        ],
      },
    ],
  },
  {
    id: 'ai-tools-section',
    label: 'AI TOOLS',
    type: 'section',
    subItems: [
      {
        id: 'copilot',
        label: 'Interview Copilot',
        icon: Bot,
        to: '/copilot',
        highlight: true,
      },
    ],
  },
  {
    id: 'settings-section',
    label: 'SETTINGS',
    type: 'section',
    subItems: [
      {
        id: 'settings',
        label: 'Settings',
        icon: Settings,
        to: '/settings',
        subItems: [
          {
            id: 'personal',
            to: '/settings/personal',
            label: 'Personal Info',
            icon: User
          },
          {
            id: 'billing',
            to: '/settings/billing',
            label: 'Credits & Billing',
            icon: Wallet
          },
          {
            id: 'notifications',
            to: '/settings/notifications',
            label: 'Notifications',
            icon: Mail
          },
        ],
      },
    ],
  },
];


// Main SideBar Component
export const SideBar = ({ onSideBarStateChange, hasCurrentCampaign = false }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeView, setActiveView] = useState('default');
  const [layoutState, setLayoutState] = useState('closedDesktop');

  const location = useLocation();
  const isEditorRoute = useMemo(() => location.pathname.includes('/editor/'), [location.pathname]);

  // If you want to forcibly close when on an editor route, you can reintroduce that logic,
  // but the user asked to remove auto-collapse, so it's commented out:
  //
  // useEffect(() => {
  //   if (isEditorRoute) {
  //     setIsExpanded(false);
  //     setIsOpen(false);
  //     setShowOverlay(false);
  //     onSideBarStateChange?.('closedDesktop');
  //   }
  // }, [isEditorRoute, onSideBarStateChange]);

  const isOpenResponsive = isMobile ? isOpen : isExpanded;

  // Build the nav items array
  const navigationItems = useMemo(() => {
    const items = getNavigationItems(hasCurrentCampaign);
    // Force-first items to the top
    return items.sort((a, b) => {
      if (a.forceFirst) return -1;
      if (b.forceFirst) return 1;
      return 0;
    });
  }, [hasCurrentCampaign]);

  // Toggle a sub-menu open/closed
  const toggleSubMenu = (id) => {
    setOpenSubMenu(prevState => (prevState === id ? null : id));
  };

  // Figure out layout state
  const getLayoutState = useCallback(() => {
    // If you want to force closed on editor route, do it here. Currently removed.
    let state = '';
    if (isExpanded && !isMobile) {
      state = 'expandedDesktop';
    } else if (!isExpanded && !isMobile && isOpen) {
      state = 'collapsedDesktop';
    } else if (!isExpanded && !isMobile && !isOpen) {
      state = 'closedDesktop';
    } else if (isMobile && isOpen) {
      state = 'openMobile';
    } else if (isMobile && !isOpen) {
      state = 'closedMobile';
    }
    onSideBarStateChange?.(state);
    return state;
  }, [isExpanded, isMobile, isOpen, onSideBarStateChange]);

  useEffect(() => {
    setLayoutState(getLayoutState());
  }, [getLayoutState]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    const debouncedHandleResize = throttle(handleResize, 100);

    window.addEventListener('resize', debouncedHandleResize);
    handleResize();
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  const handleToggleClick = () => {
    // If we want to skip toggling in editor route, we can do so, but user asked to remove auto-close
    if (isMobile) {
      setIsOpen(!isOpen);
      setShowOverlay(!isOpen);
      return;
    }
    setIsOpen(true);
    setIsExpanded(!isExpanded);
  };

  const handleToggleClose = () => {
    setIsExpanded(false);
    setIsOpen(false);
    setShowOverlay(false);
  };

  const isCollapsed = layoutState === 'collapsedDesktop';

  // Render each item
  const renderMenuItem = (item, level = 0) => (
    <li key={item.id}>
      <SideBarMenuItem
        {...item}
        isOpen={openSubMenu === item.id}
        onClick={() => {
          if (item.subItems) {
            toggleSubMenu(item.id);
          } else {
            // If there's no subItems, it means a direct link; on mobile, close after click
            if (isMobile) setIsOpen(false);
            // On desktop, collapse after click
            if (!isMobile) setIsExpanded(false);
          }
        }}
        isCollapsed={isCollapsed}
        activeView={activeView}
        level={level}
      />

      {/* If it has subItems and is open or it's a section, render sub-items */}
      {item.subItems && (openSubMenu === item.id || item.type === 'section') && (
        <ul className={`${item.type !== 'section' && level === 0 ? 'ml-6' : ''} space-y-1`}>
          {item.subItems.map(subItem => renderMenuItem(subItem, level + 1))}
        </ul>
      )}
    </li>
  );

  return (
    <>
      {/* Overlay for mobile */}
      {showOverlay && (
        <Portal container={document.getElementById('main-tag')}>
          <div
            className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-20"
            onClick={() => {
              setShowOverlay(false);
              setIsOpen(false);
            }}
          />
        </Portal>
      )}

      <aside
        className={`z-40 relative transition-all duration-300 ease-in-out ${layoutStateClassNames[layoutState]}`}
      >
        <div className="min-h-16 flex flex-nowrap w-full items-center justify-between">
          <SideBarMobileToggleButton
            onClick={handleToggleClose}
            className={`${layoutState === 'closedDesktop' ? 'hidden' : 'block'} ${isMobile && 'hidden'} w-10`}
            Icon={ChevronLeft}
            iconClassName="transition-transform duration-300"
            doubleIcon
          />

          <SideBarMobileToggleButton
            onClick={handleToggleClick}
            className={togglerLayoutClassNames[layoutState]}
            iconClassName={`transition-transform duration-300 ${(isMobile ? isOpen : isExpanded) && 'rotate-180 text-blue-500 scale-150'
              }`}
          />
        </div>

        <div className="h-full w-full mt-4 overflow-hidden">
          <nav>
            <ul className="space-y-2">
              {navigationItems.map(item => renderMenuItem(item))}
            </ul>
          </nav>
        </div>

        {/* Global style overrides */}
        <style jsx global>{`
          @keyframes pulse-green {
            0%, 100% {
              background-color: rgba(167, 243, 208, 0.5);
            }
            50% {
              background-color: rgba(167, 243, 208, 1);
            }
          }
          .animate-pulse-green {
            animation: pulse-green 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }

          .w-17 {
            width: 4.25rem;
          }

          /* Smooth transitions for the sidebar */
          .sidebar-transition {
            transition-property: transform, width, margin, padding;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 300ms;
          }

          /* Improved hover states */
          .hover-highlight:hover {
            background-color: rgba(167, 243, 208, 0.2);
          }

          /* Active state enhancements */
          .active-nav-item {
            position: relative;
          }
          .active-nav-item::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 3px;
            background-color: #3B82F6;
            border-radius: 0 4px 4px 0;
          }

          /* Mobile optimizations */
          @media (max-width: 1024px) {
            .sidebar-mobile {
              position: fixed;
              top: 0;
              left: 0;
              height: 100vh;
              z-index: 50;
            }
          }
        `}</style>
      </aside>
    </>
  );
};

export default SideBar;
