import React, { createContext, useContext, useState } from 'react';
import { useAuth } from '../useAuth/useAuth';

const HeaderContext = createContext({});
export const HeaderProvider = ({ children }) => {
  const { user, userProfile } = useAuth();
  console.log('user userProfile', user, userProfile);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const hasUserProfile = !!userProfile?.has_profile;
  console.count('HeaderProvider');
  return (
    <HeaderContext.Provider
      value={{
        user,
        userProfile,
        hasUserProfile,
        isMenuOpen,
        setIsMenuOpen,
        showPricingModal,
        setShowPricingModal,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error('useHeaderContext must be used within a HeaderProvider');
  }
  return context;
};
