import React, { useState, useCallback, useEffect } from 'react';
import {
    FileText, Upload, AlertCircle, Loader2, X, Info, ArrowRight,
} from 'lucide-react';
import { supabase } from '../../supabaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'sonner';

const ResumeUpload = ({
    isOpen,
    onClose,
    onUploadComplete,
    isDev = false,
    devFallbackUUID = null
}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState(null);
    const [uploadingId, setUploadingId] = useState(null);
    const [formData, setFormData] = useState({
        company: '',
        role: '',
        notes: ''
    });

    useEffect(() => {
        if (!isOpen || !uploadingId) return;

        const channel = supabase.channel('upload_status')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: 'resume_uploads',
                    filter: `id=eq.${uploadingId}`
                },
                (payload) => {
                    console.log('Change received!', payload);
                    if (payload.new.status === 'completed') {
                        setUploadProgress(100);
                        toast.success('Resume uploaded and processed successfully!');
                        onClose();
                        onUploadComplete();
                        window.location.href = '/resume';
                    } else if (payload.new.status === 'parsing') {
                        setUploadProgress(75);
                        toast.info('AI is processing your resume...');
                    } else if (payload.new.status === 'uploading') {
                        setUploadProgress(50);
                        toast.info('Uploading your resume...');
                    }
                }
            )
            .subscribe();

        return () => {
            supabase.removeChannel(channel);
        };
    }, [isOpen, uploadingId, onClose, onUploadComplete]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileSelect = useCallback((file) => {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            setError('File size must be less than 10MB');
            toast.error('File size must be less than 10MB');
            return;
        }

        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (!allowedTypes.includes(file.type)) {
            setError('Only PDF, DOC, and DOCX files are allowed');
            toast.error('Only PDF, DOC, and DOCX files are allowed');
            return;
        }

        setSelectedFile(file);
        setError(null);
    }, []);

    const handleUpload = async () => {
        if (!selectedFile) return;

        setError(null);
        setUploadProgress(25);
        toast.info('Starting upload...');

        try {
            let userId;

            if (isDev) {
                console.log('Development mode: Using fallback UUID');
                userId = devFallbackUUID;
            } else {
                const { data: { user }, error: userError } = await supabase.auth.getUser();

                if (userError || !user) {
                    window.location.href = '/login';
                    toast.error('You need to log in to upload resumes.');
                    return;
                }
                userId = user.id;
            }

            const recordId = uuidv4();
            setUploadingId(recordId);

            const { error: recordError } = await supabase
                .from('resume_uploads')
                .insert([{
                    id: recordId,
                    uploaded_by: userId,
                    status: 'uploading',
                    file_name: selectedFile.name,
                    candidate_name: selectedFile.name.split('.')[0],
                    company: formData.company,
                    role: formData.role,
                    notes: formData.notes,
                    created_at: new Date().toISOString()
                }]);

            if (recordError) throw recordError;
            toast.success('Resume record created successfully.');

            const filePath = `uploads/${userId}/${recordId}/${selectedFile.name}`;
            const { error: uploadError } = await supabase.storage
                .from('resumes')
                .upload(filePath, selectedFile);

            if (uploadError) throw uploadError;
            toast.success('File uploaded successfully.');

            const { data: { publicUrl } } = supabase.storage
                .from('resumes')
                .getPublicUrl(filePath);

            await supabase
                .from('resume_uploads')
                .update({
                    status: 'parsing',
                    file_url: publicUrl,
                    file_path: filePath
                })
                .eq('id', recordId);
            toast.info('File is being processed by AI.');

            const apiFormData = new FormData();
            apiFormData.append('file', selectedFile);
            apiFormData.append('user_id', userId);
            apiFormData.append('record_id', recordId);
            apiFormData.append('parse', 'true');
            apiFormData.append('mode', 'new');
            apiFormData.append('company', formData.company);
            apiFormData.append('role', formData.role);
            apiFormData.append('notes', formData.notes);

            const response = await fetch('https://robo-resumeably-backend.onrender.com/api/v1/resume/upload_robo', {
                method: 'POST',
                body: apiFormData
            });

            if (!response.ok) throw new Error('Parse API Error');

            const parseData = await response.json();
            toast.success('AI has successfully processed your resume.');

            await supabase
                .from('resume_uploads')
                .update({
                    status: 'completed',
                    parse_status: 'completed',
                    resume_json: parseData.resume_json || null,
                    resume_text: parseData.resume_text || null
                })
                .eq('id', recordId);

        } catch (error) {
            console.error('Upload error:', error);
            setError(error.message || 'Failed to upload resume');
            toast.error(error.message || 'Failed to upload resume');

            if (uploadingId) {
                await supabase
                    .from('resume_uploads')
                    .update({
                        status: 'failed',
                        parse_status: 'failed',
                        error_message: error.message
                    })
                    .eq('id', uploadingId);
                toast.error('Resume upload failed.');
            }
        }
    };

    const handleGoToDashboard = () => {
        window.location.href = '/resume';
    };

    const renderUploadArea = () => (
        <div
            className="border-2 border-dashed border-white border-opacity-50 rounded-xl p-6 
                       bg-white transition-all duration-300
                       hover:border-green-500 hover:border-opacity-100 cursor-pointer"
            onDrop={(e) => {
                e.preventDefault();
                handleFileSelect(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => e.preventDefault()}
            onClick={() => document.getElementById('file-upload').click()}
        >
            <input
                type="file"
                id="file-upload"
                className="hidden"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileSelect(e.target.files[0])}
            />
            <div className="flex flex-col items-center justify-center gap-4">
                {selectedFile ? (
                    <>
                        <div className="relative">
                            <FileText className="h-12 w-12 text-green-600" />
                            <button
                                onClick={() => setSelectedFile(null)}
                                className="absolute -top-2 -right-2 rounded-full bg-green-100 p-1
                                           hover:bg-green-200 transition-colors"
                            >
                                <X className="h-4 w-4 text-green-600" />
                            </button>
                        </div>
                        <div className="text-center">
                            <p className="text-gray-900 text-md font-medium">
                                {selectedFile.name}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                                {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <Upload className="h-12 w-12 text-green-500" />
                        <div className="text-center space-y-2">
                            <p className="text-gray-600 text-md">
                                Drag and drop your resume, or
                            </p>
                            <label
                                htmlFor="file-upload"
                                className="inline-block px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors cursor-pointer"
                            >
                                Browse Files
                            </label>
                        </div>
                        <p className="text-sm text-gray-500">
                            PDF, DOC, DOCX files (Max 10MB)
                        </p>
                    </>
                )}
            </div>
        </div>
    );

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-white/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl shadow-lg w-full max-w-4xl mx-4 p-6 relative border border-green-100 grid grid-cols-2 gap-8">
                <div className="col-span-2 flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-green-900">
                        Upload Resume
                    </h2>
                    <button
                        onClick={onClose}
                        className="p-2 rounded-full hover:bg-green-50 transition-colors"
                    >
                        <X className="h-5 w-5 text-green-600" />
                    </button>
                </div>

                {error && (
                    <div className="col-span-2 mb-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative flex items-center gap-2">
                        <AlertCircle className="h-5 w-5 flex-shrink-0" />
                        <span>{error}</span>
                    </div>
                )}

                <div className="space-y-4">
                    <div>
                        <label htmlFor="role" className="block text-sm font-medium text-green-800 mb-1">
                            Role (Optional)
                        </label>
                        <input
                            type="text"
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border border-green-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            placeholder="e.g., Software Engineer"
                        />
                    </div>
                    <div>
                        <label htmlFor="company" className="block text-sm font-medium text-green-800 mb-1">
                            Company (Optional)
                        </label>
                        <input
                            type="text"
                            id="company"
                            name="company"
                            value={formData.company}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border border-green-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            placeholder="e.g., Acme Inc."
                        />
                    </div>
                    <div>
                        <label htmlFor="notes" className="block text-sm font-medium text-green-800 mb-1">
                            Notes (Optional)
                        </label>
                        <textarea
                            id="notes"
                            name="notes"
                            value={formData.notes}
                            onChange={handleInputChange}
                            rows="2"
                            className="w-full px-3 py-2 border border-green-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-green-500"
                            placeholder="Add any additional notes..."
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center">
                    {renderUploadArea()}

                    <div className="mt-4 flex justify-end gap-4">
                        <button
                            onClick={handleUpload}
                            disabled={!selectedFile || uploadProgress > 0}
                            className={`px-4 py-2 bg-green-600 text-white rounded-lg 
                                        hover:bg-green-700 transition-colors ${(!selectedFile || uploadProgress > 0) ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {uploadProgress > 0 ? 'Uploading...' : 'Upload Resume'}
                        </button>

                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <button
                                onClick={handleGoToDashboard}
                                className="px-4 py-2 bg-green-800 text-white rounded-lg 
                                           hover:bg-green-900 transition-colors flex items-center gap-2"
                            >
                                Wait on the Resume Dashboard
                                <ArrowRight className="h-4 w-4" />
                            </button>
                        )}
                    </div>

                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <div className="mt-4 bg-green-50 border border-green-200 rounded-xl p-4">
                            <div className="flex items-center gap-3">
                                <Loader2 className="h-5 w-5 animate-spin text-green-600" />
                                <h3 className="text-lg font-medium text-green-900">Processing...</h3>
                            </div>
                            <div className="w-full bg-green-200 rounded-full h-2 mt-4">
                                <div
                                    className="bg-green-500 h-2 rounded-full transition-all duration-300"
                                    style={{ width: `${uploadProgress}%` }}
                                />
                            </div>
                            <p className="text-sm text-green-600 mt-2">
                                {uploadProgress < 50
                                    ? 'Uploading your resume...'
                                    : 'AI is analyzing your resume...'}
                            </p>
                        </div>
                    )}

                    <div className="mt-4 bg-green-50 border border-green-200 rounded-xl p-4">
                        <div className="flex gap-3">
                            <Info className="h-5 w-5 text-green-600 flex-shrink-0 mt-0.5" />
                            <div>
                                <h4 className="text-sm font-medium text-green-900">
                                    AI Processing Enabled
                                </h4>
                                <p className="text-sm text-green-700 mt-1">
                                    Our AI will automatically analyze your resume and extract key information
                                    for better matching and analysis.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeUpload;