// Campaign Success Modal Component
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Rocket, ShoppingCart } from 'lucide-react';
import confetti from 'canvas-confetti';

export const CampaignSuccessCard = ({ isOpen, onClose, jobType, numJobs, location, hasCredits }) => {
  useEffect(() => {
    if (isOpen && hasCredits) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  }, [isOpen, hasCredits]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-xl p-6 max-w-md w-full mx-4 relative"
      >
        <div className="text-center">
          {hasCredits ? (
            <>
              <motion.div
                initial={{ y: 20 }}
                animate={{ y: -10 }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 1,
                }}
                className="inline-block mb-4"
              >
                <Rocket className="h-12 w-12 text-blue-500" />
              </motion.div>

              <h2 className="text-2xl font-bold text-gray-800 mb-4">Campaign Launched! 🚀</h2>

              <div className="space-y-3 mb-6">
                <p className="text-gray-600">We've started your campaign for:</p>
                <div className="bg-blue-50 rounded-lg p-4">
                  <p className="font-semibold text-blue-900">
                    {numJobs} {jobType} positions
                  </p>
                  <p className="text-blue-700">{location}</p>
                </div>
                <p className="text-gray-600">
                  You can close this window - we'll email you updates as applications are submitted!
                </p>
              </div>
            </>
          ) : (
            <>
              <motion.div
                initial={{ y: 20 }}
                animate={{ y: -10 }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'reverse',
                  duration: 1,
                }}
                className="inline-block mb-4"
              >
                <ShoppingCart className="h-12 w-12 text-red-500" />
              </motion.div>

              <h2 className="text-2xl font-bold text-gray-800 mb-4">Insufficient Credits</h2>

              <p className="text-gray-600 mb-6">
                You do not have enough credits to launch this campaign. Please purchase more credits to continue.
              </p>
            </>
          )}

          <button
            onClick={onClose}
            className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-2 rounded-lg font-medium hover:from-blue-600 hover:to-blue-700 transition-all"
          >
            Got it!
          </button>
        </div>
      </motion.div>
    </div>
  );
};
