// // src/components/JobSearchPortal.js
// import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';
// import {
//   Search, MapPin, AlertCircle, Building
// } from 'lucide-react';
// import { toast, Toaster } from 'react-hot-toast';

// import { jobSearchApi } from '../api/JobSearchApi';
// import JobListItem from './JobListItem';
// import FilterSection from './FilterSection';
// import { formatJobDescription, formatSalary, PATTERNS } from '../utilities/description-utils';
// import JobDetails from './JobDetails';
// import Pagination from './Pagination';
// import { SiteSelector } from './SiteSelector';
// import { SearchInput } from './SearchInput';
// import { SITE_CONFIG } from './SiteSelector';

// // parseMoney / parseSalaryRange logic from before
// function parseMoney(str) {
//   if (!str) return null;
//   let clean = str.replace(/[^\d.]+/g, '');
//   if (/k$/i.test(str)) {
//     clean = clean.replace(/k$/i, '');
//     const asNum = parseFloat(clean);
//     if (isNaN(asNum)) return null;
//     return Math.round(asNum * 1000);
//   }
//   const asFloat = parseFloat(clean);
//   if (isNaN(asFloat)) return null;
//   return Math.round(asFloat);
// }



// function parseSalaryRange(text) {
//   if (!text) return null;
//   const rangeRegex = new RegExp(
//     String.raw`(\$?\d[\d,]*\.?\d*)(?:\s*(?:\/\w+|per\s*\w+|year)?)?.*?(?:up to|to|–|-)\s*(\$?\d[\d,]*\.?\d*)`,
//     'i'
//   );
//   const rangeMatch = text.match(rangeRegex);
//   if (rangeMatch) {
//     const minVal = parseMoney(rangeMatch[1]);
//     const maxVal = parseMoney(rangeMatch[2]);
//     if (minVal && maxVal) {
//       return `$${minVal.toLocaleString()} - $${maxVal.toLocaleString()}`;
//     }
//   }
//   const singleRegex = /(\$?\d[\d,]*\.?\d*)(?:\s*(?:\/\w+|per\s*\w+|year)?)/i;
//   const singleMatch = text.match(singleRegex);
//   if (singleMatch) {
//     const val = parseMoney(singleMatch[1]);
//     if (val) {
//       return `$${val.toLocaleString()}`;
//     }
//   }
//   return null;
// }

// function parseJobDescription(description) {
//   if (!description) return {};
//   const base = {
//     experience: description.match(PATTERNS.highlights.experience)?.[0],
//     technologies: [...new Set(description.match(PATTERNS.highlights.tech) || [])],
//     workType: description.match(PATTERNS.highlights.jobType)?.[0],
//     location: description.match(PATTERNS.highlights.location)?.[0],
//   };
//   const range = parseSalaryRange(description);
//   return {
//     ...base,
//     salary: range,
//   };
// }

// const ITEMS_PER_PAGE = 10;

// const JobSearchPortal = () => {
//   const [searchTerm, setSearchTerm] = useState('Data Scientist'); // Let’s set a default
//   const [location, setLocation] = useState('');
//   const [selectedJob, setSelectedJob] = useState(null);
//   const [jobs, setJobs] = useState([]);
//   const [displayedJobs, setDisplayedJobs] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   // Pagination
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalResults, setTotalResults] = useState(0);

//   // Sites
//   const [selectedSites, setSelectedSites] = useState(
//     Object.entries(SITE_CONFIG)
//       .filter(([_, config]) => config.defaultSelected)
//       .map(([key]) => key)
//   );

//   // Filters
//   const [filters, setFilters] = useState({
//     jobType: [],
//     experienceLevel: [],
//     salary: [],
//     workplaceType: [],
//     technologies: []
//   });


//   // Massive arrays defined as constants inside the component with useMemo
//   const JOB_TITLES = useMemo(() => [
//     "Accountant",
//     "Administrative Assistant",
//     "Architect",
//     "Art Director",
//     "Attorney",
//     "Biomedical Engineer",
//     "Business Analyst",
//     "Chemical Engineer",
//     "Civil Engineer",
//     "Clinical Researcher",
//     "Computer Programmer",
//     "Content Writer",
//     "Data Analyst",
//     "Data Scientist",
//     "Dentist",
//     "Designer",
//     "DevOps Engineer",
//     "Doctor",
//     "Economist",
//     "Electrical Engineer",
//     "Environmental Scientist",
//     "Financial Advisor",
//     "Graphic Designer",
//     "Human Resources Manager",
//     "Industrial Engineer",
//     "Information Security Analyst",
//     "IT Manager",
//     "Journalist",
//     "Legal Assistant",
//     "Marketing Manager",
//     "Mechanical Engineer",
//     "Medical Assistant",
//     "Nurse",
//     "Pharmacist",
//     "Photographer",
//     "Product Manager",
//     "Project Manager",
//     "Psychologist",
//     "Public Relations Specialist",
//     "Quality Assurance Engineer",
//     "Recruiter",
//     "Sales Manager",
//     "Software Developer",
//     "Software Engineer",
//     "Statistician",
//     "System Administrator",
//     "Teacher",
//     "Technical Writer",
//     "UX/UI Designer",
//     "Veterinarian",
//     "Web Developer",
//     "Writer",
//     // Add more job titles as needed
//     "Cloud Solutions Architect",
//     "Cybersecurity Specialist",
//     "Machine Learning Engineer",
//     "Mobile Application Developer",
//     "Network Engineer",
//     "Robotics Engineer",
//     "Salesforce Administrator",
//     "SEO Specialist",
//     "Social Media Manager",
//     "Blockchain Developer",
//     "Biochemist",
//     "Biomedical Technician",
//     "Business Development Manager",
//     "Cloud Engineer",
//     "Computer Vision Engineer",
//     "Data Engineer",
//     "Database Administrator",
//     "Electrical Technician",
//     "Embedded Systems Engineer",
//     "Firmware Engineer",
//     "Full Stack Developer",
//     "Game Developer",
//     "GIS Analyst",
//     "Health Informatics Specialist",
//     "Human Factors Engineer",
//     "Industrial Designer",
//     "Information Systems Manager",
//     "Interior Designer",
//     "IT Support Specialist",
//     "Logistics Coordinator",
//     "Machine Operator",
//     "Manufacturing Engineer",
//     "Marine Engineer",
//     "Materials Scientist",
//     "Mathematician",
//     "Medical Laboratory Technician",
//     "Network Administrator",
//     "Nuclear Engineer",
//     "Operations Manager",
//     "Optometrist",
//     "Organic Chemist",
//     "Pediatrician",
//     "Petroleum Engineer",
//     "Pharmaceutical Scientist",
//     "Physician Assistant",
//     "Physicist",
//     "Plant Manager",
//     "Procurement Manager",
//     "Production Manager",
//     "Project Coordinator",
//     "Quality Control Inspector",
//     "Radiologic Technologist",
//     "Rehabilitation Specialist",
//     "Research Scientist",
//     "Safety Manager",
//     "Supply Chain Manager",
//     "Technical Support Engineer",
//     "Telecommunications Engineer",
//     "Translator",
//     "Transportation Planner",
//     "Urban Planner",
//     "Veterinary Technician",
//     "Wind Energy Engineer",
//     "Zoologist",
//   ], []);

//   const MAJOR_CITIES = useMemo(() => [
//     "New York, NY",
//     "Los Angeles, CA",
//     "Chicago, IL",
//     "Houston, TX",
//     "Phoenix, AZ",
//     "Philadelphia, PA",
//     "San Antonio, TX",
//     "San Diego, CA",
//     "Dallas, TX",
//     "San Jose, CA",
//     "Austin, TX",
//     "Jacksonville, FL",
//     "Fort Worth, TX",
//     "Columbus, OH",
//     "Charlotte, NC",
//     "San Francisco, CA",
//     "Indianapolis, IN",
//     "Seattle, WA",
//     "Denver, CO",
//     "Washington, DC",
//     "Boston, MA",
//     "El Paso, TX",
//     "Nashville, TN",
//     "Detroit, MI",
//     "Oklahoma City, OK",
//     "Portland, OR",
//     "Las Vegas, NV",
//     "Memphis, TN",
//     "Louisville, KY",
//     "Baltimore, MD",
//     "Milwaukee, WI",
//     "Albuquerque, NM",
//     "Tucson, AZ",
//     "Fresno, CA",
//     "Sacramento, CA",
//     "Mesa, AZ",
//     "Kansas City, MO",
//     "Atlanta, GA",
//     "Long Beach, CA",
//     "Colorado Springs, CO",
//     "Raleigh, NC",
//     "Miami, FL",
//     "Virginia Beach, VA",
//     "Omaha, NE",
//     "Oakland, CA",
//     "Minneapolis, MN",
//     "Tulsa, OK",
//     "Arlington, TX",
//     "Tampa, FL",
//     "New Orleans, LA",
//     "Wichita, KS",
//     "Cleveland, OH",
//     "Bakersfield, CA",
//     "Aurora, CO",
//     "Anaheim, CA",
//     "Honolulu, HI",
//     "Santa Ana, CA",
//     "Riverside, CA",
//     "Corpus Christi, TX",
//     "Lexington, KY",
//     "Henderson, NV",
//     "Stockton, CA",
//     "Saint Paul, MN",
//     "Cincinnati, OH",
//     "St. Louis, MO",
//     "Pittsburgh, PA",
//     "Greensboro, NC",
//     "Lincoln, NE",
//     "Anchorage, AK",
//     "Plano, TX",
//     "Orlando, FL",
//     "Irvine, CA",
//     "Newark, NJ",
//     "Toledo, OH",
//     "Durham, NC",
//     "Chula Vista, CA",
//     "Fort Wayne, IN",
//     "Jersey City, NJ",
//     "St. Petersburg, FL",
//     "Laredo, TX",
//     "Madison, WI",
//     "Chandler, AZ",
//     "Buffalo, NY",
//     "Lubbock, TX",
//     "Scottsdale, AZ",
//     "Reno, NV",
//     "Glendale, AZ",
//     "Gilbert, AZ",
//     "Winston–Salem, NC",
//     "North Las Vegas, NV",
//     "Norfolk, VA",
//     "Chesapeake, VA",
//     "Garland, TX",
//     "Irving, TX",
//     "Hialeah, FL",
//     "Fremont, CA",
//     "Boise, ID",
//     "Richmond, VA",
//     // Add more cities as needed
//   ], []);

//   const filterJobs = useCallback((allJobs) => {
//     return allJobs.filter(job => {
//       const description = job.description?.toLowerCase() || '';
//       const parsedInfo = job.parsedInfo || {};

//       // jobType check
//       if (filters.jobType.length > 0) {
//         const jobTypeMatch = filters.jobType.some(type => {
//           const typeRegex = new RegExp(type, 'i');
//           return typeRegex.test(description) || typeRegex.test(job.job_type || '');
//         });
//         if (!jobTypeMatch) return false;
//       }
//       // experienceLevel
//       if (filters.experienceLevel.length > 0) {
//         const expMatch = description.match(PATTERNS.highlights.experience);
//         if (expMatch) {
//           const years = parseInt(expMatch[0]);
//           const level = years <= 2
//             ? 'Entry Level'
//             : years <= 5
//               ? 'Mid Level'
//               : 'Senior Level';
//           if (!filters.experienceLevel.includes(level)) return false;
//         } else {
//           return false;
//         }
//       }
//       // salary
//       if (filters.salary.length > 0) {
//         const salaryMatch = description.match(PATTERNS.highlights.salary);
//         if (salaryMatch) {
//           const numbers = salaryMatch[0].match(/\d+/g);
//           if (numbers) {
//             const amount = parseInt(numbers[0]);
//             const range = amount < 50000
//               ? '$0-50K'
//               : amount < 100000
//                 ? '$50-100K'
//                 : amount < 150000
//                   ? '$100-150K'
//                   : '$150K+';
//             if (!filters.salary.includes(range)) return false;
//           }
//         } else {
//           return false;
//         }
//       }
//       // workplaceType
//       if (filters.workplaceType.length > 0) {
//         const isRemote = description.includes('remote');
//         const isHybrid = description.includes('hybrid');
//         const isOnsite = description.includes('on-site') ||
//           description.includes('onsite') ||
//           description.includes('in office');
//         const match = (isRemote && filters.workplaceType.includes('Remote')) ||
//           (isHybrid && filters.workplaceType.includes('Hybrid')) ||
//           (isOnsite && filters.workplaceType.includes('On-site'));
//         if (!match) return false;
//       }
//       // technologies
//       if (filters.technologies.length > 0) {
//         const techMatches = description.match(PATTERNS.highlights.tech) || [];
//         const hasMatchingTech = filters.technologies.some(tech =>
//           techMatches.some(match => match.toLowerCase() === tech.toLowerCase())
//         );
//         if (!hasMatchingTech) return false;
//       }

//       return true;
//     });
//   }, [filters]);



//   // Auto-locate user
//   useEffect(() => {
//     if ('geolocation' in navigator) {
//       navigator.geolocation.getCurrentPosition(
//         async (pos) => {
//           try {
//             const { latitude, longitude } = pos.coords;
//             const res = await fetch(
//               `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
//             );
//             const data = await res.json();
//             if (data.city) {
//               setLocation(`${data.city}, ${data.principalSubdivisionCode || data.countryName}`);
//             } else {
//               setLocation('New York, NY');
//             }
//           } catch (err) {
//             console.error('Geocode error:', err);
//             setLocation('New York, NY');
//           }
//         },
//         (err) => {
//           console.error('User denied geolocation:', err);
//           setLocation('New York, NY');
//         }
//       );
//     } else {
//       setLocation('New York, NY');
//     }
//   }, []);

//   // If we have searchTerm & location & sites => do an immediate search
//   useEffect(() => {
//     if (searchTerm && location && selectedSites.length > 0) {
//       handleSearch(1, true);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [location]);

//   // Main search function
//   const handleSearch = useCallback(
//     async (page = 1, isNewSearch = false) => {
//       if (!searchTerm || !location || selectedSites.length === 0) {
//         toast.error('Please enter both job title and location.');
//         return;
//       }

//       setIsLoading(true);
//       setError(null);

//       try {
//         const response = await jobSearchApi.searchJobs({
//           search_term: searchTerm,
//           location,
//           sites: selectedSites,
//           page,
//           per_page: ITEMS_PER_PAGE,
//           filters: {
//             job_types: filters.jobType || [],
//             experience_level: filters.experienceLevel || [],
//             workplace_type: filters.workplaceType || [],
//             date_posted: filters.datePosted?.length ? filters.datePosted[0] : null,
//             salary_range: filters.salary || []
//           }
//         });

//         const processed = response.data.jobs.map(j => ({
//           ...j,
//           parsedInfo: j.description ? parseJobDescription(j.description) : {}
//         }));

//         setJobs(processed);
//         setTotalResults(response.data.total || processed.length);

//         if (isNewSearch) {
//           setCurrentPage(1);
//         }

//         if (processed.length > 0) {
//           toast.success(`Found ${response.data.total || processed.length} jobs`);
//         } else {
//           toast.info('No jobs found with current criteria');
//         }
//       } catch (err) {
//         console.error('Search error:', err);
//         setError(err.message);
//         toast.error('Failed to fetch jobs. Please try again later.');
//       } finally {
//         setIsLoading(false);
//       }
//     },
//     [searchTerm, location, selectedSites, filters]
//   );

//   // On form submit
//   const handleSubmitSearch = (e) => {
//     e?.preventDefault();
//     handleSearch(1, true);
//   };

//   // Once jobs or filters change => re-filter
//   useEffect(() => {
//     if (jobs.length > 0) {
//       const filtered = filterJobs(jobs);
//       setDisplayedJobs(filtered);
//       setTotalResults(filtered.length);

//       // If we have at least one job, auto-select the first
//       if (filtered.length > 0) {
//         setSelectedJob(filtered[0]);
//       } else {
//         setSelectedJob(null);
//       }
//     } else {
//       setDisplayedJobs([]);
//       setSelectedJob(null);
//     }
//   }, [jobs, filterJobs]);

//   // Pagination
//   const handlePageChange = useCallback((newPage) => {
//     setCurrentPage(newPage);
//     handleSearch(newPage, false);
//   }, [handleSearch]);

//   // Site changes
//   const handleSiteChange = useCallback((site) => {
//     setSelectedSites((prev) => {
//       if (prev.includes(site)) {
//         if (prev.length === 1) {
//           toast.error('At least one job site must be selected');
//           return prev;
//         }
//         return prev.filter((s) => s !== site);
//       }
//       return [...prev, site];
//     });
//   }, []);

//   // Job actions
//   const handleSaveJob = useCallback(async (job, isSaved) => {
//     try {
//       if (isSaved) {
//         await jobSearchApi.saveJob(job.id);
//         toast.success('Job saved to your favorites');
//       } else {
//         await jobSearchApi.unsaveJob(job.id);
//         toast.success('Job removed from favorites');
//       }
//     } catch (error) {
//       console.error('Error saving job:', error);
//       toast.error('Failed to save job');
//     }
//   }, []);

//   const handleShareJob = useCallback(async (job) => {
//     try {
//       if (navigator.share) {
//         await navigator.share({
//           title: `${job.title} at ${job.company}`,
//           text: `Check out this job: ${job.title} at ${job.company}`,
//           url: job.job_url
//         });
//       } else {
//         await navigator.clipboard.writeText(job.job_url);
//         toast.success('Job link copied to clipboard');
//       }
//     } catch (error) {
//       console.error('Error sharing job:', error);
//       toast.error('Failed to share job');
//     }
//   }, []);

//   const handleApplyJob = useCallback(async (job) => {
//     try {
//       await jobSearchApi.trackApplication(job.id);
//       window.open(job.job_url, '_blank');
//     } catch (error) {
//       console.error('Error tracking application:', error);
//       window.open(job.job_url, '_blank');
//     }
//   }, []);

//   // For pagination display
//   const totalPages = useMemo(() => Math.ceil(totalResults / ITEMS_PER_PAGE), [totalResults]);

//   return (
//     <div className="min-h-screen bg-gray-50">
//       <Toaster position="top-right" />

//       {/* Top bar with search */}
//       <div className="sticky top-0 z-50 bg-white border-b border-gray-200 shadow-sm">
//         <div className="max-w-7xl mx-auto px-4 py-4">
//           <div className="space-y-4">
//             <SiteSelector
//               selectedSites={selectedSites}
//               onSiteChange={handleSiteChange}
//               disabled={isLoading}
//             />

//             <form
//               onSubmit={handleSubmitSearch}
//               className="grid grid-cols-1 md:grid-cols-12 gap-4"
//             >
//               <div className="col-span-1 md:col-span-5">
//                 <SearchInput
//                   value={searchTerm}
//                   onChange={setSearchTerm}
//                   suggestions={JOB_TITLES}
//                   placeholder="Job title or keyword"
//                   icon={Search}
//                   disabled={isLoading}
//                 />
//               </div>

//               <div className="col-span-1 md:col-span-5">
//                 <SearchInput
//                   value={location}
//                   onChange={setLocation}
//                   suggestions={MAJOR_CITIES}
//                   placeholder="Location"
//                   icon={MapPin}
//                   disabled={isLoading}
//                 />
//               </div>

//               <div className="col-span-1 md:col-span-2">
//                 <motion.button
//                   type="submit"
//                   whileHover={{ scale: 1.02 }}
//                   whileTap={{ scale: 0.98 }}
//                   disabled={isLoading || !searchTerm || !location}
//                   className="w-full h-full px-4 py-2 bg-[#B0EDF1A3] text-black rounded-lg 
//                     hover:bg-[#A0C9D1A3] disabled:bg-[#A0C9D1A3] disabled:cursor-not-allowed 
//                     flex items-center justify-center space-x-2 transition-colors"
//                 >
//                   {isLoading ? (
//                     <>
//                       <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent mr-2" />
//                       <span>Searching...</span>
//                     </>
//                   ) : (
//                     <>
//                       <Search className="w-5 h-5 mr-2" />
//                       <span>Search Jobs</span>
//                     </>
//                   )}
//                 </motion.button>
//               </div>
//             </form>

//             <div className="flex items-center justify-center-left">
//               <FilterSection
//                 jobs={jobs}
//                 filters={filters}
//                 onChange={(cat, val) => {
//                   setFilters(prev => ({ ...prev, [cat]: val }));
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Main content */}
//       <div className="max-w-7xl mx-auto px-4 py-6">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-lg font-semibold text-gray-900">
//             {totalResults > 0
//               ? `${totalResults} results for "${searchTerm}" in ${location}`
//               : 'Search for jobs'}
//           </h2>
//         </div>

//         <div className="flex flex-col md:flex-row gap-6">
//           {/* Left column: Job listings */}
//           <div className="w-full md:w-5/12">
//             <div className="h-[calc(100vh-200px)] overflow-y-auto">
//               <div className="space-y-4">
//                 <AnimatePresence mode="popLayout">
//                   {isLoading ? (
//                     Array(10).fill(null).map((_, i) => (
//                       <motion.div
//                         key={`skeleton-${i}`}
//                         initial={{ opacity: 0 }}
//                         animate={{ opacity: 1 }}
//                         exit={{ opacity: 0 }}
//                         className="bg-white p-4 space-y-3 animate-pulse border-b border-gray-200"
//                       >
//                         <div className="flex space-x-4">
//                           <div className="w-12 h-12 bg-gray-200 rounded-lg" />
//                           <div className="flex-1 space-y-2">
//                             <div className="h-4 bg-gray-200 rounded w-3/4" />
//                             <div className="h-3 bg-gray-200 rounded w-1/2" />
//                           </div>
//                         </div>
//                       </motion.div>
//                     ))
//                   ) : error ? (
//                     <div className="text-center py-10">
//                       <AlertCircle className="mx-auto h-12 w-12 text-red-400" />
//                       <h3 className="mt-2 text-sm font-medium text-gray-900">
//                         Error fetching jobs
//                       </h3>
//                       <p className="mt-1 text-sm text-gray-500">{error}</p>
//                       <button
//                         onClick={() => handleSearch(1, true)}
//                         className="mt-4 text-sm text-blue-600 hover:text-blue-500 flex items-center justify-center space-x-1"
//                       >
//                         <Search className="w-4 h-4" />
//                         <span>Try again</span>
//                       </button>
//                     </div>
//                   ) : jobs.length === 0 ? (
//                     <div className="text-center py-10">
//                       <Building className="mx-auto h-12 w-12 text-gray-400" />
//                       <h3 className="mt-2 text-sm font-medium text-gray-900">
//                         No jobs found
//                       </h3>
//                       <p className="mt-1 text-sm text-gray-500">
//                         Try adjusting your search criteria or selected job sites
//                       </p>
//                     </div>
//                   ) : (
//                     displayedJobs
//                       .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
//                       .map((job) => (
//                         <JobListItem
//                           key={job.id}
//                           job={job}
//                           isSelected={selectedJob?.id === job.id}
//                           onClick={(job) => {
//                             setSelectedJob(job);
//                           }}
//                           onSave={handleSaveJob}
//                           onShare={handleShareJob}
//                         />
//                       ))
//                   )}
//                 </AnimatePresence>
//               </div>
//             </div>

//             {/* Pagination */}
//             {totalPages > 1 && (
//               <div className="mt-4 border-t border-gray-200">
//                 <Pagination
//                   currentPage={currentPage}
//                   totalPages={totalPages}
//                   onPageChange={handlePageChange}
//                 />
//               </div>
//             )}
//           </div>

//           {/* Right column: Job details (ALWAYS visible) */}
//           <div
//             className="hidden md:block w-full md:w-7/12 bg-white rounded-lg shadow-sm 
//               border border-gray-200 overflow-hidden h-[calc(100vh-200px)]"
//           >
//             <JobDetails
//               job={selectedJob}
//               onClose={() => setSelectedJob(null)}
//               onApply={handleApplyJob}
//               onSave={handleSaveJob}
//               onShare={handleShareJob}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobSearchPortal;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Search, MapPin, AlertCircle, Building
} from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';

import { jobSearchApi } from '../api/JobSearchApi';
import JobListItem from './JobListItem';
import FilterSection from './FilterSection';
import { formatJobDescription, formatSalary, PATTERNS } from '../utilities/description-utils';
import JobDetails from './JobDetails';
import Pagination from './Pagination';
import { SiteSelector } from './SiteSelector';
import { SearchInput } from './SearchInput';
import { SITE_CONFIG } from './SiteSelector';

// Utility functions
function parseMoney(str) {
  if (!str) return null;
  let clean = str.replace(/[^\d.]+/g, '');
  if (/k$/i.test(str)) {
    clean = clean.replace(/k$/i, '');
    const asNum = parseFloat(clean);
    if (isNaN(asNum)) return null;
    return Math.round(asNum * 1000);
  }
  const asFloat = parseFloat(clean);
  if (isNaN(asFloat)) return null;
  return Math.round(asFloat);
}

function parseSalaryRange(text) {
  if (!text) return null;
  const rangeRegex = new RegExp(
    String.raw`(\$?\d[\d,]*\.?\d*)(?:\s*(?:\/\w+|per\s*\w+|year)?)?.*?(?:up to|to|–|-)\s*(\$?\d[\d,]*\.?\d*)`,
    'i'
  );
  const rangeMatch = text.match(rangeRegex);
  if (rangeMatch) {
    const minVal = parseMoney(rangeMatch[1]);
    const maxVal = parseMoney(rangeMatch[2]);
    if (minVal && maxVal) {
      return `$${minVal.toLocaleString()} - $${maxVal.toLocaleString()}`;
    }
  }
  const singleRegex = /(\$?\d[\d,]*\.?\d*)(?:\s*(?:\/\w+|per\s*\w+|year)?)/i;
  const singleMatch = text.match(singleRegex);
  if (singleMatch) {
    const val = parseMoney(singleMatch[1]);
    if (val) {
      return `$${val.toLocaleString()}`;
    }
  }
  return null;
}

function parseJobDescription(description) {
  if (!description) return {};
  const base = {
    experience: description.match(PATTERNS.highlights.experience)?.[0],
    technologies: [...new Set(description.match(PATTERNS.highlights.tech) || [])],
    workType: description.match(PATTERNS.highlights.jobType)?.[0],
    location: description.match(PATTERNS.highlights.location)?.[0],
  };
  const range = parseSalaryRange(description);
  return {
    ...base,
    salary: range,
  };
}

const ITEMS_PER_PAGE = 10;

const JobSearchPortal = () => {
  // State management with the new activeLocation state
  const [searchTerm, setSearchTerm] = useState('Data Scientist');
  const [location, setLocation] = useState('');
  const [activeLocation, setActiveLocation] = useState(''); // This is the new state that controls when searches happen
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  // Sites state
  const [selectedSites, setSelectedSites] = useState(
    Object.entries(SITE_CONFIG)
      .filter(([_, config]) => config.defaultSelected)
      .map(([key]) => key)
  );

  // Filters state
  const [filters, setFilters] = useState({
    jobType: [],
    experienceLevel: [],
    salary: [],
    workplaceType: [],
    technologies: []
  });

  // Constants with useMemo
  const JOB_TITLES = useMemo(() => [
    "Accountant",
    "Administrative Assistant",
    "Architect",
    "Art Director",
    "Attorney",
    "Biomedical Engineer",
    "Business Analyst",
    "Chemical Engineer",
    "Civil Engineer",
    "Clinical Researcher",
    "Computer Programmer",
    "Content Writer",
    "Data Analyst",
    "Data Scientist",
    "Dentist",
    "Designer",
    "DevOps Engineer",
    "Doctor",
    "Economist",
    "Electrical Engineer",
    "Environmental Scientist",
    "Financial Advisor",
    "Graphic Designer",
    "Human Resources Manager",
    "Industrial Engineer",
    "Information Security Analyst",
    "IT Manager",
    "Journalist",
    "Legal Assistant",
    "Marketing Manager",
    "Mechanical Engineer",
    "Medical Assistant",
    "Nurse",
    "Pharmacist",
    "Photographer",
    "Product Manager",
    "Project Manager",
    "Psychologist",
    "Public Relations Specialist",
    "Quality Assurance Engineer",
    "Recruiter",
    "Sales Manager",
    "Software Developer",
    "Software Engineer",
    "Statistician",
    "System Administrator",
    "Teacher",
    "Technical Writer",
    "UX/UI Designer",
    "Veterinarian",
    "Web Developer",
    "Writer"
  ], []);

  const MAJOR_CITIES = useMemo(() => [
    "New York, NY",
    "Los Angeles, CA",
    "Chicago, IL",
    "Houston, TX",
    "Phoenix, AZ",
    "Philadelphia, PA",
    "San Antonio, TX",
    "San Diego, CA",
    "Dallas, TX",
    "San Jose, CA",
    "Austin, TX",
    "Jacksonville, FL",
    "Fort Worth, TX",
    "Columbus, OH",
    "Charlotte, NC",
    "San Francisco, CA",
    "Indianapolis, IN",
    "Seattle, WA",
    "Denver, CO",
    "Washington, DC",
    "Boston, MA",
    "El Paso, TX",
    "Nashville, TN",
    "Detroit, MI",
    "Oklahoma City, OK",
    "Portland, OR",
    "Las Vegas, NV",
    "Memphis, TN",
    "Louisville, KY",
    "Baltimore, MD"
  ], []);

  // Job filtering callback
  const filterJobs = useCallback((allJobs) => {
    return allJobs.filter(job => {
      const description = job.description?.toLowerCase() || '';
      const parsedInfo = job.parsedInfo || {};

      // Filter by job type
      if (filters.jobType.length > 0) {
        const jobTypeMatch = filters.jobType.some(type => {
          const typeRegex = new RegExp(type, 'i');
          return typeRegex.test(description) || typeRegex.test(job.job_type || '');
        });
        if (!jobTypeMatch) return false;
      }

      // Filter by experience level
      if (filters.experienceLevel.length > 0) {
        const expMatch = description.match(PATTERNS.highlights.experience);
        if (expMatch) {
          const years = parseInt(expMatch[0]);
          const level = years <= 2
            ? 'Entry Level'
            : years <= 5
              ? 'Mid Level'
              : 'Senior Level';
          if (!filters.experienceLevel.includes(level)) return false;
        } else {
          return false;
        }
      }

      // Filter by salary range
      if (filters.salary.length > 0) {
        const salaryMatch = description.match(PATTERNS.highlights.salary);
        if (salaryMatch) {
          const numbers = salaryMatch[0].match(/\d+/g);
          if (numbers) {
            const amount = parseInt(numbers[0]);
            const range = amount < 50000
              ? '$0-50K'
              : amount < 100000
                ? '$50-100K'
                : amount < 150000
                  ? '$100-150K'
                  : '$150K+';
            if (!filters.salary.includes(range)) return false;
          }
        } else {
          return false;
        }
      }

      // Filter by workplace type
      if (filters.workplaceType.length > 0) {
        const isRemote = description.includes('remote');
        const isHybrid = description.includes('hybrid');
        const isOnsite = description.includes('on-site') ||
          description.includes('onsite') ||
          description.includes('in office');
        const match = (isRemote && filters.workplaceType.includes('Remote')) ||
          (isHybrid && filters.workplaceType.includes('Hybrid')) ||
          (isOnsite && filters.workplaceType.includes('On-site'));
        if (!match) return false;
      }

      // Filter by technologies
      if (filters.technologies.length > 0) {
        const techMatches = description.match(PATTERNS.highlights.tech) || [];
        const hasMatchingTech = filters.technologies.some(tech =>
          techMatches.some(match => match.toLowerCase() === tech.toLowerCase())
        );
        if (!hasMatchingTech) return false;
      }

      return true;
    });
  }, [filters]);

  // Auto-locate user effect - modified to use activeLocation
  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          try {
            const { latitude, longitude } = pos.coords;
            const res = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            );
            const data = await res.json();
            const defaultLocation = data.city ?
              `${data.city}, ${data.principalSubdivisionCode || data.countryName}` :
              'New York, NY';
            setLocation(defaultLocation);
            setActiveLocation(defaultLocation); // Set both location states
            handleSearch(1, true, defaultLocation); // Initial search with default location
          } catch (err) {
            console.error('Geocode error:', err);
            const defaultLocation = 'New York, NY';
            setLocation(defaultLocation);
            setActiveLocation(defaultLocation);
          }
        },
        (err) => {
          console.error('User denied geolocation:', err);
          const defaultLocation = 'New York, NY';
          setLocation(defaultLocation);
          setActiveLocation(defaultLocation);
        }
      );
    } else {
      const defaultLocation = 'New York, NY';
      setLocation(defaultLocation);
      setActiveLocation(defaultLocation);
    }
  }, []);

  const handleApplyJob = useCallback(async (job) => {
    try {
      await jobSearchApi.trackApplication(job.id);
      window.open(job.job_url, '_blank');
    } catch (error) {
      console.error('Error tracking application:', error);
      window.open(job.job_url, '_blank');
    }
  }, []);

  const totalPages = useMemo(() => Math.ceil(totalResults / ITEMS_PER_PAGE), [totalResults]);

  // Main search function - modified to use activeLocation
  const handleSearch = useCallback(
    async (page = 1, isNewSearch = false, searchLocation = null) => {
      const locationToUse = searchLocation || activeLocation;

      if (!searchTerm || !locationToUse || selectedSites.length === 0) {
        toast.error('Please enter both job title and location.');
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await jobSearchApi.searchJobs({
          search_term: searchTerm,
          location: locationToUse,
          sites: selectedSites,
          page,
          per_page: ITEMS_PER_PAGE,
          filters: {
            job_types: filters.jobType || [],
            experience_level: filters.experienceLevel || [],
            workplace_type: filters.workplaceType || [],
            date_posted: filters.datePosted?.length ? filters.datePosted[0] : null,
            salary_range: filters.salary || []
          }
        });

        const processed = response.data.jobs.map(j => ({
          ...j,
          parsedInfo: j.description ? parseJobDescription(j.description) : {}
        }));

        setJobs(processed);
        setTotalResults(response.data.total || processed.length);

        if (isNewSearch) {
          setCurrentPage(1);
        }

        if (processed.length > 0) {
          toast.success(`Found ${response.data.total || processed.length} jobs`);
        } else {
          toast.info('No jobs found with current criteria');
        }
      } catch (err) {
        console.error('Search error:', err);
        setError(err.message);
        toast.error('Failed to fetch jobs. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    },
    [searchTerm, activeLocation, selectedSites, filters]
  );

  // Form submission and location handlers
  const handleSubmitSearch = (e) => {
    e?.preventDefault();
    setActiveLocation(location); // Update the active location
    handleSearch(1, true, location);
  };

  const handleLocationChange = (value) => {
    setLocation(value); // Only update the input value
  };

  const handleLocationKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setActiveLocation(location); // Update active location on Enter
      handleSearch(1, true, location);
    }
  };

  // Filter jobs effect
  useEffect(() => {
    if (jobs.length > 0) {
      const filtered = filterJobs(jobs);
      setDisplayedJobs(filtered);
      setTotalResults(filtered.length);

      if (filtered.length > 0) {
        setSelectedJob(filtered[0]);
      } else {
        setSelectedJob(null);
      }
    } else {
      setDisplayedJobs([]);
      setSelectedJob(null);
    }
  }, [jobs, filterJobs]);

  // Pagination handler
  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
    handleSearch(newPage, false);
  }, [handleSearch]);

  // Site selection handler
  const handleSiteChange = useCallback((site) => {
    setSelectedSites((prev) => {
      if (prev.includes(site)) {
        if (prev.length === 1) {
          toast.error('At least one job site must be selected');
          return prev;
        }
        return prev.filter((s) => s !== site);
      }
      return [...prev, site];
    });
  }, []);

  // Job action handlers
  const handleSaveJob = useCallback(async (job, isSaved) => {
    try {
      if (isSaved) {
        await jobSearchApi.saveJob(job.id);
        toast.success('Job saved to your favorites');
      } else {
        await jobSearchApi.unsaveJob(job.id);
        toast.success('Job removed from favorites');
      }
    } catch (error) {
      console.error('Error saving job:', error);
      toast.error('Failed to save job');
    }
  }, []);

  const handleShareJob = useCallback(async (job) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `${job.title} at ${job.company}`,
          text: `Check out this job: ${job.title} at ${job.company}`,
          url: job.job_url
        });
      } else {
        await navigator.clipboard.writeText(job.job_url);
        toast.success('Job link copied to clipboard');
      }
    } catch (error) {
      console.error('Error sharing job:', error);
      toast.error('Failed to share job');
    }
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Toaster position="top-right" />

      {/* Top bar with search */}
      <div className="sticky top-0 z-50 bg-white border-b border-gray-200 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="space-y-4">
            <SiteSelector
              selectedSites={selectedSites}
              onSiteChange={handleSiteChange}
              disabled={isLoading}
            />

            <form onSubmit={handleSubmitSearch} className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="col-span-1 md:col-span-5">
                <SearchInput
                  value={searchTerm}
                  onChange={setSearchTerm}
                  suggestions={JOB_TITLES}
                  placeholder="Job title or keyword"
                  icon={Search}
                  disabled={isLoading}
                />
              </div>

              <div className="col-span-1 md:col-span-5">
                <SearchInput
                  value={location}
                  onChange={handleLocationChange}
                  onKeyPress={handleLocationKeyPress}
                  suggestions={MAJOR_CITIES}
                  placeholder="Location (press Enter to search)"
                  icon={MapPin}
                  disabled={isLoading}
                />
              </div>

              <div className="col-span-1 md:col-span-2">
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isLoading || !searchTerm || !location}
                  className="w-full h-full px-4 py-2 bg-[#B0EDF1A3] text-black rounded-lg 
                    hover:bg-[#A0C9D1A3] disabled:bg-[#A0C9D1A3] disabled:cursor-not-allowed 
                    flex items-center justify-center space-x-2 transition-colors"
                >
                  {isLoading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent mr-2" />
                      <span>Searching...</span>
                    </>
                  ) : (
                    <>
                      <Search className="w-5 h-5 mr-2" />
                      <span>Search Jobs</span>
                    </>
                  )}
                </motion.button>
              </div>
            </form>

            <div className="flex items-center justify-center-left">
              <FilterSection
                jobs={jobs}
                filters={filters}
                onChange={(cat, val) => {
                  setFilters(prev => ({ ...prev, [cat]: val }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-900">
            {totalResults > 0
              ? `${totalResults} results for "${searchTerm}" in ${activeLocation}`
              : 'Search for jobs'}
          </h2>
        </div>

        <div className="flex flex-col md:flex-row gap-6">
          {/* Left column: Job listings */}
          <div className="w-full md:w-5/12">
            <div className="h-[calc(100vh-200px)] overflow-y-auto">
              <div className="space-y-4">
                <AnimatePresence mode="popLayout">
                  {isLoading ? (
                    Array(10).fill(null).map((_, i) => (
                      <motion.div
                        key={`skeleton-${i}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="bg-white p-4 space-y-3 animate-pulse border-b border-gray-200"
                      >
                        <div className="flex space-x-4">
                          <div className="w-12 h-12 bg-gray-200 rounded-lg" />
                          <div className="flex-1 space-y-2">
                            <div className="h-4 bg-gray-200 rounded w-3/4" />
                            <div className="h-3 bg-gray-200 rounded w-1/2" />
                          </div>
                        </div>
                      </motion.div>
                    ))
                  ) : error ? (
                    <div className="text-center py-10">
                      <AlertCircle className="mx-auto h-12 w-12 text-red-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Error fetching jobs
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">{error}</p>
                      <button
                        onClick={() => handleSearch(1, true)}
                        className="mt-4 text-sm text-blue-600 hover:text-blue-500 flex items-center justify-center space-x-1"
                      >
                        <Search className="w-4 h-4" />
                        <span>Try again</span>
                      </button>
                    </div>
                  ) : jobs.length === 0 ? (
                    <div className="text-center py-10">
                      <Building className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        No jobs found
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Try adjusting your search criteria or selected job sites
                      </p>
                    </div>
                  ) : (
                    displayedJobs
                      .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
                      .map((job) => (
                        <JobListItem
                          key={job.id}
                          job={job}
                          isSelected={selectedJob?.id === job.id}
                          onClick={(job) => {
                            setSelectedJob(job);
                          }}
                          onSave={handleSaveJob}
                          onShare={handleShareJob}
                        />
                      ))
                  )}
                </AnimatePresence>
              </div>
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="mt-4 border-t border-gray-200">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>

          {/* Right column: Job details */}
          <div className="hidden md:block w-full md:w-7/12 bg-white rounded-lg shadow-sm 
            border border-gray-200 overflow-hidden h-[calc(100vh-200px)]"
          >
            <JobDetails
              job={selectedJob}
              onClose={() => setSelectedJob(null)}
              onApply={handleApplyJob}
              onSave={handleSaveJob}
              onShare={handleShareJob}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSearchPortal;
