import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  format,
  parseISO,
  differenceInDays,
  startOfToday,
  endOfToday,
  startOfWeek,
  startOfMonth,
  isWithinInterval,
} from 'date-fns';
import { BadgePlus, ExternalLink } from 'lucide-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from './base/Button/Button';
import { useNavigate } from 'react-router';

const JobDescriptionCell = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`p-2 text-sm text-gray-700 ${isExpanded ? '' : 'line-clamp-2'} cursor-pointer transition-all`}
      onClick={() => setIsExpanded(!isExpanded)}
      title="Click to expand/collapse"
    >
      {description || 'No description available'}
    </div>
  );
};

const formatNumber = (number) => {
  if (number < 10000) {
    return number.toLocaleString();
  }
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + 'B';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + 'M';
  } else if (number >= 1e4) {
    return (number / 1000).toFixed(1) + 'k';
  }
  return number.toLocaleString();
};

const StatCard = ({ title, value }) => (
  <div className="p-4 bg-green-100 rounded-lg shadow">
    <h3 className="text-lg font-semibold">{title}</h3>
    <p className="text-2xl font-bold truncate">{value}</p>
  </div>
);

const ApplicationsGrid = ({ applications, className }) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [kpiData, setKpiData] = useState({
    totalApplications: 0,
    applicationsToday: 0,
    applicationsThisWeek: 0,
    applicationsThisMonth: 0,
    mostAppliedCompany: '',
    mostAppliedJobType: '',
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [gridApi]);

  useEffect(() => {
    const jobTypeCounts = {};
    const companyCounts = {};
    let totalApplications = 0;
    let applicationsToday = 0;
    let applicationsThisWeek = 0;
    let applicationsThisMonth = 0;

    const todayStart = startOfToday();
    const todayEnd = endOfToday();
    const weekStart = startOfWeek(new Date());
    const monthStart = startOfMonth(new Date());
    if (!applications) return;
    applications.forEach((app) => {
      totalApplications++;
      const appliedDate = parseISO(app.time_applied);

      if (isWithinInterval(appliedDate, { start: todayStart, end: todayEnd })) {
        applicationsToday++;
      }
      if (appliedDate >= weekStart) {
        applicationsThisWeek++;
      }
      if (appliedDate >= monthStart) {
        applicationsThisMonth++;
      }

      if (app.job_type) {
        jobTypeCounts[app.job_type] = (jobTypeCounts[app.job_type] || 0) + 1;
      }
      if (app.company) {
        companyCounts[app.company] = (companyCounts[app.company] || 0) + 1;
      }
    });

    const mostAppliedJobType = Object.entries(jobTypeCounts).reduce((a, b) => (b[1] > a[1] ? b : a), [null, 0])[0];
    const mostAppliedCompany = Object.entries(companyCounts).reduce((a, b) => (b[1] > a[1] ? b : a), [null, 0])[0];

    setKpiData({
      totalApplications,
      applicationsToday,
      applicationsThisWeek,
      applicationsThisMonth,
      mostAppliedCompany,
      mostAppliedJobType,
    });
  }, [applications]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const exportData = (format) => {
    if (!gridApi) return;

    const rowData = [];
    gridApi.forEachNode((node) => rowData.push(node.data));

    const filename = `applications_data.${format}`;
    if (format === 'csv' || format === 'txt') {
      const dataStr = rowData.map((row) => Object.values(row).join(',')).join('\n');
      const fileLink = document.createElement('a');
      fileLink.href = `data:text/${format};charset=utf-8,${encodeURIComponent(dataStr)}`;
      fileLink.download = filename;
      fileLink.click();
    } else if (format === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(rowData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications');
      XLSX.writeFile(workbook, filename);
    }
  };

  const getColumnDefs = () => {
    const baseColumnDefs = [
      {
        headerName: 'Job Title',
        field: 'job_link',
        cellRenderer: (params) => (
          <div className="p-2">
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 font-medium mb-1 block"
              title={params.data.job_title || 'View Job'}
            >
              {params.data.job_title || 'View Job'}
              <ExternalLink size={16} className="inline-block ml-1" />
            </a>
          </div>
        ),
        flex: 2,
        minWidth: 200,
        tooltipField: 'job_title',
      },
      {
        headerName: 'Company',
        field: 'company',
        cellRenderer: (params) => (
          <div className="p-2 text-sm text-gray-600 truncate" title={params.value || 'Unknown Company'}>
            {params.value || 'Unknown Company'}
          </div>
        ),
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: 'Job Type',
        field: 'job_type',
        cellRenderer: (params) => (
          <div className="p-2 text-xs text-gray-500" title={params.value || 'Unknown Job Type'}>
            {params.value || 'Unknown Job Type'}
          </div>
        ),
        flex: 1,
        minWidth: 120,
      },
    ];

    // Add additional columns for desktop view
    if (windowWidth >= 768) {
      baseColumnDefs.push(
        {
          headerName: 'Job Description',
          field: 'job_description',
          cellRenderer: (params) => <JobDescriptionCell description={params.value} />,
          flex: 3,
          minWidth: 300,
          tooltipField: 'job_description',
          cellClass: 'cursor-pointer',
        },
        {
          headerName: 'Applied',
          field: 'time_applied',
          cellRenderer: (params) => (
            <div className="p-2">
              <div className="text-sm text-gray-700" title={params.value}>
                {params.value ? format(parseISO(params.value), 'PP p') : 'N/A'}
              </div>
            </div>
          ),
          flex: 1,
          minWidth: 150,
          sort: 'desc',
        },
        {
          headerName: 'Days Since Posted',
          field: 'days_since_posted',
          valueGetter: (params) => {
            const postedDate = params.data.posted_date ? parseISO(params.data.posted_date) : null;
            const appliedDate = params.data.time_applied ? parseISO(params.data.time_applied) : null;
            return postedDate && appliedDate ? differenceInDays(appliedDate, postedDate) : 'N/A';
          },
          cellRenderer: (params) => (
            <div className="p-2 text-sm text-gray-500">{params.value !== 'N/A' ? `${params.value} day(s)` : 'N/A'}</div>
          ),
          flex: 1,
          minWidth: 150,
          sortable: true,
        },
      );
    }

    return baseColumnDefs;
  };

  const gridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      tooltipComponentParams: { color: '#ececec' },
    },
    rowHeight: 60,
    headerHeight: 48,
    animateRows: true,
    rowClass: 'hover:bg-green-50 transition-all duration-300',
    getRowStyle: (params) => ({
      backgroundColor: params.node.rowIndex % 2 === 0 ? '#f0fdf4' : '#ffffff',
      borderLeft:
        params.data.status === 'Successful'
          ? '4px solid #10B981'
          : params.data.status === 'Failed'
            ? '4px solid #EF4444'
            : '4px solid #F59E0B',
      transition: 'all 0.3s ease',
    }),
    onGridReady,
    pagination: true,
    paginationPageSize: windowWidth >= 768 ? 50 : 10,
    suppressCellFocus: true,
    sortModel: [{ colId: 'time_applied', sort: 'desc' }],
  };

  return (
    <div className=" lg:col-span-2 bg-white p-4 md:p-8 rounded-2xl border-t-2 shadow-lg">
      <span className={'flex flex-nowrap justify-between items-center mb-6'}>
        <h2 className="text-2xl md:text-3xl font-bold ">Recent Applications</h2>
        <Button
          className={
            'cursor-pointer rounded-xl hover:shadow-blue-500/50 p-0 md:p-2 flex items-center flex-nowrap md:border md:border-green-300 md:gap-2'
          }
          onClick={() => {
            navigate('/auto-apply');
          }}
        >
          <span className={'hidden md:block text-nowrap'}>Create New Campaign</span>
          <BadgePlus className={'w-6 text-green-600'} />
        </Button>
      </span>

      {/* Stats Grid - Responsive */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-8">
        <StatCard title="Total Applications" value={formatNumber(kpiData.totalApplications)} />
        <StatCard title="Applications Today" value={formatNumber(kpiData.applicationsToday)} />
        <StatCard title="Applications This Week" value={formatNumber(kpiData.applicationsThisWeek)} />
        <StatCard title="Applications This Month" value={formatNumber(kpiData.applicationsThisMonth)} />
        <StatCard title="Most Applied Company" value={kpiData.mostAppliedCompany || 'N/A'} />
        <StatCard title="Most Applied Job Type" value={kpiData.mostAppliedJobType || 'N/A'} />
      </div>

      {/* AG Grid - Responsive */}
      <div className="ag-theme-alpine" style={{ height: windowWidth >= 768 ? 600 : 400, width: '100%' }}>
        <AgGridReact
          rowData={applications}
          columnDefs={getColumnDefs()}
          gridOptions={gridOptions}
          tooltipShowDelay={0}
          tooltipHideDelay={200}
        />
      </div>

      {/* Export Buttons - Responsive */}
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4">
        <button
          onClick={() => exportData('csv')}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          Download CSV
        </button>
        <button
          onClick={() => exportData('txt')}
          className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
        >
          Download TXT
        </button>
        <button
          onClick={() => exportData('xlsx')}
          className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition-colors"
        >
          Download Excel
        </button>
      </div>
    </div>
  );
};

export default ApplicationsGrid; // import { useState, useEffect, useMemo, useCallback } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import {
//   format,
//   parseISO,
//   differenceInDays,
//   startOfToday,
//   endOfToday,
//   startOfWeek,
//   startOfMonth,
//   isWithinInterval,
// } from 'date-fns';
// import { ExternalLink } from 'lucide-react';
// import * as XLSX from 'xlsx';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import { debounce } from '../utilities/debounce';
//
// const JobDescriptionCell = ({ description }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//
//   return (
//     <div
//       className={`p-2 text-sm text-gray-700 ${isExpanded ? '' : 'line-clamp-2'} cursor-pointer transition-all`}
//       onClick={() => setIsExpanded(!isExpanded)}
//       title="Click to expand/collapse"
//     >
//       {description || 'No description available'}
//     </div>
//   );
// };
// const isValidDate = (dateString) => {
//   const date = parseISO(dateString);
//   return !isNaN(date);
// };
//
// const formatNumber = (number) => {
//   if (number < 10000) {
//     return number.toLocaleString();
//   }
//   if (number >= 1e9) {
//     return (number / 1e9).toFixed(1) + 'B';
//   } else if (number >= 1e6) {
//     return (number / 1e6).toFixed(1) + 'M';
//   } else if (number >= 1e4) {
//     return (number / 1000).toFixed(1) + 'k';
//   }
//   return number.toLocaleString();
// };
//
// const StatCard = ({ title, value }) => (
//   <div className="p-4 bg-green-100 rounded-lg shadow">
//     <h3 className="text-lg font-semibold">{title}</h3>
//     <p className="text-2xl font-bold truncate">{value}</p>
//   </div>
// );
//
// const ApplicationsGrid = ({ applications = [] }) => {
//   const [gridApi, setGridApi] = useState(null);
//   const [gridColumnApi, setGridColumnApi] = useState(null);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const [kpiData, setKpiData] = useState({
//     totalApplications: 0,
//     applicationsToday: 0,
//     applicationsThisWeek: 0,
//     applicationsThisMonth: 0,
//     mostAppliedCompany: '',
//     mostAppliedJobType: '',
//   });
//   const handleResize = useCallback(() => {
//     const newWidth = window.innerWidth;
//     if (newWidth !== windowWidth) {
//       setWindowWidth(newWidth);
//       if (gridApi) {
//         gridApi.sizeColumnsToFit();
//       }
//     }
//   }, [gridApi, windowWidth]);
//   const debouncedHandleResize = useMemo(() => debounce(handleResize, 200), [handleResize]);
//   useEffect(() => {
//     window.addEventListener('resize', debouncedHandleResize);
//     return () => window.removeEventListener('resize', debouncedHandleResize);
//   }, [debouncedHandleResize]);
//
//   useEffect(() => {
//     const jobTypeCounts = {};
//     const companyCounts = {};
//     let totalApplications = 0;
//     let applicationsToday = 0;
//     let applicationsThisWeek = 0;
//     let applicationsThisMonth = 0;
//
//     const todayStart = startOfToday();
//     const todayEnd = endOfToday();
//     const weekStart = startOfWeek(new Date());
//     const monthStart = startOfMonth(new Date());
//     if (!applications) return;
//     applications.forEach((app) => {
//       totalApplications++;
//       const appliedDate = parseISO(app.time_applied);
//
//       if (isWithinInterval(appliedDate, { start: todayStart, end: todayEnd })) {
//         applicationsToday++;
//       }
//       if (appliedDate >= weekStart) {
//         applicationsThisWeek++;
//       }
//       if (appliedDate >= monthStart) {
//         applicationsThisMonth++;
//       }
//
//       if (app.job_type) {
//         jobTypeCounts[app.job_type] = (jobTypeCounts[app.job_type] || 0) + 1;
//       }
//       if (app.company) {
//         companyCounts[app.company] = (companyCounts[app.company] || 0) + 1;
//       }
//     });
//
//     const mostAppliedJobType = Object.entries(jobTypeCounts).reduce((a, b) => (b[1] > a[1] ? b : a), [null, 0])[0];
//     const mostAppliedCompany = Object.entries(companyCounts).reduce((a, b) => (b[1] > a[1] ? b : a), [null, 0])[0];
//     const newKpiData = {
//       totalApplications,
//       applicationsToday,
//       applicationsThisWeek,
//       applicationsThisMonth,
//       mostAppliedCompany,
//       mostAppliedJobType,
//     };
//
//     // Only update state if the new data is different
//     if (JSON.stringify(kpiData) !== JSON.stringify(newKpiData)) {
//       setKpiData(newKpiData);
//     }
//   }, [applications, setKpiData, kpiData]);
//
//   const onGridReady = useCallback(
//     (params) => {
//       console.log('onGridReady params: ', params);
//       if (!params) return;
//       setGridApi(params.api);
//       setGridColumnApi(params.columnApi);
//       params.api.sizeColumnsToFit();
//     },
//     [setGridApi, setGridColumnApi],
//   );
//
//   const exportData = (format) => {
//     if (!gridApi) return;
//
//     const rowData = [];
//     gridApi.forEachNode((node) => rowData.push(node.data));
//
//     const filename = `applications_data.${format}`;
//     if (format === 'csv' || format === 'txt') {
//       const dataStr = rowData.map((row) => Object.values(row).join(',')).join('\n');
//       const fileLink = document.createElement('a');
//       fileLink.href = `data:text/${format};charset=utf-8,${encodeURIComponent(dataStr)}`;
//       fileLink.download = filename;
//       fileLink.click();
//     } else if (format === 'xlsx') {
//       const worksheet = XLSX.utils.json_to_sheet(rowData);
//       const workbook = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(workbook, worksheet, 'Applications');
//       XLSX.writeFile(workbook, filename);
//     }
//   };
//
//   const getColumnDefs = () => {
//     const baseColumnDefs = [
//       {
//         headerName: 'Job Title',
//         field: 'job_link',
//         cellRenderer: (params) => (
//           <div className="p-2">
//             <a
//               href={params.value}
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-blue-600 hover:text-blue-800 font-medium mb-1 block"
//               title={params.data.job_title || 'View Job'}
//             >
//               {params.data.job_title || 'View Job'}
//               <ExternalLink size={16} className="inline-block ml-1" />
//             </a>
//           </div>
//         ),
//         flex: 2,
//         minWidth: 200,
//         tooltipField: 'job_title',
//       },
//       {
//         headerName: 'Company',
//         field: 'company',
//         cellRenderer: (params) => (
//           <div className="p-2 text-sm text-gray-600 truncate" title={params.value || 'Unknown Company'}>
//             {params.value || 'Unknown Company'}
//           </div>
//         ),
//         flex: 1,
//         minWidth: 150,
//       },
//       {
//         headerName: 'Job Type',
//         field: 'job_type',
//         cellRenderer: (params) => (
//           <div className="p-2 text-xs text-gray-500" title={params.value || 'Unknown Job Type'}>
//             {params.value || 'Unknown Job Type'}
//           </div>
//         ),
//         flex: 1,
//         minWidth: 120,
//       },
//     ];
//
//     // Add additional columns for desktop view
//     if (windowWidth >= 768) {
//       baseColumnDefs.push(
//         {
//           headerName: 'Job Description',
//           field: 'job_description',
//           cellRenderer: (params) => <JobDescriptionCell description={params.value} />,
//           flex: 3,
//           minWidth: 300,
//           tooltipField: 'job_description',
//           cellClass: 'cursor-pointer',
//         },
//         {
//           headerName: 'Applied',
//           field: 'time_applied',
//           cellRenderer: (params) => (
//             <div className="p-2">
//               <div className="text-sm text-gray-700" title={params.value}>
//                 {params.value && isValidDate(params.value) ? format(parseISO(params.value), 'PP p') : 'N/A'}
//               </div>
//             </div>
//           ),
//           flex: 1,
//           minWidth: 150,
//           sort: 'desc',
//         },
//         {
//           headerName: 'Days Since Posted',
//           field: 'days_since_posted',
//           valueGetter: (params) => {
//             const postedDateValid = isValidDate(params.data.posted_date);
//             const appliedDateValid = isValidDate(params.data.time_applied);
//             if (postedDateValid && appliedDateValid) {
//               const postedDate = parseISO(params.data.posted_date);
//               const appliedDate = parseISO(params.data.time_applied);
//               return differenceInDays(appliedDate, postedDate);
//             }
//             return 'N/A';
//           },
//           cellRenderer: (params) => (
//             <div className="p-2 text-sm text-gray-500">{params.value !== 'N/A' ? `${params.value} day(s)` : 'N/A'}</div>
//           ),
//           flex: 1,
//           minWidth: 150,
//           sortable: true,
//         },
//       );
//     }
//
//     return baseColumnDefs;
//   };
//
//   const gridOptions = {
//     defaultColDef: {
//       sortable: true,
//       filter: true,
//       resizable: true,
//       tooltipComponentParams: { color: '#ececec' },
//     },
//     rowHeight: 60,
//     headerHeight: 48,
//     animateRows: true,
//     rowClass: 'hover:bg-green-50 transition-all duration-300',
//     getRowStyle: (params) => ({
//       backgroundColor: params.node.rowIndex % 2 === 0 ? '#f0fdf4' : '#ffffff',
//       borderLeft:
//         params.data.status === 'Successful'
//           ? '4px solid #10B981'
//           : params.data.status === 'Failed'
//             ? '4px solid #EF4444'
//             : '4px solid #F59E0B',
//       transition: 'all 0.3s ease',
//     }),
//     onGridReady,
//     pagination: true,
//     paginationPageSize: windowWidth >= 768 ? 50 : 10,
//     suppressCellFocus: true,
//     sortModel: [{ colId: 'time_applied', sort: 'desc' }],
//   };
//
//   const columnDefs = useMemo(() => getColumnDefs(), [windowWidth]);
//
//   return (
//     <div className="mt-12 bg-white p-4 md:p-8 rounded-2xl shadow-lg">
//       <h2 className="text-2xl md:text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-blue-500">
//         Recent Applications
//       </h2>
//
//       {/* Stats Grid - Responsive */}
//       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 mb-8">
//         <StatCard title="Total Applications" value={formatNumber(kpiData.totalApplications)} />
//         <StatCard title="Applications Today" value={formatNumber(kpiData.applicationsToday)} />
//         <StatCard title="Applications This Week" value={formatNumber(kpiData.applicationsThisWeek)} />
//         <StatCard title="Applications This Month" value={formatNumber(kpiData.applicationsThisMonth)} />
//         <StatCard title="Most Applied Company" value={kpiData.mostAppliedCompany || 'N/A'} />
//         <StatCard title="Most Applied Job Type" value={kpiData.mostAppliedJobType || 'N/A'} />
//       </div>
//
//       {/* AG Grid - Responsive */}
//       <div className="ag-theme-alpine" style={{ height: windowWidth >= 768 ? 600 : 400, width: '100%' }}>
//         <AgGridReact
//           rowData={applications}
//           columnDefs={columnDefs}
//           gridOptions={gridOptions}
//           tooltipShowDelay={0}
//           tooltipHideDelay={200}
//         />
//       </div>
//
//       {/* Export Buttons - Responsive */}
//       <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mt-4">
//         <button
//           onClick={() => exportData('csv')}
//           className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
//         >
//           Download CSV
//         </button>
//         <button
//           onClick={() => exportData('txt')}
//           className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
//         >
//           Download TXT
//         </button>
//         <button
//           onClick={() => exportData('xlsx')}
//           className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 transition-colors"
//         >
//           Download Excel
//         </button>
//       </div>
//     </div>
//   );
// };
//
// export default ApplicationsGrid;
