import React from 'react';
import { twMerge } from 'tailwind-merge';
// Card Components
export const Card = React.forwardRef(({ className, children, ...props }, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={twMerge('text-card-foreground rounded-lg border bg-card shadow-sm', className)}
    {...props}
  >
    {children}
  </div>
));
Card.displayName = 'Card';
