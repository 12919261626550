import { getStoredSession, removeTokensFromLocalStorage } from './localStorageClient';
import { handleError } from '../utilities/handleError';

export const getAuthenticatedSession = async () => {
  try {
    const session = await getStoredSession();
    console.log('session:', session);
    // Check if the session is valid
    if (!session?.user?.id) {
      removeTokensFromLocalStorage(); // Only clear relevant session data
      return;
      // handleError({
      //   error: 'User not authenticated',
      //   message: {
      //     title: 'User not authenticated',
      //     message: 'Please log in to access this page.',
      //   },
      //   toastType: 'info',
      // });
      // return redirect('/'); // React Router-specific behavior
    }

    return session; // Return the valid session
  } catch (error) {
    handleError({
      message: 'Failed to retrieve authenticated session',
      error,
      logError: true,
      showToast: false,
    });
    throw error; // Ensure errors are properly bubbled up
  }
};
