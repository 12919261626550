import React, { useEffect } from 'react';
import { Form, useFetcher } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import { Briefcase, Loader } from 'lucide-react';
import { ResumeUploadButton } from '../ResumeUploadButton/ResumeUploadButton';
import { ExistingDiceAccountForm } from '../ExistingDiceAccountForm/ExistingDiceAccountForm';
import { Button } from '../../base/Button/Button';
import { useToastContext } from '../../contexts/useToastContext/useToastContext';
import { twMerge } from 'tailwind-merge';

const LoadingMessage = ({ message }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex items-center justify-center space-x-3 py-4"
  >
    <Loader className="animate-spin text-blue-500" size={20} />
    <span className="text-gray-600 font-medium">{message}</span>
  </motion.div>
);

const Input = ({ icon: Icon = null, required, hasError, ...props }) => {
  const borderClasses = hasError ? 'border-red-500' : 'border-gray-300';
  const textClasses = hasError ? 'text-red-500 placeholder-red-500' : 'text-gray-800 placeholder-gray-400';
  const focusClasses = hasError
    ? 'focus:ring-red-500 focus:border-red-500'
    : 'focus:ring-blue-500 focus:border-blue-500';

  // if (hasError) {
  //   console.error('Error in CreateProfileCardUI.jsx: ', props.name, props.value);
  // }
  return (
    <div className="relative">
      {Icon && <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />}
      <input
        className={`w-full py-2 px-3 ${
          Icon && 'pl-10'
        } bg-gray-100 rounded-md ${borderClasses} ${textClasses} ${focusClasses} transition-all`}
        {...props}
      />
      {required && <span className="required-asterisk absolute top-0 left-1 text-red-400">*</span>}
    </div>
  );
};

const Select = ({ icon: Icon, required, ...props }) => (
  <div className="relative">
    <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
    <select
      className="w-full py-2 pl-10 pr-3 bg-gray-100 border border-gray-300 rounded-md text-gray-800 appearance-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
      {...props}
    />
    {required && <span className="required-asterisk absolute top-0 left-1 text-red-600">*</span>}
  </div>
);

export const CreateProfileCardUI = ({
  className,
  user,
  formData,
  setFormData,
  error,
  errorFields,
  showDiceLoginForm,
  showConfetti,
  setShowLoginForm,
  setError,
  handleResumeUpload,
  handleSubmit,
  loading,
  isSubmitting,
  loadingStep,
  // handleExistingAccountLogin,
  jobTitles,
  onCompletedDiceLogin,
}) => {
  // const fetcher = useFetcher();
  // const { showToast } = useToastContext();
  if (errorFields.length > 0) {
    console.error('Error in CreateProfileCardUI.jsx: ', errorFields);
  }
  if (showDiceLoginForm) {
    return (
      <>
        {showConfetti && <ReactConfetti numberOfPieces={500} recycle={false} gravity={0.2} />}
        <ExistingDiceAccountForm
          userEmail={user.email}
          userId={user.id}
          onBack={() => {
            setShowLoginForm(false);
            setError(null);
          }}
          onSuccess={onCompletedDiceLogin}
          loading={isSubmitting || loading}
          loadingStep={loadingStep}
        />
      </>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={twMerge('max-w-2xl mx-auto bg-white p-8 pt-6 rounded-xl', className)}
    >
      {showConfetti && <ReactConfetti numberOfPieces={500} recycle={false} gravity={0.2} />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        className="relative"
      >
        <input type="hidden" name="actionType" value="registerUserProfile" />
        <input type="hidden" name="userId" value={user?.id} />

        <div className="flex flex-nowrap justify-between items-center mb-6 ">
          <h2 className="text-2xl flex-shrink font-bold text-gray-800">Complete Your Profile</h2>
          <div className="min-w-10 w-12 h-12 rounded-full bg-gray-200 flex self-start justify-center overflow-hidden border-2 border-gray-300">
            <img src={formData.avatarUrl} alt="Profile" className="w-full h-full object-cover" />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <Input
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={(e) => setFormData((prev) => ({ ...prev, username: e.target.value }))}
            required
            disabled={isSubmitting}
            autoComplete="username"
            hasError={errorFields.includes('username')}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Input
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={(e) => setFormData((prev) => ({ ...prev, firstName: e.target.value }))}
              required
              disabled={isSubmitting}
              autoComplete="given-name"
              hasError={errorFields.includes('firstName')}
            />

            <Input
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={(e) => setFormData((prev) => ({ ...prev, lastName: e.target.value }))}
              required
              disabled={isSubmitting}
              autoComplete="family-name"
              hasError={errorFields.includes('lastName')}
            />
          </div>

          {/*<Select*/}
          {/*  name="jobTitle"*/}
          {/*  icon={Briefcase}*/}
          {/*  value={formData.jobTitle}*/}
          {/*  onChange={(e) => setFormData((prev) => ({ ...prev, jobTitle: e.target.value }))}*/}
          {/*  disabled={isSubmitting}*/}
          {/*>*/}
          {/*  <option value="">Select Job Title</option>*/}
          {/*  {jobTitles.map((title) => (*/}
          {/*    <option key={title} value={title}>*/}
          {/*      {title}*/}
          {/*    </option>*/}
          {/*  ))}*/}
          {/*</Select>*/}

          <div className="mb-6">
            <ResumeUploadButton
              required
              name="resume"
              onResumeUpload={handleResumeUpload}
              hasCurrentResume={!!formData.resume}
              disabled={isSubmitting}
              hasError={errorFields.includes('resume')}
            />
          </div>
        </div>

        <div>
          <AnimatePresence>{isSubmitting && loadingStep && <LoadingMessage message={loadingStep} />}</AnimatePresence>
          <Button
            type="submit"
            className={`w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white hover:from-blue-600 hover:to-purple-600 ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="flex flex-nowrap items-center justify-center space-x-2">
                <Loader className="animate-spin" size={16} />
                <span>Processing...</span>
              </span>
            ) : (
              'Complete Profile'
            )}
          </Button>
        </div>

        {/*<AnimatePresence>*/}
        {/*  {error && (*/}
        {/*    <motion.div*/}
        {/*      initial={{ opacity: 0, y: -10 }}*/}
        {/*      animate={{ opacity: 1, y: 0 }}*/}
        {/*      exit={{ opacity: 0, y: -10 }}*/}
        {/*      className="rounded-lg bg-red-50 p-4 border border-red-200"*/}
        {/*    >*/}
        {/*      <p className="text-red-500 text-center">{error}</p>*/}
        {/*    </motion.div>*/}
        {/*  )}*/}
        {/*</AnimatePresence>*/}
      </form>

      <AnimatePresence>
        {isSubmitting && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed bottom-4 right-4 bg-white rounded-lg shadow-xl p-4 max-w-md"
          >
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-700">{loadingStep}</span>
                <Loader className="animate-spin" size={16} />
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1">
                <motion.div
                  className="h-1 rounded-full bg-blue-500"
                  initial={{ width: '0%' }}
                  animate={{ width: '100%' }}
                  transition={{ duration: 2, repeat: Infinity }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
