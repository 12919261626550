import React from 'react';
import { motion } from 'framer-motion';
import {
    MapPin,
    Briefcase,
    Globe,
    DollarSign,
    Clock,
    AlertCircle,
    Star,
    Sparkles,
    X,
    ExternalLink,
    Search,
    Code,
    Cpu,
    Bookmark,
    GraduationCap
} from 'lucide-react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { FormattedJobDescription } from '../utilities/description-utils';
import Badge from './Badge';

import { SITE_CONFIG } from './SiteSelector';
const JobDetails = ({ job, onClose, onApply, onSave, onShare, onFindSimilar }) => {
    if (!job) {
        return (
            <div className="h-full flex items-center justify-center text-gray-500">
                <div className="text-center">
                    <AlertCircle className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-lg font-medium text-gray-900">
                        Place a Search!
                    </h3>
                    <p className="mt-1 text-gray-500">
                        Select a job from the list to view details.
                    </p>
                </div>
            </div>
        );
    }

    // Get site configuration
    const getSiteConfig = (sourceSite) => {
        const siteKey = sourceSite?.toLowerCase()?.replace(/[^a-z0-9]/g, '_');
        return SITE_CONFIG[siteKey] || {
            name: sourceSite?.toUpperCase() || 'Unknown',
            color: 'bg-gray-100',
            textColor: 'text-gray-600',
            borderColor: 'border-gray-300',
            hoverColor: 'hover:bg-gray-50',
            logo: '/favicon.ico'
        };
    };

    const siteConfig = getSiteConfig(job.source_site);

    const {
        title,
        company,
        company_info,
        location,
        salary,
        job_type,
        is_remote,
        date_posted,
        description,
        benefits,
        parsedInfo
    } = job;

    // Directly open job site:
    const handleApplyClick = () => {
        if (job?.job_url) {
            window.open(job.job_url, '_blank');
        }
    };

    // Button styles based on site config
    const primaryButtonClass = `px-4 py-2 ${siteConfig.color} ${siteConfig.textColor} rounded-lg 
        ${siteConfig.hoverColor} transition-all duration-200 border ${siteConfig.borderColor}
        hover:shadow-md active:scale-95`;

    const secondaryButtonClass = `px-4 py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-100 
        transition-all duration-200 border border-gray-200 hover:shadow-md active:scale-95`;

    return (
        <div className="h-full overflow-auto">
            {/* Sticky Header with site-specific styling */}
            <div className={`sticky top-0 z-10 bg-white border-b ${siteConfig.borderColor}`}>
                {/* Header with Company Info and Close Button */}
                <div className="flex items-start justify-between p-6">
                    <div className="flex items-center space-x-4">
                        <div className="relative">
                            <img
                                src={company_info?.logo_url || '/favicon-96x96.png'}
                                alt={`${company} Logo`}
                                className="w-12 h-12 rounded-lg object-contain bg-gray-50"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = '/favicon-96x96.png';
                                }}
                            />
                            {/* Site indicator badge */}
                            <div className="absolute -top-2 -right-2">
                                <img
                                    src={siteConfig.logo}
                                    alt={siteConfig.name}
                                    className="w-6 h-6 rounded-full border-2 border-white shadow-sm"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/favicon.ico';
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-xl font-bold text-gray-900">{title}</h2>
                            <p className="text-lg text-gray-600">{company}</p>
                        </div>
                    </div>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={onClose}
                        className="rounded-full p-2 hover:bg-gray-100 text-gray-400 
                            hover:text-gray-500 transition-all duration-200"
                        aria-label="Close Job Details"
                    >
                        <X className="w-6 h-6" />
                    </motion.button>
                </div>

                {/* Quick Info + Buttons */}
                <div className="flex flex-col md:flex-row justify-between items-center px-6 pb-4">
                    <div className="flex flex-wrap items-center gap-4 mb-2 md:mb-0">
                        {/* Location */}
                        <span className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 
                            transition-colors duration-200">
                            <MapPin className="w-4 h-4 mr-1" />
                            {[location?.city, location?.state, location?.country]
                                .filter(Boolean)
                                .join(', ') || 'Location not specified'}
                        </span>

                        {/* Salary */}
                        {salary && (
                            <span className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 
                                transition-colors duration-200">
                                <DollarSign className="w-4 h-4 mr-1" />
                                {salary}
                            </span>
                        )}
                    </div>

                    {/* Action Buttons */}
                    <div className="flex items-center gap-2">
                        {/* Search Similar Button */}
                        {/* <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={() => {
                                const searchTerms = [
                                    job.title,
                                    ...(parsedInfo?.technologies || []).slice(0, 3),
                                    job_type,
                                    is_remote ? 'remote' : ''
                                ].filter(Boolean);
                                onFindSimilar?.(searchTerms.join(' '));
                            }}
                            className={secondaryButtonClass}
                            aria-label="Search Similar Jobs"
                        >
                            <div className="flex items-center space-x-2">
                                <Search className="w-4 h-4" />
                                <span>Find Similar</span>
                            </div>
                        </motion.button> */}

                        {/* AI Resume Button */}
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className={primaryButtonClass}
                            aria-label="Customize AI Resume"
                        >
                            <div className="flex items-center space-x-2">
                                <Sparkles className="w-4 h-4" />
                                <span>AI Resume</span>
                            </div>
                        </motion.button>

                        {/* Apply Now Button */}
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={handleApplyClick}
                            className={`${primaryButtonClass} font-medium`}
                            aria-label="Apply Now"
                        >
                            <div className="flex items-center space-x-2">
                                <span>Apply Now</span>
                                <ExternalLink className="w-4 h-4" />
                            </div>
                        </motion.button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="p-6 space-y-6">
                {/* Tags Section with site-specific styling */}
                <div className="flex flex-wrap gap-4">
                    {job_type && (
                        <motion.span
                            whileHover={{ scale: 1.05 }}
                            className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm 
                                ${siteConfig.color} ${siteConfig.textColor} ${siteConfig.borderColor} border`}
                        >
                            <Briefcase className="w-4 h-4 mr-2" />
                            {job_type}
                        </motion.span>
                    )}

                    {is_remote && (
                        <motion.span
                            whileHover={{ scale: 1.05 }}
                            className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm 
                                ${siteConfig.color} ${siteConfig.textColor} ${siteConfig.borderColor} border`}
                        >
                            <Globe className="w-4 h-4 mr-2" />
                            Remote
                        </motion.span>
                    )}

                    <motion.span
                        whileHover={{ scale: 1.05 }}
                        className="inline-flex items-center px-3 py-1.5 rounded-lg text-sm 
                            bg-gray-100 text-gray-600 border border-gray-200"
                    >
                        <Clock className="w-4 h-4 mr-2" />
                        Posted {formatDistanceToNow(parseISO(date_posted), { addSuffix: true })}
                    </motion.span>
                </div>

                {/* Skills and Requirements Section */}
                {(parsedInfo?.skills?.length > 0 || parsedInfo?.technologies?.length > 0) && (
                    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                        <div className="grid grid-cols-1 md:grid-cols-2 divide-y md:divide-y-0 md:divide-x divide-gray-200">
                            {/* Technical Skills */}
                            {parsedInfo?.technologies?.length > 0 && (
                                <div className="p-6">
                                    <div className="flex items-center space-x-2 mb-4">
                                        <Code className="w-5 h-5 text-blue-500" />
                                        <h3 className="text-lg font-semibold text-gray-900">Technical Skills</h3>
                                    </div>
                                    <div className="flex flex-wrap gap-2">
                                        {parsedInfo.technologies.map((tech, index) => (
                                            <motion.span
                                                key={index}
                                                whileHover={{ scale: 1.05 }}
                                                className={`inline-flex items-center px-3 py-1.5 rounded-lg text-sm 
                                                    ${siteConfig.color} ${siteConfig.textColor} border ${siteConfig.borderColor}
                                                    hover:shadow-sm transition-all duration-200`}
                                            >
                                                <Cpu className="w-4 h-4 mr-2" />
                                                {tech}
                                            </motion.span>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Other Skills/Requirements */}
                            {parsedInfo?.skills?.length > 0 && (
                                <div className="p-6">
                                    <div className="flex items-center space-x-2 mb-4">
                                        <GraduationCap className="w-5 h-5 text-purple-500" />
                                        <h3 className="text-lg font-semibold text-gray-900">Required Skills</h3>
                                    </div>
                                    <div className="flex flex-wrap gap-2">
                                        {parsedInfo.skills.map((skill, index) => (
                                            <motion.span
                                                key={index}
                                                whileHover={{ scale: 1.05 }}
                                                className="inline-flex items-center px-3 py-1.5 rounded-lg text-sm 
                                                    bg-purple-50 text-purple-700 border border-purple-200
                                                    hover:shadow-sm transition-all duration-200"
                                            >
                                                <Star className="w-4 h-4 mr-2" />
                                                {skill}
                                            </motion.span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Job Description with styled container */}
                <div className={`rounded-lg border ${siteConfig.borderColor} p-6 bg-white space-y-4`}>
                    <h3 className="text-lg font-semibold text-gray-900">Job Description</h3>
                    <div className="prose prose-gray max-w-none">
                        <FormattedJobDescription description={description} />
                    </div>
                </div>

                {/* Benefits with styled container */}
                {benefits && (
                    <div className="bg-gray-50 rounded-lg p-6">
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Benefits</h3>
                        {typeof benefits === 'string' ? (
                            <p className="text-gray-700">{benefits}</p>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {benefits.map((benefit, index) => (
                                    <motion.div
                                        key={index}
                                        whileHover={{ scale: 1.02 }}
                                        className="flex items-center space-x-2 bg-white p-3 rounded-lg
                                            border border-gray-200 hover:shadow-md transition-all duration-200"
                                    >
                                        <Star className="w-5 h-5 text-yellow-400 flex-shrink-0" />
                                        <span className="text-gray-700">{benefit}</span>
                                    </motion.div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {/* AI Application Support */}
                <motion.div
                    whileHover={{ scale: 1.01 }}
                    className={`${siteConfig.color} rounded-lg p-6 hover:shadow-lg 
                        transition-all duration-200 border ${siteConfig.borderColor}`}
                >
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className={`text-lg font-semibold ${siteConfig.textColor}`}>
                                Free AI Application Pack
                            </h3>
                            <p className="text-gray-600 mt-1">
                                Get AI-powered help with your application
                            </p>
                        </div>
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className={primaryButtonClass}
                            aria-label="Customize AI Pack"
                        >
                            <div className="flex items-center space-x-2">
                                <Sparkles className="w-4 h-4" />
                                <span>Customize</span>
                            </div>
                        </motion.button>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default JobDetails;