// src/components/concrete/CreateApplicationForm/CreateApplicationForm.jsx

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import {
  Briefcase,
  MapPin,
  Hash,
  ArrowRight,
  ArrowLeft,
  CheckCircle,
  AlertTriangle,
  FileText,
  ArrowUpCircle,
  RefreshCw,
  Sparkles,
  ShoppingCart,
  Link2,
  Info as InfoIcon,
} from 'lucide-react';
import { useToast } from '../../Toast';
import { supabase } from '../../../supabaseConfig';
import { PricingModal } from '../../Header';
import { postApplication, submitJobApplication } from '../../../api/apiClient';
import { getCreditsByUserId, getDicePasswordByUserId } from '../../../api/supabaseClient';
import { handleError } from '../../../utilities/handleError';
import { Modal } from '../../base/Modal/Modal';
import CampaignOverlay, { CampaignInProgressMessage } from '../CurrentCampaignOverlay/CampaignInProgressMessage';
import { toast } from 'react-hot-toast';
import { PricingCard } from '../PricingCard/PricingCard';

// API Configuration
// https://8217-45-130-83-55.ngrok-free.app/

// Application Configuration
const APPLICATION_CONFIG = {
  JOB_TYPES: [
    'Software Engineer',
    'Senior Software Engineer',
    'Full Stack Developer',
    'Frontend Developer',
    'Backend Developer',
    'DevOps Engineer',
    'Data Scientist',
    'Data Analyst',
    'Machine Learning Engineer',
    'Product Manager',
    'Project Manager',
    'UI/UX Designer',
    'Quality Assurance Engineer',
    'Business Analyst',
    'Systems Analyst',
    'Cloud Engineer',
    'Network Engineer',
    'Security Engineer',
    'Technical Writer',
    'Web Developer',
    'Mobile Developer',
    'Game Developer',
    'Data Engineer',
    'DevSecOps Engineer',
    'Research Scientist',
    'Blockchain Developer',
    'AI Engineer',
    'Embedded Systems Engineer',
    'Site Reliability Engineer',
    'Game Designer',
    'IT Support Specialist',
    'Chief Technology Officer (CTO)',
    'Technical Project Manager',
    'Product Designer',
    'Digital Marketing Manager',
    'SEO Specialist',
    'Content Marketing Strategist',
    'Social Media Manager',
    'Email Marketing Specialist',
    'PPC Specialist',
    'Growth Hacker',
    'Brand Manager',
    'Marketing Analyst',
    'Product Marketing Manager',
    'Marketing Coordinator',
    'Affiliate Marketing Manager',
    'Public Relations Specialist',
    'Event Marketing Manager',
    'Influencer Marketing Manager',
    'Creative Director',
    'Art Director',
    'Graphic Designer',
    'Copywriter',
    'Content Strategist',
    'Web Analytics Specialist',
    'User Experience Researcher',
    'Customer Experience Manager',
    'Sales Manager',
    'Account Executive',
    'Sales Development Representative',
    'Business Development Manager',
    'Sales Operations Manager',
    'Inside Sales Representative',
    'Outside Sales Representative',
    'Territory Sales Manager',
    'Field Sales Representative',
    'Regional Sales Manager',
    'Sales Engineer',
    'Key Account Manager',
    'Customer Success Manager',
    'Lead Generation Specialist',
    'Proposal Manager',
    'Channel Sales Manager',
    'Sales Trainer',
    'Revenue Operations Manager',
    'Sales Enablement Manager',
    'Director of Sales',
    'Chief Sales Officer (CSO)',
    'Chief Marketing Officer (CMO)',
    'Head of Growth',
    'Market Research Analyst',
    'Market Intelligence Analyst',
    'Retail Marketing Manager',
    'Product Analyst',
    'Supply Chain Manager',
    'Operations Manager',
    'Logistics Coordinator',
    'Inventory Manager',
    'Vendor Manager',
    'Financial Analyst',
    'Cost Analyst',
    'Investment Analyst',
    'Compliance Manager',
    'Risk Manager',
    'Project Coordinator',
    'Program Manager',
    'Change Manager',
    'Agile Coach',
    'Scrum Master',
    'Operations Analyst',
    'Quality Control Inspector',
    'Facilities Manager',
    'Data Governance Manager',
    'Database Administrator',
    'IT Project Coordinator',
    'Help Desk Technician',
    'Technical Support Engineer',
    'System Administrator',
    'Network Administrator',
    'IT Manager',
    'Chief Information Officer (CIO)',
    'Chief Data Officer (CDO)',
    'Data Privacy Officer',
    'Compliance Analyst',
    'Training and Development Manager',
    'HR Business Partner',
    'Talent Acquisition Specialist',
    'Employee Engagement Manager',
    'Organizational Development Specialist',
    'Diversity and Inclusion Manager',
  ],
  LOCATIONS: [
    'REMOTE',
    'San Francisco, CA, USA',
    'New York, NY, USA',
    'Los Angeles, CA, USA',
    'Chicago, IL, USA',
    'Houston, TX, USA',
    'Phoenix, AZ, USA',
    'Philadelphia, PA, USA',
    'San Antonio, TX, USA',
    'San Diego, CA, USA',
    'Dallas, TX, USA',
    'San Jose, CA, USA',
    'Austin, TX, USA',
    'Jacksonville, FL, USA',
    'San Francisco Bay Area, CA, USA',
    'Columbus, OH, USA',
    'Fort Worth, TX, USA',
    'Indianapolis, IN, USA',
    'Charlotte, NC, USA',
    'Seattle, WA, USA',
    'Denver, CO, USA',
    'Washington, D.C., USA',
    'Boston, MA, USA',
    'El Paso, TX, USA',
    'Detroit, MI, USA',
    'Nashville, TN, USA',
    'Baltimore, MD, USA',
    'Oklahoma City, OK, USA',
    'Milwaukee, WI, USA',
    'Albuquerque, NM, USA',
    'Tucson, AZ, USA',
    'Fresno, CA, USA',
    'Sacramento, CA, USA',
    'Long Beach, CA, USA',
    'Kansas City, MO, USA',
    'Mesa, AZ, USA',
    'Virginia Beach, VA, USA',
    'Atlanta, GA, USA',
    'Colorado Springs, CO, USA',
    'Omaha, NE, USA',
    'Raleigh, NC, USA',
    'Miami, FL, USA',
    'Cleveland, OH, USA',
    'Tulsa, OK, USA',
    'Oakland, CA, USA',
    'Minneapolis, MN, USA',
    'Wichita, KS, USA',
    'New Orleans, LA, USA',
    'Arlington, TX, USA',
    'Bakersfield, CA, USA',
    'Tampa, FL, USA',
    'Honolulu, HI, USA',
    'Anaheim, CA, USA',
    'Santa Ana, CA, USA',
    'St. Louis, MO, USA',
    'Riverside, CA, USA',
    'Corpus Christi, TX, USA',
    'Pittsburgh, PA, USA',
    'Lexington, KY, USA',
    'Anchorage, AK, USA',
    'Stockton, CA, USA',
    'Cincinnati, OH, USA',
    'Saint Paul, MN, USA',
    'Greensboro, NC, USA',
    'Chula Vista, CA, USA',
    'Jersey City, NJ, USA',
    'Henderson, NV, USA',
    'Lincoln, NE, USA',
    'Buffalo, NY, USA',
    'Chandler, AZ, USA',
    'Scottsdale, AZ, USA',
    'Glendale, AZ, USA',
    'Overland Park, KS, USA',
    'Tempe, AZ, USA',
    'Cape Coral, FL, USA',
    'Mobile, AL, USA',
    'Tallahassee, FL, USA',
    'Grand Rapids, MI, USA',
    'Salt Lake City, UT, USA',
    'Canton, OH, USA',
    'Oceanside, CA, USA',
    'Sarasota, FL, USA',
    'Jackson, MS, USA',
    'Macon, GA, USA',
    'Little Rock, AR, USA',
    'Baton Rouge, LA, USA',
    'Huntsville, AL, USA',
    'Chattanooga, TN, USA',
    'Columbia, SC, USA',
    'Des Moines, IA, USA',
    'Provo, UT, USA',
    'Killeen, TX, USA',
    'Boulder, CO, USA',
    'Boise, ID, USA',
    'Winston-Salem, NC, USA',
    'Springfield, MA, USA',
    'Akron, OH, USA',
    'Nashua, NH, USA',
    'Maui, HI, USA',
    'Remote',
    'Hybrid (Remote/In-office)',
    'International (Various Locations)',
    'United Kingdom',
    'Germany',
    'Canada',
    'Australia',
    'Singapore',
  ],
  APPLICATION_COUNTS: [10, 20, 40, 80, 120, 160, 200, 400, 600, 800, 1000],
};

// Resume Status Configuration
const RESUME_STATUS_CONFIG = {
  IDLE: {
    icon: FileText,
    color: 'text-gray-500',
    backgroundColor: 'bg-gray-100',
    text: 'Ready to upload',
  },
  UPLOADING: {
    icon: RefreshCw,
    color: 'text-blue-500',
    backgroundColor: 'bg-blue-100',
    text: 'Uploading...',
  },
  SUCCESS: {
    icon: CheckCircle,
    color: 'text-green-500',
    backgroundColor: 'bg-green-100',
    text: 'Upload successful',
  },
  ERROR: {
    icon: AlertTriangle,
    color: 'text-red-500',
    backgroundColor: 'bg-red-100',
    text: 'Upload failed',
  },
};

// Step Indicator Component
const StepIndicator = ({ steps, currentStep, completedSteps }) => {
  return (
    <div className="flex items-center justify-between mb-12 relative">
      <div className="absolute h-1 bg-gray-200 w-full top-1/2 -translate-y-1/2 z-0" />
      {steps.map((step, index) => (
        <motion.div
          key={index}
          className="relative z-10 flex flex-col items-center "
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <div
            className={`
              w-10 h-10 rounded-full 
              flex items-center justify-center 
              font-semibold
              ${completedSteps[index]
                ? 'bg-green-500 text-white'
                : currentStep === index
                  ? 'bg-blue-500 text-white'
                  : 'bg-white border-2 border-gray-200 text-gray-400'
              }
            `}
          >
            {completedSteps[index] ? <CheckCircle className="w-5 h-5" /> : <step.icon className="w-5 h-5" />}
          </div>
          <span className="absolute -bottom-6 whitespace-nowrap text-xs font-medium text-gray-600">{step.title}</span>
        </motion.div>
      ))}
    </div>
  );
};

// Resume Status Component
const ResumeStatus = ({ status, file }) => {
  const config = RESUME_STATUS_CONFIG[status.toUpperCase()];
  const StatusIcon = config.icon;

  return (
    <div className={`mt-4 p-4 rounded-lg ${config.backgroundColor}`}>
      <div className="flex items-center space-x-3">
        <StatusIcon className={`w-5 h-5 ${config.color}`} />
        <span className={`text-sm ${config.color}`}>{file ? `${config.text} - ${file.name}` : config.text}</span>
      </div>
    </div>
  );
};

// Application Success Component with Confetti and Congratulations Modal
const ApplicationSuccess = ({
  numberOfJobs,
  remainingCredits,
  jobType,
  location,
  userId,
  setShowCongratsModal, // Added prop
}) => {
  const [showPricing, setShowPricing] = useState(false);

  useEffect(() => {
    if (remainingCredits > 0) {
      // Launch confetti
      const duration = 3 * 1000;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
      const animationEnd = Date.now() + duration;

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();
        if (timeLeft <= 0) {
          clearInterval(interval);
          return;
        }

        const particleCount = 50 * (timeLeft / duration);
        confetti({
          ...defaults,
          particleCount,
          origin: { x: Math.random(), y: Math.random() - 0.2 },
        });
      }, 250);

      // Show Congratulations Modal
      setShowCongratsModal(true);

      return () => clearInterval(interval);
    }
  }, [remainingCredits, setShowCongratsModal]); // Included setShowCongratsModal

  if (remainingCredits <= 0) {
    return (
      <>
        {showPricing ? (
          <Modal onClose={() => setShowPricing(false)}>
            <PricingCard
              userId={userId}
              onClose={() => setShowPricing(false)}
              onSuccess={(newCredits) => {
                // Handle success - maybe refresh credits count
                setShowPricing(false);
              }}
            />
          </Modal>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center p-6"
          >
            <div className="mb-8">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="mx-auto w-16 h-16 bg-red-100 rounded-full flex items-center justify-center"
              >
                <AlertTriangle className="w-8 h-8 text-red-500" />
              </motion.div>
              <h2 className="text-2xl font-bold mt-4 mb-2 text-red-600">Insufficient Credits</h2>
              <p className="text-gray-600">You need more credits to submit {numberOfJobs} applications</p>
            </div>

            <div className="mt-8 bg-red-50 rounded-lg p-6 text-left">
              <h3 className="font-semibold text-red-800 mb-3">What you need to do:</h3>
              <ul className="space-y-3">
                <li className="flex items-center text-red-700">
                  <AlertTriangle className="w-5 h-5 mr-2" />
                  Purchase additional credits to proceed
                </li>
                <li className="flex items-center text-red-700">
                  <AlertTriangle className="w-5 h-5 mr-2" />
                  Your application settings will be saved
                </li>
                <li className="flex items-center text-red-700">
                  <AlertTriangle className="w-5 h-5 mr-2" />
                  Resume submission once credits are added
                </li>
              </ul>
            </div>

            <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => setShowPricing(true)}
                className="px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 
                                text-white rounded-lg hover:from-blue-600 hover:to-blue-700 
                                transition-all duration-200 flex items-center justify-center 
                                shadow-lg transform hover:scale-105"
              >
                <ShoppingCart className="w-5 h-5 mr-2" />
                View Pricing Plans
              </button>
              <button
                onClick={() => setShowPricing(true)}
                className="px-6 py-3 bg-gradient-to-r from-green-500 to-green-600 
                                text-white rounded-lg hover:from-green-600 hover:to-green-700 
                                transition-all duration-200 flex items-center justify-center 
                                shadow-lg transform hover:scale-105"
              >
                <Link2 className="w-5 h-5 mr-2" />
                Quick Purchase
              </button>
            </div>

            <div className="mt-6 p-4 bg-gray-50 rounded-lg">
              <p
                className="text-gray-600 cursor-pointer hover:text-gray-800"
                onClick={() => {
                  window.location.href = `mailto:roboapplyai@gmail.com?subject=${encodeURIComponent('Support Request')}`;
                }}
              >
                Need help? Contact our support team at roboapplyai@gmail.com
              </p>
            </div>
          </motion.div>
        )}
      </>
    );
  }

  return (
    <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} className="text-center p-6">
      <div className="mb-8">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center"
        >
          <Sparkles className="w-8 h-8 text-blue-500" />
        </motion.div>
        <h2 className="text-2xl font-bold mt-4 mb-2">Applications Started! 🚀</h2>
        <p className="text-gray-600">
          We're submitting {numberOfJobs} {jobType} applications in {location}
        </p>
      </div>

      <div className="mt-4 p-4 bg-blue-50 rounded-lg">
        <div className="animate-pulse flex space-x-2 items-center justify-center mb-3">
          <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
          <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
          <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
        </div>
        <p className="text-blue-700">Your applications are being processed...</p>
      </div>

      <div className="mt-8 bg-blue-50 rounded-lg p-6 text-left">
        <h3 className="font-semibold text-blue-800 mb-3">What's happening now:</h3>
        <ul className="space-y-3">
          <li className="flex items-center text-blue-700">
            <CheckCircle className="w-5 h-5 mr-2" />
            Submitting your resume to matching positions
          </li>
          <li className="flex items-center text-blue-700">
            <CheckCircle className="w-5 h-5 mr-2" />
            Using your saved credentials
          </li>
          <li className="flex items-center text-blue-700">
            <CheckCircle className="w-5 h-5 mr-2" />
            You'll receive email confirmations as applications are submitted
          </li>
        </ul>
      </div>

      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <p className="text-gray-600">
          You can safely close this window - we'll continue processing your applications in the background.
        </p>
      </div>

      <div className="mt-4 bg-green-50 px-4 py-3 rounded-lg inline-block">
        <span className="text-green-700 font-medium">{remainingCredits} Credits Remaining</span>
      </div>
    </motion.div>
  );
};

// Main Application Form Component
const CreateApplicationForm = ({
  handleSubmit,
  loading = false,
  error = null,
  activelyApplying = false,
  userId,
  most_recent_resume_link,
  userEmail,
  diceEmail,
  setShowPricingScreen,
  setShowPricingModal,
  hasActiveCampaign,
}) => {
  const { addToast } = useToast();
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [formComplete, setFormComplete] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false); // Defined here

  // Form Fields State
  const [jobType, setJobType] = useState('');
  const [customJobType, setCustomJobType] = useState('');
  const [numberOfJobs, setNumberOfJobs] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  // Resume Upload State
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isResumeUploaded, setIsResumeUploaded] = useState(Boolean(most_recent_resume_link));
  const [remainingCredits, setRemainingCredits] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Step Configuration
  const formSteps = [
    {
      title: 'Resume',
      icon: FileText,
      validation: () => isResumeUploaded || most_recent_resume_link,
    },
    {
      title: 'Job Details',
      icon: Briefcase,
      validation: () => jobType && selectedLocation && numberOfJobs,
    },
  ];

  // Fetch credits on mount and after form submission
  useEffect(() => {
    let isMounted = true;

    const fetchCredits = async () => {
      if (!userId) return;

      try {
        const { data, error } = await getCreditsByUserId(userId);

        if (error) throw error;

        if (isMounted) {
          setRemainingCredits(data?.credits || 0);
        }
      } catch (error) {
        console.error('Error fetching credits:', error);
        if (isMounted) {
          addToast({
            message: {
              title: 'Error',
              description: 'Failed to fetch credit balance',
            },
            type: 'error',
          });
        }
      }
    };

    fetchCredits();

    return () => {
      isMounted = false;
    };
  }, [userId, addToast]); // Included 'addToast', removed 'supabase'

  // Handle form completion check
  useEffect(() => {
    const newCompletedSteps = {
      0: isResumeUploaded || Boolean(most_recent_resume_link),
      1: Boolean(jobType) && Boolean(selectedLocation) && Boolean(numberOfJobs),
    };

    setCompletedSteps(newCompletedSteps);
    setFormComplete(Object.values(newCompletedSteps).every(Boolean));
  }, [isResumeUploaded, most_recent_resume_link, jobType, selectedLocation, numberOfJobs]);

  // Password Retrieval Function
  const retrievePasswordFromSupabase = async () => {
    try {
      const { data, error } = await getDicePasswordByUserId(userId);

      if (error) {
        throw new Error('Failed to retrieve password from database');
      }
      if (!data) return;

      return data.dice_password;
    } catch (error) {
      console.error('Password retrieval error:', error);
      addToast({
        message: {
          title: 'Credential Error',
          description: 'Unable to retrieve account credentials. Please contact support.',
        },
        type: 'error',
      });
      throw error;
    }
  };

  // Resume Upload Handler
  const handleResumeUpload = (e) => {
    setUploadStatus('uploading');
    const file = e?.target?.files[0];
    if (!file) {
      setUploadStatus('idle'); // Reset status if no file is selected
      return;
    }

    const allowedTypes = ['.pdf', '.doc', '.docx'];
    const fileExt = `.${file.name.split('.').pop().toLowerCase()}`;

    if (!allowedTypes.includes(fileExt)) {
      toast.error('Please upload a PDF, DOC, or DOCX file');
      setUploadStatus('idle'); // Reset status on error
      return;
    }

    setUploadedFile(file);
    toast.success('Resume selected successfully!');
    setUploadStatus('success');
    setIsResumeUploaded(true); // Now used
  };

  // Form Navigation Handler
  const handleStepNavigation = (direction) => {
    if (direction === 'next' && currentStep < formSteps.length - 1) {
      if (formSteps[currentStep].validation()) {
        setCurrentStep((prevStep) => prevStep + 1);
        setValidationErrors({});
      } else {
        setValidationErrors({
          [currentStep]: 'Please complete all required fields',
        });
        addToast({
          message: {
            title: 'Validation Error',
            description: 'Please complete all required fields',
          },
          type: 'error',
        });
      }
    } else if (direction === 'back' && currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Final Form Submission Handler
  const handleFormSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();

    try {
      // Fetch latest credit balance
      const { data: creditData } = await getCreditsByUserId(userId);

      const currentCredits = creditData?.credits || 0;
      setRemainingCredits(currentCredits);
      const requestedJobs = parseInt(numberOfJobs);
      if (currentCredits < requestedJobs) {
        // Insufficient credits
        setShowSuccess(false);

        addToast({
          message: {
            title: 'Insufficient Credits',
            description: 'Please purchase more credits to continue.',
          },
          type: 'warning',
        });
        setIsSubmitting(false);
        setShowPricingModal(true);
        return;
      }

      // Proceed with application if credits are available
      const password = await retrievePasswordFromSupabase();
      const emailToUse = diceEmail || userEmail;

      const applicationData = {
        user_id: userId,
        email: emailToUse,
        password: password,
        job_type: jobType === 'Other' ? customJobType : jobType,
        num_jobs: parseInt(numberOfJobs),
        location: selectedLocation,
        resume_url: uploadedFile ? URL.createObjectURL(uploadedFile) : most_recent_resume_link, // Ensure a valid URL
      };

      // Trigger success state
      setShowSuccess(true);

      // Show success toast
      addToast({
        message: {
          title: 'Applications Started',
          description: `Started processing ${numberOfJobs} applications`,
        },
        type: 'success',
      });

      // Call the handleSubmit prop if provided
      if (handleSubmit) {
        handleSubmit(event, applicationData);
      }

      // Optionally, start the application process
      // const result = await submitJobApplication(applicationData);
      // console.log('Application submitted successfully:', result);
    } catch (error) {
      setIsSubmitting(false);
      console.error('Submission error:', error);
      addToast({
        message: {
          title: 'Submission Error',
          description: error.message || 'Failed to start job applications',
        },
        type: 'error',
      });
      setShowSuccess(false);
    }
  };

  // Render Step Content
  const renderStepContent = () => {
    switch (currentStep) {
      case 0: // Resume Upload Step
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
          >
            {most_recent_resume_link && (
              <div className="p-4 bg-green-50 rounded-lg border border-green-200">
                <div className="flex items-center space-x-3">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                  <div>
                    <h3 className="font-medium text-green-700">Existing Resume Available</h3>
                    <p className="text-sm text-green-600">Your previously uploaded resume is ready to use</p>
                  </div>
                </div>
              </div>
            )}

            <div className="w-full max-w-2xl mx-auto">
              <label
                htmlFor="resume-upload"
                className="flex items-center justify-center w-full px-6 py-4 
                                bg-blue-600 text-white rounded-xl font-medium 
                                hover:bg-blue-700 transition-colors cursor-pointer"
              >
                <ArrowUpCircle className="w-6 h-6 mr-2" />
                {most_recent_resume_link ? 'Upload New Resume' : 'Upload Your Resume'}
              </label>
              <input
                type="file"
                id="resume-upload"
                accept=".pdf,.doc,.docx"
                onChange={handleResumeUpload}
                className="hidden"
              />
            </div>

            {uploadStatus !== 'idle' && <ResumeStatus status={uploadStatus} file={uploadedFile} />}
          </motion.div>
        );

      case 1: // Job Details Step
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="space-y-6"
          >
            <div className="space-y-4">
              {/* Job Type Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Job Type</label>
                <div className="relative">
                  <Briefcase className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <select
                    value={jobType}
                    onChange={(event) => {
                      setJobType(event.target.value);
                      if (event.target.value !== 'Other') {
                        setCustomJobType('');
                      }
                    }}
                    className="pl-10 w-full p-3 border border-gray-300 rounded-lg 
                                        focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Job Type</option>
                    {APPLICATION_CONFIG.JOB_TYPES.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                    <option value="Other">Other (Custom)</option>
                  </select>
                </div>

                <AnimatePresence>
                  {jobType === 'Other' && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="mt-2"
                    >
                      <input
                        type="text"
                        value={customJobType}
                        onChange={(event) => setCustomJobType(event.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg 
                                                focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter custom job title"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Location Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <select
                    value={selectedLocation}
                    onChange={(event) => setSelectedLocation(event.target.value)}
                    className="pl-10 w-full p-3 border border-gray-300 rounded-lg 
                                        focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Location</option>
                    {APPLICATION_CONFIG.LOCATIONS.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Number of Applications Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Number of Applications</label>
                <div className="relative">
                  <Hash className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                  <select
                    value={numberOfJobs}
                    onChange={(event) => setNumberOfJobs(event.target.value)}
                    className="pl-10 w-full p-3 border border-gray-300 rounded-lg 
                                        focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Number of Applications</option>
                    {APPLICATION_CONFIG.APPLICATION_COUNTS.map((count) => (
                      <option key={count} value={count}>
                        {count} applications
                      </option>
                    ))}
                  </select>
                </div>

                {numberOfJobs && (
                  <div className="mt-2 text-sm text-blue-600 flex items-center">
                    <InfoIcon className="w-4 h-4 mr-1" />
                    <span>Will use {numberOfJobs} credits</span>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        );

      default:
        return null;
    }
  };

  // Final Component Render

  if (hasActiveCampaign) {
    return <CampaignInProgressMessage isVisible={true} />;
  }

  return (
    <div className="bg-white w-full p-6 rounded-xl shadow-sm border border-gray-200">
      {showSuccess ? (
        <ApplicationSuccess
          userId={userId}
          numberOfJobs={numberOfJobs}
          remainingCredits={remainingCredits}
          jobType={jobType === 'Other' ? customJobType : jobType}
          location={selectedLocation}
          setShowCongratsModal={setShowCongratsModal} // Passed here
        />
      ) : (
        <>
          <StepIndicator steps={formSteps} currentStep={currentStep} completedSteps={completedSteps} />

          <AnimatePresence mode="wait">{renderStepContent()}</AnimatePresence>

          <div className="flex justify-between mt-8">
            <button
              onClick={() => handleStepNavigation('back')}
              disabled={currentStep === 0}
              className={`
                            flex items-center gap-2 px-6 py-3 rounded-lg font-medium
                            ${currentStep === 0
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }
                        `}
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>

            {currentStep === formSteps.length - 1 ? (
              <button
                onClick={handleFormSubmit}
                disabled={loading || activelyApplying || !formComplete || isSubmitting}
                className={`
                                flex items-center gap-2 px-6 py-3 rounded-lg font-medium
                                ${loading || activelyApplying || !formComplete
                    ? 'bg-gray-400 text-white cursor-not-allowed'
                    : 'bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700'
                  }
                            `}
              >
                {loading ? (
                  <motion.div animate={{ rotate: 360 }} transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}>
                    <RefreshCw className="w-5 h-5" />
                  </motion.div>
                ) : isSubmitting ? (
                  'Applying...'
                ) : (
                  <>
                    Start Applying
                    <ArrowRight className="w-4 h-4" />
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={() => handleStepNavigation('next')}
                disabled={!formSteps[currentStep].validation()}
                className="flex items-center gap-2 px-6 py-3 bg-blue-500 text-white 
                                rounded-lg font-medium hover:bg-blue-600 
                                disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
                <ArrowRight className="w-4 h-4" />
              </button>
            )}
          </div>

          {/* Credit Display */}
          <div className="mt-4 flex justify-end">
            <div className="bg-gray-50 px-4 py-2 rounded-lg flex items-center space-x-2">
              <span className="text-gray-600">Available Credits:</span>
              <span className="font-medium text-blue-600">{remainingCredits}</span>
            </div>
          </div>

          {/* Error Messages */}
          <AnimatePresence>
            {(error || validationErrors[currentStep]) && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-600"
              >
                <AlertTriangle className="w-5 h-5" />
                <span>{error || validationErrors[currentStep]}</span>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}

      {/* Congratulations Modal */}
      {showCongratsModal && (
        <Modal onClose={() => setShowCongratsModal(false)}>
          <div className="p-6 text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-2">Congratulations!</h2>
            <p className="text-gray-600">
              Your campaign has been successfully launched. 🎉
            </p>
            <button
              onClick={() => setShowCongratsModal(false)}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CreateApplicationForm;
