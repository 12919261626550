import {
  registerUserProfile,
  postApplication,
  validateDiceCredentials,
  postPurchaseCredits,
  warmupRoute,
} from '../api/apiClient';
import { createUserProfile, updateUserProfile } from '../api/supabaseClient';
import { supabase } from '../supabaseConfig';
import { useCallback } from 'react';
import { convertKeysToSnakeCase } from '../utilities/caseUtils';
import { NEW_USER_CREDIT_AMOUNT, NEW_USER_FREE_CREDIT_AMOUNT } from '../utilities/constants';

export const postCampaignAction = async ({ request }) => {
  console.log('request: ', request);
  if (request?.actionType) {
    console.log('postCampaignAction request.actionType: ', request.actionType);
  }
  console.log('postCampaignAction: ', request);
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  console.log('PostCampaignAction data', data);
  try {
    const response = await postApplication(data);
    console.log('response: ', response);
    if (!response) {
      return { error: 'Failed to create campaign.' };
    }
    return { success: true, campaign: response.data };
  } catch (error) {
    return { error: error.message || 'Failed to create campaign.' };
  }
};

export const createUserProfileAction = async ({ request, ...params }) => {
  // TODO: check if request is FormData
  // TODO: refactor to move error handling to the API client, catching them below to return the error message or act accordingly
  console.log('createUserProfileAction params: ', params);
  console.log('createUserProfileAction request: ', request);
  const formData = await request.formData();
  const userId = formData.get('id');

  formData.append('credits', NEW_USER_FREE_CREDIT_AMOUNT);

  if (!userId) {
    return { success: false, error: '', message: 'Invalid User ID. Please refresh and try again.' };
  }
  try {
    const attemptWarmup = async (retryCount = 0) => {
      try {
        await warmupRoute({});
        return true;
      } catch (error) {
        console.warn(`Warmup attempt ${retryCount + 1} failed:`, error.message);
        if (retryCount < 3) {
          await new Promise((resolve) => setTimeout(resolve, 1000 * (retryCount + 1)));
          return attemptWarmup(retryCount + 1);
        }
        console.warn('Warmup failed after 3 attempts.');
        return false;
      }
    };

    const isWarmedUp = await attemptWarmup();
    //TODO: if warmup fails, return error
    if (!isWarmedUp) {
      throw new Error('Failed to warm up server. Please try again');
    }
    const username = formData.get('username');

    const { error: profileError } = await createUserProfile({ id: userId, username });

    if (profileError) throw profileError;

    formData.append('user_id', userId);
    // Post registration data
    const response = await registerUserProfile(formData);

    if (response?.data?.success) {
      // Update profile with additional information
      // TODO: handle adding response data in the backend.
      const updateProfileResponse = await updateUserProfile(userId, {
        dice_password: response?.data?.password,
        already_had_dice_ind: false,
        has_profile: true,
      });

      const { error: updateError, data, ...restRes } = updateProfileResponse;
      console.log('UpdateProfileResponse: ', updateProfileResponse);

      if (updateError) throw updateError;

      return { success: true, message: 'Profile created successfully.' };
    }
  } catch (error) {
    // Check if the error response indicates an existing Dice account
    const hasExistingDiceAccount =
      error.response?.data?.detail?.includes('Dice') || error.response?.data?.detail?.includes('error'); //TODO: check latter of the condition
    if (error.response?.data?.detail?.includes('error')) {
      console.log(
        "IF NOT DICE ERROR REMOVE FROM CONDITIONAL: error.response.data.detail.includes('error') : ",
        error.response.data.detail,
      );
    }

    if (!hasExistingDiceAccount) {
      return {
        success: false,
        error: error,
        message: 'Failed to create profile. Please try again.',
      };
    }

    // Update profile to indicate existing Dice account
    const { error: updateError } = await updateUserProfile(userId, {
      already_had_dice_ind: true,
    });

    if (updateError) {
      console.error('Error updating profile:', updateError);
      return { success: false, error: updateError, message: 'Failed to update profile.' };
    }

    return {
      success: true,
      message: 'Existing Dice account detected',
      showExistingDiceLoginForm: true,
    };
  }
};

export const purchaseCreditsAction = async ({ sku, userId }) => {
  try {
    const response = await postPurchaseCredits({ sku: sku, userId: userId });
    if (response?.ok === true) {
      const data = await response.json();
      console.log('Parsed Response JSON:', data);

      return { success: true, message: 'Credits purchased successfully!', data: data };
    }

    return { error: 'Sorry, we have failed to take you to Stripe for payment. Please try again later ', response };
  } catch (error) {
    return { error: error.message || 'Failed to purchase credits.' };
  }
};

export const verifyPurchaseAction = async ({ request }) => {};

export async function postExistingDiceLoginAction({ request }) {
  const formData = await request.formData();
  console.log('Object.entries(formData): ', Object.entries(formData));
  const credentials = {
    email: formData.get('email'),
    diceSpecificEmail: formData.get('diceEmail'),
    dicePassword: formData.get('dicePassword'),
    userId: formData.get('userId'),
  };

  const response = await validateDiceCredentials(credentials.diceSpecificEmail, credentials.dicePassword);

  if (response?.data?.status !== 'success') {
    return { error: 'Invalid Dice credentials' };
  }

  const newProfileData = {
    email: credentials.email,
    dice_password: credentials.dicePassword,
    dice_specific_email: credentials.diceSpecificEmail,
    already_had_dice_ind: true,
    has_profile: true,
  };

  const { error: updateError } = updateUserProfile(credentials.userId, newProfileData);

  if (updateError) {
    console.error('Error updating profile:', updateError);
    return { error: 'Error updating profile' };
  } else {
    console.log('response: ', response);
    return { success: true, actionType: 'dice-success', message: 'Dice credentials validated successfully!' };
  }
}
